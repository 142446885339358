import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from "../../../api/axiosConfigRoute";
import SisprestamosNavbar from "../../SisprestamosNavbar";

class AnularGasto extends Component {
  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarEmpresa = () => {
    const url = `${api}/empresa/get`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ EmpresaA: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  constructor() {
    super();
    this.state = {
      ALIAS: "",
      COD_NIVEL: "",
      ID_ROL: "",
      COD_ROL_SUC: "",
      FECHA_SERVER: "",

      SucursalA: [],
      EmpresaA: [],

      COD_EMPRESA_B: "",
      COD_SUCURSAL_B: "",
      NO_RECIBO:"",

      NombreEmpresa:"",
      NombreSucursal:"",
      COD_EGRESO: "",
      NO_COMPROBANTE:"",
      FECHA: "",
      FechaFormato:"",
      PagoA:"",
      Concepto:"",
      mensaje: "",
      block: "true",
      color: "white",

      A_EMPRESA:  "",
      SucursalComp:  "",
      NO_COMPROBANTE_C:  "",
      Tipo_doc:  "",
      FECHA_SAVE_COMPROBANTE:  "",
      TIPO_DE_CAMBIO:  "",
      rowData:  "",

      PERMISO_ROL: "1044",
      VALIDACION: "hidden",
      BTN_APL: "true",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        COD_NIVEL: res.data.COD_NIVEL,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
        FECHA_SERVER: res.data.FECHA_SERVER,
      });
      let tiempo = "300";
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
        this.consultarEmpresa();
        this.consultarSucursal();
      }, tiempo);
    });
  }

  getdatos = () => {
    return axios
      .get(
        `${api}/anular/gasto/${this.state.NO_RECIBO}/${this.state.COD_EMPRESA_B}/${this.state.COD_SUCURSAL_B}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  };


  obtenerDatos = (e) => {
    if (this.state.VALIDACION === "visible") {
      if (this.state.NO_RECIBO === "") {
        e.preventDefault();
        Swal.fire(
          "Anulacion de Gastos",
          "Por Favor Agregue un Numero de Recibo",
          "error"
        );
      } else if (this.state.COD_EMPRESA_B === "") {
        e.preventDefault();
        Swal.fire(
          "Anulacion de Gastos",
          "Por Favor Seleccione Una Empresa",
          "error"
        );
      } else if (this.state.COD_SUCURSAL_B === "") {
        e.preventDefault();
        Swal.fire(
          "Anulacion de Gastos",
          "Por Favor Seleccione Una Sucursal",
          "error"
        );
      } else {
        e.preventDefault();
        this.getdatos().then((res) => {
          this.setState({
            NombreEmpresa:res.data.NombreEmpresa,
            NombreSucursal:res.data.NombreSucursal,
            COD_EGRESO: res.data.COD_EGRESO,
            NO_COMPROBANTE:res.data.NO_COMPROBANTE,
            FECHA: res.data.FECHA,
            FechaFormato:res.data.FechaFormato,
            PagoA:res.data.PagoA,
            Concepto:res.data.Concepto,
            mensaje: res.data.mensaje,
            block: res.data.block,
            color: res.data.color,
          });
          TimerMixin.setTimeout(() => {
            if (parseInt(res.data.NO_COMPROBANTE ) > 0 ) {
                this.DATOS_COMPROBANTE();
            }
          }, 3000);
        });
      }
    } else {
      window.alert("No tiene permisos para usar este modulo");
    }
  };

  BTN_APL_DESAC() {
    this.setState({
      BTN_APL: "false",
      block: "false",
    });
  }

  Anular = (anularGasto) => {
    return axios
      .post(`${api}/anular/anulargast`, {
        USUARIO: anularGasto.USUARIO,
        COD_EGRESO: anularGasto.COD_EGRESO,
        FECHA: anularGasto.FECHA,
      })
      .then((response) => {
        if ( parseInt(this.state.NO_COMPROBANTE) > parseInt(0)){
          this.Reversion_comprobante();
        }else{
            Swal.fire(
                "Anulacion de Gastos",
                response.data.mensaje,
                response.data.alerta
              );

              this.setState({
                COD_EMPRESA_B: "",
                COD_SUCURSAL_B: "",
                NO_RECIBO:"",
          
                NombreEmpresa:"",
                NombreSucursal:"",
                COD_EGRESO: "",
                NO_COMPROBANTE:"",
                FECHA: "",
                FechaFormato:"",
                PagoA:"",
                Concepto:"",
                mensaje: "",
                color: "white",
                BTN_APL: "true",
                block: "true",

                A_EMPRESA:  "",
                SucursalComp:  "",
                NO_COMPROBANTE_C:  "",
                Tipo_doc:  "",
                FECHA_SAVE_COMPROBANTE:  "",
                TIPO_DE_CAMBIO:  "",
                rowData:  "",
              });
        }
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
     
        window.alert("Verifique los Datos");
      });
  };


  onSubmit(e) {
    if (this.state.COD_EGRESO === "") {
      e.preventDefault();
      Swal.fire(
        "Anulacion de Gastos",
        "Por Favor Busque Los Datos Del Recibo",
        "error"
      );
    } else {
      e.preventDefault();
      Swal.fire({
        title: "Desea Anular El Gasto",
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.BTN_APL_DESAC();
          let timerInterval;
          TimerMixin.setTimeout(() => {
            Swal.fire({
              title: "Anulacion del Gasto",
              html: "Los Datos se Estan Anulando",
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
            });
            const anularGasto = {
              USUARIO: this.state.ALIAS,
              COD_EGRESO: this.state.COD_EGRESO,
              FECHA: this.state.FechaFormato,
            };
            this.Anular(anularGasto).then((res) => {});
          }, 4000);
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    }
  }

  consulta = (DatosComprobante) => {
    return axios
      .post(`${api}/banco/reimpresion_movim_c_conta`, {
        NO_COMPROBANTE: DatosComprobante.NO_COMPROBANTE,
      })
      .then((response) => {
        this.setState({
          A_EMPRESA: response.data.COD_EMPRESA_C,
          SucursalComp: response.data.COD_SUCURSAL_C,
          NO_COMPROBANTE_C: response.data.NO_COMPROBANTE_C,
          Tipo_doc: response.data.TIPO_DOCUMENTO_C,
          FECHA_SAVE_COMPROBANTE: response.data.fecha_del_comprobante,
          TIPO_DE_CAMBIO: response.data.TIPO_DE_CAMBIO,
          rowData: response.data.Tabla,
        });
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        this.setState({
            block: "false",
            mensaje: "Error al Buscar El Comprobante De gasto"
          });
        Swal.fire(
          "Comprobante",
          "Datos del comprobante no encontrados",
          "error"
        );
      });
  };
  
  DATOS_COMPROBANTE(e) {
    const DatosComprobante = {
      NO_COMPROBANTE: this.state.NO_COMPROBANTE,
    };

    this.consulta(DatosComprobante).then((res) => {});
  }

  Reversion = (Data) => {
    return axios
      .post(`${api}/contabilidad/revertir_comprobante`, {
        COD_EMPRESA: Data.COD_EMPRESA,
        COD_SUCURSAL:  Data.COD_SUCURSAL,
        NO_COMPROBANTE:  Data.NO_COMPROBANTE,
        TIP_DOC: Data.TIP_DOC,
        FECHA_REGISTRO:  Data.FECHA_REGISTRO,
        VALOR_T_CAMBIO:  Data.VALOR_T_CAMBIO,
        ADICIONADO_POR:  Data.ADICIONADO_POR,
        DATOS_DATA_GRID:  Data.DATOS_DATA_GRID,
      })
      .then((response) => {

        Swal.fire(
            "Anulacion de Gastos / Reversion de comprobante",
            response.data.mensaje,
            response.data.alerta
          );

          this.setState({
            COD_EMPRESA_B: "",
            COD_SUCURSAL_B: "",
            NO_RECIBO:"",
      
            NombreEmpresa:"",
            NombreSucursal:"",
            COD_EGRESO: "",
            NO_COMPROBANTE:"",
            FECHA: "",
            FechaFormato:"",
            PagoA:"",
            Concepto:"",
            mensaje: "",
            color: "white",
            BTN_APL: "true",
            block: "true",

            A_EMPRESA:  "",
            SucursalComp:  "",
            NO_COMPROBANTE_C:  "",
            Tipo_doc:  "",
            FECHA_SAVE_COMPROBANTE:  "",
            TIPO_DE_CAMBIO:  "",
            rowData:  "",
          });
       
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Swal.fire("Reversion de comprobante", "Error de registro", "error");
      });
  };


  Reversion_comprobante(e) {
      let tiempo = "500";
      TimerMixin.setTimeout(() => {
            const Data = {
                COD_EMPRESA: this.state.A_EMPRESA,
                COD_SUCURSAL:  this.state.SucursalComp,
                NO_COMPROBANTE:  this.state.NO_COMPROBANTE_C,
                TIP_DOC:  this.state.Tipo_doc,
                FECHA_REGISTRO:  this.state.FECHA_SAVE_COMPROBANTE,
                VALOR_T_CAMBIO:  this.state.TIPO_DE_CAMBIO,
                ADICIONADO_POR:  this.state.ALIAS,
                DATOS_DATA_GRID:  this.state.rowData,
            };

            this.Reversion(Data).then((res) => {});
          }, tiempo);
  }

  render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}>
        <SisprestamosNavbar />
        <div className="busqueda container">
          <div className="shadow-lg mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
            <div className="row">
              <div className="col-md-3">
                <div style={{ textAlign: "left" }} htmlFor="COD_EMPRESA_B">
                  EMPRESA
                </div>
                <select
                  name="COD_EMPRESA_B"
                  className="form-control text-center"
                  value={this.state.COD_EMPRESA_B}
                  onChange={this.onChange}
                  disabled={this.state.BTN_APL === "false" ? true : false}
                >
                  <option></option>
                  {this.state.EmpresaA.map((item) => (
                    <option key={item.COD_EMPRESA} value={item.COD_EMPRESA}>
                      {item.NOMBRE_EMPRESA}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <div style={{ textAlign: "left" }} htmlFor="COD_SUCURSAL_B">
                  SUCURSAL
                </div>
                <select
                  name="COD_SUCURSAL_B"
                  className="form-control text-center"
                  value={this.state.COD_SUCURSAL_B}
                  onChange={this.onChange}
                  placeholder="Campo obligatorio"
                  disabled={this.state.BTN_APL === "false" ? true : false}
                >
                  <option></option>
                  {this.state.SucursalA.map((item) => (
                    <option key={item.COD_SUCURSAL} value={item.COD_SUCURSAL}>
                      {item.NOMBRE_SUCURSAL}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="NO_RECIBO">
                  NO.RECIBO
                </div>
                <input
                  type="NO_RECIBO"
                  className="form-control text-right"
                  name="NO_RECIBO"
                  placeholder="No.Recibo.Ejemplo: 718"
                  value={this.state.NO_RECIBO}
                  onChange={this.onChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group col-md-2 p-4">
                <button
                  onClick={this.obtenerDatos}
                  className="btn btn-outline-info"
                  disabled={this.state.BTN_APL === "false" ? true : false}
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mt-3 mx-auto">
                  <h1 className="h3 mb-4 font-weight-normal  text-center">
                    DATOS DEL GASTO
                  </h1>
                  <div align="end" className="col-md-12 mb-2">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        onClick={this.onSubmit}
                        className="btn btn-outline-success btn-lg"
                        disabled={this.state.block === "false" ? true : false}
                      >
                        ANULAR
                      </button>
                    </div>
                  </div>
                  <div className="container jumbotrom p-3 mb-2 bg-light text-dark ">
                    <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-2 bg-light text-dark border rounded border-dark">
                      <div className="form-row">
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          NO.RECIBO{" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          : {this.state.COD_EGRESO}
                        </div>

                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          FECHA{" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          : {this.state.FECHA}
                        </div>

                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          SUCURSAL{" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          : {this.state.NombreSucursal}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12 text-left">
                          PAGO A{" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          : {this.state.PagoA}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12 text-left">
                         CONCEPTO {" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          : {this.state.Concepto}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12 text-left">
                          VERIFICACION{" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          :{" "}
                          <font color={this.state.color}>
                            {this.state.mensaje}
                          </font>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default AnularGasto;

import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import jsPDF from 'jspdf'
import autoTable from "jspdf-autotable";
import Swal from "sweetalert2";
// import { registerLocale } from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import es from "date-fns/locale/es";
// import moment from "moment-timezone";
// registerLocale("es", es);
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";


class Estado_de_cuenta extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };
    
      consultarEmpresa = () => {
        const url = `${api}/empresa/get`;
        //console.log(url);
        fetch(url)
          .then((respuesta) => respuesta.json())
          .then((resultado) => this.setState({ EmpresaA: resultado }));
      };

      consultarCliente = () => {
        const url = `${api}/credito/datos/${this.state.COD_EMPRESA_B}/${this.state.COD_SUCURSAL_B}/`;
        //console.log(url);
        fetch(url)
          .then((respuesta) => respuesta.json())
          .then((resultado) => this.setState({ ClienteA: resultado }));
      };

 
  constructor() {
    super();
    this.state = {
        ALIAS:"",
        Tipo_usuario:"",
        sucursal:"",
        COD_REGION:"",
        COD_NIVEL:"",
        NOMBRE_SUCURSAL_USER:"",
        ID_ROL:"",
        COD_ROL_SUC: "",

        SucursalA:[],
        EmpresaA:[],
        ClienteA:[],

        COD_EMPRESA_B:"",
        COD_SUCURSAL_B:"",
        CLIENTE:"",

        COD_CREDITO:"",
        COD_EMPRESA:"",
        COD_SUCURSAL:"",
        NOMBRE_EMPRESA:"",
        NOMBRE_SUCURSAL:"",
        FECHA_DESEMBOLSO:"",
        COD_CLIENTE:"",
        NOMBRE1:"",
        NOMBRE_ANALISTA:"",
        MONTO_TOTAL_APROBADO:"",
        PLAZO_APROBADO:"",
        TIC_APROBADO:"",
        INTERESES_PAGAR:"",
        FRECUENCIA_PAGO:"",
        FECHA_VENCIMIENTO:"",
        DESEMB_PRINCIP:"",
        TOTAL_PAGADO:"",
        OTROS_CARGOS:"0",
        PAGO_EXTRA:"",
        TOTAL_RECIBIDO:"0",
        SALDO_FINAL:"",

        TOTAL_CORDOBAS:"0",
        TOTAL_DOLARES:"0",

        DIAS_MORA:"0",
        VALOR_CUOTA:"0",
        MONTO_MORA:"0",

        rowDataRecibos:[],

        PERMISO_ROL: "1033",
        VALIDACION: "hidden",
  errors: {},
    };
    this.onChange = this.onChange.bind(this); 
    this.BUSQUEDA = this.BUSQUEDA.bind(this); 
    this.obtenerdatos = this.obtenerdatos.bind(this); 
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  BUSQUEDA(e) {
    this.setState({ [e.target.name]: e.target.value });
  
    var tiempo = '300'
      TimerMixin.setTimeout(() => {
        if (this.state.COD_EMPRESA_B !== "" && this.state.COD_SUCURSAL_B !== "") {
          this.consultarCliente();
        }
        
      }, tiempo);
  }
  

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS : res.data.Alias,
        Tipo_usuario : res.data.Tipo_usuario,
        COD_REGION : res.data.COD_REGION,
        COD_NIVEL : res.data.COD_NIVEL,
        NOMBRE_SUCURSAL_USER : res.data.COD_SUCURSAL,
        ID_ROL : res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
      });
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
      }, 300);
      var tiempo = '300'
      TimerMixin.setTimeout(() => {
        this.consultarEmpresa();
        this.consultarSucursal();
      }, tiempo);
    });
  }
  

  consulta = (dato) => {

    return axios
      .post(`${api}/credito/estado_cuenta_credito`, {
        COD_CREDITO: dato.COD_CREDITO,
        COD_SUCURSAL_B:dato.COD_SUCURSAL_B
      })
      .then((response) => {
        this.setState({
    COD_CREDITO:response.data.COD_CREDITO,
    COD_EMPRESA:response.data.COD_EMPRESA,
    COD_SUCURSAL:response.data.COD_SUCURSAL,
    NOMBRE_EMPRESA:response.data.NOMBRE_EMPRESA,
    NOMBRE_SUCURSAL:response.data.NOMBRE_SUCURSAL,
    FECHA_DESEMBOLSO:response.data.FECHA_DESEMBOLSO,
    COD_CLIENTE:response.data.COD_CLIENTE,
    NOMBRE1:response.data.NOMBRE1,
    NOMBRE_ANALISTA:response.data.NOMBRE_ANALISTA,
    MONTO_TOTAL_APROBADO:response.data.MONTO_TOTAL_APROBADO,
    PLAZO_APROBADO:response.data.PLAZO_APROBADO,
    TIC_APROBADO:response.data.TIC_APROBADO,
    INTERESES_PAGAR:response.data.INTERESES_PAGAR,
    FRECUENCIA_PAGO:response.data.FRECUENCIA_PAGO,
    FECHA_VENCIMIENTO:response.data.FECHA_VENCIMIENTO,
    DESEMB_PRINCIP:response.data.DESEMB_PRINCIP,
    TOTAL_PAGADO:response.data.TOTAL_PAGADO,
    OTROS_CARGOS:response.data.OTROS_CARGOS,
    PAGO_EXTRA:response.data.PAGO_EXTRA,
    TOTAL_RECIBIDO:response.data.TOTAL_RECIBIDO,
    SALDO_FINAL:response.data.SALDO_FINAL,
    rowDataRecibos:response.data.rowDataRecibos,
    TOTAL_CORDOBAS:response.data.TOTAL_CORDOBAS,
    TOTAL_DOLARES:response.data.TOTAL_DOLARES,
    DIAS_MORA:response.data.DIAS_MORA,
    VALOR_CUOTA:response.data.VALOR_CUOTA,
    MONTO_MORA:response.data.MONTO_MORA,
        })

      })
      .catch((err) => {
        console.log(err)
        window.alert("Verifique los Datos");
        window.location.reload();
      });
  };

  obtenerdatos(e) {
    if (this.state.COD_EMPRESA_B === "") {
        e.preventDefault();
        Swal.fire(
              "Estado De Cuenta",
          "Por favor ingrese la empresa",
          "error"
        );
      }  else if (this.state.COD_SUCURSAL_B === "") {
        e.preventDefault();
        Swal.fire(
              "Estado De Cuenta",
          "Por favor ingrese la sucursal",
          "error"
        );
      } else if (this.state.CLIENTE === "") {
        e.preventDefault();
        Swal.fire(
              "Estado De Cuenta",
          "Por favor seleccione un credito",
          "error"
        );
      }else{ 
    e.preventDefault();

    var tiempo = "300";
    TimerMixin.setTimeout(() => {
      const dato = {
        COD_CREDITO: this.state.CLIENTE,
        COD_SUCURSAL_B:this.state.COD_SUCURSAL_B
      };

      this.consulta(dato).then((res) => { });
    }, tiempo);

      }
  }

  printDocument = (e) => {
    e.preventDefault();
    var doc = new jsPDF("l", "pt", "letter");
    doc.text("ESTADO DE CUENTA",310,20)
    doc.autoTable({
      body:[
        ['EMPRESA',':', this.state.NOMBRE_EMPRESA, 'SUCURSAL',':',this.state.NOMBRE_SUCURSAL],
        ['N.CREDITO',':', this.state.COD_CREDITO,  'CLIENTE',':',this.state.NOMBRE1],
        ['FECHA',':', this.state.FECHA_DESEMBOLSO,  'VENCIMIENTO',':',this.state.FECHA_VENCIMIENTO],
        ['SALDO INICIAL',':', this.state.DESEMB_PRINCIP,  'TOTAL PAGADO',':',this.state.TOTAL_PAGADO],
        ['OTROS CARGOS',':',this.state.OTROS_CARGOS, 'PAGO EXTRA',':',this.state.PAGO_EXTRA],
        ['TOTAL RECIBIDO',':',this.state.TOTAL_RECIBIDO, 'SALDO FINAL',':',this.state.SALDO_FINAL],
        ['VALOR CUOTA',':',this.state.VALOR_CUOTA, 'DIAS MORA',':',this.state.DIAS_MORA],
        ['MONTO EN MORA',':',this.state.MONTO_MORA],
      ],theme: 'plain',tableWidth: 'wrap',   margin: { left: 180 },
    })
    autoTable(doc, { html: "#body2" });

    doc.save("ESTADO DE CUENTA.pdf");
};
 
  render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
        <div className="container mt-5">
          <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
            <div className="row">
                
              <div className="col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="COD_EMPRESA_B">
                  EMPRESA
                </div>
                <select
                  name="COD_EMPRESA_B"
                  className="form-control"
                  value={this.state.COD_EMPRESA_B}
                  onChange={this.BUSQUEDA}
                >
                  <option></option>
                  {this.state.EmpresaA.map((item) => (
                    <option key={item.NOMBRE_EMPRESA} value={item.COD_EMPRESA}>
                      {item.NOMBRE_EMPRESA}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="COD_SUCURSAL_B">
                  SUCURSAL
                </div>
                <select
                  name="COD_SUCURSAL_B"
                  className="form-control"
                  value={this.state.COD_SUCURSAL_B}
                  onChange={this.BUSQUEDA}
                  placeholder="Campo obligatorio"
                >
                  <option></option>
                  {this.state.SucursalA.map((item) => (
                    <option
                      key={item.NOMBRE_SUCURSAL}
                      value={item.COD_SUCURSAL}
                    >
                      {item.NOMBRE_SUCURSAL}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="CLIENTE">
                  CLIENTE
                </div>
                <input
                  list="data"
                  name="CLIENTE"
                  className="form-control "
                  value={this.state.CLIENTE}
                  onChange={this.onChange}
                />
                <datalist id="data">
                  <option></option>
                  {this.state.ClienteA.map((item) => (
                    <option key={item.COD_CREDITO} value={item.COD_CREDITO}>
                      {item.NOMBRE1}
                    </option>
                  ))}
                </datalist>
              </div>
            </div>
            <div className="container">
  <div className="row">
    <div className="col-md-4">
      
    </div>
    <div className="col-md-4">
    <div className="btn-group" role="group" aria-label="Basic mixed styles example">
  <button type="button" className="btn btn-success"  onClick={this.obtenerdatos}>BUSCAR</button>
  <button type="button" className="btn btn-danger"  onClick={this.printDocument}>DESCARGAR</button>
</div>
    </div>
    <div className="col-md-4">
      
    </div>
  </div>
</div>
          </div>
        
        </div>

        <div className="container mt-2">
                        <div className="row">
                            <div className="shadow-lg p-2 mb-5 bg-white rounded  col-md-12 mx-auto border rounded border-dark" >
        <div className="container p-3">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <h1 className="h3 mb-0 font-weight-normal  text-center">
        ESTADO DE CUENTA
              </h1>
            </div>
          </div>
          <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-0 bg-light text-dark border rounded border-dark">
                      <div className="form-row">
                       
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          EMPRESA{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            --
                          </font>
                          : {this.state.NOMBRE_EMPRESA}
                        </div>

                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          SUCURSAL{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            ---
                          </font>
                          : {this.state.NOMBRE_SUCURSAL}
                        </div>
    
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          FECHA{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            ---
                          </font>
                          : {this.state.FECHA_DESEMBOLSO}
                        </div>
                      
                      </div>
    
                      <div className="row">
                    
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6 text-left">
                          CLIENTE{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            ----
                          </font>
                          : {this.state.NOMBRE1}
                        </div>

                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6 text-left">
                          ANALISTA{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            --
                          </font>
                          : {this.state.NOMBRE_ANALISTA}
                        </div>

    
                      {/* </div>
                       */}
                    </div>
    
                    <div className="row">

                
                    
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                      PLAZO{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.PLAZO_APROBADO}
                    </div>
    
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-3 text-left">
                      TASA DE INTERES{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.TIC_APROBADO}
                    </div>
                    
                  
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-5 text-left">
                      FRECUENCIA{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.FRECUENCIA_PAGO}
                    </div>

                   
    
                  {/* </div>
                   */}
                </div>
                <div className="row">
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                  SALDO INICIAL{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    ----
                  </font>
                  : {this.state.DESEMB_PRINCIP}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                      TOTAL PAGADO{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ----
                      </font>
                      : {this.state.TOTAL_PAGADO}
                    </div>

                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                      OTROS CARGOS{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.OTROS_CARGOS}
                    </div>
              
                  {/* </div>
                   */}
                </div>

                <div className="row">
                    
                  

                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                      PAGO EXTRA{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.PAGO_EXTRA}
                    </div>
                    
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                   TOTAL RECIBIDO {" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.TOTAL_RECIBIDO}
                    </div>

                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                    SALDO FINAL{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.SALDO_FINAL}
                    </div>
                </div> 
                   <div className="row">
                   <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                   VALOR CUOTA {" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.VALOR_CUOTA}
                    </div>

                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                    DIAS EN MORA{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.DIAS_MORA}
                    </div>

                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                    MONTO EN MORA{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.MONTO_MORA}
                    </div>
                 
              
                </div>
                <div className="row">
                 
                   <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                  VENCIMIENTO{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    ----
                  </font>
                  : {this.state.FECHA_VENCIMIENTO}
                </div>
                 
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                  MONTO DESEMBOLSADO{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    ----
                  </font>
                  : {this.state.MONTO_TOTAL_APROBADO}
                  
                </div>
                </div>


             
       
       
               
                  </div>
        </div>
             <div className="table-responsive"> 
                <table className="table table-striped "id="body2" >
                  <thead className="text-center thead-dark">
                    <tr>
                      <th>No.Recibo</th>
                      <th>Tipo</th>
                      <th>Fecha</th>
                      <th>Cordobas</th>
                      <th>Dolares</th>
                      <th>Tip.Cambio</th>
                      <th>Total</th>
                      <th>Otros Cargos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.rowDataRecibos.map((item) => {
                      return (
                        <tr>
                          <td>{item.NO_RECIBO}</td>
                          <td>{item.TIPO_MOVIMIENTO}</td>
                          <td style={{ width: "10%" }}>{item.FECHA}</td>
                          <td>{parseFloat(item.MONTO_CORDOBA).toFixed(2)}</td>
                          <td>{parseFloat(item.MONTO_DOLAR).toFixed(2)}</td>
                          <td>{parseFloat(item.TIPODECAMBIO).toFixed(2)}</td>
                          <td>{parseFloat(item.TOTAL_RECIBIDO).toFixed(2)}</td>
                          <td>{parseFloat(item.OTROS_CARGOS).toFixed(2)}</td>
                          </tr>
                      );
                    })}
                    <tr>
                    
                         <td  style={{ width: "10%" }}>Total</td>
                         <td className=" text-center"></td>
                         <td className=" text-center"></td>
                         <td className=" text-center">{parseFloat(this.state.TOTAL_CORDOBAS).toFixed(2)}</td>
                         <td className=" text-center">{parseFloat(this.state.TOTAL_DOLARES).toFixed(2)}</td>
                         <td className=" text-center"></td>
                         <td className=" text-center">{parseFloat(this.state.TOTAL_RECIBIDO).toFixed(2)}</td>
                         <td className=" text-center">{parseFloat(this.state.OTROS_CARGOS).toFixed(2)}</td>
                       </tr>
                  </tbody>
                </table>
              </div>
        </div>
          </div>
        </div>
      </form>
    );
}
}
export default Estado_de_cuenta;


import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import "./css/style.css";
import "./css/style.violet.css";

// import  "./css/estilos.css";
class Landing extends Component {
  constructor() {
    super();
    this.state = {
      TEXTO: "SISPRESTAMOS",
      errors: {},
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 500) {
      document.querySelector(".navbar").className =
        "navbar scroll navbar-expand-lg navbar-dark bg-dark bg-primary fixed-top";
    } else {
      document.querySelector(".navbar").className =
        "navbar navbar-expand-lg navbar-dark bg-dark bg-primary fixed-top";
    }
  };

  datosBusqueda = (termino) => {
    console.log(termino);
  };

  logOut(e) {
    localStorage.removeItem("usertoken");
  }
  render() {
    const loginRegLink = (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/login" className="nav-link">
            Login
          </Link>
        </li>
      </ul>
    );
    const userLink = (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/SisprestamosNavbar" className="nav-link">
            SISPRESTAMOS
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/SiscontapresNavbar" className="nav-link">
            SISCONTAPRES
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/SisRRHNavbar" className="nav-link">
            RECURSOS HUMANOS
          </Link>
        </li>

        <li className="nav-item">
          <a href="/" onClick={this.logOut} className="nav-link">
            Salir
          </a>
        </li>
      </ul>
    );
    return (
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark bg-primary fixed-top">
          <div className="container">
        
            <button
              type="button"
              data-toggle="collapse"
              data-target="#navbarcollapse"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              className="navbar-toggler navbar-toggler-right "
            >
              <span className="navbar-toggler-icon"></span>{" "}
              <ion-icon name="menu-outline"></ion-icon>
            </button>
            <div
              id="navbarcollapse"
              className="collapse navbar-collapse justify-content-md-center"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Inicio
                  </Link>
                </li>
              </ul>
              {localStorage.usertoken ? userLink : loginRegLink}
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(Landing);

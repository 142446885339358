import React, { Component } from "react";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";

import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import { PermisoModulo } from "../../GlobalFuntions"; // Usuarios
import { Empresa } from "../../GlobalFuntions"; // Usuarios
import { DatosEmpresa } from "../../GlobalFuntions"; // Usuarios
import { Sucursal } from "../../GlobalFuntions"; // Usuarios

import { RecuperacionCartera } from "../../DataReportes"; // Usuarios

import SisprestamosNavbar from "../../SisprestamosNavbar";

registerLocale("es", es);

class ResumenRecCartera extends Component {
  constructor() {
    super();
    this.state = {
      Id: "",
      ALIAS: "",
      NombreUsuario: "",

      IMAGEN: "",
      width: "",
      height: "",
      REPORT_WIDTH: "",
      REPORT_HEIGHT: "",

      DatoEmpresa: [],
      COD_EMPRESA_B: "",
      DatoSucursal: [],
      COD_SUCURSAL_B: "",

      FECHA_INICIO: "",
      FECHA_FIN: "",

      DataReporte: [],
      TotalMonto: "",
      TotalPago: "",

      PERMISO_ROL: "1046",
      VALIDACION: "hidden",
      BTN_APL: true,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.JSPDF = this.JSPDF.bind(this);
    this.ExcelData = this.ExcelData.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        Id: res.data.COD_USUARIO,
        ALIAS: res.data.Alias,
        NombreUsuario: res.data.Nombre,
      });
      let tiempo = 300;
      TimerMixin.setTimeout(() => {
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Empresa().then((res) => {
          this.setState({
            DatoEmpresa: res.data.EMPRESA,
            COD_EMPRESA_B: res.data.EMPRESA[0].COD_EMPRESA,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Sucursal(res.data.COD_ROL_SUC).then((res) => {
          this.setState({
            DatoSucursal: res.data.SUCURSAL,
            COD_SUCURSAL_B: res.data.SUCURSAL[0].COD_SUCURSAL,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        PermisoModulo(res.data.ID_ROL, this.state.PERMISO_ROL).then((res) => {
          this.setState({
            VALIDACION: res.data.ESTADO,
          });
        });
        TimerMixin.setTimeout(() => {
          // if (this.state.COD_EMPRESA_B !== "" && this.state.IMAGEN === "") {
          ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
          DatosEmpresa(this.state.COD_EMPRESA_B).then((res) => {
            this.setState({
              IMAGEN: res.data.IMAGEN,
              width: res.data.width,
              height: res.data.height,
              REPORT_WIDTH: res.data.REPORT_WIDTH,
              REPORT_HEIGHT: res.data.REPORT_HEIGHT,
            });
          });
          // }
        }, 500);
      }, tiempo);
    });
  }
  onChange3 = (FECHA_INICIO) => {
    this.setState({ FECHA_INICIO: FECHA_INICIO });
  };
  onChange4 = (FECHA_FIN) => {
    this.setState({ FECHA_FIN: FECHA_FIN });
  };

  Limpiar = () => {
    this.setState({
      DataReporte: [],
      TotalMonto: "",
      TotalPago: "",
    });
  };

  //+++++++++++++++ BOTON BUSCAR DATA ++++++++++++++++++
  obtenerDatos = () => {
    this.Limpiar();
    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Resumen de Recuperacion por Cartera",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    } else if (this.state.COD_SUCURSAL_B === "") {
      Swal.fire(
        "Resumen de Recuperacion por Cartera",
        "Por Favor Seleccione una Sucursal",
        "error"
      );
    } else if (this.state.FECHA_INICIO === "") {
      Swal.fire(
        "Resumen de Recuperacion por Cartera",
        "Por Favor Seleccione un Credito",
        "error"
      );
    } else if (this.state.FECHA_FIN === "") {
      Swal.fire(
        "Resumen de Recuperacion por Cartera",
        "Por Favor Seleccione un Credito",
        "error"
      );
    } else {
      const Data = {
        CodigoEmpresa: this.state.COD_EMPRESA_B,
        CodigoSucursal: this.state.COD_SUCURSAL_B,
        FechaInicio: this.state.FECHA_INICIO,
        FechaFin: this.state.FECHA_FIN,
      };
      RecuperacionCartera(Data).then((res) => {
        this.setState({
          DataReporte: res.data.DataReporte,
          TotalMonto: res.data.TotalMonto,
          TotalPago: res.data.TotalPago,
        });
      });
    }
  };
  JSPDF(e) {
    e.preventDefault();
    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Resumen de Recuperacion por Cartera",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    } else {
      if (this.state.COD_EMPRESA_B !== "" && this.state.IMAGEN === "") {
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        DatosEmpresa(this.state.COD_EMPRESA_B).then((res) => {
          this.setState({
            IMAGEN: res.data.IMAGEN,
            width: res.data.width,
            height: res.data.height,
            REPORT_WIDTH: res.data.REPORT_WIDTH,
            REPORT_HEIGHT: res.data.REPORT_HEIGHT,
          });
        });
      }

      let doc = new jsPDF("l", "pt", "letter");

      let imgData;
      let REPORT_WIDTH = parseInt(this.state.REPORT_WIDTH);
      let REPORT_HEIGHT = parseInt(this.state.REPORT_HEIGHT);
      imgData = this.state.IMAGEN;

      doc.addImage(imgData, 20, 12, REPORT_WIDTH, REPORT_HEIGHT);

      doc.setFontSize(14);
      doc.text("RESUMEN DE RECUPERACION POR CARTERA", 260, 60);

      doc.autoTable({
        html: "#body",
        useCss: true,
        startY: 80,
        showHead: "firstPage",
        headStyles: { cellPadding: 0.3, fontSize: 8 },
        styles: { cellPadding: 0.3, fontSize: 8 },
        margin: { right: 10 },
        theme: "plain",
        //   columnStyles:  { cellWidth: '5%' } ,
      });

      doc.save("ResumenRecuCartera.pdf");
    }
  }

  ExcelData(e) {
    e.preventDefault();
    let table1 = [];

    let DATA = this.state.DataReporte;

    DATA.forEach((row) => {
      table1.push({
        A: row.NOMBRE_SUCURSAL,
        B: row.NOMBRE_ANALISTA1,
        C: row.MONTO_RECUPERADO,
        D: row.NUMEROS_PAGOS,
      });
    });

    table1 = [{ A: "RESUMEN DE RECUPERACION POR CARTERA" }]
    .concat([ { A: "", B: "", C: "", D: ""}])
    .concat([{
        A: "SUCURSAL",
        B: "ANALISTA",
        C: "MONTO RECUPERADO",
        D: "NO.PAGOS",
      }
    ])
      .concat(table1)
      .concat([
        {
          A: "",
          B: "TOTAL",
          C: this.state.TotalMonto,
          D: this.state.TotalPago,
        },
      ]);

    const finalData = [...table1];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "ResumenRecu_Cartera");

    XLSX.writeFile(wb, "ResumenRecuCartera.xlsx");
  }

  render() {
    return (
      <form>
        <div style={{ visibility: this.state.VALIDACION }}>
          {" "}
          <SisprestamosNavbar />
          {/*  */}
          <div className="container mt-5">
            <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
              <div className="row">
                <div className="col-md-3">
                  <h5 className="labelb"> EMPRESA </h5>

                  <select
                    name="COD_EMPRESA_B"
                    className="form-control text-center"
                    value={this.state.COD_EMPRESA_B}
                    onChange={this.onChange}
                  >
                    <option></option>
                    {this.state.DatoEmpresa.map((item) => (
                      <option key={item.COD_EMPRESA} value={item.COD_EMPRESA}>
                        {item.NOMBRE_EMPRESA}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <h5 className="labelb">SUCURSAL</h5>
                  <select
                    name="COD_SUCURSAL_B"
                    className="form-control text-center"
                    value={this.state.COD_SUCURSAL_B}
                    onChange={this.onChange}
                  >
                    <option></option>
                    <option>Todas</option>
                    {this.state.DatoSucursal.map((item) => (
                      <option key={item.COD_SUCURSAL} value={item.COD_SUCURSAL}>
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <h5 className="labelb">FECHA INICIO</h5>
                  <DatePicker
                    type="date"
                    dateFormat="dd '/' M '/' yyyy"
                    selected={this.state.FECHA_INICIO}
                    onChange={this.onChange3}
                    locale="es"
                    className="form-control text-center"
                  />
                </div>
                <div className="col-md-3">
                  <h5 className="labelb">FECHA FIN</h5>
                  <DatePicker
                    type="date"
                    dateFormat="dd '/' M '/' yyyy"
                    selected={this.state.FECHA_FIN}
                    onChange={this.onChange4}
                    locale="es"
                    className="form-control text-center"
                  />
                </div>
              </div>

              <div className="container ">
                <div className="row">
                  <div className="col-md-1"></div>
                  <div className="col-md-3 p-1">
                    <button
                      type="button"
                      onClick={this.ExcelData}
                      className="btn btn-outline-success btn-lg col-md-12"
                    >
                      EXCEL
                    </button>
                  </div>
                  <div className="col-md-3 p-1">
                    <button
                      type="button"
                      onClick={this.JSPDF}
                      className="btn btn-outline-danger btn-lg col-md-12"
                    >
                      PDF
                    </button>
                  </div>
                  <div className="col-md-3 p-1">
                    <button
                      type="button"
                      onClick={this.obtenerDatos}
                      className="btn btn-outline-dark btn-lg col-md-12"
                    >
                      BUSCAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="container ">
            <div className="row">
              <div className="shadow-lg bg-white rounded  col-md-12 mx-auto border rounded border-dark p-3">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={this.state.IMAGEN}
                        alt="logo"
                        className="rounded"
                        style={{
                          width: this.state.width,
                          height: this.state.height,
                          float: "left",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <h3 className="text-center encabezado">
                        RESUMEN DE RECUPERACION POR CARTERA
                      </h3>
                      <table
                        className="table table-bordered table-css"
                        style={{ marginTop: 30 }}
                        id="body"
                      >
                        <thead>
                          <tr>
                            <th>SUCURSAL</th>
                            <th>ANALISTA</th>
                            <th>MONTO RECUPERADO</th>
                            <th>NO.PAGOS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.DataReporte.map((item) => {
                            return (
                              <tr key={item.NOMBRE_ANALISTA1}>
                                <td>{item.NOMBRE_SUCURSAL}</td>
                                <td>{item.NOMBRE_ANALISTA1}</td>
                                <td className="text-right">
                                  {item.MONTO_RECUPERADO}
                                </td>
                                <td className="text-right">
                                  {item.NUMEROS_PAGOS}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan="1"></td>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td className=" text-right">
                              {this.state.TotalMonto}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalPago}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </form>
    );
  }
}
export default ResumenRecCartera;

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { getUser } from "../../UserFunctions";
import HomeNavbar from "../../HomeNavbar";

class Profile extends Component {

  constructor() {
    super();
    let loggedIn = false;
    this.state = {
      name         : "",      //GET
      alias        : "",     //GET
      Tipo_usuario : "",    //GET
      COD_SUCURSAL : "",   //GET
      COD_EMPRESA  : "",  //GET
      IP_MOVIL     : [], //GET ARRAY
      loggedIn,
      errors: {},
    };
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        name         : res.data.Nombre,
        alias        : res.data.Alias,
        Tipo_usuario : res.data.Tipo_usuario,
        COD_SUCURSAL : res.data.COD_SUCURSAL,
        COD_EMPRESA  : res.data.COD_EMPRESA,
        loggedIn     : true,
      });
      
    });
  }

  render() {
    if (this.setState.loggedIn) {
      return <Redirect to="/admin" />;
    }
    return (



      <div className="container "><HomeNavbar />
        <div className="row">
          <div className="col-md-12 mt-3 mx-auto">

            <div className="d-flex justify-content-center">
              {/* <img src={require('./imagenes/PRESTA CASH.jpg')} alt="" width="200" height="150" align="center" /> */}
              {/* <img src={require('./imagenes/logo_1.jpg')}alt=""  width="350" height="170"  align="center"/>  */}
            </div>


            <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-2 bg-light text-dark border rounded border-dark">

              <div className="form-row">
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6">
                  Nombre{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -
                </font>
                : {this.state.name}
                </div>
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6">
                  Alias{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -------
                </font>
                : {this.state.alias}
                </div>
              </div>
              <div className="form-row">
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6">
                  Empresa{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>

                  </font>
                : {this.state.COD_EMPRESA}
                </div>
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6">
                  Sucursal : {this.state.COD_SUCURSAL}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Profile;

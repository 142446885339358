import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import {
  PermisoModulo,
  Empresa,
  Sucursal,
  ListaAnalista,
  CostoPorAnalista,
} from "../../GlobalFuntions"; // Usuarios
import api from "../../../api/axiosConfigRoute";
import SisprestamosNavbar from "../../SisprestamosNavbar";

registerLocale("es", es);

class CostoAnalista extends Component {
  constructor() {
    super();
    this.state = {
      Id: "",
      ALIAS: "",
      NombreUsuario: "",

      DatoEmpresa: [],
      COD_EMPRESA_B: "",
      DatoSucursal: [],
      COD_SUCURSAL_B: "",
      DataAnalista: [],
      COD_ANALISTA: "",

      ResultEmpresa: "",
      ResultSucursal: "",
      ResultAnalista: "",
      ResultNombre: "",
      ResultMes: "",
      ResultCosto: "",

      FechaBusqueda: "",

      VerificacionRegistro: false,
      VerificacionActualizar: false,

      PERMISO_ROL: "1049",
      VALIDACION: "hidden",
      BTN_APL: true,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeDataAnalist = this.onChangeDataAnalist.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.RegistroCosto = this.RegistroCosto.bind(this);
    this.ActualizarCosto = this.ActualizarCosto.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeDataAnalist(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({
      DataAnalista: [],
    });
    this.UpdateDataAnalist();
  }

  onChangeFecha = (FECHA) => {
    this.setState({ FECHA: FECHA });
  };
  onChangeFechaBusqueda = (FechaBusqueda) => {
    this.setState({ FechaBusqueda: FechaBusqueda });
  };

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        Id: res.data.COD_USUARIO,
        ALIAS: res.data.Alias,
        NombreUsuario: res.data.Nombre,
      });
      TimerMixin.setTimeout(() => {
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Empresa().then((res) => {
          this.setState({
            DatoEmpresa: res.data.EMPRESA,
            COD_EMPRESA_B: res.data.EMPRESA[0].COD_EMPRESA,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Sucursal(res.data.COD_ROL_SUC).then((res) => {
          this.setState({
            DatoSucursal: res.data.SUCURSAL,
            COD_SUCURSAL_B: res.data.SUCURSAL[0].COD_SUCURSAL,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        PermisoModulo(res.data.ID_ROL, this.state.PERMISO_ROL).then((res) => {
          this.setState({
            VALIDACION: res.data.ESTADO,
          });
        });
        this.UpdateDataAnalist();
      }, 300);
    });
  }

  UpdateDataAnalist() {
    TimerMixin.setTimeout(() => {
      if (this.state.COD_EMPRESA_B !== "" && this.state.COD_SUCURSAL_B !== "") {
        ListaAnalista(this.state.COD_EMPRESA_B, this.state.COD_SUCURSAL_B).then(
          (res) => {
            this.setState({
              DataAnalista: res.data.DATA,
            });
          }
        );
      }
    }, 800);
  }

  Limpiar() {
    this.setState({
      ResultEmpresa: "",
      ResultSucursal: "",
      ResultAnalista: "",
      ResultNombre: "",
      ResultMes: "",
      ResultCosto: "",
      BTN_APL: true,
    });
  }

  obtenerDatos = () => {
    this.Limpiar();
    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Costo por Analista",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    } else if (this.state.COD_SUCURSAL_B === "") {
      Swal.fire(
        "Costo por Analista",
        "Por Favor Seleccione una Sucursal",
        "error"
      );
    } else if (this.state.FechaBusqueda === "") {
      Swal.fire("Costo por Analista", "Por Favor Seleccione un Mes", "error");
    } else if (this.state.COD_ANALISTA === "") {
      Swal.fire(
        "Costo por Analista",
        "Por Favor Seleccione un Analista",
        "error"
      );
    } else {
      const DataAnalistaCosto = {
        Empresa: this.state.COD_EMPRESA_B,
        Sucursal: this.state.COD_SUCURSAL_B,
        Analista: this.state.COD_ANALISTA,
        Mes: this.state.FechaBusqueda,
      };
      CostoPorAnalista(DataAnalistaCosto).then((res) => {
        this.setState({
          ResultEmpresa: res.data.ResultEmpresa,
          ResultSucursal: res.data.ResultSucursal,
          ResultAnalista: res.data.ResultAnalista,
          ResultNombre: res.data.ResultNombre,
          ResultMes: new Date(res.data.ResultMes),
          ResultCosto: res.data.ResultCosto,
          VerificacionRegistro: res.data.VerificacionRegistro,
          VerificacionActualizar: res.data.VerificacionActualizar,
        });
      });
    }
  };

  registro = (newCosto) => {
    return axios
      .post(
        `${api}/analista/registrocosto/${this.state.ResultEmpresa}/${this.state.ResultSucursal}/${this.state.ResultAnalista}`,
        {
          Mes: newCosto.Mes,
          Costo: newCosto.Costo,
          Usuario: newCosto.Usuario,
        }
      )
      .then((response) => {
        Swal.fire(
          "Costo por Analista",
          response.data.Mensaje,
          response.data.Alerta
        );
        this.Limpiar();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Costo por Analista",
          "Por Favor Verifique Los Datos",
          "error"
        );
        this.setState({
          BTN_APL: true,
          VerificacionRegistro: true,
        });
      });
  };

  Actualizar = (ActCosto) => {
    return axios
      .post(
        `${api}/analista/ActualizCosto/${this.state.ResultEmpresa}/${this.state.ResultSucursal}/${this.state.ResultAnalista}`,
        {
          Mes: ActCosto.Mes,
          Costo: ActCosto.Costo,
          Usuario: ActCosto.Usuario,
        }
      )
      .then((response) => {
        Swal.fire(
          "Costo por Analista",
          response.data.Mensaje,
          response.data.Alerta
        );
        this.Limpiar();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Costo por Analista",
          "Por Favor Verifique Los Datos",
          "error"
        );
        this.setState({
          BTN_APL: true,
          VerificacionRegistro: true,
        });
      });
  };

  BTN_APL_DESAC() {
    this.setState({
      BTN_APL: false,
      VerificacionRegistro: false,
      VerificacionActualizar: false,
    });
  }

  RegistroCosto(e) {
    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Costo por Analista",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    } else if (this.state.COD_SUCURSAL_B === "") {
      Swal.fire(
        "Costo por Analista",
        "Por Favor Seleccione una Sucursal",
        "error"
      );
    } else if (this.state.FechaBusqueda === "") {
      Swal.fire("Costo por Analista", "Por Favor Seleccione un Mes", "error");
    } else if (this.state.COD_ANALISTA === "") {
      Swal.fire(
        "Costo por Analista",
        "Por Favor Seleccione un Analista",
        "error"
      );
    } else if (this.state.ResultCosto === "") {
      Swal.fire("Costo por Analista", "Por Favor Registre el Costo", "error");
    } else {
      e.preventDefault();
      Swal.fire({
        title: "Desea Registrar El Costo",
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.BTN_APL_DESAC();
          let timerInterval;
          Swal.fire({
            title: "Registro de Costo por Analista",
            html: "Los Datos se Estan Registrando",
            timer: 2000000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              try {
              } catch (error) {}
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log("I was closed by the timer");
            }
          });
          const newCosto = {
            Mes: this.state.ResultMes,
            Costo: this.state.ResultCosto,
            Usuario: this.state.ALIAS,
          };

          this.registro(newCosto).then((res) => {});
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    }
  }

  ActualizarCosto(e) {
    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Costo por Analista",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    } else if (this.state.COD_SUCURSAL_B === "") {
      Swal.fire(
        "Costo por Analista",
        "Por Favor Seleccione una Sucursal",
        "error"
      );
    } else if (this.state.FechaBusqueda === "") {
      Swal.fire("Costo por Analista", "Por Favor Seleccione un Mes", "error");
    } else if (this.state.COD_ANALISTA === "") {
      Swal.fire(
        "Costo por Analista",
        "Por Favor Seleccione un Analista",
        "error"
      );
    } else if (this.state.ResultCosto === "") {
      Swal.fire("Costo por Analista", "Por Favor Registre el Costo", "error");
    } else {
      e.preventDefault();
      Swal.fire({
        title: "Desea Actualizar El Costo",
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.BTN_APL_DESAC();
          let timerInterval;
          Swal.fire({
            title: "Actualizacion de Costo por Analista",
            html: "Los Datos se Estan Actualizando",
            timer: 2000000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              try {
              } catch (error) {}
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log("I was closed by the timer");
            }
          });
          const ActCosto = {
            Mes: this.state.ResultMes,
            Costo: this.state.ResultCosto,
            Usuario: this.state.ALIAS,
          };

          this.Actualizar(ActCosto).then((res) => {});
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    }
  }

  render() {
    return (
      <form>
        <div style={{ visibility: this.state.VALIDACION }}>
          {" "}
          <SisprestamosNavbar />
          {/*  */}
          <div className="container mt-5">
            <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
              <div className="row">
                <div className="col-md-3">
                  <h5 className="labelb"> EMPRESA </h5>

                  <select
                    name="COD_EMPRESA_B"
                    className="form-control text-center"
                    value={this.state.COD_EMPRESA_B}
                    onChange={this.onChangeDataAnalist}
                    disabled={this.state.BTN_APL === false ? true : false}
                  >
                    <option></option>
                    {this.state.DatoEmpresa.map((item) => (
                      <option key={item.COD_EMPRESA} value={item.COD_EMPRESA}>
                        {item.NOMBRE_EMPRESA}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <h5 className="labelb">SUCURSAL</h5>
                  <select
                    name="COD_SUCURSAL_B"
                    className="form-control text-center"
                    value={this.state.COD_SUCURSAL_B}
                    onChange={this.onChangeDataAnalist}
                    disabled={this.state.BTN_APL === false ? true : false}
                  >
                    <option></option>
                    {this.state.DatoSucursal.map((item) => (
                      <option key={item.COD_SUCURSAL} value={item.COD_SUCURSAL}>
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <h5 className="labelb">MES</h5>
                  <DatePicker
                    type="date"
                    dateFormat=" M '/' yyyy"
                    selected={this.state.FechaBusqueda}
                    onChange={this.onChangeFechaBusqueda}
                    locale="es"
                    className="form-control text-center"
                    disabled={this.state.BTN_APL === false ? true : false}
                  />
                </div>
                <div className="col-md-3">
                  <h5 className="labelb">ANALISTA</h5>
                  <input
                    list="data"
                    name="COD_ANALISTA"
                    className="form-control text-center"
                    value={this.state.COD_ANALISTA}
                    onChange={this.onChange}
                    autoComplete="off"
                    disabled={this.state.BTN_APL === false ? true : false}
                  />
                  <datalist id="data">
                    <option></option>
                    {this.state.DataAnalista.map((item) => (
                      <option key={item.COD_ANALISTA} value={item.COD_ANALISTA}>
                        {item.NOMBRE_ANALISTA}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="container ">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-4 ">
                    <button
                      type="button"
                      onClick={this.obtenerDatos}
                      className="btn btn-outline-dark btn-lg  col-md-12 "
                      disabled={this.state.BTN_APL === false ? true : false}
                    >
                      BUSCAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="container ">
            <div className="row">
              <div className="shadow-lg bg-white rounded  col-md-12 mx-auto border rounded border-dark p-3">
                <div className="container">
                  <div className="row">
                    <h3 className="text-center encabezado">
                      COSTO POR ANALISTA
                    </h3>

                    <div className="container">
                      <div className="row">
                        <div className="form-group col-md-6">
                          <h6 className="text-left">NOMBRE</h6>
                          <input
                            type="text"
                            className="form-control text-left"
                            name="ResultNombre"
                            value={this.state.ResultNombre}
                            onChange={this.onChange}
                            autoComplete="off"
                            disabled="off"
                          />
                        </div>
                        <div className="col-md-2">
                          <h5 className="labelb">MES</h5>
                          <DatePicker
                            type="date"
                            dateFormat=" M '/' yyyy"
                            selected={this.state.ResultMes}
                            onChange={this.onChangeFecha}
                            locale="es"
                            className="form-control text-center"
                            disabled="off"
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <h6 className="text-left">COSTO</h6>
                          <input
                            type="text"
                            className="form-control text-right"
                            name="ResultCosto"
                            value={this.state.ResultCosto}
                            onChange={this.onChange}
                            autoComplete="off"
                            disabled={
                              this.state.BTN_APL === false ? true : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="container ">
                      <div className="row">
                        <div className="col-md-3"> </div>
                        <div className="col-md-3 p-3">
                          <button
                            type="button"
                            onClick={this.RegistroCosto}
                            className="btn btn-outline-dark btn-lg  col-md-12 "
                            disabled={
                              this.state.VerificacionRegistro === false
                                ? true
                                : false
                            }
                          >
                            REGISTRAR
                          </button>
                        </div>
                        <div className="col-md-3 p-3">
                          <button
                            type="button"
                            onClick={this.ActualizarCosto}
                            className="btn btn-outline-success btn-lg col-md-12 "
                            disabled={
                              this.state.VerificacionActualizar === false
                                ? true
                                : false
                            }
                          >
                            ACTUALIZAR
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
export default CostoAnalista;

import React, { Component } from "react";
import axios                from "axios";
import { geolocated }       from "react-geolocated";
import TimerMixin           from "react-timer-mixin";
import Swal                 from "sweetalert2";
import moment from "moment-timezone";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

class No_Pago extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

consultarGestor = () => {
const url = `${api}/gestor/get/${this.state.Tipo_usuario}/${this.state.sucursal}`;
//console.log(url);
fetch(url)
.then((respuesta) => respuesta.json())
.then((resultado) => this.setState({ GestorA: resultado }));
};


getCredito = (Id_credito) => {
return axios
.get(`${api}/maps/getcredito/${this.busquedaRef.current.value}/${this.state.Tipo_usuario}/${this.state.sucursal}`)
.then((res_credito) => {
return res_credito;
})
.catch((err) => {
window.alert("El Credito no esta registrado");
window.location.reload();
return err;
});
};

busquedaRef = React.createRef();
obtenerDatos = (e) => {
e.preventDefault();
this.obtenerlatitude();
this.getCredito().then((res_credito) => {
this.setState({
COD_CREDITO      : res_credito.data.COD_CREDITO,
COD_CLIENTE      : res_credito.data.COD_CLIENTE,
NOMBRE1          : res_credito.data.NOMBRE1,
BARRIO           : res_credito.data.BARRIO,
COD_SUCURSAL     : res_credito.data.COD_SUCURSAL,
COD_EMPRESA      : res_credito.data.COD_EMPRESA,
COD_MUNICIPIO    : res_credito.data.COD_MUNICIPIO,
COD_DEPARTAMENTO : res_credito.data.COD_DEPARTAMENTO,
});
});
};

registro_no_pago = (newSolicitud) => {
return axios
.post(`${api}/maps/no_recibo`, {
BARRIO           : newSolicitud.BARRIO,                //POST
latitude         : newSolicitud.latitude,             //POST
longitude        : newSolicitud.longitude,           //POST
CAJERO           : newSolicitud.CAJERO,             //POST
COD_SUCURSAL     : newSolicitud.COD_SUCURSAL,      //POST
COD_EMPRESA      : newSolicitud.COD_EMPRESA,      //POST
COD_DEPARTAMENTO : newSolicitud.COD_DEPARTAMENTO,//POST
COD_MUNICIPIO    : newSolicitud.COD_MUNICIPIO,  //POST
COD_CREDITO      : newSolicitud.COD_CREDITO,   //POST
CONCEPTO         : newSolicitud.CONCEPTO,     //POST
N_GESTOR         : newSolicitud.N_GESTOR,    //POST
COD_CLIENTE      : newSolicitud.COD_CLIENTE,//POST
TIME_P           : newSolicitud.TIME_P,    //POST
FECHA            : newSolicitud.FECHA,   
HORA_DESEMB      : newSolicitud.HORA_DESEMB,   
})
.then((response) => {
Swal.fire(
'Cliente sin pagar',
'Registrado',
'SUCCESS'
)
this.props.history.push('/')
this.props.history.push('/No_pago')
})
.catch((err) => {
console.log(err)
window.alert("Verifique los Datos");
window.location.reload();
});
};

constructor() {
super();
this.state = {
place            : null,
FECHA_MOMENT_TZ    : "",
HORA_MOMENT_TZ     : "",
COD_CREDITO      : [],       //GET
NOMBRE1          : [],      //GET
BARRIO           : [],     //GET
COD_SUCURSAL     : [],    //GET
COD_EMPRESA      : [],   //GET
COD_MUNICIPIO    : [],  //GET
COD_DEPARTAMENTO : [], //GET
CAJERO        : [],   //GET
GestorA       : [],  //GET ARRAY
COD_CLIENTE   : [], //GET
N_GESTOR      : "",//POST
P             :"192.168.1.5",
latitude      : "", //POST
longitude     : "",//POST
IP            : "",
CONCEPTO      : "No pago",//POST
    
block         : "enviar",
Condicion     : "",
V_Condicion   : "FALSE",
V_Condicion_T : "TRUE",
Activar_c     : "",
TIME          : "",
TIME_P        : "5000",
Tipo_usuario:"",
sucursal:"",
FECHA         : new Date(),//GET
HORA_DESEMB      : "",

ID_ROL: "",
PERMISO_ROL: "1009",
VALIDACION: "hidden",
errors        : {},
};
this.onChange = this.onChange.bind(this);
this.onSubmit = this.onSubmit.bind(this);
}

onChange(e) {
this.setState({ [e.target.name]: e.target.value });
}

onSubmit(e) {
var tiempo ="1000";
if (this.state.N_GESTOR === "") {
e.preventDefault();
Swal.fire(
'Cliente sin pagar',
'Por favor ingrese el gestor',
'error'
)
} else {
Swal.fire({
title: 'Desea realizar el registro',
showDenyButton: true,
// showCancelButton: true,
confirmButtonText: `Registrar`,
denyButtonText: `Cancelar`,
}).then((result) => {
/* Read more about isConfirmed, isDenied below */
if (result.isConfirmed) {
// e.preventDefault();
TimerMixin.setTimeout(() => {
//e.preventDefault();
const newSolicitud = {
BARRIO           : this.state.BARRIO,           //POST
latitude         : this.state.latitude,        //POST
longitude        : this.state.longitude,      //POST
CAJERO           : this.state.CAJERO,        //POST
COD_SUCURSAL     : this.state.COD_SUCURSAL, //POST
COD_EMPRESA      : this.state.COD_EMPRESA, //POST
COD_DEPARTAMENTO : this.state.COD_DEPARTAMENTO, //POST
COD_MUNICIPIO    : this.state.COD_MUNICIPIO,   //POST
COD_CREDITO      : this.state.COD_CREDITO,    //POST
CONCEPTO         : this.state.CONCEPTO,      //POST
N_GESTOR         : this.state.N_GESTOR,     //POST
COD_CLIENTE      : this.state.COD_CLIENTE, //POST
TIME_P           : this.state.TIME_P,     //POST
FECHA            : this.state.FECHA_MOMENT_TZ,    
HORA_DESEMB      : this.state.HORA_MOMENT_TZ,  
};
this.registro_no_pago(newSolicitud).then((res) => {});
}, tiempo);
// Swal.fire('Registrado!', '', 'success')
} else if (result.isDenied) {
Swal.fire('Registro cancelado', '', 'info')
}
})

} // here else cerrare
}


Conv_fecha(){

  //**************************MOMENT TIME ZONE************************/
  var jun = moment(this.state.FECHA);
  
  var fecha_time_conv=jun.tz('América/Managua').format('l');  // 5am PDT
  var hora_time_conv=jun.tz('América/Managua').format('LT');  // 5am PDT
  
  //**************************MOMENT TIME ZONE************************/
    
    this.setState({
     FECHA_MOMENT_TZ:fecha_time_conv,
     HORA_MOMENT_TZ:hora_time_conv,
      });
  }

componentDidMount() {
  this.Conv_fecha()

const token = localStorage.usertoken;
const decoded = jwt_decode(token);
getUser(decoded.uid).then((res) => {
this.setState({
CAJERO         : res.data.Alias,
TIME           : res.data.TIME,
IP             : res.data.IP,
COD_SUCURSAL_U : res.data.COD_SUCURSAL,
COD_EMPRESA_U  : res.data.COD_EMPRESA,
Tipo_usuario: res.data.Tipo_usuario,
sucursal: res.data.sucursal,
ID_ROL: res.data.ID_ROL,
});
let tiempo = '300'
TimerMixin.setTimeout(() => {
this.CONSULTA_PERMISO(); 
this.consultarGestor()
}, tiempo);   

});
}


busquedalatitude = React.createRef();

obtenerlatitude = (e) => {
console.log(this.busquedalatitude.current.value);
this.obtenerlongitude();
const termino1 = this.busquedalatitude.current.value;
this.setState({
latitude: termino1,
});
};

busquedalongitude = React.createRef();

obtenerlongitude = (e) => {
console.log(this.busquedalongitude.current.value);
const termino2 = this.busquedalongitude.current.value;
this.setState({
longitude: termino2,
});
};

  render() {
    // console.warn("RESULT RETURN HERE",this.state.place)
    return !this.props.isGeolocationAvailable ? (
      <div>Your browser does not support Geolocation</div>
    ) : !this.props.isGeolocationEnabled ? (
      <div>Geolocation is not enabled</div>
    ) : this.props.coords ? (

      <form onSubmit={this.obtenerDatos} style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
        <div className="busqueda container">
      <div className="jumbotron jumbotron mt-5 p-3 mb-2 bg-secondary text-white">
        <h1 className="h3 mb-4 font-weight-normal  text-center">
            </h1>

            <div className="row">
              <div className="form-group col-md-8">
                <input
                  ref={this.busquedaRef}
                  type="text"
                  className="form-control 
                    form-control-lg text-right"
                  placeholder="Credito.Ejemplo: 
                    718"
                />
              </div>

              <div className="form-group col-md-4">
                <input
                  type="submit"
                  className="btn btn-lg 
                    btn-danger btn-block"
                  value="Buscar"
                />
              </div>
            </div>
          </div>
        </div>
     
     

        <div className="container">
          <div className="row">
          <div className="col-md-12 mt-3 mx-auto">
          <h1 className="h3 mb-4 font-weight-normal  text-center">
              Datos del credito
              </h1>
           

              <div className="container jumbotrom p-3 mb-2 bg-light text-dark ">
            <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-2 bg-light text-dark border rounded border-dark">
              <div className="form-row">
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  Credito{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    ------
                  </font>
                  : {this.state.COD_CREDITO}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-8">
                    Nombre{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -------
                  </font>
                  : {this.state.NOMBRE1}
                </div>

               
              </div>
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12">
                  Barrio{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -------
                  </font>
                  : {this.state.BARRIO}
                </div>
              </div>
              </div>

             </div>
          </div>
        </div>

        

        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-3 mx-auto">
              <div className="container jumbotrom p-3 mb-2 bg-light text-dark ">

              <div className="form-row">
                  <div className="form-group text-center col-md-6">
                <label htmlFor="GestorA">Gestor</label>
                <select
                  name="N_GESTOR"
                  className="form-control"
                  value={this.state.N_GESTOR}
                  onChange={this.onChange}
                >
                  <option></option>
                  {this.state.GestorA.map((item) => (
                    <option
                      key={item.COD_ANALISTA}
                      value={item.NOMBRE_ANALISTA}
                    >
                      {item.NOMBRE_ANALISTA}
                    </option>
                  ))}
                </select>
              </div>



                  <div className="form-group text-center col-md-6">
                    <label htmlFor="CONCEPTO">Concepto</label>
                    <select
                      name="CONCEPTO"
                      className="form-control text-center"
                      value={this.state.CONCEPTO}
                      onChange={this.onChange}
                    >
                      <option className="form-control text-center">
                        No pago
                      </option>
                    </select>
                  </div>
    
                  </div>
                  <div className="d-flex justify-content-center">  
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary btn-block col-md-4"
                    onClick={this.onSubmit}
                  >
                    Registrar
                  </button>
  
               </div>
             
            </div>
          </div>

          </div>

          <div className="col-md-12">
                  <p className="text-light bg-dark text-center">Usuario : {this.state.CAJERO} <font size="1" color="white" style={{ visibility: "hidden" }}>---</font>Sucursal : {this.state.COD_SUCURSAL_U}</p>
              </div>
               </div>








          
       

        <div style={{ visibility: "hidden",position: "fixed"  }}>
          <div className="jumbotron">
            <p className="lead text-center h3 mb-3 font-weight-normal">
              Consultar datos del Cliente
            </p>

           
            < div className="form-group">
                <label htmlFor="latitude">latitude</label>
                <input
                  type="latitude"
                  className="form-control"
                  name="latitude"
                  planceholder="Enter your Alias"
                  value={this.props.coords.latitude}
                  ref={this.busquedalatitude}
                />
              </div>


              <  div className="form-group">
                <label htmlFor="longitude">longitude</label>
                <input
                  type="longitude"
                  className="form-control"
                  name="longitude"
                  planceholder="Enter your Alias"
                  value={this.props.coords.longitude}
                  ref={this.busquedalongitude}
                />
              </div>




           
               
              </div>
            </div>
          


        
      </form>
    ) : (
      <div>Getting the location data&hellip; </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 10000,
})(No_Pago);

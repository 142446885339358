import React, { Component } from "react";
import TimerMixin from "react-timer-mixin";
import axios from "axios";
import Swal from "sweetalert2";
import * as jsPDF           from "jspdf";
import { geolocated } from "react-geolocated";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

class OtrosCargos extends Component {
  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarEmpresa = () => {
    const url = `${api}/empresa/get`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ EmpresaA: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  ConsultarCargos = () => {
    //
    // console.log("LA IP ES ", this.state.IP );
    //
    const url = `${api}/consulta/tipo_cargos/${this.state.COD_EMPRESA_B}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ MotivoA: resultado }));
  };

  consultarCreditos = () => {
    const url = `${api}/otroscargos/creditos_caja/${this.state.COD_EMPRESA_B}/${this.state.COD_SUCURSAL_B}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ ClienteA: resultado }));
  };

  constructor() {
    super();
    this.state = {
      ALIAS: "",
      Tipo_usuario: "",
      COD_SUCURSAL: "",
      COD_REGION: "",
      COD_NIVEL: "",
      NOMBRE_SUCURSAL: "",
      COD_ROL_SUC: "",

      //////DATOS Y RESULTADO DE BUSQUEDA
      EmpresaA: [],
      COD_EMPRESA_B: "",
      SucursalA: [], //GET ARRAY
      COD_SUCURSAL_B: "", //POST
      ClienteA: [],
      COD_CREDITO_B: "",
      MotivoA:[],
      MOTIVO:"",

      LATITUD: "",
      LONGITUD: "",

      PERMISO_ROL: "1008",
      VALIDACION: "hidden",
      BTN_APL: "true",

      COD_EMPRESA_R: "",
      COD_SUCURSAL_R: "",
      NOMBRE_SUCURSAL_R: "",
      COD_CREDITO: "",
      NOMBRE1: "",
      VALOR_TIPO_CAMBIO: "",
      COD_CLIENTE: "",
      ANALISTA: "",
      BARRIO: "",
      DEPARTAMENTO: "",
      MUNICIPIO: "",

       ////////DATOS DE LA CAJA
    CONCEPTO:"",
    RECIBO_ANALISTA:"",
    CORDOBAS:"0",
    DOLARES:"0",



      ///////////datos recibo
      NOMBRE_EMPRESA_R: "",
      NOMBRE_SUCURSAL_Recibo: "",
      TELEFONO_R: "",
      CELULAR_R: "",
      NO_RECIBO_R: "",
      FECHA_R: "",
      HORA_R: "",
      NOMBRE1_R: "",
      CO_CREDITO_R: "",
      CONCEPTO_1_R: "",
      MONTO_CORDOBA_R: "",
      MONTO_DOLAR_R: "",
      TOTAL_RECIBIDO_R: "",
      ADICIONADO_POR_R: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onChange_sucursal = this.onChange_sucursal.bind(this);
    this.onChange_cargos = this.onChange_cargos.bind(this);
    this.CONSULTA_DATOS = this.CONSULTA_DATOS.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChange_sucursal(e) {
    this.setState({ [e.target.name]: e.target.value });

    this.setState({
      ClienteA: [],
    });

    TimerMixin.setTimeout(() => {
      this.consultarCreditos();
    }, 300);
  }
  
  onChange_cargos(e) {
    this.setState({ [e.target.name]: e.target.value });

    TimerMixin.setTimeout(() => {
      if (this.state.COD_EMPRESA_B){
        this.ConsultarCargos();
      }
    }, 300);
  }


  BTN_APL_DESAC() {
    this.setState({ BTN_APL: "false" });
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        Tipo_usuario: res.data.Tipo_usuario,
        COD_SUCURSAL: res.data.COD_SUCURSAL,
        COD_REGION: res.data.COD_REGION,
        COD_NIVEL: res.data.COD_NIVEL,
        NOMBRE_SUCURSAL: res.data.NOMBRE_SUCURSAL,
        COD_EMPRESA_USUARIO: res.data.COD_EMPRESA,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
      });
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
        this.ConsultarCargos();
      }, 300);
      TimerMixin.setTimeout(() => {
        this.consultarEmpresa();
        this.consultarSucursal();
       
        
      }, 300);
    });
  }

  //////BUSQUEDA DE DATOS DEL CREDITO
  CONSULTA_CREDITO = (newCliente) => {
    return axios
      .post(`${api}/otroscargos/creditos_caja`, {
        COD_EMPRESA: newCliente.COD_EMPRESA,
        COD_SUCURSAL: newCliente.COD_SUCURSAL,
        COD_CREDITO: newCliente.COD_CREDITO,
      })
      .then((response) => {
        this.setState({
          COD_EMPRESA_R: response.data.COD_EMPRESA_R,
          COD_SUCURSAL_R: response.data.COD_SUCURSAL_R,
          NOMBRE_SUCURSAL_R: response.data.NOMBRE_SUCURSAL_R,
          COD_CREDITO: response.data.COD_CREDITO,
          NOMBRE1: response.data.NOMBRE1,
          VALOR_TIPO_CAMBIO: response.data.VALOR_TIPO_CAMBIO,
          COD_CLIENTE: response.data.COD_CLIENTE,
          ANALISTA: response.data.ANALISTA,
          BARRIO: response.data.BARRIO,
          DEPARTAMENTO: response.data.DEPARTAMENTO,
          MUNICIPIO: response.data.MUNICIPIO,
          TASA_INT: response.data.TASA_INT,
        });
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {});
  };

  CONSULTA_DATOS(e) {
    e.preventDefault();
    TimerMixin.setTimeout(() => {
      this.obtenerlatitude();
      const newCliente = {
        COD_EMPRESA: this.state.COD_EMPRESA_B,
        COD_SUCURSAL: this.state.COD_SUCURSAL_B,
        COD_CREDITO: this.state.COD_CREDITO_B,
      };
      this.CONSULTA_CREDITO(newCliente).then((res) => {});
    }, 100);
  }

  //////////*********GEOLOCALIZACION */

  busquedalatitude = React.createRef();

  obtenerlatitude = (e) => {
    this.obtenerlongitude();
    const termino1 = this.busquedalatitude.current.value;

    this.setState({
      LATITUD: termino1,
    });
  };

  busquedalongitude = React.createRef();
  obtenerlongitude = (e) => {
    const termino2 = this.busquedalongitude.current.value;
    this.setState({
      LONGITUD: termino2,
    });
  };

  registro = (newCliente) => {
    return axios
      .post(`${api}/otroscargos/pago_caja`, {
        COD_EMPRESA: newCliente.COD_EMPRESA,
        COD_SUCURSAL: newCliente.COD_SUCURSAL,
        COD_CREDITO: newCliente.COD_CREDITO,
        VALOR_TIPO_CAMBIO: newCliente.VALOR_TIPO_CAMBIO,
        CORDOBAS: newCliente.CORDOBAS,
        DOLARES: newCliente.DOLARES,
        ALIAS: newCliente.ALIAS,
        CONCEPTO: newCliente.CONCEPTO,
        RECIBO_ANALISTA: newCliente.RECIBO_ANALISTA,
        COD_CLIENTE: newCliente.COD_CLIENTE,
        ANALISTA: newCliente.ANALISTA,
        BARRIO: newCliente.BARRIO,
        LATITUD: newCliente.LATITUD,
        LONGITUD: newCliente.LONGITUD,
        DEPARTAMENTO: newCliente.DEPARTAMENTO,
        MUNICIPIO: newCliente.MUNICIPIO,
        NOMBRE1: newCliente.NOMBRE1,
        MOTIVO: newCliente.MOTIVO,
      })
      .then((response) => {
        Swal.fire("Caja", response.data.mensaje, response.data.alerta);
        this.setState({
          NOMBRE_EMPRESA_R: response.data.NOMBRE_EMPRESA_R,
          NOMBRE_SUCURSAL_Recibo: response.data.NOMBRE_SUCURSAL_R,
          TELEFONO_R: response.data.TELEFONO_R,
          CELULAR_R: response.data.CELULAR_R,
          NO_RECIBO_R: response.data.NO_RECIBO_R,
          FECHA_R: response.data.FECHA_R,
          HORA_R: response.data.HORA_R,
          NOMBRE1_R: response.data.NOMBRE1_R,
          CO_CREDITO_R: response.data.CO_CREDITO_R,
          CONCEPTO_1_R: response.data.CONCEPTO_1_R,
          MONTO_CORDOBA_R: response.data.MONTO_CORDOBA_R,
          MONTO_DOLAR_R: response.data.MONTO_DOLAR_R,
          TOTAL_RECIBIDO_R: response.data.TOTAL_RECIBIDO_R,
          ADICIONADO_POR_R: response.data.ADICIONADO_POR_R,
        });

        let tiempo = "300";
        TimerMixin.setTimeout(() => {
          if (response.data.alerta === "error") {
            this.setState({
              BTN_APL: "true",
            });
          } else {
            TimerMixin.setTimeout(() => {
              this.jsPdfGenerator();
            }, 300);
          }
        }, tiempo);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Caja", "Por Favor Verifique Los Datos", "error");
        this.setState({
          BTN_APL: "true",
        });
      });
  };

  onSubmit(e) {
    if (this.state.COD_CREDITO === "") {
      e.preventDefault();
      Swal.fire("Caja", "Por Favor Busque Los Datos Del Credito", "error");
    } else if (this.state.COD_EMPRESA_R === "") {
      e.preventDefault();
      Swal.fire(
        "Caja",
        "Por Favor Verifique Los Datos(Empresa No Encontrada",
        "error"
      );
    } else if (this.state.COD_SUCURSAL_R === "") {
      e.preventDefault();
      Swal.fire(
        "Caja",
        "Por Favor Verifique Los Datos(Sucursal No Encontrada",
        "error"
      );
    } else if (this.state.VALOR_TIPO_CAMBIO === "") {
      e.preventDefault();
      Swal.fire(
        "Caja",
        "Por Favor Verifique Los Datos(Tipo De Cambio No Encontrado",
        "error"
      );
    } else if (this.state.CONCEPTO === "") {
      e.preventDefault();
      Swal.fire("Caja", "Por Favor Agregue Un Concepto", "error");
    } else if (this.state.MOTIVO === "") {
      e.preventDefault();
      Swal.fire("Caja", "Por Favor Agregue Un Tipo De Cargo", "error");
    } else {
      e.preventDefault();
      Swal.fire({
        title: "Desea Aplicar El Pago",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.BTN_APL_DESAC();
          TimerMixin.setTimeout(() => {
            let timerInterval;

            Swal.fire({
              title: "Caja",
              html: "El Pago Se Esta Aplicando",
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                try {
                  const b = Swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft();
                  }, 2000000);
                } catch (error) {}
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
              }
            });
            const newCliente = {
              COD_EMPRESA: this.state.COD_EMPRESA_R,
              COD_SUCURSAL: this.state.COD_SUCURSAL_R,
              COD_CREDITO: this.state.COD_CREDITO,
              VALOR_TIPO_CAMBIO: this.state.VALOR_TIPO_CAMBIO,
              CORDOBAS: this.state.CORDOBAS,
              DOLARES: this.state.DOLARES,
              ALIAS: this.state.ALIAS,
              CONCEPTO: this.state.CONCEPTO,
              RECIBO_ANALISTA: this.state.RECIBO_ANALISTA,
              COD_CLIENTE: this.state.COD_CLIENTE,
              ANALISTA: this.state.ANALISTA,
              BARRIO: this.state.BARRIO,
              LATITUD: this.state.LATITUD,
              LONGITUD: this.state.LONGITUD,
              DEPARTAMENTO: this.state.DEPARTAMENTO,
              MUNICIPIO: this.state.MUNICIPIO,
              NOMBRE1: this.state.NOMBRE1,
              MOTIVO:this.state.MOTIVO,
            };

            this.registro(newCliente).then((res) => {});
          }, 100);
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    }
  }


jsPdfGenerator = (e) => {
  // e.preventDefault();

  let RECIBO=String(this.state.NO_RECIBO_R);

  let CO_CREDITO_R=String(this.state.CO_CREDITO_R);
  
  TimerMixin.setTimeout(() => {

    var doc = new jsPDF("p", "mm", [300, 180]);
    doc.setFont("courier");
    doc.setFontSize(6);
    doc.text(20,6,String(this.state.NOMBRE_EMPRESA_R));
    doc.text(20,9,String(this.state.NOMBRE_SUCURSAL_Recibo));
    doc.text(2,13,'NO.RECIBO           :');
     doc.text(30,13, RECIBO);
    doc.text(2,16,'FECHA               :');
    doc.text(30,16,String(this.state.FECHA_R));
    doc.text(2,19,'NOMBRE              :');
    doc.text(2,22,String(this.state.NOMBRE1_R));
    doc.text(2,25,'NO.CREDITO          :');
    doc.text(30,25,CO_CREDITO_R);
  
 let  y=parseInt(25);

       y=(parseInt(y) + 3 );
    doc.text(2,y,'CONCEPTO            :');
    y=(parseInt(y) + 3 );
    doc.text(2,y,String(this.state.CONCEPTO_1_R));


    y=(parseInt(y) + 3 );
    doc.text(2,y,'MONTO CORDOBAS      :');
    doc.text(30,y,String(this.state.MONTO_CORDOBA_R));

    y=(parseInt(y) + 3 );
    doc.text(2,y,'MONTO DOLARES       :');
    doc.text(30,y,String(this.state.MONTO_DOLAR_R));

    // // y=(parseInt(y) + 3 );
    // // doc.text(3,y,'ABONO A PRESTAMO :');

    y=(parseInt(y) + 3 );
    doc.text(2,y,'TOTAL PAGADO        :');
    doc.text(30,y,String(this.state.TOTAL_RECIBIDO_R));

    y=(parseInt(y) + 3 );
    doc.text(2,y,'USUARIO             :');
    doc.text(30,y,String(this.state.ADICIONADO_POR_R));

    y=(parseInt(y) + 3 );
    doc.text(2,y,'FECHA DEL RECIBO    :');
    doc.text(30,y,String(this.state.FECHA_R)); 
    


    doc.save("RECIBO DE CAJA.pdf");

    TimerMixin.setTimeout(() => {
      window.location.reload();
    }, 1000);
  }, 300);
};

  render() {
    return !this.props.isGeolocationAvailable ? (
      <div>Este navegador no soporta la geolocalizacion</div>
    ) : !this.props.isGeolocationEnabled ? (
      <div>Por favor activa la UBICACION(GPS)</div>
    ) : this.props.coords ? (
      <form>
        <div style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
     
          <div className="container mt-5">
            <div className="row">
              <div className="shadow-lg mb-2  bg-white rounded  col-md-12 mx-auto border rounded border-dark">
                <h1 className="h3  font-weight-normal  text-center">
                  OTROS INGRESOS
                </h1>

                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <div
                        style={{ textAlign: "left" }}
                        htmlFor="COD_EMPRESA_B"
                      >
                        EMPRESA
                      </div>
                      <select
                        name="COD_EMPRESA_B"
                        className="form-control"
                        defaultValue={this.state.COD_EMPRESA_B}
                        onChange={this.onChange_cargos}
                      >
                         <option> </option>
                        {this.state.EmpresaA.map((item) => (
                          <option
                            key={item.COD_EMPRESA}
                            value={item.COD_EMPRESA}
                          >
                            {item.NOMBRE_EMPRESA}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-3">
                      <div
                        style={{ textAlign: "left" }}
                        htmlFor="COD_SUCURSAL_B"
                      >
                        SUCURSAL
                      </div>
                      <select
                        name="COD_SUCURSAL_B"
                        className="form-control"
                        defaultValue={this.state.COD_SUCURSAL_B}
                        onChange={this.onChange_sucursal}
                        placeholder="Campo obligatorio"
                      >
                        <option></option>
                        {this.state.SucursalA.map((item) => (
                          <option
                            key={item.COD_SUCURSAL}
                            value={item.COD_SUCURSAL}
                          >
                            {item.NOMBRE_SUCURSAL}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-4">
                      <div
                        style={{ textAlign: "left" }}
                        htmlFor="COD_CREDITO_B"
                      >
                        NO.CREDITO/CLIENTE
                      </div>
                      <input
                        list="data"
                        name="COD_CREDITO_B"
                        className="form-control "
                        defaultValue={this.state.COD_CREDITO_B}
                        onChange={this.onChange}
                        autoComplete="off"
                      />
                      <datalist id="data">
                        <option></option>
                        {this.state.ClienteA.map((item) => (
                          <option key={item.COD_CREDITO} value={item.COD_CREDITO}>
                            {item.NOMBRE1}
                          </option>
                        ))}
                      </datalist>
                    </div>
                    <div className="col-md-2 p-3">
                      <button
                        type="button"
                        className="btn btn-secondary btn-lg col-md-12"
                        onClick={this.CONSULTA_DATOS}
                      >
                        BUSCAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="shadow-lg  bg-white rounded  col-md-12 mx-auto border rounded border-dark">
                  <div className="container ">
                    <div className="row">
                      <div className="col-md-3 p-2">
                        <div
                          style={{ textAlign: "left" }}
                          htmlFor="COD_CREDITO"
                        >
                          NO.CREDITO
                        </div>
                        <input
                          type="COD_CREDITO"
                          className="form-control text-right"
                          name="COD_CREDITO"
                          value={this.state.COD_CREDITO}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>

                      <div className="col-md-3 p-2">
                        <div
                          style={{ textAlign: "left" }}
                          htmlFor="NOMBRE_SUCURSAL_R"
                        >
                          SUCURSAL
                        </div>
                        <input
                          type="NOMBRE_SUCURSAL_R"
                          className="form-control text-left"
                          name="NOMBRE_SUCURSAL_R"
                          value={this.state.NOMBRE_SUCURSAL_R}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>

                      <div className="col-md-6 p-2">
                        <div style={{ textAlign: "left" }} htmlFor="NOMBRE1">
                          CLIENTE
                        </div>
                        <input
                          type="NOMBRE1"
                          className="form-control text-left"
                          name="NOMBRE1"
                          value={this.state.NOMBRE1}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="container">
                    <div className="row">
                      <div className="shadow-lg mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
                        <div className="container p-1">
                          <div className="row">
                            <div className="col-md-4">
                              <div
                                style={{ textAlign: "left" }}
                                htmlFor="CONCEPTO"
                              >
                                CONCEPTO
                              </div>
                              <input
                                type="CONCEPTO"
                                className="form-control text-right"
                                name="CONCEPTO"
                                value={this.state.CONCEPTO}
                                onChange={this.onChange}
                                autoComplete="off"
                              />
                            </div>

                            <div className="col-md-4">
                              <div
                                style={{ textAlign: "left" }}
                                htmlFor="RECIBO_ANALISTA"
                              >
                                RECIBO
                              </div>
                              <input
                                type="RECIBO_ANALISTA"
                                className="form-control text-left"
                                name="RECIBO_ANALISTA"
                                value={this.state.RECIBO_ANALISTA}
                                onChange={this.onChange}
                                autoComplete="off"
                              />
                            </div>

                            <div className="form-group text-center col-md-4">
                            <div
                                style={{ textAlign: "left" }}
                                htmlFor="RECIBO_ANALISTA"
                              >
                                TIPO
                              </div>
                <select
                  name="MOTIVO"
                  className="form-control"
                  value={this.state.MOTIVO}
                  onChange={this.onChange}
                >
                  <option></option>
                  {this.state.MotivoA.map((item) => (
                    <option key={item.COD_TIPO_CARGO} value={item.COD_TIPO_CARGO}>
                      {item.DESCRIPCION}
                    </option>
                  ))}
                </select>
              </div>
                          </div>
                        </div>

                        <div className="container ">
                          <div className="row">
                            <div className="col-md-4">
                              <div
                                style={{ textAlign: "left" }}
                                htmlFor="CORDOBAS"
                              >
                                CORDOBAS
                              </div>
                              <input
                                type="CORDOBAS"
                                className="form-control text-right"
                                name="CORDOBAS"
                                value={this.state.CORDOBAS}
                                onChange={this.onChange}
                                autoComplete="off"
                              />
                            </div>

                            <div className="col-md-4">
                              <div
                                style={{ textAlign: "left" }}
                                htmlFor="DOLARES"
                              >
                                DOLARES
                              </div>
                              <input
                                type="DOLARES"
                                className="form-control text-right"
                                name="DOLARES"
                                value={this.state.DOLARES}
                                onChange={this.onChange}
                                autoComplete="off"
                              />
                            </div>

                            <div className="col-md-4">
                              <div
                                style={{ textAlign: "left" }}
                                htmlFor="VALOR_TIPO_CAMBIO"
                              >
                                TIPO DE CAMBIO
                              </div>
                              <input
                                type="VALOR_TIPO_CAMBIO"
                                className="form-control text-right"
                                name="VALOR_TIPO_CAMBIO"
                                value={this.state.VALOR_TIPO_CAMBIO}
                                onChange={this.onChange}
                                disabled="disabled"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="container p-4">
                          <div className="row">
                            <div className="col-md-4 "></div>
                            <div className="col-md-4">
                              <button
                                type="button"
                                className="btn btn-success btn-lg col-md-12"
                                onClick={this.onSubmit}
                                disabled={
                                  this.state.BTN_APL === "false" ? true : false
                                }
                              >
                                PAGAR
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ visibility: "hidden",position: "fixed"  }}>
            <div className="jumbotron">
              <p className="lead text-center h3 mb-3 font-weight-normal">
                Consultar datos del Cliente
              </p>

              <div className="form-group">
                <label htmlFor="latitude">latitude</label>
                <input
                  type="latitude"
                  className="form-control"
                  name="latitude"
                  planceholder="Enter your Alias"
                  value={this.props.coords.latitude}
                  ref={this.busquedalatitude}
                  onChange={this.onChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="longitude">longitude</label>
                <input
                  type="longitude"
                  className="form-control"
                  name="longitude"
                  planceholder="Enter your Alias"
                  value={this.props.coords.longitude}
                  ref={this.busquedalongitude}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    ) : (
      <div>Getting the location data&hellip; </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 10000,
})(OtrosCargos);

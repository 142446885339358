import axios from "axios";
import api from "../../src/api/axiosConfigRoute";

export const RecibosCaja = (DataCajero) => {
  return axios
    .post(
      `${api}/analista/datarecibo/${DataCajero.Empresa}/${DataCajero.Sucursal}/${DataCajero.tpusuario}/${DataCajero.cajero}`,
      {
        CAJERO: DataCajero.CAJERO,
        FECHA: DataCajero.FECHA,
        ValidacionFecha: DataCajero.ValidacionFecha,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};




export const ImpresionRecibosCaja = (DataRecibo) => {
  return axios
    .post(
      `${api}/recibos/datarecibo/${DataRecibo.Empresa}/${DataRecibo.Sucursal}/${DataRecibo.tpusuario}/${DataRecibo.cajero}`,
      {
        NoReciboCaja: DataRecibo.NO_RECIBO,
        CAJERO: DataRecibo.CAJERO,
        FECHA: DataRecibo.FECHA,
        ValidacionFecha: DataRecibo.ValidacionFecha,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};
import React, { Component } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
// import ReactToExcel from "react-html-table-to-excel";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import TimerMixin from "react-timer-mixin";
import ReactToExcel from "react-html-table-to-excel";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

registerLocale("es", es);

class Reporte_Consolidado_Egreso extends Component {
  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  R_caja = (registro) => {
    return axios
      .post(
        `${api}/reporte/consolidado_egreso/${this.state.COD_NIVEL}/${this.state.SUCURSAL_C}`,
        {
          fecha_1: registro.fecha_1,
          fecha_2: registro.fecha_2,
        }
      )
      .then((response) => {
        this.setState({
          CAJA: response.data.ConsultaCajason,
          PAGOS: response.data.PAGOS,
          CORDOBA: response.data.CORDOBA,
          DOLAR: response.data.DOLAR,
        });
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("el error es ", err);
        window.alert("Verifique las fechas", err);
        window.location.reload();
      });
  };

  constructor() {
    super();
    this.state = {
      fecha_1: new Date(), //POST
      fecha_2: new Date(), //POST

      CAJA: [], //GET ARRAY
      PAGOS: "0",
      CORDOBA: "0",
      DOLAR: "0",

      SucursalA: [], //GET ARRAY
      A_SUCURSAL: "", //POST
      SUCURSAL_C: "Todas", //POST

      ALIAS: "",
      Tipo_usuario: "",
      COD_REGION: "",
      COD_NIVEL: "",
      ID_ROL: "",
      COD_EMPRESA: "",
      COD_ROL_SUC: "",

      PERMISO_ROL: "1041",
      VALIDACION: "hidden",
      fecha: new Date(),
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const registro = {
      fecha_1: this.state.fecha_1,
      fecha_2: this.state.fecha_2,
    };

    this.R_caja(registro).then((res) => {});
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        Tipo_usuario: res.data.Tipo_usuario,
        COD_REGION: res.data.COD_REGION,
        COD_NIVEL: res.data.COD_NIVEL,
        ID_ROL: res.data.ID_ROL,
        COD_EMPRESA: res.data.COD_EMPRESA_B,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
        COD_EMPRESA_B: res.data.COD_EMPRESA_B,
      });
      TimerMixin.setTimeout(() => {
      this.CONSULTA_PERMISO();
      this.consultarSucursal();
    }, 300);
    });
  }

  jsPdfGenerator = (e) => {
    e.preventDefault();
    var doc = new jsPDF("l", "pt", "letter");

    doc.text(300,20,'CONSOLIDADO DE EGRESOS');

    autoTable(doc, { html: "#body" });

    doc.save("REPORTE CONSOLIDADO DE EGRESOS.pdf");
  };
  onChange2 = (fecha) => {
    this.setState({ fecha_1: fecha });
  };

  onChange3 = (fecha1) => {
    this.setState({ fecha_2: fecha1 });
  };

  render() {
    return (
      <form>
        <div
          className="container mt-5"
          style={{ visibility: this.state.VALIDACION }}
        >
           <SisprestamosNavbar />
          <div className="row">
            <div
              className="shadow-lg p-3 mb-5 bg-white rounded  col-md-8 mx-auto border rounded border-dark"
             
            >
              <h1 className="h3 mb-4 font-weight-normal  text-center">
         CONSOLIDADO DE EGRESOS
              </h1>

              <div className="form-row">
                <div className="form-group text-center col-md-4">
                  <label htmlFor="SucursalA">SUCURSAL</label>
                  <select
                    name="SUCURSAL_C"
                    className="form-control"
                    value={this.state.SUCURSAL_C}
                    onChange={this.onChange}
                    placeholder="Campo obligatorio"
                  >
                    <option>Todas</option>
                    {this.state.SucursalA.map((item) => (
                      <option
                        key={item.COD_SUCURSAL}
                        value={item.COD_SUCURSAL}
                      >
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className="form-group text-center col-md-4 mx-auto"
                
                >
                  <label htmlFor="Fecha">FECHA INICIO : </label>

                  <DatePicker
                    selected={this.state.fecha_1}
                    onChange={this.onChange2}
                    locale="es"
                    className="form-control text-center"
                    dateFormat="dd '/' M '/' yyyy"
                  />
                </div>
                <div
                  className="form-group text-center col-md-4 mx-auto"
                  
                >
                  <label htmlFor="Fecha">FECHA FIN : </label>

                  <DatePicker
                    selected={this.state.fecha_2}
                    onChange={this.onChange3}
                    locale="es"
                    className="form-control text-center"
                    dateFormat="dd '/' M '/' yyyy"
                  />
                </div>
              </div>

                <div className="container text-center">
  <div className="row">

  <div className="col-md-1">
            
            </div> 

      <div className="col-md-3">
    <button type="button" className="btn btn-info col-md-12" onClick={this.onSubmit}>BUSCAR</button>
    </div>
    <div className="col-md-3">
    <button type="button" className="btn btn-danger col-md-12"  onClick={this.jsPdfGenerator}>PDF</button>
    </div>
    <div className="col-md-3">
    <ReactToExcel
                    className="btn btn-success col-md-12"
                    table="body"
                    filename="REPORTE CONSOLIDADO DE EGRESOS"
                    sheet="sheet 1"
                    buttonText=" EXCEL"
                  />
    </div>
  </div>
</div>
              </div>




 
             
              
            
          </div>
        </div>
        {/* <div align="center">
          <ExcelFile element= {<button>Exportar a excel</button>} filename="Ingresos financieros"> 
          <ExcelSheet data= {this.state.Creditos} name="Creditos"/>
          <Excelcolumn label="No.credito" value="COD_CREDITO"/>
          <Excelcolumn label="Fecha" value="FECHA_PAGO"/>
          <Excelcolumn label="No.Cliente" value="COD_CLIENTE"/>
          <Excelcolumn label="Analista" value="ANALISTA"/>
          <Excelcolumn label="Cliente" value="NOMBRE_CLIENTE"/>
          <Excelcolumn label="Interes pagado" value="INTERES_PAGADOS"/>
          <Excelcolumn label="Principal pagado" value="PRINCIPAL_PAGADO"/>
          <Excelcolumn label="Extra" value="EXTRA"/>
          <Excelcolumn label="Otros cargos" value="OTROS_CARGOS"/>
          <Excelcolumn label="Total pagado" value="TOTAL_PAGADO"/>
          <Excelcolumn label="Tipo movimiento" value="TIPO_MOVIMIENTO"/>
          
            
          </ExcelFile>
           
      
        </div> */}
        <div
      
        >
          <div
            className="table-responsive"
            style={{ visibility: this.state.VALIDACION }}
          >
            <table className="table table-striped " id="body">
              <thead className="text-center thead-dark">
                <tr>
                  <th>Cajero</th>
                  <th>Sucursal</th>
                  <th>Fecha</th>
                  <th>No.Egresos</th>
                  <th>Cordobas</th>
                  <th>Dolares</th>
                </tr>
              </thead>
              <tbody>
                {this.state.CAJA.map((item) => {
                  return (
                    <tr>
                      <td>{item.CAJERO}</td>
                      <td>{item.NOMBRE_SUCURSAL}</td>
                      <td style={{ width: "10%" }}>{item.FECHA}</td>
                      <td className=" text-center">{item.NO_DE_PAGOS}</td>
                      <td className=" text-center">{parseFloat(item.CORDOBAS).toFixed(4)}</td>
                      <td className=" text-center">{parseFloat(item.DOLARES).toFixed(4)}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan="2"></td>
                  <td>TOTAL</td>
                  <td className=" text-center">{parseFloat(this.state.PAGOS).toFixed(4)}</td>
                  <td className=" text-center">{parseFloat(this.state.CORDOBA).toFixed(4)}</td>
                  <td className=" text-center">{parseFloat(this.state.DOLAR).toFixed(4)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    );
  }
}

export default Reporte_Consolidado_Egreso;




import React, { Component } from "react";
import axios from "axios";
import jsPDF from 'jspdf'
import autoTable from "jspdf-autotable";
import ReactToExcel from "react-html-table-to-excel";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import TimerMixin from "react-timer-mixin";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

registerLocale("es", es);

class Reporte_saldo_cartera extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

    R_desembolso = (registro) => {
        return axios
            .post(`${api}/reporte/saldo_cartera`, {
                fecha_1: registro.fecha_1,
                SUCURSAL_B:registro.SUCURSAL_B
            })
            .then((response) => {

                this.setState({
                    ConsultaJson: response.data.ConsultaJson,
                    monto_aprobado: response.data.monto_aprobado,
                    int_pagar: response.data.int_pagar,
                    int_pagado: response.data.int_pagado,
                    cap_pagado: response.data.cap_pagado,
                    pago_extra: response.data.pago_extra,
                    saldo_interes: response.data.saldo_interes,
                    saldo_principal: response.data.saldo_principal,
                    saldo_credito: response.data.saldo_credito,
                });
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("el error es ", err);
                window.alert("Verifique las fechas", err);
                window.location.reload();
            });
    };
    constructor() {
        super();
        this.state = {
            fecha_1            : "", //POST 
           
            COD_ROL_SUC: "",
           
            ConsultaJson     : [],
            monto_aprobado   : "",
            int_pagar        : "",
            int_pagado       : "",
            cap_pagado       : "",
            pago_extra       : "",
            saldo_interes    : "",
            saldo_principal  : "",
            saldo_credito    : "",

            SucursalA    : [], //GET ARRAY
            A_SUCURSAL   : "", //POST 
            SUCURSAL_C   : "",//POST 

            Tipo_usuario : "", //RESTRICCION
            sucursal     : "",//RESTRICCION

            IP                 : [],          //GET
            alias              : "",         //GET
            fecha              : new Date(),//GET

            ID_ROL: "",
            PERMISO_ROL: "1029",
            VALIDACION: "hidden",
            errors             : {},       //GET
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
       
            e.preventDefault();
            const registro = {
              fecha_1: this.state.fecha_1,
              SUCURSAL_B: this.state.SUCURSAL_C,
            };

            this.R_desembolso(registro).then((res) => {
            });
   
    }

    componentDidMount() {
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);
        getUser(decoded.uid).then((res) => {
            this.setState({
                alias        : res.data.Alias,
                IP           : res.data.IP,
                Tipo_usuario   : res.data.Tipo_usuario,
                COD_ROL_SUC: res.data.COD_ROL_SUC,
                ID_ROL: res.data.ID_ROL,
              });   
      
              TimerMixin.setTimeout(() => {
              this.CONSULTA_PERMISO(); 
            }, 300); 
        });
    }

    jsPdfGenerator = (e) => {
        e.preventDefault();
        var doc = new jsPDF("l", "pt", "legal");

        autoTable(doc, { html: "#body" });

        doc.save("reporte.pdf");
    };
    onChange2 = (fecha) => {
        this.setState({ fecha_1: fecha });
    };

    



    render() {
        return (
          <form style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
            <div className="container mt-5">
              <div className="row">
              <div className="shadow-lg p-3 mb-5 bg-white rounded  col-md-8 mx-auto border rounded border-dark" style={{width: "200px;"}}>
                  <h1 className="h3 mb-4 font-weight-normal  text-center">
                    Reporte de saldo de cartera
                  </h1>
    
    
                  <div className="form-row">

                  <div className="form-group text-center col-md-4">
                  <label htmlFor="SucursalA">Sucursal</label>
                  <select
                    name="SUCURSAL_C"
                    className="form-control"
                    value={this.state.SUCURSAL_C}
                    onChange={this.onChange}
                    placeholder="Campo obligatorio"
                    onClick={this.consultarSucursal()}
                   
                  >
                     <option></option>
                    <option>Todas</option>
                    {this.state.SucursalA.map((item) => (
                      <option
                        key={item.NOMBRE_SUCURSAL}
                        value={item.COD_SUCURSAL}
                      >
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}

                  </select>
                </div>

                  <div className="form-group text-center col-md-4 mx-auto" style={{width: "200px;"}}>
                    <label htmlFor="Fecha">Fecha : </label>
                   
                        <DatePicker
                          selected={this.state.fecha_1}
                          onChange={this.onChange2}
                          locale="es"
                          className="form-control"
                          dateFormat="dd '/' M '/' yyyy"
                        />
                         </div> 
                     
                  </div> 
                  
                  <div className="d-flex justify-content-center">
                  <button
                    onClick={this.onSubmit}
                    className="btn btn-lg btn-primary btn-block col-md-4"
                  >
                    Buscar
                  </button>
                  </div> 
                  <br></br>
    
                  <div align="center">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        onClick={this.jsPdfGenerator}
                        className="btn btn-danger"
                      >
                        Generar pdf
                      </button>
                      <ReactToExcel
                        className="btn btn-success"
                        table="body"
                        filename="ecxelfile"
                        sheet="sheet 1"
                        buttonText="Generar excel"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div align="center">
              <ExcelFile element= {<button>Exportar a excel</button>} filename="Ingresos financieros"> 
              <ExcelSheet data= {this.state.Creditos} name="Creditos"/>
              <Excelcolumn label="No.credito" value="COD_CREDITO"/>
              <Excelcolumn label="Fecha" value="FECHA_PAGO"/>
              <Excelcolumn label="No.Cliente" value="COD_CLIENTE"/>
              <Excelcolumn label="Analista" value="ANALISTA"/>
              <Excelcolumn label="Cliente" value="NOMBRE_CLIENTE"/>
              <Excelcolumn label="Interes pagado" value="INTERES_PAGADOS"/>
              <Excelcolumn label="Principal pagado" value="PRINCIPAL_PAGADO"/>
              <Excelcolumn label="Extra" value="EXTRA"/>
              <Excelcolumn label="Otros cargos" value="OTROS_CARGOS"/>
              <Excelcolumn label="Total pagado" value="TOTAL_PAGADO"/>
              <Excelcolumn label="Tipo movimiento" value="TIPO_MOVIMIENTO"/>
              
                
              </ExcelFile>
               
          
            </div> */}
            <div
              style={{
                //  display: "flex",
                //  justifyContent: "flex-end",
                marginTop: "5px",
                marginRight: "10px",
                color: "black",
                //  width: "110%",
                // height: "50px"
              }}
            >
              <div className="table-responsive">
                <table className="table table-striped " id="body">
                  <thead className="text-center thead-dark">
                    <tr>
                      <th>No.Credito</th>
                      <th>Sucursal</th>
                      <th>Fecha desembolso</th>
                      <th>Fecha de vencimiento</th>
                      <th>Cliente</th>
                      <th>Analista</th>
                      <th>Monto aprobado</th>
                      <th>Int.Pagar</th>
                      <th>Int.Pagado</th>
                      <th>Cap.Pagado</th>
                      <th>Pago extra</th>
                      <th>Saldo interes</th>
                      <th>Saldo principal</th>
                      <th>Saldo credito</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.ConsultaJson.map((item) => {
                      return (
                        <tr>
                          <td>{item.COD_CREDITO}</td>
                          <td>{item.NOMBRE_SUCURSAL}</td>
                          <td style={{ width: "10%" }}>{item.FECHA_DESEMBOLSO}</td>
                          <td style={{ width: "10%" }}>{item.FECHA_VENCIMIENTO}</td>
                          <td>{item.NOMBRE_CLIENTE}</td>
                          <td>{item.ANALISTA}</td>
                          <td className=" text-center">{item.MONTO_TOTAL_APROBADO}</td>
                          <td className=" text-center">{item.INTERESES_PAGAR}</td>
                          <td className=" text-center">{item.INTERES_PAGADOS}</td>
                          <td className=" text-center">{item.CAPITAL_PAGADO}</td>
                          <td className=" text-center">{item.PAGO_EXTRA}</td>
                          <td className=" text-center">{item.SALDO_INTERES}</td>
                          <td className=" text-center">{item.SALDO_PRINCIPAL}</td>
                          <td className=" text-center">{item.SALDO_CREDITO}</td>
                        </tr>
                      );
                    })}
                    <tr>
                         <td colspan="5"></td>
                         <td  style={{ width: "10%" }}>Total</td>
                         <td className=" text-center">{this.state.monto_aprobado}</td>
                         <td className=" text-center">{this.state.int_pagar}</td>
                         <td className=" text-center">{this.state.int_pagado}</td>
                         <td className=" text-center">{this.state.cap_pagado}</td>
                         <td className=" text-center">{this.state.pago_extra}</td>
                         <td className=" text-center">{this.state.saldo_interes}</td>
                         <td className=" text-center">{this.state.saldo_principal}</td>
                         <td className=" text-center">{this.state.saldo_credito}</td>
                       </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        );
      }
    }
    
    export default Reporte_saldo_cartera;
    
import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import  { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import moment from "moment-timezone";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";


registerLocale("es", es);


class Solicitud extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarCliente = () => {
    const url = `${api}/registros/get/${this.state.COD_NIVEL}/${this.state.A_SUCURSAL}/${this.state.COD_REGION}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ ClienteA: resultado }));
  };

  consultarAnalista = () => {
    const url = `${api}/gestor/get/${this.state.Tipo_usuario}/${this.state.A_SUCURSAL}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ Analista_A: resultado }));
  };

  consultarPromotor = () => {
    const url = `${api}/solicitud/nombre_promotor/${this.state.Tipo_usuario}/${this.state.A_SUCURSAL}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ Promotor_A: resultado }));
  };

  consultarMoneda = () => {
    const url = `${api}/moneda/get`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ Moneda_A: resultado }));
  };

  consultarActividad = () => {
    const url = `${api}/consulta/get_actividad`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ Actividad_A: resultado }));
  };

  consultarClsf = () => {
    const url = `${api}/consulta/get_clasf/${this.state.A_SUCURSAL}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ Clasificacion_contable_A: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  consulta_datos = (registro) => {
    return axios
      .post(`${api}/registros/datos/${this.state.COD_NIVEL}/${this.state.A_SUCURSAL}/${this.state.COD_REGION}`, {
        CEDULA_INPUT: registro.CEDULA_INPUT,
        CLIENTE: registro.CLIENTE,
      })
      .then((response) => {

        this.setState({
          COD_CLIENTE: response.data.COD_CLIENTE,
          NOMBRE1: response.data.NOMBRE1,
          CEDULA: response.data.CEDULA,
          NOMBRE_NEG: response.data.NOMBRE_NEG,
          DIRECCION_ACTUAL: response.data.DIRECCION_ACTUAL,
          DIRECCION_NEG: response.data.DIRECCION_NEG,
          SUCURSAL: response.data.SUCURSAL,
          COD_SUCURSAL_CLIENTE: response.data.COD_SUCURSAL_CLIENTE,
          COD_EMPRESA_CLIENTE: response.data.COD_EMPRESA_CLIENTE,
          CREDITO_ANTERIOR:response.data.CREDITO_ANTERIOR,
          SALDO_ANTERIOR:response.data.SALDO_ANTERIOR,
        });
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("el error es ", err);
        Swal.fire(
          'Registro de solicitud',
          'Por favor verifique los datos',
          'error'
        )
        this.props.history.push('/')
        this.props.history.push('/Solicitud')
      });
  };

  constructor() {
    super();
    this.state = {
      Analista_A: [],    //ARRAY
      Moneda_A: [],   //ARRAY
      Promotor_A: [],  //ARRAY
      Actividad_A: [], //ARRAY

      CEDULA_INPUT: "",

      COD_CLIENTE: "",      //GET
      NOMBRE1: "",     //GET
      CEDULA: "",    //GET
      NOMBRE_NEG: "",   //GET
      DIRECCION_ACTUAL: "",  //GET
      DIRECCION_NEG: "",
      SUCURSAL: "", //GET
      COD_SUCURSAL_CLIENTE: "",
      COD_EMPRESA_CLIENTE:"",
      CREDITO_ANTERIOR:"",
      SALDO_ANTERIOR:"",
      codigo_sucursal:"",
      COD_ROL_SUC:"",

      COD_SUCURSAL: [],  //GET


      OBSERVACION:"",
      GASTO_DESEMBOLSO: "0",
      A_ANALISTA: "",               //POST
      A_MONEDA: "",              //POST
      A_PROMOTOR: "",             //POST
      ACTIVIDAD: "",            //POST
      GARANTIA: "",           //POST
      MONTO_SOL: "",          //POST
      PLAZO_SOL: "",         //POST
      TIC: "",        //POST
      FREC_PAG: "",       //POST
      TIPO_DESEMBOLSO: "",      //POST
      ADICIONADO_POR: "",     //POST
      TIPO_CREDITO: "",    //POST
      TIME: "",   //POST
      TIME_P: "",   //POST
      COD_SUCURSAL_U: "",  //POST
      COD_EMPRESA_U: "", //POST
      FECHA_SOLI: "", //POST
      FECHA_MODIFICADO: "", //POST

      ALIAS: "",
      Tipo_usuario: "",
      sucursal: "",
      COD_REGION: "",
      COD_NIVEL: "",
      NOMBRE_SUCURSAL: "",
      ID_ROL: "",
      ////////////////////////////////////////////////////////
      ClienteA: [],
      CLIENTE: "",
      Clasificacion_contable_A : [],
      COD_CLASF_CONTABLE   : "",
      FIADOR : "",
      CEDULA_FIADOR : "",
      ////////////////////////////////////////////////////////
      Fecha_del_dia      : new Date(),//GET
      Fecha_conv:"",
      FECHA_MOMENT_TZ:"",

      MANEJO_GASTO:"",

      SucursalA:[],
      A_SUCURSAL:"",

      PERMISO_ROL: "1001",
      VALIDACION: "hidden",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.BUSQUEDA = this.BUSQUEDA.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.reestriccion_cedula = this.reestriccion_cedula.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  BUSQUEDA(e) {
    this.setState({ [e.target.name]: e.target.value });

    this.setState({ ClienteA:[] })

    TimerMixin.setTimeout(() => {
    if (this.state.A_SUCURSAL !== ""){
      this.consultarCliente();
      this.consultarAnalista();
      this.consultarPromotor();
      this.consultarClsf();
    }
  }, 300);
  }

  onSubmit(e) {
    let tiempo = "1000";
    if (this.state.A_ANALISTA === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor ingrese el analista',
        'error'
      )
    } else if (this.state.A_MONEDA === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor ingrese la moneda',
        'error'
      )
    } else if (this.state.MONTO_SOL === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor ingrese el monto solicitado',
        'error'
      )
    } else if (this.state.TIPO_CREDITO === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor ingrese el tipo de credito',
        'error'
      )
    } else if (this.state.TIPO_DESEMBOLSO === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor ingrese el tipo de desembolso',
        'error'
      )
    } else if (this.state.FREC_PAG === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor ingrese la frecuencia de pago',
        'error'
      )
    } else if (this.state.PLAZO_SOL === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor ingrese el plazo solicitado',
        'error'
      )
    } else if (this.state.TIC === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor ingrese la TIC',
        'error'
      )
    } else if (this.state.ACTIVIDAD === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor ingrese la actividad',
        'error'
      )
    } else if (this.state.NOMBRE1 === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor busque los datos del cliente',
        'error'
      )
    } else if (this.state.A_PROMOTOR === "") {
      e.preventDefault();
      Swal.fire(
        'Registro de solicitud',
        'Por favor ingrese el promotor',
        'error'
      )
    } else {
      e.preventDefault();
      let GASTO ;
      if ( this.state.GASTO_DESEMBOLSO === "" ){
        GASTO=0
      }else{
        GASTO=this.state.GASTO_DESEMBOLSO;
      }
      Swal.fire({
        title: 'Desea registrar la solicitud',
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Registrar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // e.preventDefault();
          TimerMixin.setTimeout(
            () => {
              const newSolicitud = {
                COD_CLIENTE: this.state.COD_CLIENTE,
                COD_SUCURSAL: this.state.COD_SUCURSAL_CLIENTE,
                COD_EMPRESA: this.state.COD_EMPRESA_CLIENTE,
                A_ANALISTA: this.state.A_ANALISTA,
                A_MONEDA: this.state.A_MONEDA,
                ACTIVIDAD: this.state.ACTIVIDAD,
                GARANTIA: this.state.GARANTIA,
                MONTO_SOL: this.state.MONTO_SOL,
                PLAZO_SOL: this.state.PLAZO_SOL,
                TIC: this.state.TIC,
                FREC_PAG: this.state.FREC_PAG,
                TIPO_DESEMBOLSO: this.state.TIPO_DESEMBOLSO,
                ADICIONADO_POR: this.state.ALIAS,
                TIPO_CREDITO: this.state.TIPO_CREDITO,
                A_PROMOTOR: this.state.A_PROMOTOR,
                GASTO_DESEMBOLSO: GASTO,
                OBSERVACION:this.state.OBSERVACION,
                FECHA_SOLI:this.state.Fecha_conv,
                FECHA_MODIFICADO:this.state.Fecha_conv,
                COD_CLASF_CONTABLE:this.state.COD_CLASF_CONTABLE,
                FIADOR:this.state.FIADOR,
                CEDULA_FIADOR:this.state.CEDULA_FIADOR,
              };
              this.registro_solicitud(newSolicitud).then((res) => {
                // this.props.history.push(`/login`)
              });
            },

            tiempo
          );
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire('Registro cancelado', '', 'info')
        }
      })
    } // here else cerrare
  }
  registro_solicitud = (newSolicitud) => {
    return axios
      .post(`${api}/solicitud/cliente/registro_s`, {
        COD_CLIENTE: newSolicitud.COD_CLIENTE,
        COD_SUCURSAL: newSolicitud.COD_SUCURSAL,
        COD_EMPRESA: newSolicitud.COD_EMPRESA,
        A_ANALISTA: newSolicitud.A_ANALISTA,
        A_MONEDA: newSolicitud.A_MONEDA,
        ACTIVIDAD: newSolicitud.ACTIVIDAD,
        GARANTIA: newSolicitud.GARANTIA,
        MONTO_SOL: newSolicitud.MONTO_SOL,
        PLAZO_SOL: newSolicitud.PLAZO_SOL,
        TIC: newSolicitud.TIC,
        FREC_PAG: newSolicitud.FREC_PAG,
        TIPO_DESEMBOLSO: newSolicitud.TIPO_DESEMBOLSO,
        ADICIONADO_POR: newSolicitud.ADICIONADO_POR,
        TIPO_CREDITO: newSolicitud.TIPO_CREDITO,
        A_PROMOTOR: newSolicitud.A_PROMOTOR,
        GASTO_DESEMBOLSO: newSolicitud.GASTO_DESEMBOLSO,
        OBSERVACION:newSolicitud.OBSERVACION,
        FECHA_SOLI:newSolicitud.FECHA_SOLI,
        FECHA_MODIFICADO:newSolicitud.FECHA_MODIFICADO,
        COD_CLASF_CONTABLE:newSolicitud.COD_CLASF_CONTABLE,
        FIADOR:newSolicitud.FIADOR,
        CEDULA_FIADOR:newSolicitud.CEDULA_FIADOR,
      })
      .then((response) => {
        Swal.fire(
          'Registro de solicitud',
          'Solicitud registrada',
          'success'
        )
        this.props.history.push('/')
        this.props.history.push('/Solicitud')
      })
      .catch((err) => {
        Swal.fire(
          'Registro de solicitud',
          'Por favor verifique los datos',
          'error'
        )
        this.props.history.push('/')
        this.props.history.push('/Solicitud')
      });
  };


  obtenerDatos = (e) => {
    e.preventDefault();
    const registro = {
      CEDULA_INPUT: this.state.CEDULA_INPUT,
      CLIENTE: this.state.CLIENTE,
    };

    this.consulta_datos(registro).then((res) => {
    });


  };

  componentDidMount() {

    this.Conv_fecha()

    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        Tipo_usuario: res.data.Tipo_usuario,
        codigo_sucursal: res.data.COD_SUCURSAL_B,
        COD_REGION: res.data.COD_REGION,
        COD_NIVEL: res.data.COD_NIVEL,
        NOMBRE_SUCURSAL: res.data.COD_SUCURSAL,
        ID_ROL: res.data.ID_ROL,
        MANEJO_GASTO:res.data.MANEJO_GASTO,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
      });
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
        this.consultarSucursal();
      }, 300);
      this.consultarMoneda();
      this.consultarActividad();
      // console.log(res);
    });
   
  }

  Conv_fecha(){

    //**************************MOMENT TIME ZONE************************/
    var jun = moment(this.state.Fecha_del_dia);
    
    var fecha_time_conv=jun.tz('America/Managua').format('l');  // 5am PDT
   // var hora_time_conv=jun.tz('América/Managua').format('LT');  // 5am PDT
    
    //**************************MOMENT TIME ZONE************************/
    
    
    
    
      var hoy = new Date(this.state.Fecha_del_dia);
      var dd = hoy.getDate();
      var mm = hoy.getMonth() + 1;
      var yyyy = hoy.getFullYear();
    
      hoy = yyyy + '-' + mm + '-' + dd;
    
    
    
      this.setState({
       Fecha_conv : hoy,
       FECHA_MOMENT_TZ:fecha_time_conv,
       //HORA_MOMENT_TZ:hora_time_conv,
        });
    }

    reestriccion_cedula(e){

      if (this.state.CEDULA_FIADOR !== "") {
    
        if(this.state.CEDULA_FIADOR.length < 16){
          e.preventDefault();
          Swal.fire(
            "Registro de cliente",
            "El numero de cedula debe tener 16 caracteres",
            "error"
          );
        }else if(this.state.CEDULA_FIADOR.length > 16){
          e.preventDefault();
          Swal.fire(
            "Registro de cliente",
            "El numero de cedula debe tener 16 caracteres",
            "error"
          );
        } 
      }
    }

  render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}>  <SisprestamosNavbar />

        <div className="container mt-5" >

          <div
            className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark"
          >
            <div className="container text-left">
              <div className="row">
              <div className="col-md-3">
              <label htmlFor="A_SUCURSAL">SUCURSAL</label>
                  <input
                    list="data1"
                    name="A_SUCURSAL"
                    placeholder="Sucursal"
                    className="form-control "
                    value={this.state.A_SUCURSAL}
                    onChange={this.BUSQUEDA}
                    autoComplete="off" />
                  <datalist id="data1">
                    <option></option>
                    {this.state.SucursalA.map((item) => (
                      <option
                        key={item.COD_SUCURSAL}
                        value={item.COD_SUCURSAL}
                      >
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                  </datalist>
                </div>


              
                <div className="col-md-3">
                <label htmlFor="CLIENTE">CLIENTE</label>
                  <input
                    list="data"
                    name="CLIENTE"
                    placeholder="Nombre del cliente"
                    className="form-control "
                    value={this.state.CLIENTE}
                    onChange={this.onChange} 
                    autoComplete="off"
                    />
                  <datalist id="data">
                    <option></option>
                    {this.state.ClienteA.map((item) => (
                      <option
                        key={item.COD_CLIENTE}
                        value={item.NOMBRE1}
                      >
                        {item.NOMBRE1}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className="col-md-3">
                <label  htmlFor="CEDULA_INPUT">CEDULA</label>
                  <input
                    style={{ textTransform: 'uppercase' }}
                    type="CEDULA_INPUT"
                    className="form-control"
                    name="CEDULA_INPUT"
                    placeholder="Identificacion"
                    value={this.state.CEDULA_INPUT}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                </div>
                <div className="col-md-2 p-4">
                  <button type="submit" className="btn btn-info mb-3" onClick={this.obtenerDatos}>Buscar</button>
                </div>
              </div>
            </div>
            {/* <input  
        list="data"
        name="CLIENTE"
                      className="form-control "
                      value={this.state.CLIENTE}
                      onChange={this.onChange} />
        <datalist id="data">
                      <option></option>
                      {this.state.ClienteA.map((item) => (
                        <option
                         key={item.COD_CLIENTE}
                          value={item.NOMBRE1}
                        >
                          {item.NOMBRE1}
                        </option>
                      ))}
                    </datalist> */}

            {/* <select
                      name="CLIENTE"
                      className="form-control "
                      value={this.state.CLIENTE}
                      onChange={this.onChange}
                    >
                      <option></option>
                      {this.state.ClienteA.map((item) => (
                        <option
                          key={item.NOMBRE1}
                          value={item.COD_CLIENTE}
                        >
                          {item.NOMBRE1}
                        </option>
                      ))}
                    </select> */}


          </div>

        </div>


        <div className="container ">
          <div className="col-sm-8 mx-auto">
            <h1 className="h3 mb-4 font-weight-normal  text-center">DATOS DEL CLIENTE</h1>


            <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-2 bg-light text-dark  border rounded border-dark">
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold  text-left">
                NOMBRE{" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  ---
                </font>
                : {this.state.NOMBRE1}
              </div>
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold text-left">
                CEDULA{" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  -----
                </font>
                : {this.state.CEDULA}
              </div>
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold text-left">
                NEGOCIO{" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  --
                </font>
                : {this.state.NOMBRE_NEG}
              </div>
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold text-left">
                DIRECCION : {this.state.DIRECCION_ACTUAL}
              </div>
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold text-left">
              DIRECCION NEGOCIO: {this.state.DIRECCION_NEG}
              </div>
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold text-left">
                SUCURSAL{" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  --
                </font>
                : {this.state.SUCURSAL}
              </div>
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold text-left">
                CREDITO ANTERIOR {" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  --
                </font>
                : {this.state.CREDITO_ANTERIOR}
              </div>
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold text-left">
                SALDO ANTERIOR{" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  --
                </font>
                : {this.state.SALDO_ANTERIOR}
              </div>
            </div>
          </div>
        </div>


        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-3 mx-auto">
              <h1 className="h3 mb-4 font-weight-normal  text-center">
                SOLICITUD
              </h1>
              <div className="container jumbotrom p-3 mb-2 bg-light text-dark ">
                {/* <div className="form-group">
     <label htmlfor="ADICIONADO_POR">Usuario</label>
     <input type="ADICIONADO_POR"
     className="form-control"
     name="ADICIONADO_POR"
     planceholder="Enter your Alias"
     value={this.state.alias} 
     />
     </div>  */}
                <div className="form-row">
                  <div className="form-group col-md-4 text-center">
                    <label htmlFor="Analista_A">ANALISTA</label>
                    <select
                      name="A_ANALISTA"
                      className="form-control "
                      value={this.state.A_ANALISTA}
                      onChange={this.onChange}
                    >
                      <option></option>
                      {this.state.Analista_A.map((item) => (
                        <option
                          key={item.NOMBRE_ANALISTA}
                          value={item.COD_ANALISTA}
                        >
                          {item.NOMBRE_ANALISTA}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group col-md-4 text-center">
                    <label htmlFor="Actividad_A">ACTIVIDAD</label>
                    <select
                      name="ACTIVIDAD"
                      className="form-control "
                      value={this.state.ACTIVIDAD}
                      onChange={this.onChange}
                    >
                      <option></option>
                      {this.state.Actividad_A.map((item) => (
                        <option
                          key={item.ACTIVIDAD}
                          value={item.COD_ACTIVIDAD}
                        >
                          {item.ACTIVIDAD}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group col-md-4 text-center">
                    <label htmlFor="Moneda_A">MONEDA</label>
                    <select
                      name="A_MONEDA"
                      className="form-control"
                      value={this.state.A_MONEDA}
                      onChange={this.onChange}
                    >
                      <option></option>
                      {this.state.Moneda_A.map((item) => (
                        <option key={item.NOMBRE_MONEDA} value={item.TIPO_MONEDA}>
                          {item.NOMBRE_MONEDA}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group text-center">

                  <div className="form-row">

                    <div className="form-group col-md-4 text-center">
                      <label htmlFor="Promotor_A">PROMOTOR</label>
                      <select
                        name="A_PROMOTOR"
                        className="form-control "
                        value={this.state.A_PROMOTOR}
                        onChange={this.onChange}
                      >
                        <option></option>
                        {this.state.Promotor_A.map((item) => (
                          <option
                            key={item.NOMBRE}
                            value={item.COD_PROMOTOR}
                          >
                            {item.NOMBRE}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-md-8 text-center">
                      <label htmlFor="GARANTIA">GARANTIA</label>
                      <input
                        type="GARANTIA"
                        className="form-control"
                        name="GARANTIA"
                        planceholder="Enter your Password"
                        value={this.state.GARANTIA}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-4 text-center ">
                    <label htmlFor="TIPO_CREDITO">TIPO DE CREDITO</label>
                    <select
                      name="TIPO_CREDITO"
                      className="form-control"
                      value={this.state.TIPO_CREDITO}
                      onChange={this.onChange}
                    >
                      <option></option>
                      <option>Nuevo</option>
                      <option>Represtamo</option>
                      <option>Reestructurado</option>
                      <option>Reactivado</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4  text-center">
                    <label htmlFor="TIPO_DESEMBOLSO">TIPO DE DESEMBOLSO</label>
                    <select
                      name="TIPO_DESEMBOLSO"
                      className="form-control"
                      value={this.state.TIPO_DESEMBOLSO}
                      onChange={this.onChange}
                    >
                      <option></option>
                      <option>Efectivo</option>
                      <option>Cheque</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4 text-center">
                    <label htmlFor="FREC_PAG">FRECUENCIA DE PAGO</label>
                    <select
                      name="FREC_PAG"
                      className="form-control"
                      value={this.state.FREC_PAG}
                      onChange={this.onChange}
                    >
                      <option></option>
                      <option>Diario</option>
                      <option>Semanal</option>
                      <option>Quincenal</option>
                      <option>Mensual</option>
                      <option>Al Vencimiento</option>
                    </select>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4 text-center">
                    <label htmlFor="MONTO_SOL">MONTO SOLICITADO</label>
                    <input
                      type="MONTO_SOL"
                      className="form-control text-right"
                      name="MONTO_SOL"
                      placeholder="                 Campo obligatorio"
                      value={this.state.MONTO_SOL}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group col-md-4 text-center ">
                    <label htmlFor="PLAZO_SOL">PLAZO SOLICITADO</label>
                    <input
                      type="PLAZO_SOL"
                      className="form-control text-right"
                      name="PLAZO_SOL"
                      placeholder="                Campo obligatorio"
                      value={this.state.PLAZO_SOL}
                      onChange={this.onChange}
                    />
                  </div>
                  <div className="form-group col-md-4 text-center">
                    <label htmlFor="TIC">TIC</label>
                    <input
                      type="TIC"
                      className="form-control text-right"
                      name="TIC"
                      placeholder="                Campo obligatorio"
                      value={this.state.TIC}
                      onChange={this.onChange}
                    />
                  </div>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="GASTO_DESEMBOLSO">{this.state.MANEJO_GASTO}</label>
                      <input
                        type="GASTO_DESEMBOLSO"
                        className="form-control text-right"
                        name="GASTO_DESEMBOLSO"
                        value={this.state.GASTO_DESEMBOLSO}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="col-md-8">
                      <label htmlFor="OBSERVACION">OBSERVACIONES</label>
                      <input
                        type="OBSERVACION"
                        className="form-control"
                        name="OBSERVACION"
                        value={this.state.OBSERVACION}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="container">
  <div className="row">
    <div className="col-md-4">
    <label htmlFor="Clasificacion_contable_A">CLASF.CONTABLE</label>
                    <select
                      name="COD_CLASF_CONTABLE"
                      className="form-control text-center"
                      value={this.state.COD_CLASF_CONTABLE}
                      onChange={this.onChange}
                    >
                      <option></option>
                      {this.state.Clasificacion_contable_A.map((item) => (
                        <option
                          key={item.DESCRIPCION}
                          value={item.TIPO_CREDITO}
                        >
                          {item.DESCRIPCION}
                        </option>
                      ))}
                    </select>
    </div>
    <div className="col-md-4">
    <label htmlFor="FIADOR">FIADOR</label>
                  <input
                style={{ textTransform: 'uppercase'}}
                    type="FIADOR"
                    className="form-control"
                    name="FIADOR"
                   // placeholder="                       Campo obligatorio"
                    value={this.state.FIADOR}
                    onChange={this.onChange}
                  />
    </div>
    <div className="col-md-4">
    <label htmlFor="CEDULA_FIADOR">CEDULA FIADOR</label>
                  <input
                    style={{ textTransform: 'uppercase'}}
                    type="CEDULA_FIADOR"
                    className="form-control"
                    name="CEDULA_FIADOR"
                    //placeholder="                       Campo obligatorio"
                    value={this.state.CEDULA_FIADOR}
                    onChange={this.onChange}
                    onBlur={this.reestriccion_cedula}
                  />
    </div>
  </div>
</div>



                <div className="d-flex justify-content-center">
                  <button
                    onClick={this.onSubmit}
                    className="btn btn-lg btn-primary btn-block col-md-4 "
                  >
                    Registrar
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>

      </form>

    );
  }
}
export default Solicitud;

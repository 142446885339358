import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
// import { registerLocale } from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import es from "date-fns/locale/es";
// import moment from "moment-timezone";
// registerLocale("es", es);
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import { DatosTipoCredito } from "../../GlobalFuntions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

class Aprobacion extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };


  consultarEmpresa = () => {
    const url = `${api}/empresa/get`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ EmpresaA: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

      consultarCliente = () => {
        const url = `${api}/solicitud/pendiente/${this.state.COD_SUCURSAL_B}`;
        //console.log(url);
        fetch(url)
          .then((respuesta) => respuesta.json())
          .then((resultado) => this.setState({ ClienteA: resultado }));
      };

 
  constructor() {
    super();
    this.state = {
      ALIAS: "",
      Tipo_usuario: "",
      COD_SUCURSAL: "",
      COD_REGION: "",
      COD_NIVEL: "",
      NOMBRE_SUCURSAL: "",
      ID_ROL: "",
      COD_ROL_SUC: "",


        SucursalA:[],
        EmpresaA:[],
        ClienteA:[],

        COD_EMPRESA_B:"",
        COD_SUCURSAL_B:"",
        CLIENTE:"",

  NO_SOLI:"",
  COD_EMPRESA_R:"",
  COD_SUCURSAL_R:"",
  NOMBRE_EMPRESA:"",
  NOMBRE_SUCURSAL_R:"",
  FECHA_SOLI:"",
  COD_CLIENTE:"",
  NOMBRE1:"",
  TIPO_CREDITO:"",
  COD_ANALISTA:"",
  NOMBRE_ANALISTA:"",
  COD_PROMOTOR:"",
  NOMBRE:"",
  TIPO_MONEDA:"",
  NOMBRE_MONEDA:"",
  MONTO_SOL:"",
  PLAZO_SOL:"",
  TIC:"",
  TIM:"",
  FREC_PAG:"",
  OBSERVACION:"",
  GASTO_DESEMB:"",
  CREDITO_ANTERIOR:"",
  SALDO_ANTERIOR:"",
  COD_CLASF_CONTABLE:"",
  SEXO:"",

  MONTO_CRED:"",
  PLAZO_CRED:"",
  TIC_CRED:"",

  TIM_CRED:"",
  FREC_PAGO_CRED:"",
  TIP_DESEMB_CRED:"",
  TIPO_PLANPAGO:"",

  BTN_APL: "true",

  MANEJO_GASTO:"",

  TipoFormalizacion:[],

  PERMISO_ROL: "1002",
  VALIDACION: "hidden",
  errors: {},
    };
    this.onChange = this.onChange.bind(this); 
    this.BUSQUEDA = this.BUSQUEDA.bind(this); 
    this.obtenerdatos = this.obtenerdatos.bind(this); 
    this.onSubmit = this.onSubmit.bind(this); 
  }

  BTN_APL_DESAC() {
    this.setState({ BTN_APL: "false" });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    var tiempo = "300";
    TimerMixin.setTimeout(() => {
    if (this.state.TIC_CRED !== ""){
      let TIC=parseFloat(this.state.TIC_CRED);

      let calculo=(parseFloat(TIC) / 4).toFixed(2)

      this.setState({TIM_CRED:calculo});
  }
  }, tiempo);
  }

  BUSQUEDA(e) {
    this.setState({ [e.target.name]: e.target.value });
  
  
    let tiempo = '300'
      TimerMixin.setTimeout(() => {
       
        if (this.state.COD_EMPRESA_B !== ""){
          this.DatosFormalizacion();
          if (this.state.COD_SUCURSAL_B !== ""){
          this.consultarCliente();
          }
        }else{
          this.setState({
            TipoFormalizacion: [],
          });
        }
      }, tiempo);
  }

  DatosFormalizacion (){
    DatosTipoCredito(this.state.COD_EMPRESA_B).then((res) => {
      this.setState({
        TipoFormalizacion: res.data.DATOS,
      });
    });
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        Tipo_usuario: res.data.Tipo_usuario,
        COD_REGION: res.data.COD_REGION,
        COD_NIVEL: res.data.COD_NIVEL,
        NOMBRE_SUCURSAL: res.data.COD_SUCURSAL,
        COD_EMPRESA_USUARIO: res.data.COD_EMPRESA_B,
        COD_EMPRESA_B: res.data.COD_EMPRESA_B,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
        MANEJO_GASTO:res.data.MANEJO_GASTO,
      });
      
      let tiempo = '300'
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
        this.consultarEmpresa();
        this.consultarSucursal();
        if (this.state.COD_EMPRESA_B !== ""){
          this.DatosFormalizacion();
        }
      }, tiempo);
    });
  }

  consulta = (dato) => {

    return axios
      .post(`${api}/solicitud/datos`, {
        COD_EMPRESA: dato.COD_EMPRESA,
        COD_SUCURSAL: dato.COD_SUCURSAL,
        CLIENTE: dato.CLIENTE,
      })
      .then((response) => {
        this.setState({
          NO_SOLI:response.data.NO_SOLI,
          COD_EMPRESA_R:response.data.COD_EMPRESA_R,
          COD_SUCURSAL_R:response.data.COD_SUCURSAL_R,
          NOMBRE_EMPRESA:response.data.NOMBRE_EMPRESA,
          NOMBRE_SUCURSAL_R:response.data.NOMBRE_SUCURSAL_R,
          FECHA_SOLI:response.data.FECHA_SOLI,
          COD_CLIENTE:response.data.COD_CLIENTE,
          NOMBRE1:response.data.NOMBRE1,
          TIPO_CREDITO:response.data.TIPO_CREDITO,
          COD_ANALISTA:response.data.COD_ANALISTA,
          NOMBRE_ANALISTA:response.data.NOMBRE_ANALISTA,
          COD_PROMOTOR:response.data.COD_PROMOTOR,
          NOMBRE:response.data.NOMBRE,
          TIPO_MONEDA:response.data.TIPO_MONEDA,
          NOMBRE_MONEDA:response.data.NOMBRE_MONEDA,
          MONTO_SOL:response.data.MONTO_SOL,
          PLAZO_SOL:response.data.PLAZO_SOL,
          TIC:response.data.TIC,
          TIM:response.data.TIM,
          FREC_PAG:response.data.FREC_PAG,
          OBSERVACION:response.data.OBSERVACION,
          GASTO_DESEMB:response.data.GASTO_DESEMB,
          CREDITO_ANTERIOR:response.data.CREDITO_ANTERIOR,
          SALDO_ANTERIOR:response.data.SALDO_ANTERIOR,
          COD_CLASF_CONTABLE:response.data.COD_CLASF_CONTABLE,
          SEXO:response.data.SEXO,
        })

      })
      .catch((err) => {
        console.log(err)
        window.alert("Verifique los Datos");
        window.location.reload();
      });
  };

  obtenerdatos(e) {
    e.preventDefault();

    let tiempo = "100";
    TimerMixin.setTimeout(() => {
      const dato = {
        CLIENTE: this.state.CLIENTE,
        COD_EMPRESA: this.state.COD_EMPRESA_B,
        COD_SUCURSAL: this.state.COD_SUCURSAL_B,
      };

      this.consulta(dato).then((res) => { });
    }, tiempo);


  }

  registro = (newSolicitud) => {

    return axios
      .post(`${api}/credito/registro_aprob`, {
         COD_EMPRESA: newSolicitud.COD_EMPRESA,
         NO_SOLI: newSolicitud.NO_SOLI,
         COD_CLASF_CONTABLE : newSolicitud.COD_CLASF_CONTABLE,
         TIPO_MONEDA : newSolicitud.TIPO_MONEDA,
         PLAZO_APROBADO: newSolicitud.PLAZO_APROBADO,
         MONTO_TOTAL_APROBADO : newSolicitud.MONTO_TOTAL_APROBADO,
         TIC_APROBADO : newSolicitud.TIC_APROBADO,
         TIM_APROBADO : newSolicitud.TIM_APROBADO,
         FREC_PAG_APROBADO : newSolicitud.FREC_PAG_APROBADO,
         TIPO_DESEMBOLSO_APROBADO : newSolicitud.TIPO_DESEMBOLSO_APROBADO,
         ANALISTA : newSolicitud.ANALISTA,
         NOMBRE_CLIENTE : newSolicitud.NOMBRE_CLIENTE,
         COD_CLIENTE: newSolicitud.COD_CLIENTE,
         COD_SUCURSAL : newSolicitud.COD_SUCURSAL,
         ADICIONADO_POR : newSolicitud.ADICIONADO_POR,
         TIPO_CREDITO : newSolicitud.TIPO_CREDITO,
         NOMBRE_GESTOR: newSolicitud.NOMBRE_GESTOR,
         CREDITO_ANTERIOR : newSolicitud.CREDITO_ANTERIOR,
         SALDO_ANTERIOR: newSolicitud.SALDO_ANTERIOR,
         SEXO : newSolicitud.SEXO,
         COD_PROMOTOR : newSolicitud.COD_PROMOTOR,
         TIPO_PLANPAGO : newSolicitud.TIPO_PLANPAGO,
         GASTO_DESEMB : newSolicitud.GASTO_DESEMB,
      })
      .then((response) => {
        Swal.fire(
          "Aprobacion De Credito",
          response.data.mensaje,
                response.data.alerta
          // 'Registrado',
          // 'SUCCESS'
        )
        let tiempo = "300";
        TimerMixin.setTimeout(() => {
          if (response.data.alerta === "error") {
            this.setState({
              BTN_APL: "true",
            });
          } else {

         
            TimerMixin.setTimeout(() => {
            window.location.reload();
          }, 300);
          }
        }, tiempo);
      })
      .catch((err) => {
        console.log(err)
        window.alert("Verifique los Datos");
        window.location.reload();
      });
  };


  onSubmit(e) {

    if (this.state.NO_SOLI === "") {
      e.preventDefault();
      Swal.fire(
        "Aprobacion De Credito",
        "Por favor busque los datos del cliente",
        "error"
      );
    }else if (this.state.MONTO_CRED === "") {
        e.preventDefault();
        Swal.fire(
          "Aprobacion De Credito",
          "Por favor ingrese el monto",
          "error"
        );
      } else if (this.state.PLAZO_CRED === "") {
        e.preventDefault();
        Swal.fire(    "Aprobacion De Credito", 
        "Por favor ingrese el plazo", "error");
      } else if (this.state.TIC_CRED === "") {
        e.preventDefault();
        Swal.fire(    "Aprobacion De Credito", 
        "Por favor ingrese la TIC", "error");
      } else if (this.state.FREC_PAGO_CRED === "") {
        e.preventDefault();
        Swal.fire(
              "Aprobacion De Credito",
          "Por favor ingrese la frecuencia de pago",
          "error"
        );
      } else if (this.state.TIP_DESEMB_CRED === "") {
        e.preventDefault();
        Swal.fire(
              "Aprobacion De Credito",
          "Por favor ingrese el tipo de desembolso",
          "error"
        );
      } else if (this.state.TIPO_PLANPAGO === "") {
        e.preventDefault();
        Swal.fire(
              "Aprobacion De Credito",
          "Por favor seleccione un tipo de plan de pago",
          "error"
        );
      } else{
      // else if (this.state.Tipo_usuario === 1 || this.state.Tipo_usuario === 2) { 
    e.preventDefault();
    let GASTO ;
    if ( this.state.GASTO_DESEMB === "" ){
      GASTO=0
    }else{
      GASTO=this.state.GASTO_DESEMB;
    }
    

    Swal.fire({
      title: 'Desea aprobar el siguiente credito',
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: `Aprobar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.BTN_APL_DESAC();
        let timerInterval
        Swal.fire({
          title:      "Aprobacion De Credito",
          html: 'La Solicitud Se Estan Aprobando',
          timer: 2000000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            try {
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = Swal.getTimerLeft()
            }, 2000000)

          } catch (error) {
                  
          }
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer')
          }
        })
        const newSolicitud = {

  COD_EMPRESA:this.state.COD_EMPRESA_R,
  NO_SOLI:this.state.NO_SOLI,
  COD_CLASF_CONTABLE :this.state.COD_CLASF_CONTABLE,
  TIPO_MONEDA :this.state.TIPO_MONEDA,
  PLAZO_APROBADO:this.state.PLAZO_CRED,
  MONTO_TOTAL_APROBADO :this.state.MONTO_CRED,
  TIC_APROBADO :this.state.TIC_CRED,
  TIM_APROBADO :this.state.TIM_CRED,
  FREC_PAG_APROBADO :this.state.FREC_PAGO_CRED,
  TIPO_DESEMBOLSO_APROBADO :this.state.TIP_DESEMB_CRED,
  ANALISTA :this.state.NOMBRE_ANALISTA,
  NOMBRE_CLIENTE :this.state.NOMBRE1,
  COD_CLIENTE:this.state.COD_CLIENTE,
  COD_SUCURSAL :this.state.COD_SUCURSAL_R,
  ADICIONADO_POR :this.state.ALIAS,
  TIPO_CREDITO :this.state.TIPO_CREDITO,
  NOMBRE_GESTOR:this.state.NOMBRE_ANALISTA,
  CREDITO_ANTERIOR :this.state.CREDITO_ANTERIOR,
  SALDO_ANTERIOR:this.state.SALDO_ANTERIOR,
  SEXO :this.state.SEXO,
  COD_PROMOTOR :this.state.COD_PROMOTOR,
  TIPO_PLANPAGO:this.state.TIPO_PLANPAGO,
  GASTO_DESEMB:GASTO,
        };
        this.registro(newSolicitud).then((res) => { });

        // Swal.fire('Registrado!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Registro cancelado', '', 'info')
      }
    })

// } else{
//   e.preventDefault();
//   Swal.fire(
//         "Aprobacion De Credito",
//     "No tiene permiso para usar este modulo",
//     "error"
//   );
// }
}
  }
 
  render() {
    return (
      <form>
           
        <div style={{ visibility: this.state.VALIDACION }}>     <SisprestamosNavbar />
           
        <div className="container mt-5"  >
          <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
            <div className="row">
              <div className="col-md-3">
                <div style={{ textAlign: "left" }} htmlFor="COD_EMPRESA_B">
                  EMPRESA
                </div>
                <select
                  name="COD_EMPRESA_B"
                  className="form-control"
                  value={this.state.COD_EMPRESA_B}
                  onChange={this.BUSQUEDA}
                >
                  <option></option>
                  {this.state.EmpresaA.map((item) => (
                    <option key={item.NOMBRE_EMPRESA} value={item.COD_EMPRESA}>
                      {item.NOMBRE_EMPRESA}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <div style={{ textAlign: "left" }} htmlFor="COD_SUCURSAL_B">
                  SUCURSAL
                </div>
                <select
                  name="COD_SUCURSAL_B"
                  className="form-control"
                  value={this.state.COD_SUCURSAL_B}
                  onChange={this.BUSQUEDA}
                  placeholder="Campo obligatorio"
                >
                  <option></option>
                  {this.state.SucursalA.map((item) => (
                    <option
                      key={item.NOMBRE_SUCURSAL}
                      value={item.COD_SUCURSAL}
                    >
                      {item.NOMBRE_SUCURSAL}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="CLIENTE">
                  NO.SOLICITUD
                </div>
                <input
                  list="data"
                  name="CLIENTE"
                  className="form-control "
                  value={this.state.CLIENTE}
                  onChange={this.onChange}
                  autoComplete="off"
                />
                <datalist id="data">
                  <option></option>
                  {this.state.ClienteA.map((item) => (
                    <option key={item.NO_SOLI} value={item.NO_SOLI}>
                      {item.NOMBRE1}
                    </option>
                  ))}
                </datalist>
              </div>
              <div  className="orm-group col-md-2 p-4" onClick={this.obtenerdatos}>
                <button type="submit" className="btn btn-success ">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-2">
                        <div className="row">
                            <div className="shadow-lg p-2 mb-5 bg-white rounded  col-md-12 mx-auto border rounded border-dark" >
        <div className="container p-3">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <h1 className="h3 mb-0 font-weight-normal  text-center">
            APROBACION DE CREDITO
              </h1>
            </div>
          </div>
          <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-0 bg-light text-dark border rounded border-dark">
                      <div className="form-row">
                       
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          EMPRESA{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            --
                          </font>
                          : {this.state.NOMBRE_EMPRESA}
                        </div>

                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          SUCURSAL{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            ---
                          </font>
                          : {this.state.NOMBRE_SUCURSAL_R}
                        </div>
    
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          FECHA{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            ---
                          </font>
                          : {this.state.FECHA_SOLI}
                        </div>
                      
                      </div>
    
                      <div className="row">
                    
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6 text-left">
                          CLIENTE{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            ----
                          </font>
                          : {this.state.NOMBRE1}
                        </div>

                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6 text-left">
                          TIPO{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            --
                          </font>
                          : {this.state.TIPO_CREDITO}
                        </div>

    
                      {/* </div>
                       */}
                    </div>
    
                    <div className="row">

                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6 text-left">
                      ANALISTA{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ----
                      </font>
                      : {this.state.NOMBRE_ANALISTA}
                    </div>
                    
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6 text-left">
                      PROMOTOR{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.NOMBRE}
                    </div>
    
                  
                  

                    
    
                  {/* </div>
                   */}
                </div>
                <div className="row">
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                      MONEDA{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.NOMBRE_MONEDA}
                    </div>
                    
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                      MONTO{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ----
                      </font>
                      : {this.state.MONTO_SOL}
                    </div>

                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                      PLAZO{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.PLAZO_SOL}
                    </div>

              
                  {/* </div>
                   */}
                </div>
                   <div className="row">
                    
                   <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                  TIC{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    ----
                  </font>
                  : {this.state.TIC}
                </div>
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                  TIM{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    ----
                  </font>
                  : {this.state.TIM}
                </div>
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                  FRECUENCIA{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    ----
                  </font>
                  : {this.state.FREC_PAG}
                </div>

                </div>

  {/* <div className="row">
                    
  <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12">
                      OBSERVACIONES{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.OBSERVACION}
                    </div>

                </div> */}


             
                <div className="row">
                    
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                      CREDITO ANTERIOR{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ----
                      </font>
                      : {this.state.CREDITO_ANTERIOR}
                    </div>

                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                      SALDO ANTERIOR{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ---
                      </font>
                      : {this.state.SALDO_ANTERIOR}
                    </div>

                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                     
                    </div>
                </div>
       
               
                <div className="row">
                    
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12 text-left">
                      OBSERVACION{" "}
                      <font size="1" color="white" style={{ visibility: "hidden" }}>
                        ----
                      </font>
                      : {this.state.OBSERVACION}
                    </div>
                </div>
                  </div>

                  <div  className="container">
  <div  className="row">
    <div  className="col-md-3">
    <div style={{textAlign: "left"}} htmlFor="MONTO_CRED">MONTO</div>
                  <input
                    type="MONTO_CRED"
                    className="form-control text-right"
                    name="MONTO_CRED"
                    planceholder="Enter your Password"
                    value={this.state.MONTO_CRED}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
    </div>
    <div  className="col-md-3">
    <div style={{textAlign: "left"}} htmlFor="PLAZO_CRED">PLAZO / MESES</div>
                  <input
                    type="PLAZO_CRED"
                    className="form-control text-right"
                    name="PLAZO_CRED"
                    planceholder="Enter your Password"
                    value={this.state.PLAZO_CRED}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
    </div>
    <div  className="col-md-2">
    <div style={{textAlign: "left"}} htmlFor="TIC_CRED">TIC</div>
                  <input
                    type="TIC_CRED"
                    className="form-control text-right"
                    name="TIC_CRED"
                    planceholder="Enter your Password"
                    value={this.state.TIC_CRED}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
    </div>
    <div  className="col-md-2">
    <div style={{textAlign: "left"}} htmlFor="TIM_CRED">TIM</div>
                  <input
                    type="TIM_CRED"
                    className="form-control text-right"
                    name="TIM_CRED"
                    planceholder="Enter your Password"
                    value={this.state.TIM_CRED}
                    onChange={this.onChange}
                    disabled = "disabled"
                  />
    </div>
    <div  className="col-md-2">
    <div style={{textAlign: "left"}} htmlFor="GASTO_DESEMB">{this.state.MANEJO_GASTO}</div>
                  <input
                    type="GASTO_DESEMB"
                    className="form-control text-right"
                    name="GASTO_DESEMB"
                    planceholder="Enter your Password"
                    value={this.state.GASTO_DESEMB}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
    </div>
  </div>
  
</div>
<div  className="container">
  <div  className="row">
   
    <div  className="col-md-4">
    <div style={{textAlign: "left"}} htmlFor="FREC_PAGO_CRED">FRECUENCIA DE PAGO</div>
    <select
                      name="FREC_PAGO_CRED"
                      className="form-control text-center"
                      value={this.state.FREC_PAGO_CRED}
                      onChange={this.onChange}
                      autoComplete="off"
                    >
                      <option></option>
                      <option>Diario</option>
                      <option>Semanal</option>
                      <option>Quincenal</option>
                      <option>Mensual</option>
                      <option>Al Vencimiento</option>
                    </select>
    </div>
    <div  className="col-md-4">
    <div style={{textAlign: "left"}} htmlFor="TIP_DESEMB_CRED">TIPO DE DESEMBOLSO</div>
    <select
                      name="TIP_DESEMB_CRED"
                      className="form-control text-center"
                      value={this.state.TIP_DESEMB_CRED}
                      onChange={this.onChange}
                      autoComplete="off"
                    >
                      <option></option>
                      <option>Efectivo</option>
                      <option>Cheque</option>
                    </select>
                
           
    </div>

    <div  className="col-md-4">
    <div style={{textAlign: "left"}} htmlFor="TIPO_PLANPAGO">PLAN DE PAGO</div>
    <select
                  list="DataCred"
                  name="TIPO_PLANPAGO"
                  className="form-control text-center"
                  value={this.state.TIPO_PLANPAGO}
                  onChange={this.onChange}
                  autoComplete="off"
                >

                  <option></option>
                  {this.state.TipoFormalizacion.map((item) => (
                    <option key={item.COD_PLANPAGO} value={item.TIPO}>
                      {item.TIPO}
                    </option>
                  ))}
            
                
                </select>
    </div>
  </div>
</div>
<div className="container">
  <div className="row">
  <div className="col-md-4">  </div>
    <div className="col-md-4">
    <font size="1" color="white" style={{ visibility: "hidden" }}>
    Column
  
                  </font>   
                  <button
                     onClick={this.onSubmit}
                    className="btn btn-lg btn-dark col-md-12"
                    disabled={this.state.BTN_APL === "false" ? true : false }
                  >
                    APROBAR
                  </button>
    </div>
    <div className="col-md-4">
  
    </div>
  </div>
</div>
        </div>
        </div>
          </div>
        </div>
        </div>
      </form>
    );
}
}
export default Aprobacion;


import React, { Component } from "react";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";

import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import { PermisoModulo } from "../../GlobalFuntions"; // Usuarios
import { Empresa } from "../../GlobalFuntions"; // Usuarios
import { DatosEmpresa } from "../../GlobalFuntions"; // Usuarios
import { Sucursal } from "../../GlobalFuntions"; // Usuarios

import { ConsolidadoMora } from "../../DataReportes"; // Usuarios

import SisprestamosNavbar from "../../SisprestamosNavbar";

import "../../TableCss/ReportTable.css";

class ResumenMoraDias extends Component {
  constructor() {
    super();
    this.state = {
      Id: "",
      ALIAS: "",
      NombreUsuario: "",

      IMAGEN: "",
      width: "",
      height: "",
      REPORT_WIDTH: "",
      REPORT_HEIGHT: "",

      DatoEmpresa: [],
      COD_EMPRESA_B: "",
      DatoSucursal: [],
      COD_SUCURSAL_B: "",

      DataReporte1: [],
      TotalMONTO_MORA1: "",
      TotalSALDO_EXPUESTO1: "",
      TotalCANTIDAD_CLIENTES1: "",
      PorcentajeMora1: "",

      DataReporte2: [],
      TotalMONTO_MORA2: "",
      TotalSALDO_EXPUESTO2: "",
      TotalCANTIDAD_CLIENTES2: "",
      PorcentajeMora2: "",

      DataReporte3: [],
      TotalMONTO_MORA3: "",
      TotalSALDO_EXPUESTO3: "",
      TotalCANTIDAD_CLIENTES3: "",
      PorcentajeMora3: "",

      DataReporte4: [],
      TotalMONTO_MORA4: "",
      TotalSALDO_EXPUESTO4: "",
      TotalCANTIDAD_CLIENTES4: "",
      PorcentajeMora4: "",

      DataReporte5: [],
      TotalMONTO_MORA5: "",
      TotalSALDO_EXPUESTO5: "",
      TotalCANTIDAD_CLIENTES5: "",
      PorcentajeMora5: "",

      DataReporte6: [],
      TotalMONTO_MORA6: "",
      TotalSALDO_EXPUESTO6: "",
      TotalCANTIDAD_CLIENTES6: "",
      PorcentajeMora6: "",

      DataReporte7: [],
      TotalMONTO_MORA7: "",
      TotalSALDO_EXPUESTO7: "",
      TotalCANTIDAD_CLIENTES7: "",
      PorcentajeMora7: "",

      DataReporte8: [],
      TotalMONTO_MORA8: "",
      TotalSALDO_EXPUESTO8: "",
      TotalCANTIDAD_CLIENTES8: "",
      PorcentajeMora8: "",

      TotalMora: [],
      TotalSaldo: "",
      TotalCantidad: "",
      Porcentaje: "",

      PERMISO_ROL: "1047",
      VALIDACION: "hidden",
      BTN_APL: true,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.JSPDF = this.JSPDF.bind(this);
    this.ExcelData = this.ExcelData.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        Id: res.data.COD_USUARIO,
        ALIAS: res.data.Alias,
        NombreUsuario: res.data.Nombre,
      });
      let tiempo = 300;
      TimerMixin.setTimeout(() => {
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Empresa().then((res) => {
          this.setState({
            DatoEmpresa: res.data.EMPRESA,
            COD_EMPRESA_B: res.data.EMPRESA[0].COD_EMPRESA,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Sucursal(res.data.COD_ROL_SUC).then((res) => {
          this.setState({
            DatoSucursal: res.data.SUCURSAL,
            COD_SUCURSAL_B: res.data.SUCURSAL[0].COD_SUCURSAL,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        PermisoModulo(res.data.ID_ROL, this.state.PERMISO_ROL).then((res) => {
          this.setState({
            VALIDACION: res.data.ESTADO,
          });
        });
        TimerMixin.setTimeout(() => {
          // if (this.state.COD_EMPRESA_B !== "" && this.state.IMAGEN === "") {
          ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
          DatosEmpresa(this.state.COD_EMPRESA_B).then((res) => {
            this.setState({
              IMAGEN: res.data.IMAGEN,
              width: res.data.width,
              height: res.data.height,
              REPORT_WIDTH: res.data.REPORT_WIDTH,
              REPORT_HEIGHT: res.data.REPORT_HEIGHT,
            });
          });
          // }
        }, 500);
      }, tiempo);
    });
  }

  Limpiar = () => {
    this.setState({
      DataReporte1: [],
      TotalMONTO_MORA1: "",
      TotalSALDO_EXPUESTO1: "",
      TotalCANTIDAD_CLIENTES1: "",
      PorcentajeMora1: "",

      DataReporte2: [],
      TotalMONTO_MORA2: "",
      TotalSALDO_EXPUESTO2: "",
      TotalCANTIDAD_CLIENTES2: "",
      PorcentajeMora2: "",

      DataReporte3: [],
      TotalMONTO_MORA3: "",
      TotalSALDO_EXPUESTO3: "",
      TotalCANTIDAD_CLIENTES3: "",
      PorcentajeMora3: "",

      DataReporte4: [],
      TotalMONTO_MORA4: "",
      TotalSALDO_EXPUESTO4: "",
      TotalCANTIDAD_CLIENTES4: "",
      PorcentajeMora4: "",

      DataReporte5: [],
      TotalMONTO_MORA5: "",
      TotalSALDO_EXPUESTO5: "",
      TotalCANTIDAD_CLIENTES5: "",
      PorcentajeMora5: "",

      DataReporte6: [],
      TotalMONTO_MORA6: "",
      TotalSALDO_EXPUESTO6: "",
      TotalCANTIDAD_CLIENTES6: "",
      PorcentajeMora6: "",

      DataReporte7: [],
      TotalMONTO_MORA7: "",
      TotalSALDO_EXPUESTO7: "",
      TotalCANTIDAD_CLIENTES7: "",
      PorcentajeMora7: "",

      DataReporte8: [],
      TotalMONTO_MORA8: "",
      TotalSALDO_EXPUESTO8: "",
      TotalCANTIDAD_CLIENTES8: "",
      PorcentajeMora8: "",

      TotalMora: [],
      TotalSaldo: "",
      TotalCantidad: "",
      Porcentaje: "",
    });
  };

  //+++++++++++++++ BOTON BUSCAR DATA ++++++++++++++++++
  obtenerDatos = () => {
    this.Limpiar();
    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Resumen de Mora",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    } else if (this.state.COD_SUCURSAL_B === "") {
      Swal.fire(
        "Resumen de Mora",
        "Por Favor Seleccione una Sucursal",
        "error"
      );
    } else {
      const Data = {
        CodigoEmpresa: this.state.COD_EMPRESA_B,
        CodigoSucursal: this.state.COD_SUCURSAL_B,
      };
      ConsolidadoMora(Data).then((res) => {
        this.setState({
          DataReporte1: res.data.DataReporte1,
          TotalMONTO_MORA1: res.data.TotalMONTO_MORA1,
          TotalSALDO_EXPUESTO1: res.data.TotalSALDO_EXPUESTO1,
          TotalCANTIDAD_CLIENTES1: res.data.TotalCANTIDAD_CLIENTES1,
          PorcentajeMora1: res.data.PorcentajeMora1,

          DataReporte2: res.data.DataReporte2,
          TotalMONTO_MORA2: res.data.TotalMONTO_MORA2,
          TotalSALDO_EXPUESTO2: res.data.TotalSALDO_EXPUESTO2,
          TotalCANTIDAD_CLIENTES2: res.data.TotalCANTIDAD_CLIENTES2,
          PorcentajeMora2: res.data.PorcentajeMora2,

          DataReporte3: res.data.DataReporte3,
          TotalMONTO_MORA3: res.data.TotalMONTO_MORA3,
          TotalSALDO_EXPUESTO3: res.data.TotalSALDO_EXPUESTO3,
          TotalCANTIDAD_CLIENTES3: res.data.TotalCANTIDAD_CLIENTES3,
          PorcentajeMora3: res.data.PorcentajeMora3,

          DataReporte4: res.data.DataReporte4,
          TotalMONTO_MORA4: res.data.TotalMONTO_MORA4,
          TotalSALDO_EXPUESTO4: res.data.TotalSALDO_EXPUESTO4,
          TotalCANTIDAD_CLIENTES4: res.data.TotalCANTIDAD_CLIENTES4,
          PorcentajeMora4: res.data.PorcentajeMora4,

          DataReporte5: res.data.DataReporte5,
          TotalMONTO_MORA5: res.data.TotalMONTO_MORA5,
          TotalSALDO_EXPUESTO5: res.data.TotalSALDO_EXPUESTO5,
          TotalCANTIDAD_CLIENTES5: res.data.TotalCANTIDAD_CLIENTES5,
          PorcentajeMora5: res.data.PorcentajeMora5,

          DataReporte6: res.data.DataReporte6,
          TotalMONTO_MORA6: res.data.TotalMONTO_MORA6,
          TotalSALDO_EXPUESTO6: res.data.TotalSALDO_EXPUESTO6,
          TotalCANTIDAD_CLIENTES6: res.data.TotalCANTIDAD_CLIENTES6,
          PorcentajeMora6: res.data.PorcentajeMora6,

          DataReporte7: res.data.DataReporte7,
          TotalMONTO_MORA7: res.data.TotalMONTO_MORA7,
          TotalSALDO_EXPUESTO7: res.data.TotalSALDO_EXPUESTO7,
          TotalCANTIDAD_CLIENTES7: res.data.TotalCANTIDAD_CLIENTES7,
          PorcentajeMora7: res.data.PorcentajeMora7,

          DataReporte8: res.data.DataReporte8,
          TotalMONTO_MORA8: res.data.TotalMONTO_MORA8,
          TotalSALDO_EXPUESTO8: res.data.TotalSALDO_EXPUESTO8,
          TotalCANTIDAD_CLIENTES8: res.data.TotalCANTIDAD_CLIENTES8,
          PorcentajeMora8: res.data.PorcentajeMora8,

          TotalMora: res.data.TotalMora,
          TotalSaldo: res.data.TotalSaldo,
          TotalCantidad: res.data.TotalCantidad,
          Porcentaje: res.data.Porcentaje,
        });
      });
    }
  };
  JSPDF(e) {
    e.preventDefault();
    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Resumen de Mora",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    } else {
      if (this.state.COD_EMPRESA_B !== "" && this.state.IMAGEN === "") {
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        DatosEmpresa(this.state.COD_EMPRESA_B).then((res) => {
          this.setState({
            IMAGEN: res.data.IMAGEN,
            width: res.data.width,
            height: res.data.height,
            REPORT_WIDTH: res.data.REPORT_WIDTH,
            REPORT_HEIGHT: res.data.REPORT_HEIGHT,
          });
        });
      }

      let doc = new jsPDF("l", "pt", "letter");

      let imgData;
      let REPORT_WIDTH = parseInt(this.state.REPORT_WIDTH);
      let REPORT_HEIGHT = parseInt(this.state.REPORT_HEIGHT);
      imgData = this.state.IMAGEN;

      doc.addImage(imgData, 20, 12, REPORT_WIDTH, REPORT_HEIGHT);

      doc.setFontSize(14);
      doc.text("RESUMEN DE MORA", 370, 60);

      doc.setFontSize(10);
      doc.text("MORA DE 0 A 3 DIAS", 40, 74);
      doc.text("___________________", 40, 75);
      doc.autoTable({
        html: "#body",
        useCss: true,
        startY: 80,
        showHead: "firstPage",
        headStyles: { cellPadding: 0.3, fontSize: 8 },
        styles: { cellPadding: 0.3, fontSize: 8 },
        margin: { right: 10 },
        theme: "plain",
        //   columnStyles:  { cellWidth: '5%' } ,
      });

      let finalY = doc.lastAutoTable.finalY || 10;

      doc.text("MORA DE 4 A 7 DIAS", 40, finalY + 21);
      doc.text("___________________", 40, finalY + 22);
      doc.autoTable({
        html: "#body2",
        useCss: true,
        startY: finalY + 28,
        showHead: "firstPage",
        headStyles: { cellPadding: 0.3, fontSize: 8 },
        styles: { cellPadding: 0.3, fontSize: 8 },
        margin: { right: 10 },
        theme: "plain",
        //   columnStyles:  { cellWidth: '5%' } ,
      });

      finalY = doc.lastAutoTable.finalY;

      doc.text("MORA DE 8 A 15 DIAS", 40, finalY + 21);
      doc.text("___________________", 40, finalY + 22);
      doc.autoTable({
        html: "#body3",
        useCss: true,
        startY: finalY + 28,
        showHead: "firstPage",
        headStyles: { cellPadding: 0.3, fontSize: 8 },
        styles: { cellPadding: 0.3, fontSize: 8 },
        margin: { right: 10 },
        theme: "plain",
        //   columnStyles:  { cellWidth: '5%' } ,
      });

      finalY = doc.lastAutoTable.finalY;

      doc.text("MORA DE 16 A 22 DIAS", 40, finalY + 21);
      doc.text("___________________", 40, finalY + 22);
      doc.autoTable({
        html: "#body4",
        useCss: true,
        startY: finalY + 28,
        showHead: "firstPage",
        headStyles: { cellPadding: 0.3, fontSize: 8 },
        styles: { cellPadding: 0.3, fontSize: 8 },
        margin: { right: 10 },
        theme: "plain",
        //   columnStyles:  { cellWidth: '5%' } ,
      });

      finalY = doc.lastAutoTable.finalY;

      doc.text("MORA DE 23 A 30 DIAS", 40, finalY + 21);
      doc.text("___________________", 40, finalY + 22);
      doc.autoTable({
        html: "#body5",
        useCss: true,
        startY: finalY + 28,
        showHead: "firstPage",
        headStyles: { cellPadding: 0.3, fontSize: 8 },
        styles: { cellPadding: 0.3, fontSize: 8 },
        margin: { right: 10 },
        theme: "plain",
        //   columnStyles:  { cellWidth: '5%' } ,
      });

      finalY = doc.lastAutoTable.finalY;

      doc.text("MORA DE 31 A 60 DIAS", 40, finalY + 21);
      doc.text("___________________", 40, finalY + 22);
      doc.autoTable({
        html: "#body6",
        useCss: true,
        startY: finalY + 28,
        showHead: "firstPage",
        headStyles: { cellPadding: 0.3, fontSize: 8 },
        styles: { cellPadding: 0.3, fontSize: 8 },
        margin: { right: 10 },
        theme: "plain",
        //   columnStyles:  { cellWidth: '5%' } ,
      });

      finalY = doc.lastAutoTable.finalY;

      doc.text("MORA DE 61 A 90 DIAS", 40, finalY + 21);
      doc.text("___________________", 40, finalY + 22);
      doc.autoTable({
        html: "#body7",
        useCss: true,
        startY: finalY + 28,
        showHead: "firstPage",
        headStyles: { cellPadding: 0.3, fontSize: 8 },
        styles: { cellPadding: 0.3, fontSize: 8 },
        margin: { right: 10 },
        theme: "plain",
        //   columnStyles:  { cellWidth: '5%' } ,
      });

      finalY = doc.lastAutoTable.finalY;

      doc.text("MORA DE 91 A MAS DIAS", 40, finalY + 21);
      doc.text("___________________", 40, finalY + 22);
      doc.autoTable({
        html: "#body8",
        useCss: true,
        startY: finalY + 28,
        showHead: "firstPage",
        headStyles: { cellPadding: 0.3, fontSize: 8 },
        styles: { cellPadding: 0.3, fontSize: 8 },
        margin: { right: 10 },
        theme: "plain",
        //   columnStyles:  { cellWidth: '5%' } ,
      });

      finalY = doc.lastAutoTable.finalY;

      doc.autoTable({
        html: "#body9",
        useCss: true,
        startY: finalY + 28,
        showHead: "firstPage",
        headStyles: { cellPadding: 0.3, fontSize: 8 },
        styles: { cellPadding: 0.3, fontSize: 8 },
        margin: { right: 10 },
        theme: "plain",
        //   columnStyles:  { cellWidth: '5%' } ,
      });

      doc.save("ResumenMora.pdf");
    }
  }

  ExcelData(e) {
    e.preventDefault();

    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Resumen de Mora",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    } else {
      if (this.state.COD_EMPRESA_B !== "" && this.state.IMAGEN === "") {
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        DatosEmpresa(this.state.COD_EMPRESA_B).then((res) => {
          this.setState({
            IMAGEN: res.data.IMAGEN,
            width: res.data.width,
            height: res.data.height,
            REPORT_WIDTH: res.data.REPORT_WIDTH,
            REPORT_HEIGHT: res.data.REPORT_HEIGHT,
          });
        });
      }

    let Table = [];

    let table1 = [];

    let DataReporte1 = this.state.DataReporte1;

    DataReporte1.forEach((row) => {
      table1.push({
        A: row.ANALISTA,
        B: row.MONTO_MORA,
        C: row.SALDO_EXPUESTO,
        D: row.CANTIDAD_CLIENTES,
        E: row.PORCENTAJE_EN_MORA,
      });
    });

    ///**************************************************************/
    let table2 = [];

    let DataReporte2 = this.state.DataReporte2;

    DataReporte2.forEach((row) => {
      table2.push({
        A: row.ANALISTA,
        B: row.MONTO_MORA,
        C: row.SALDO_EXPUESTO,
        D: row.CANTIDAD_CLIENTES,
        E: row.PORCENTAJE_EN_MORA,
      });
    });

    ////************************************************************/

    let table3 = [];

    let DataReporte3 = this.state.DataReporte3;

    DataReporte3.forEach((row) => {
      table3.push({
        A: row.ANALISTA,
        B: row.MONTO_MORA,
        C: row.SALDO_EXPUESTO,
        D: row.CANTIDAD_CLIENTES,
        E: row.PORCENTAJE_EN_MORA,
      });
    });

    ////************************************************************/

    let table4 = [];

    let DataReporte4 = this.state.DataReporte4;

    DataReporte4.forEach((row) => {
      table4.push({
        A: row.ANALISTA,
        B: row.MONTO_MORA,
        C: row.SALDO_EXPUESTO,
        D: row.CANTIDAD_CLIENTES,
        E: row.PORCENTAJE_EN_MORA,
      });
    });

    ////************************************************************/

    let table5 = [];

    let DataReporte5 = this.state.DataReporte5;

    DataReporte5.forEach((row) => {
      table5.push({
        A: row.ANALISTA,
        B: row.MONTO_MORA,
        C: row.SALDO_EXPUESTO,
        D: row.CANTIDAD_CLIENTES,
        E: row.PORCENTAJE_EN_MORA,
      });
    });

    ////************************************************************/

    let table6 = [];

    let DataReporte6 = this.state.DataReporte6;

    DataReporte6.forEach((row) => {
      table6.push({
        A: row.ANALISTA,
        B: row.MONTO_MORA,
        C: row.SALDO_EXPUESTO,
        D: row.CANTIDAD_CLIENTES,
        E: row.PORCENTAJE_EN_MORA,
      });
    });

    ////************************************************************/

    let table7 = [];

    let DataReporte7 = this.state.DataReporte7;

    DataReporte7.forEach((row) => {
      table7.push({
        A: row.ANALISTA,
        B: row.MONTO_MORA,
        C: row.SALDO_EXPUESTO,
        D: row.CANTIDAD_CLIENTES,
        E: row.PORCENTAJE_EN_MORA,
      });
    });

    ////************************************************************/

    let table8 = [];

    let DataReporte8 = this.state.DataReporte8;

    DataReporte8.forEach((row) => {
      table8.push({
        A: row.ANALISTA,
        B: row.MONTO_MORA,
        C: row.SALDO_EXPUESTO,
        D: row.CANTIDAD_CLIENTES,
        E: row.PORCENTAJE_EN_MORA,
      });
    });

    ////************************************************************/

    Table = [{ A: "RESUMEN DE MORA" }]
      .concat([{ A: "MORA DE 0 A 3 DIAS" }])
      .concat([
        {
          A: "ANALISTA",
          B: "MONTO",
          C: "SALSDO EXPUESTO",
          D: "CANTIDAD",
          E: "PORCENTAJE",
        },
      ])
      .concat(table1)
      .concat([
        {
          A: "TOTAL",
          B: this.state.TotalMONTO_MORA1,
          C: this.state.TotalSALDO_EXPUESTO1,
          D: this.state.TotalCANTIDAD_CLIENTES1,
          E: this.state.PorcentajeMora1,
        },
      ])
      .concat([{ A: "", B: "", C: " ", D: "", E: "" }])
      .concat([{ A: "MORA DE 4 A 7 DIAS" }])
      .concat([
        {
          A: "ANALISTA",
          B: "MONTO",
          C: "SALSDO EXPUESTO",
          D: "CANTIDAD",
          E: "PORCENTAJE",
        },
      ])
      .concat(table2)
      .concat([
        {
          A: "TOTAL",
          B: this.state.TotalMONTO_MORA2,
          C: this.state.TotalSALDO_EXPUESTO2,
          D: this.state.TotalCANTIDAD_CLIENTES2,
          E: this.state.PorcentajeMora2,
        },
      ])
      .concat([{ A: "", B: "", C: " ", D: "", E: "" }])
      .concat([{ A: "MORA DE 8 A 15 DIAS" }])
      .concat([
        {
          A: "ANALISTA",
          B: "MONTO",
          C: "SALSDO EXPUESTO",
          D: "CANTIDAD",
          E: "PORCENTAJE",
        },
      ])
      .concat(table3)
      .concat([
        {
          A: "TOTAL",
          B: this.state.TotalMONTO_MORA3,
          C: this.state.TotalSALDO_EXPUESTO3,
          D: this.state.TotalCANTIDAD_CLIENTES3,
          E: this.state.PorcentajeMora3,
        },
      ])
      .concat([{ A: "", B: "", C: " ", D: "", E: "" }])
      .concat([{ A: "MORA DE 16 A 22 DIAS" }])
      .concat([
        {
          A: "ANALISTA",
          B: "MONTO",
          C: "SALSDO EXPUESTO",
          D: "CANTIDAD",
          E: "PORCENTAJE",
        },
      ])
      .concat(table4)
      .concat([
        {
          A: "TOTAL",
          B: this.state.TotalMONTO_MORA4,
          C: this.state.TotalSALDO_EXPUESTO4,
          D: this.state.TotalCANTIDAD_CLIENTES4,
          E: this.state.PorcentajeMora4,
        },
      ])
      .concat([{ A: "", B: "", C: " ", D: "", E: "" }])
      .concat([{ A: "MORA DE 23 A 30 DIAS" }])
      .concat([
        {
          A: "ANALISTA",
          B: "MONTO",
          C: "SALSDO EXPUESTO",
          D: "CANTIDAD",
          E: "PORCENTAJE",
        },
      ])
      .concat(table5)
      .concat([
        {
          A: "TOTAL",
          B: this.state.TotalMONTO_MORA5,
          C: this.state.TotalSALDO_EXPUESTO5,
          D: this.state.TotalCANTIDAD_CLIENTES5,
          E: this.state.PorcentajeMora5,
        },
      ])
      .concat([{ A: "", B: "", C: " ", D: "", E: "" }])
      .concat([{ A: "MORA DE 31 A 60 DIAS" }])
      .concat([
        {
          A: "ANALISTA",
          B: "MONTO",
          C: "SALSDO EXPUESTO",
          D: "CANTIDAD",
          E: "PORCENTAJE",
        },
      ])
      .concat(table6)
      .concat([
        {
          A: "TOTAL",
          B: this.state.TotalMONTO_MORA6,
          C: this.state.TotalSALDO_EXPUESTO6,
          D: this.state.TotalCANTIDAD_CLIENTES6,
          E: this.state.PorcentajeMora6,
        },
      ])
      .concat([{ A: "", B: "", C: " ", D: "", E: "" }])
      .concat([{ A: "MORA DE 61 A 90 DIAS" }])
      .concat([
        {
          A: "ANALISTA",
          B: "MONTO",
          C: "SALSDO EXPUESTO",
          D: "CANTIDAD",
          E: "PORCENTAJE",
        },
      ])
      .concat(table7)
      .concat([
        {
          A: "TOTAL",
          B: this.state.TotalMONTO_MORA7,
          C: this.state.TotalSALDO_EXPUESTO7,
          D: this.state.TotalCANTIDAD_CLIENTES7,
          E: this.state.PorcentajeMora7,
        },
      ])
      .concat([{ A: "", B: "", C: " ", D: "", E: "" }])
      .concat([{ A: "MORA DE 91 A MAS DIAS" }])
      .concat([
        {
          A: "ANALISTA",
          B: "MONTO",
          C: "SALSDO EXPUESTO",
          D: "CANTIDAD",
          E: "PORCENTAJE",
        },
      ])
      .concat(table8)
      .concat([
        {
          A: "TOTAL",
          B: this.state.TotalMONTO_MORA8,
          C: this.state.TotalSALDO_EXPUESTO8,
          D: this.state.TotalCANTIDAD_CLIENTES8,
          E: this.state.PorcentajeMora8,
        },
      ])
      .concat([{ A: "", B: "", C: " ", D: "", E: "" }])
      .concat([
        {
          A: "TOTAL",
          B: this.state.TotalMora,
          C: this.state.TotalSaldo,
          D: this.state.TotalCantidad,
          E: this.state.Porcentaje,
        },
      ]);

    const finalData = [...Table];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "Resumen_Mora");

    XLSX.writeFile(wb, "ResumenMora.xlsx");
  }
  }

  render() {
    return (
      <form>
        <div style={{ visibility: this.state.VALIDACION }}>
          {" "}
          <SisprestamosNavbar />
          {/*  */}
          <div className="container mt-5">
            <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
            <div className="row">
            <div className="col-md-2"> </div>
                <div className="col-md-4">
                  <h5 className="labelb"> EMPRESA </h5>

                  <select
                    name="COD_EMPRESA_B"
                    className="form-control text-center"
                    value={this.state.COD_EMPRESA_B}
                    onChange={this.onChange}
                  >
                    <option></option>
                    {this.state.DatoEmpresa.map((item) => (
                      <option key={item.COD_EMPRESA} value={item.COD_EMPRESA}>
                        {item.NOMBRE_EMPRESA}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <h5 className="labelb">SUCURSAL</h5>
                  <select
                    name="COD_SUCURSAL_B"
                    className="form-control text-center"
                    value={this.state.COD_SUCURSAL_B}
                    onChange={this.onChange}
                  >
                    <option></option>
                    <option>Todas</option>
                    {this.state.DatoSucursal.map((item) => (
                      <option key={item.COD_SUCURSAL} value={item.COD_SUCURSAL}>
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="container ">
              <div className="row">
              <div className="col-md-1"> </div>
                <div className="col-md-3 p-1">
                  <button
                    type="button"
                    onClick={this.obtenerDatos}
                    className="btn btn-outline-dark btn-lg  col-md-12 "
                  >
                    BUSCAR
                  </button>
                  </div>
                  <div className="col-md-3 p-1">
                  <button
                    type="button"
                    onClick={this.ExcelData}
                    className="btn btn-outline-success btn-lg col-md-12 "
                  >
                    EXCEL
                  </button>
                  </div>
                  <div className="col-md-3 p-1">
                  <button
                    type="button"
                    onClick={this.JSPDF}
                    className="btn btn-outline-danger btn-lg col-md-12"
                  >
                    PDF
                  </button>
                  </div>
              </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="container ">
            <div className="row">
              <div className="shadow-lg bg-white rounded  col-md-12 mx-auto border rounded border-dark p-3">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={this.state.IMAGEN}
                        alt="logo"
                        className="rounded"
                        style={{
                          width: this.state.width,
                          height: this.state.height,
                          float: "left",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <h3 className="text-center encabezado">
                        RESUMEN DE MORA
                      </h3>

                      <h6 className="descripcion">MORA DE 0 A 3 DIAS</h6>
                      <table
                        className="table table-bordered table-css"
                        id="body"
                      >
                        <thead>
                          <tr>
                            <th>ANALISTA</th>
                            <th>MONTO</th>
                            <th>SALSDO EXPUESTO</th>
                            <th>CANTIDAD</th>
                            <th>PORCENTAJE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.DataReporte1.map((item) => {
                            return (
                              <tr key={item.ANALISTA}>
                                <td>{item.ANALISTA}</td>
                                <td className="text-right">
                                  {item.MONTO_MORA}
                                </td>
                                <td className="text-right">
                                  {item.SALDO_EXPUESTO}
                                </td>
                                <td className="text-right">
                                  {item.CANTIDAD_CLIENTES}
                                </td>
                                <td className="text-right">
                                  {item.PORCENTAJE_EN_MORA}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td className=" text-right">
                              {this.state.TotalMONTO_MORA1}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalSALDO_EXPUESTO1}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalCANTIDAD_CLIENTES1}
                            </td>
                            <td className=" text-right">
                              {this.state.PorcentajeMora1}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <h6 className="descripcion">MORA DE 4 A 7 DIAS</h6>
                      <table
                        className="table table-bordered table-css"
                        id="body2"
                      >
                        <thead>
                          <tr>
                            <th>ANALISTA</th>
                            <th>MONTO</th>
                            <th>SALSDO EXPUESTO</th>
                            <th>CANTIDAD</th>
                            <th>PORCENTAJE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.DataReporte2.map((item) => {
                            return (
                              <tr key={item.ANALISTA}>
                                <td>{item.ANALISTA}</td>
                                <td className="text-right">
                                  {item.MONTO_MORA}
                                </td>
                                <td className="text-right">
                                  {item.SALDO_EXPUESTO}
                                </td>
                                <td className="text-right">
                                  {item.CANTIDAD_CLIENTES}
                                </td>
                                <td className="text-right">
                                  {item.PORCENTAJE_EN_MORA}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td className=" text-right">
                              {this.state.TotalMONTO_MORA2}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalSALDO_EXPUESTO2}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalCANTIDAD_CLIENTES2}
                            </td>
                            <td className=" text-right">
                              {this.state.PorcentajeMora2}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <h6 className="descripcion">MORA DE 8 A 15 DIAS</h6>
                      <table
                        className="table table-bordered table-css"
                        id="body3"
                      >
                        <thead>
                          <tr>
                            <th>ANALISTA</th>
                            <th>MONTO</th>
                            <th>SALSDO EXPUESTO</th>
                            <th>CANTIDAD</th>
                            <th>PORCENTAJE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.DataReporte3.map((item) => {
                            return (
                              <tr key={item.ANALISTA}>
                                <td>{item.ANALISTA}</td>
                                <td className="text-right">
                                  {item.MONTO_MORA}
                                </td>
                                <td className="text-right">
                                  {item.SALDO_EXPUESTO}
                                </td>
                                <td className="text-right">
                                  {item.CANTIDAD_CLIENTES}
                                </td>
                                <td className="text-right">
                                  {item.PORCENTAJE_EN_MORA}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td className=" text-right">
                              {this.state.TotalMONTO_MORA3}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalSALDO_EXPUESTO3}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalCANTIDAD_CLIENTES3}
                            </td>
                            <td className=" text-right">
                              {this.state.PorcentajeMora3}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <h6 className="descripcion">MORA DE 16 A 22 DIAS</h6>
                      <table
                        className="table table-bordered table-css"
                        id="body4"
                      >
                        <thead>
                          <tr>
                            <th>ANALISTA</th>
                            <th>MONTO</th>
                            <th>SALSDO EXPUESTO</th>
                            <th>CANTIDAD</th>
                            <th>PORCENTAJE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.DataReporte4.map((item) => {
                            return (
                              <tr key={item.ANALISTA}>
                                <td>{item.ANALISTA}</td>
                                <td className="text-right">
                                  {item.MONTO_MORA}
                                </td>
                                <td className="text-right">
                                  {item.SALDO_EXPUESTO}
                                </td>
                                <td className="text-right">
                                  {item.CANTIDAD_CLIENTES}
                                </td>
                                <td className="text-right">
                                  {item.PORCENTAJE_EN_MORA}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td className=" text-right">
                              {this.state.TotalMONTO_MORA4}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalSALDO_EXPUESTO4}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalCANTIDAD_CLIENTES4}
                            </td>
                            <td className=" text-right">
                              {this.state.PorcentajeMora4}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <h6 className="descripcion">MORA DE 23 A 30 DIAS</h6>
                      <table
                        className="table table-bordered table-css"
                        id="body5"
                      >
                        <thead>
                          <tr>
                            <th>ANALISTA</th>
                            <th>MONTO</th>
                            <th>SALSDO EXPUESTO</th>
                            <th>CANTIDAD</th>
                            <th>PORCENTAJE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.DataReporte5.map((item) => {
                            return (
                              <tr key={item.ANALISTA}>
                                <td>{item.ANALISTA}</td>
                                <td className="text-right">
                                  {item.MONTO_MORA}
                                </td>
                                <td className="text-right">
                                  {item.SALDO_EXPUESTO}
                                </td>
                                <td className="text-right">
                                  {item.CANTIDAD_CLIENTES}
                                </td>
                                <td className="text-right">
                                  {item.PORCENTAJE_EN_MORA}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td className=" text-right">
                              {this.state.TotalMONTO_MORA5}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalSALDO_EXPUESTO5}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalCANTIDAD_CLIENTES5}
                            </td>
                            <td className=" text-right">
                              {this.state.PorcentajeMora5}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <h6 className="descripcion">MORA DE 31 A 60 DIAS</h6>
                      <table
                        className="table table-bordered table-css"
                        id="body6"
                      >
                        <thead>
                          <tr>
                            <th>ANALISTA</th>
                            <th>MONTO</th>
                            <th>SALSDO EXPUESTO</th>
                            <th>CANTIDAD</th>
                            <th>PORCENTAJE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.DataReporte6.map((item) => {
                            return (
                              <tr key={item.ANALISTA}>
                                <td>{item.ANALISTA}</td>
                                <td className="text-right">
                                  {item.MONTO_MORA}
                                </td>
                                <td className="text-right">
                                  {item.SALDO_EXPUESTO}
                                </td>
                                <td className="text-right">
                                  {item.CANTIDAD_CLIENTES}
                                </td>
                                <td className="text-right">
                                  {item.PORCENTAJE_EN_MORA}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td className=" text-right">
                              {this.state.TotalMONTO_MORA6}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalSALDO_EXPUESTO6}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalCANTIDAD_CLIENTES6}
                            </td>
                            <td className=" text-right">
                              {this.state.PorcentajeMora6}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <h6 className="descripcion">MORA DE 61 A 90 DIAS</h6>
                      <table
                        className="table table-bordered table-css"
                        id="body7"
                      >
                        <thead>
                          <tr>
                            <th>ANALISTA</th>
                            <th>MONTO</th>
                            <th>SALSDO EXPUESTO</th>
                            <th>CANTIDAD</th>
                            <th>PORCENTAJE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.DataReporte7.map((item) => {
                            return (
                              <tr key={item.ANALISTA}>
                                <td>{item.ANALISTA}</td>
                                <td className="text-right">
                                  {item.MONTO_MORA}
                                </td>
                                <td className="text-right">
                                  {item.SALDO_EXPUESTO}
                                </td>
                                <td className="text-right">
                                  {item.CANTIDAD_CLIENTES}
                                </td>
                                <td className="text-right">
                                  {item.PORCENTAJE_EN_MORA}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td className=" text-right">
                              {this.state.TotalMONTO_MORA7}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalSALDO_EXPUESTO7}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalCANTIDAD_CLIENTES7}
                            </td>
                            <td className=" text-right">
                              {this.state.PorcentajeMora7}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <h6 className="descripcion">MORA DE 91 A MAS DIAS</h6>
                      <table
                        className="table table-bordered table-css"
                        id="body8"
                      >
                        <thead>
                          <tr>
                            <th>ANALISTA</th>
                            <th>MONTO</th>
                            <th>SALSDO EXPUESTO</th>
                            <th>CANTIDAD</th>
                            <th>PORCENTAJE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.DataReporte8.map((item) => {
                            return (
                              <tr key={item.ANALISTA}>
                                <td>{item.ANALISTA}</td>
                                <td className="text-right">
                                  {item.MONTO_MORA}
                                </td>
                                <td className="text-right">
                                  {item.SALDO_EXPUESTO}
                                </td>
                                <td className="text-right">
                                  {item.CANTIDAD_CLIENTES}
                                </td>
                                <td className="text-right">
                                  {item.PORCENTAJE_EN_MORA}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td className=" text-right">
                              {this.state.TotalMONTO_MORA8}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalSALDO_EXPUESTO8}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalCANTIDAD_CLIENTES8}
                            </td>
                            <td className=" text-right">
                              {this.state.PorcentajeMora8}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <table
                        className="table table-bordered table-css"
                        id="body9"
                      >
                        <tbody>
                          <tr>
                            <td>
                              <b>TOTAL</b>
                            </td>
                            <td className=" text-right">
                              {this.state.TotalMora}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalSaldo}
                            </td>
                            <td className=" text-right">
                              {this.state.TotalCantidad}
                            </td>
                            <td className=" text-right">
                              {this.state.Porcentaje}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </form>
    );
  }
}
export default ResumenMoraDias;

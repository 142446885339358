import React, { Component } from "react";
import axios from "axios";
import { Map, Marker, TileLayer, Popup } from "react-leaflet";
// import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import TimerMixin from "react-timer-mixin";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

registerLocale("es", es);

// import { Icon } from "leaflet";

class Ubicaciones_analistas extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarAnalista = () => {
    const url = `${api}/cajero/get/${this.state.Tipo_usuario}/${this.state.SUCURSAL_C}/${this.state.SUCURSAL_C}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ Analista_A: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  Ubicaciones_Analistas = (registro) => {
    return axios
      .post(
        `${api}/maps/analistas/${this.state.Tipo_usuario}/${this.state.SUCURSAL_C}/${this.state.SUCURSAL_C}`,
        {
          analista1: registro.analista1,
          analista2: registro.analista2,
          analista3: registro.analista3,
          analista4: registro.analista4,
          analista5: registro.analista5,
          analista6: registro.analista6,
          analista7: registro.analista7,
          analista8: registro.analista8,
          analista9: registro.analista9,
          analista10: registro.analista10,
          analista11: registro.analista11,
          analista12: registro.analista12,
          analista13: registro.analista13,
          analista14: registro.analista14,
          analista15: registro.analista15,
          analista16: registro.analista16,
          analista17: registro.analista17,
          analista18: registro.analista18,
          analista19: registro.analista19,
          analista20: registro.analista20,
          analista21: registro.analista21,
          analista22: registro.analista22,
          analista23: registro.analista23,
          analista24: registro.analista24,
          analista25: registro.analista25,
          fecha_1: registro.fecha_1,
          fecha_2: registro.fecha_2,
        }
      )
      .then((response) => {
        // console.log(
        //   "Consulta de los creditos activos del cliente",
        //   response.data
        // );

        this.setState({
          Consulta: response.data.Consulta,
        });
        // window.location.reload();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("el error es ", err);
        window.alert("Verifique los datos ingresados", err);
        // window.location.reload();
      });
  };

  constructor() {
    super();
    this.state = {
      CAJERO: "",
      fecha_1: "",
      fecha_2: "",

      SucursalA: [], //GET ARRAY
      A_SUCURSAL: "", //POST
      SUCURSAL_C: "", //POST
      COD_ROL_SUC: "",

      Tipo_usuario: "", //RESTRICCION
      sucursal: "", //RESTRICCION

      Consulta: [],
      fecha: new Date(), //GET
      IP: "",
      P: "192.168.1.5",
      Analista_A: [],
      A_ANALISTA: "",
      analista1: "",
      analista2: "",
      analista3: "",
      analista4: "",
      analista5: "",
      analista6: "",
      analista7: "",
      analista8: "",
      analista9: "",
      analista10: "",
      analista11: "",
      analista12: "",
      analista13: "",
      analista14: "",
      analista15: "",
      analista16: "",
      analista17: "",
      analista18: "",
      analista19: "",
      analista20: "",
      analista21: "",
      analista22: "",
      analista23: "",
      analista24: "",
      analista25: "",

      ID_ROL: "",
      PERMISO_ROL: "1015",
      VALIDACION: "hidden",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onChange2 = this.onChange2.bind(this);
    this.onChange3 = this.onChange3.bind(this);
    this.onChange4 = this.onChange4.bind(this);
    this.onChange5 = this.onChange5.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange2(e) {
    e.preventDefault();
    if (this.state.analista1 === "") {
      e.preventDefault();
      this.setState({
        analista1: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista2 === "") {
      e.preventDefault();
      this.setState({
        analista2: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista3 === "") {
      e.preventDefault();
      this.setState({
        analista3: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista4 === "") {
      e.preventDefault();
      this.setState({
        analista4: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista5 === "") {
      e.preventDefault();
      this.setState({
        analista5: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista6 === "") {
      e.preventDefault();
      this.setState({
        analista6: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista7 === "") {
      e.preventDefault();
      this.setState({
        analista7: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista8 === "") {
      e.preventDefault();
      this.setState({
        analista8: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista9 === "") {
      e.preventDefault();
      this.setState({
        analista9: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista10 === "") {
      e.preventDefault();
      this.setState({
        analista10: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista11 === "") {
      e.preventDefault();
      this.setState({
        analista11: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista12 === "") {
      e.preventDefault();
      this.setState({
        analista12: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista13 === "") {
      e.preventDefault();
      this.setState({
        analista13: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista14 === "") {
      e.preventDefault();
      this.setState({
        analista14: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista15 === "") {
      e.preventDefault();
      this.setState({
        analista15: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista16 === "") {
      e.preventDefault();
      this.setState({
        analista16: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista17 === "") {
      e.preventDefault();
      this.setState({
        analista17: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista18 === "") {
      e.preventDefault();
      this.setState({
        analista18: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista19 === "") {
      e.preventDefault();
      this.setState({
        analista19: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista20 === "") {
      e.preventDefault();
      this.setState({
        analista20: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista21 === "") {
      e.preventDefault();
      this.setState({
        analista21: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista22 === "") {
      e.preventDefault();
      this.setState({
        analista22: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista23 === "") {
      e.preventDefault();
      this.setState({
        analista23: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista24 === "") {
      e.preventDefault();
      this.setState({
        analista24: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else if (this.state.analista25 === "") {
      e.preventDefault();
      this.setState({
        analista25: this.state.A_ANALISTA,
        A_ANALISTA: "",
      });
    } else {
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    e.preventDefault();
  }
  onChange3(e) {
    if (this.state.analista1 === "") {
      e.preventDefault();
      this.setState({
        analista1: this.state.analista1,
      });
    }

    if (this.state.analista2 === "") {
      e.preventDefault();
      this.setState({
        analista2: this.state.analista1,
      });
    }
    if (this.state.analista3 === "") {
      e.preventDefault();
      this.setState({
        analista3: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista4 === "") {
      e.preventDefault();
      this.setState({
        analista4: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista5 === "") {
      e.preventDefault();
      this.setState({
        analista5: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista6 === "") {
      e.preventDefault();
      this.setState({
        analista6: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista7 === "") {
      e.preventDefault();
      this.setState({
        analista7: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista8 === "") {
      e.preventDefault();
      this.setState({
        analista8: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista9 === "") {
      e.preventDefault();
      this.setState({
        analista9: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista10 === "") {
      e.preventDefault();
      this.setState({
        analista10: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista11 === "") {
      e.preventDefault();
      this.setState({
        analista11: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista12 === "") {
      e.preventDefault();
      this.setState({
        analista12: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista13 === "") {
      e.preventDefault();
      this.setState({
        analista13: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista14 === "") {
      e.preventDefault();
      this.setState({
        analista14: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista15 === "") {
      e.preventDefault();
      this.setState({
        analista15: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista16 === "") {
      e.preventDefault();
      this.setState({
        analista16: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista17 === "") {
      e.preventDefault();
      this.setState({
        analista17: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista18 === "") {
      e.preventDefault();
      this.setState({
        analista18: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista19 === "") {
      e.preventDefault();
      this.setState({
        analista19: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista20 === "") {
      e.preventDefault();
      this.setState({
        analista20: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista21 === "") {
      e.preventDefault();
      this.setState({
        analista21: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista22 === "") {
      e.preventDefault();
      this.setState({
        analista22: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista23 === "") {
      e.preventDefault();
      this.setState({
        analista23: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista24 === "") {
      e.preventDefault();
      this.setState({
        analista24: this.state.analista1,
      });
    } else {
    }
    if (this.state.analista25 === "") {
      e.preventDefault();
      this.setState({
        analista25: this.state.analista1,
      });
    } else {
    }
    this.onSubmit();
  }

  onChange4(e) {
    e.preventDefault();

    this.setState({
      analista1: "",
      analista2: "",
      analista3: "",
      analista4: "",
      analista5: "",
      analista6: "",
      analista7: "",
      analista8: "",
      analista9: "",
      analista10: "",
      analista11: "",
      analista12: "",
      analista13: "",
      analista14: "",
      analista15: "",
      analista16: "",
      analista17: "",
      analista18: "",
      analista19: "",
      analista20: "",
      analista21: "",
      analista22: "",
      analista23: "",
      analista24: "",
      analista25: "",
    });
  }

  onChange5(e) {
    e.preventDefault();
    if (this.state.analista25 !== "") {
      e.preventDefault();
      this.setState({
        analista25: "",
      });
    } else if (this.state.analista24 !== "") {
      e.preventDefault();
      this.setState({
        analista24: "",
      });
    } else if (this.state.analista23 !== "") {
      e.preventDefault();
      this.setState({
        analista23: "",
      });
    } else if (this.state.analista22 !== "") {
      e.preventDefault();
      this.setState({
        analista22: "",
      });
    } else if (this.state.analista21 !== "") {
      e.preventDefault();
      this.setState({
        analista21: "",
      });
    } else if (this.state.analista20 !== "") {
      e.preventDefault();
      this.setState({
        analista20: "",
      });
    } else if (this.state.analista19 !== "") {
      e.preventDefault();
      this.setState({
        analista19: "",
      });
    } else if (this.state.analista18 !== "") {
      e.preventDefault();
      this.setState({
        analista18: "",
      });
    } else if (this.state.analista17 !== "") {
      e.preventDefault();
      this.setState({
        analista17: "",
      });
    } else if (this.state.analista16 !== "") {
      e.preventDefault();
      this.setState({
        analista16: "",
      });
    } else if (this.state.analista15 !== "") {
      e.preventDefault();
      this.setState({
        analista15: "",
      });
    } else if (this.state.analista14 !== "") {
      e.preventDefault();
      this.setState({
        analista14: "",
      });
    } else if (this.state.analista13 !== "") {
      e.preventDefault();
      this.setState({
        analista13: "",
      });
    } else if (this.state.analista12 !== "") {
      e.preventDefault();
      this.setState({
        analista12: "",
      });
    } else if (this.state.analista11 !== "") {
      e.preventDefault();
      this.setState({
        analista11: "",
      });
    } else if (this.state.analista10 !== "") {
      e.preventDefault();
      this.setState({
        analista10: "",
      });
    } else if (this.state.analista9 !== "") {
      e.preventDefault();
      this.setState({
        analista9: "",
      });
    } else if (this.state.analista8 !== "") {
      e.preventDefault();
      this.setState({
        analista8: "",
      });
    } else if (this.state.analista7 !== "") {
      e.preventDefault();
      this.setState({
        analista7: "",
      });
    } else if (this.state.analista6 !== "") {
      e.preventDefault();
      this.setState({
        analista6: "",
      });
    } else if (this.state.analista5 !== "") {
      e.preventDefault();
      this.setState({
        analista5: "",
      });
    } else if (this.state.analista4 !== "") {
      e.preventDefault();
      this.setState({
        analista4: "",
      });
    } else if (this.state.analista3 !== "") {
      e.preventDefault();
      this.setState({
        analista3: "",
      });
    } else if (this.state.analista2 !== "") {
      e.preventDefault();
      this.setState({
        analista2: "",
      });
    } else if (this.state.analista1 !== "") {
      e.preventDefault();
      this.setState({
        analista1: "",
      });
    } else {
    }
  }

  onSubmit(e) {
  
      //     e.preventDefault();
      const registro = {
        analista1: this.state.analista1,
        analista2: this.state.analista2,
        analista3: this.state.analista3,
        analista4: this.state.analista4,
        analista5: this.state.analista5,
        analista6: this.state.analista6,
        analista7: this.state.analista7,
        analista8: this.state.analista8,
        analista9: this.state.analista9,
        analista10: this.state.analista10,
        analista11: this.state.analista11,
        analista12: this.state.analista12,
        analista13: this.state.analista13,
        analista14: this.state.analista14,
        analista15: this.state.analista15,
        analista16: this.state.analista16,
        analista17: this.state.analista17,
        analista18: this.state.analista18,
        analista19: this.state.analista19,
        analista20: this.state.analista20,
        analista21: this.state.analista21,
        analista22: this.state.analista22,
        analista23: this.state.analista23,
        analista24: this.state.analista24,
        analista25: this.state.analista25,
        fecha_1: this.state.fecha_1,
        fecha_2: this.state.fecha_2,
      };

      this.Ubicaciones_Analistas(registro).then((res) => {
        // this.props.history.push(`/login`)
        // window.alert("busqueda realizada exitosamente");
      });
 
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        alias: res.data.Alias,
        IP: res.data.IP,
        Tipo_usuario: res.data.Tipo_usuario,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
      });
      TimerMixin.setTimeout(() => {
      this.CONSULTA_PERMISO(); 
    }, 300);   
    });
  }

  onChange_FECHA1 = (fecha) => {
    this.setState({ fecha_1: fecha });
  };

  onChange_FECHA2 = (fecha1) => {
    this.setState({ fecha_2: fecha1 });
  };
  render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}> <SisprestamosNavbar />
        <div className="container mt-5">
          <div className="row">
            <div
              className="shadow-lg p-3 mb-5 bg-white rounded  col-md-6 mx-auto "
              style={{ width: "200px;" }}
            >
              <h1 className="h3 mb-4 font-weight-normal  text-center">
                CONSULTA
              </h1>

              <div className="d-flex justify-content-center">
                <div className="form-group text-center col-md-4">
                  <label htmlFor="SucursalA">Sucursal</label>
                  <select
                    name="SUCURSAL_C"
                    className="form-control"
                    value={this.state.SUCURSAL_C}
                    onChange={this.onChange}
                    placeholder="Campo obligatorio"
                    onClick={this.consultarSucursal()}
                  >
                    <option>Todas</option>
                    {this.state.SucursalA.map((item) => (
                      <option
                        key={item.NOMBRE_SUCURSAL}
                        value={item.COD_SUCURSAL}
                      >
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group text-center col-md-6">
                  <label htmlFor="Analista_A">Cajero</label>
                  <select
                    name="A_ANALISTA"
                    className="form-control "
                    value={this.state.A_ANALISTA}
                    onChange={this.onChange}
                    onClick={this.consultarAnalista}
                  >
                    <option></option>
                    {this.state.Analista_A.map((item) => (
                      <option key={item.id} value={item.alias}>
                        {item.alias}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div align="center">
                <div>
                  <button
                    onClick={this.onChange2}
                    className="btn btn-success btn-block "
                  >
                    Agregar
                  </button>
                  <button
                    onClick={this.onChange5}
                    className="btn  btn-warning btn-block "
                  >
                    {" "}
                    Eliminar
                  </button>

                  <button
                    onClick={this.onChange4}
                    className="btn  btn-danger btn-block "
                  >
                    {" "}
                    Eliminar todo{" "}
                  </button>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table " id="body">
                  <thead className="text-center">
                    <tr>
                      <th>Cajeros</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{this.state.analista1}</td>
                      <td>{this.state.analista2}</td>
                      <td>{this.state.analista3}</td>
                      <td>{this.state.analista4}</td>
                      <td>{this.state.analista5}</td>
                      <td>{this.state.analista6}</td>
                      <td>{this.state.analista7}</td>
                      <td>{this.state.analista8}</td>
                      <td>{this.state.analista9}</td>
                      <td>{this.state.analista10}</td>
                      <td>{this.state.analista11}</td>
                      <td>{this.state.analista12}</td>
                      <td>{this.state.analista13}</td>
                      <td>{this.state.analista14}</td>
                      <td>{this.state.analista15}</td>
                      <td>{this.state.analista16}</td>
                      <td>{this.state.analista17}</td>
                      <td>{this.state.analista18}</td>
                      <td>{this.state.analista19}</td>
                      <td>{this.state.analista20}</td>
                      <td>{this.state.analista21}</td>
                      <td>{this.state.analista22}</td>
                      <td>{this.state.analista23}</td>
                      <td>{this.state.analista24}</td>
                      <td>{this.state.analista25}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="form-row">
                <div
                  className="form-group text-center col-md-4 mx-auto"
                  style={{ width: "200px;" }}
                >
                  <label htmlFor="Fecha">Fecha : </label>

                  <DatePicker
                    selected={this.state.fecha_1}
                    onChange={this.onChange_FECHA1}
                    locale="es"
                    className="pickers"
                    dateFormat="dd '/' M '/' yyyy"
                  />
                </div>
                <div
                  className="form-group text-center col-md-4 mx-auto"
                  style={{ width: "200px;" }}
                >
                  <label htmlFor="Fecha">Fecha : </label>

                  <DatePicker
                    selected={this.state.fecha_2}
                    onChange={this.onChange_FECHA2}
                    locale="es"
                    className="pickers"
                    dateFormat="dd '/' M '/' yyyy"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <button
                  onClick={this.onChange3}
                  className="btn btn-lg btn-primary btn-block col-md-4"
                >
                  Buscar
                </button>
              </div>

              <div style={{ visibility: "hidden" }}>
                <div align="center">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      onClick={this.jsPdfGenerator}
                      className="btn btn-danger"
                    >
                      Generar pdf
                    </button>
                  </div>
                </div>
                <div align="center">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      onClick={this.jsPdfGenerator}
                      className="btn btn-danger"
                    >
                      Generar pdf
                    </button>
                  </div>
                </div>
                <div align="center">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      onClick={this.jsPdfGenerator}
                      className="btn btn-danger"
                    >
                      Generar pdf
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CORDENADAS DE MATAGALPA */}
        {/* <Map center={[12.921159, -85.9164926]} zoom={12}> */}
        {/* CORDENADAS DE CHINANDEGA */}
        <Map center={[12.8647101, -87.2627031]} zoom={7}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          {this.state.Consulta.map((crime) => (
            <Marker
              key={crime.NO_RECIBO}
              position={[crime.LATITUD, crime.LONGITUD]}
            >
              <Popup>
                <div>
                  <h2>Cajero:{crime.CAJERO}</h2>
                  <h2>No.Recibo:{crime.NO_RECIBO}</h2>
                  <h2>Fecha:{crime.FECHA}</h2>
                  <h2>Hora:{crime.HORA}</h2>
                </div>
              </Popup>
            </Marker>
          ))}
        </Map>
      </form>
    );
  }
}
export default Ubicaciones_analistas;

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "./UserFunctions"; // Usuarios
// import api from '../api/axiosConfigRoute';
// import TimerMixin from "react-timer-mixin";

import "./css/style.css";
import "./css/style.violet.css";

// import  "./css/estilos.css";
class Landing extends Component {
  // CONSULTA_PERMISO = () => {
  //   const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

  //   fetch(url)
  //     .then((respuesta) => respuesta.json())
  //     .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  // };

  constructor() {
    super();
    this.state = {
      ID_ROL: "",
      Tipo_usuario: "",

      // PERMISO_ROL: "1036",
      //  VALIDACION: "hidden",
      errors: {},
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ID_ROL: res.data.ID_ROL,
        Tipo_usuario: res.data.Tipo_usuario,
      });
      //   TimerMixin.setTimeout(() => {
      //     this.CONSULTA_PERMISO();
      // }, 300);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 500) {
      document.querySelector(".navbar").className =
        "navbar scroll navbar-expand-lg navbar-dark bg-dark bg-primary fixed-top";
    } else {
      document.querySelector(".navbar").className =
        "navbar navbar-expand-lg navbar-dark bg-dark bg-primary fixed-top";
    }
  };

  datosBusqueda = (termino) => {
    console.log(termino);
  };

  logOut(e) {
    //e.prevenDefault()
    localStorage.removeItem("usertoken");
    //this.props.history.push(`/`)
  }
  render() {
    const loginRegLink = (
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/login" className="nav-link">
            Login
          </Link>
        </li>
      </ul>
    );
    const userLink = (
      //DROPDOWN REGISTRO
      <ul className="navbar-nav">
        <li className="nav-item dropdown show">
          <a
            className="nav-link dropdown-toggle"
            href="https://sisprestamos-siscontapres.herokuapp.com/#/"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Registro
          </a>
          <ul
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <Link to="/datos_cliente" className="dropdown-item">
              <font color="black"> Registrar Cliente</font>
            </Link>

            <Link to="/solicitud" className="dropdown-item">
              <font color="black"> Solicitud</font>
            </Link>

            <Link to="/Aprobacion" className="dropdown-item">
              <font color="black">Aprobacion</font>
            </Link>

            <Link to="/PlanpagoNivelado" className="dropdown-item">
              <font color="black">Credito Nivelado</font>
            </Link>

            <Link to="/SobreSaldo" className="dropdown-item">
              <font color="black">Credito Sobre Saldo</font>
            </Link>

            <Link to="/seguimiento" className="dropdown-item">
              <font color="black"> Seguimientos</font>
            </Link>
          </ul>
        </li>

        <li className="nav-item dropdown show">
          <a
            className="nav-link dropdown-toggle"
            href="https://sisprestamos-siscontapres.herokuapp.com/#/"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Desembolsos
          </a>
          <ul
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
          >
            <Link to="/Creditos_desembolsar" className="dropdown-item">
              <font color="black">Desembolsar</font>
            </Link>

            <Link to="/Desembolsos_dia" className="dropdown-item">
              <font color="black">Asignacion del dia</font>
            </Link>

            <Link to="/Estado_desembolso" className="dropdown-item">
              <font color="black">Estado de desembolsos del dia</font>
            </Link>
          </ul>
        </li>

        <li className="nav-item dropdown show">
          <a
            className="nav-link dropdown-toggle"
            href="https://sisprestamos-siscontapres.herokuapp.com/#/"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Caja
          </a>
          <ul
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
          >
            {/* <div   data-toggle="collapse" data-target=".navbar-collapse.show">
            <Link to="/caja" className="dropdown-item">
              <font color="black"> Caja</font>
            </Link>
            </div> */}

            <div data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/CajaIngresos" className="dropdown-item">
                <font color="black"> Caja Ingresos</font>
              </Link>
            </div>

            <div data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/CajaSobreSaldo" className="dropdown-item">
                <font color="black">Caja Sobre Saldo</font>
              </Link>
            </div>

            <div data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/OtrosCargos" className="dropdown-item">
                <font color="black"> Otros Ingresos</font>
              </Link>
            </div>

            <div data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/No_Pago" className="dropdown-item">
                <font color="black">Cliente sin pagar</font>
              </Link>
            </div>

            <li className="dropdown-submenu">
              <div className="dropdown-item dropdown-toggle" href="#">
                Caja de Gastos
              </div>
              <ul
                className="dropdown-menu"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/Caja_Gasto" className="dropdown-item">
                  <font color="black"> Caja de Gastos</font>
                </Link>

                <Link to="/AnularGasto" className="dropdown-item">
                  <font color="black">Anular Gastos</font>
                </Link>
              </ul>
            </li>

            <li className="dropdown-submenu">
              <div className="dropdown-item dropdown-toggle" href="#">
                Consulta
              </div>
              <ul
                className="dropdown-menu"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/agenda_dia" className="dropdown-item">
                  <font color="black"> Agenda del dia</font>
                </Link>

                <Link to="/Recibos_dia" className="dropdown-item">
                  <font color="black">Recibos del dia</font>
                </Link>

                <Link to="/reporte_cajau" className="dropdown-item">
                  <font color="black"> Reporte de caja(Cajero)</font>
                </Link>
              </ul>
            </li>
          </ul>
        </li>

        <li className="nav-item dropdown show">
          <a
            className="nav-link dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            id="navbarDropdownMenuLink"
            href="https://sisprestamos-siscontapres.herokuapp.com/#/"
          >
            Consulta
          </a>
          <ul
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <div data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/Estado_de_cuenta" className="dropdown-item">
                <font color="black"> Estado de cuenta</font>
              </Link>
            </div>

            <div data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/consultacreditosactivos" className="dropdown-item">
                <font color="black"> Consulta de creditos</font>
              </Link>
            </div>
            <li className="dropdown-submenu">
              <div className="dropdown-item dropdown-toggle" href="#">
                Geolocalizacion
              </div>
              <ul
                className="dropdown-menu"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/google_coordinates" className="dropdown-item">
                  <font color="black"> Ubicaciones</font>
                </Link>

                <Link to="/Ubicaciones_analistas" className="dropdown-item">
                  <font color="black"> Ubicaciones(Analistas)</font>
                </Link>

                <Link to="/Ubicaciones_Recibos" className="dropdown-item">
                  <font color="black"> Ubicaciones(Recibos)</font>
                </Link>

                <Link to="/Ubicacion_Desembolso" className="dropdown-item">
                  <font color="black"> Desembolsos</font>
                </Link>
              </ul>
            </li>
          </ul>
        </li>

        <li className="nav-item dropdown show">
          <a
            className="nav-link dropdown-toggle"
            href="https://sisprestamos-siscontapres.herokuapp.com/#/"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            DatatGrid
          </a>
          <ul
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <li className="dropdown-submenu">
              <div className="dropdown-item dropdown-toggle" href="#">
                DatatGrid
              </div>
              <ul
                className="dropdown-menu"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/Edit_grid" className="dropdown-item">
                  <font color="black"> Plan Pago</font>
                </Link>
                <Link to="/Grid_M_Caja" className="dropdown-item">
                  <font color="black">Caja</font>
                </Link>
                <Link to="/Grid_M_Cuota" className="dropdown-item">
                  <font color="black">Ingreso Financiero</font>
                </Link>
              </ul>
            </li>

            <li className="dropdown-submenu">
              <div className="dropdown-item dropdown-toggle" href="#">
                Anulacion
              </div>
              <ul
                className="dropdown-menu"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/Anular_planpago" className="dropdown-item">
                  <font color="black">Anular credito</font>
                </Link>

                <Link to="/Anular_recibos" className="dropdown-item">
                  <font color="black">Anular recibo</font>
                </Link>
              </ul>
            </li>
          </ul>
        </li>

        <li className="nav-item dropdown show">
          <a
            className="nav-link dropdown-toggle"
            href="https://sisprestamos-siscontapres.herokuapp.com/#/"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Reportes
          </a>
          <ul
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <li className="dropdown-submenu">
              <div className="dropdown-item dropdown-toggle" href="#">
                Reportes Por Detalle
              </div>
              <ul
                className="dropdown-menu"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                   <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/Reporte_Caja" className="dropdown-item">
                      <font color="black">Caja</font>
                    </Link>
                  </div>

                  <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/IngrsoFinanciero" className="dropdown-item">
                      <font color="black">Ingreso financiero</font>
                    </Link>
                </div>

                <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/Reporte_desembolso" className="dropdown-item">
                      <font color="black">Desembolsos</font>
                    </Link>
                </div>

                <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/Reporte_saldo_cartera" className="dropdown-item">
                      <font color="black">Saldo de cartera</font>
                    </Link>
</div>
<div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link
                      to="/Reporte_saldo_cartera_bruta"
                      className="dropdown-item"
                    >
                      <font color="black">Saldo de cartera bruta</font>
                    </Link>
                </div>

                <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/Reporte_prospectacion" className="dropdown-item">
                      <font color="black">Prospectacion</font>
                    </Link>
                </div>
                <div data-toggle="collapse" data-target=".navbar-collapse.show">
                  <Link to="/ReporteMora" className="dropdown-item">
                    <font color="black"> Proyeccion de pagos</font>
                  </Link>
                </div>

                <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/Reporte_caja_egreso" className="dropdown-item">
                      <font color="black"> Reporte De Egresos</font>
                    </Link>
</div>
<div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link
                      to="/ReporteDetalleCajaEgreso"
                      className="dropdown-item"
                    >
                      <font color="black"> Detalle De Egresos</font>
                    </Link>
                 </div>
              </ul>
            </li>
            {/*  */}

            {/*  */}
            <li className="dropdown-submenu">
              <div className="dropdown-item dropdown-toggle" href="#">
                Reportes Consolidados
              </div>
              <ul
                className="dropdown-menu"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link
                      to="/Reporte_desembolso_analista"
                      className="dropdown-item"
                    >
                      <font color="black">Desembolso analista</font>
                    </Link>
</div>
<div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link
                      to="/Reporte_desembolso_promotor"
                      className="dropdown-item"
                    >
                      <font color="black">Desembolso promotor</font>
                    </Link>
                    </div>

                    <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/Reporte_consolidado" className="dropdown-item">
                      <font color="black">Consolidado de caja</font>
                    </Link>
                  </div>

                  <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/ResumenRecGestor" className="dropdown-item">
                      <font color="black">
                        {" "}
                        Consolidado Recuperacion por Gestor
                      </font>
                    </Link>
                    </div>

                    <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/ResumenRecCartera" className="dropdown-item">
                      <font color="black">
                        {" "}
                        Consolidado Recuperacion por Cartera
                      </font>
                    </Link>
</div>
<div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/EfectividadAnalista" className="dropdown-item">
                      <font color="black">
                        {" "}
                       Efectividad por Analista
                      </font>
                    </Link>
                  </div>

                  <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link to="/ResumenMoraDias" className="dropdown-item">
                      <font color="black"> Consolidado de Mora</font>
                    </Link>
                 </div>

                 <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link
                      to="/Reporte_Consolidado_Egreso"
                      className="dropdown-item"
                    >
                      <font color="black"> Consolidado De Egresos</font>
                    </Link>
                    </div>
                    <div data-toggle="collapse" data-target=".navbar-collapse.show">
                    <Link
                      to="/Reporte_Consolidado_Gasto"
                      className="dropdown-item"
                    >
                      <font color="black"> Consolidado De Gastos</font>
                    </Link>
                </div>
              </ul>
            </li>
          </ul>
        </li>

        <li className="nav-item dropdown show">
          <a
            className="nav-link dropdown-toggle"
            href="https://sisprestamos-siscontapres.herokuapp.com/#/"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Mantenimiento
          </a>
          <ul
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <div data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/profile" className="dropdown-item">
                <font color="black">Perfil de Usuario</font>
              </Link>
            </div>

            <li className="dropdown-submenu">
              <div className="dropdown-item dropdown-toggle" href="#">
            Usuario
              </div>
              <ul
                className="dropdown-menu"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/register" className="dropdown-item">
                <font color="black"> Registro de Usuario</font>
              </Link>
              </ul>
            </li>
           
            <li className="dropdown-submenu">
              <div className="dropdown-item dropdown-toggle" href="#">
                Datos de Analista
              </div>
              <ul
                className="dropdown-menu"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/CostoAnalista" className="dropdown-item">
                  <font color="black"> Costo por Analista</font>
                </Link>
              </ul>
            </li>
            
            <li className="dropdown-submenu">
              <div className="dropdown-item dropdown-toggle" href="#">
                Gastos
              </div>
              <ul
                className="dropdown-menu"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
              >
                <Link to="/Tipos_De_Gastos" className="dropdown-item">
                <font color="black">Tipo de Gasto</font>
              </Link>
              </ul>
            </li>
          </ul>
        </li>

     

        <li className="nav-item">
          <a href="/" onClick={this.logOut} className="nav-link">
            Salir
          </a>
        </li>
      </ul>
    );
    return (
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark bg-primary fixed-top">
          <div className="container">
            <a href="#intro" className="navbar-brand scrollTo sombreado">
              {/* <font size="2" color="white">
            {this.state.TEXTO}
          </font> */}

              {/* <img
              src={foto}
              alt="This is me - IT worker"
              width="130" height="100"
               className="image rounded-circle img-fluid"
            /> */}
            </a>
            <button
              type="button"
              data-toggle="collapse"
              data-target="#navbarcollapse"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              className="navbar-toggler navbar-toggler-right "
            >
              <span className="navbar-toggler-icon"></span>{" "}
              <ion-icon name="menu-outline"></ion-icon>
            </button>
            <div
              id="navbarcollapse"
              className="collapse navbar-collapse justify-content-md-center"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Inicio
                  </Link>
                </li>
              </ul>
              {localStorage.usertoken ? userLink : loginRegLink}
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(Landing);

import React, { Component } from "react";
import axios from "axios";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import TimerMixin           from 'react-timer-mixin';
//import Swal from "sweetalert2";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";


class Grid_M_Caja extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

UpCaja = (registro) => {
return axios
.post(
`${api}/grid/postp_caja`,
{
A_Caja    : registro.A_Caja,
NO_RECIBO : registro.NO_RECIBO,
}
)
.then((response) => {
// Swal.fire(
// 'Registro Grid',
// 'grid registrado',
// 'SUCCESS'
// )
// this.props.history.push('/')
// this.props.history.push('/Edit_grid')
})
.then((res) => {
  window.alert("Actualizado");
return res;
})
.catch((err) => {
window.alert("Verifique los Datos");
window.location.reload();
});
};    
                  
consultarMovimientocaja= () => {
const url = `${api}/grid/get_M_caja/${this.busquedaRef.current.value}`;
//console.log(url);
fetch(url)
.then((respuesta) => respuesta.json())
.then((resultado) => this.setState({ rowData: resultado }));
};
        


consultarTotal = (Id) => {
return axios
.get(`${api}/grid/get_total/${this.busquedaRef.current.value}`)
.then((res) => {
return res;
})
.catch((err) => {
window.alert("Verifique los datos");
window.location.reload();
return err;
});
};


busquedaRef = React.createRef();
obtenerDatos = (e) => {

if (this.state.Tipo_usuario === 1 ) {

e.preventDefault();
this.consultarTotal().then((res) => {
this.setState({
NO_RECIBO  : this.busquedaRef.current.value, 
TOTAL      : res.data.TOTAL,  
TOTAL_Caja : res.data.TOTAL_Caja,  
T_CUOTA    : res.data.T_CUOTA,
DIFERENCIA : res.data.DIFERENCIA,
}); 
});
this.consultarMovimientocaja()
} else {
  window.alert("No tiene permisos para usar este modulo");
  }
};

reload = (e) =>{
e.preventDefault()
this.consultarMovimientocaja()

this.consultarTotal().then((res) => {
this.setState({
NO_RECIBO  : this.busquedaRef.current.value, 
TOTAL      : res.data.TOTAL, 
TOTAL_Caja : res.data.TOTAL_Caja, 
T_CUOTA    : res.data.T_CUOTA,
DIFERENCIA : res.data.DIFERENCIA, 
}); 
});
}


constructor() {
super();
this.state = {
A_MCAJA      : [],//GET ARRAY
A_MCUOTA     : [],//GET ARRAY

NO_RECIBO    : [], 

columnDefs:[ 
    {headerName : 'Credito',        field : 'CO_CREDITO' ,      sortable : true , filter : true , editable : true},
    {headerName : 'Recibo',         field : 'NO_RECIBO',        sortable : true , filter : true , editable : true},
    {headerName : 'Fecha',          field : 'FECHA',            sortable : true , filter : true , editable : true},
    {headerName : 'Total',          field : 'TOTAL_RECIBIDO',   sortable : true , filter : true , editable : true},
    {headerName : 'Tipo movimiento',field : 'TIPO_MOVIMIENTO' , sortable : true , filter : true , editable : true},
    {headerName : 'Cordoba',        field : 'MONTO_CORDOBA',    sortable : true , filter : true , editable : true},
    {headerName : 'Dolar',          field : 'MONTO_DOLAR',      sortable : true , filter : true , editable : true},
    {headerName : 'Tipo cambio',    field : 'TIPODECAMBIO',     sortable : true , filter : true , editable : true},
    {headerName : 'Concepto',       field : 'CONCEPTO_1',       sortable : true , filter : true , editable : true},
    {headerName : 'Abono',          field : 'MONTO_ABONO_CUOTA',sortable : true , filter : true , editable : true},
    {headerName : 'Saldo',          field : 'SALDO_CREDITO',     sortable : true , filter : true , editable : true},
    {headerName : 'Cuota',          field : 'NO_CUOTAS' ,        sortable : true , filter : true , editable : true},
    {headerName : 'Fuente pago',    field : 'FUENTE_PAGO' ,      sortable : true , filter : true , editable : true},
    {headerName : 'Otros cargos',   field : 'OTROS_CARGOS' ,     sortable : true , filter : true , editable : true},
   
],

rowData :[],

TOTAL      : "0.00",
TOTAL_Caja : "0.00",
T_CUOTA    : "0.00",
DIFERENCIA : "0.00",

visibilidad  :"",

alias        : "",//GET USER
IP           : "",//GET USER
Tipo_usuario : "",//GET USER
ID_ROL: "",
PERMISO_ROL: "1019",
VALIDACION: "hidden",
errors       : {},
};
this.onChange = this.onChange.bind(this);
this.onSubmit = this.onSubmit.bind(this);
}

onChange(e) {
this.setState({ [e.target.name]: e.target.value });
}
 
onSubmit(e) {
if (this.state.Tipo_usuario === 1) {
e.preventDefault();
const registro = {
A_Caja     : this.state.rowData,
NO_RECIBO  : this.state.NO_RECIBO,
};

this.UpCaja(registro).then((res) => {
});
} else {
e.preventDefault();
window.alert("No tiene permisos para usar este modulo");
} // here else cerrare
}

visibilidad() {
TimerMixin.setTimeout(
() =>{ 
  if (this.state.Tipo_usuario === 1) {
this.setState({
visibilidad : "none",
});
} else {
this.setState({
visibilidad : "hidden",
});
window.alert("No tiene permisos para usar este modulo");
} 
},
5000
);
}


componentDidMount() {
const token = localStorage.usertoken;
const decoded = jwt_decode(token);
getUser(decoded.uid).then((res) => {
this.setState({
alias: res.data.Alias,
IP: res.data.IP,
Tipo_usuario: res.data.Tipo_usuario,
ID_ROL: res.data.ID_ROL,
});
let tiempo = '300'
TimerMixin.setTimeout(() => {
this.CONSULTA_PERMISO(); 
}, tiempo);  
});
this.visibilidad()
}

render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
        <div className="busqueda container">
          <div className="jumbotron mt-4 shadow-lg p-3 mb-5 bg-secondary text-white rounded">
            <h1 className="h3 mb-4 font-weight-normal  text-center">
              Consultar Caja
            </h1>
            <div className="row">
              <div className="form-group col-md-8">
                <input
                  ref={this.busquedaRef}
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="No.Credito.Ejemplo: 718"
                />
              </div> 
              <div className="form-group col-md-4">
                <button
                  onClick={this.obtenerDatos}
                  className="btn btn-lg btn-danger btn-block"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

      <div  className="container jumbotrom p-3 mb-2 bg-light text-dark ">
      <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-2 bg-light text-dark border rounded border-dark col-md-4">
        <div className="form-row">
    
        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12">
                  Plan pago{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -----------------
                  </font>
                  : {this.state.TOTAL}
                </div>
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12">
                  Caja{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    ----------------------------
                  </font>
                  : {this.state.TOTAL_Caja}
                </div>
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12">
                  Ingreso financiero{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    --
                  </font>
                  : {this.state.T_CUOTA}
                </div>
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12">
                  Diferencia{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -----------------
                  </font>
                  : {this.state.DIFERENCIA}
                </div>
        </div>
        </div>
        </div>
   

        <div align="end" className="col-md-12 mb-2">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button onClick={this.reload} className="btn btn-lg btn-success">
              Reload
            </button>
            <button onClick={this.onSubmit} className="btn btn-lg btn-primary ">
              Guardar
            </button>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div
              className="ag-theme-balham col-md-12"
              style={{
                width: 500,
                height: 400,
              }}
            >
                
              <AgGridReact
                columnDefs={this.state.columnDefs}
                rowData={this.state.rowData}
                rowSelection="multiple"
              />
            </div>
          </div>
        </div>       
      </form>
    );
  }
}
export default Grid_M_Caja;
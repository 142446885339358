import React, { Component } from "react";
import { login } from "../../UserFunctions";
import HomeNavbar from "../../HomeNavbar";

class Login extends Component {
  
constructor() {
super();
this.state = {
alias       : "",
password    : "",
err: {},
};
this.onChange = this.onChange.bind(this);
this.onSubmit = this.onSubmit.bind(this);
}


onChange(e) {
this.setState({ [e.target.name]: e.target.value });
}

onSubmit(e) {
e.preventDefault();
const user = {
alias    : this.state.alias,
password : this.state.password,
};
login(user).then((res) => {
  this.props.history.push(`/`);
});
}

  render() {
    return (
      <div className="container"><HomeNavbar />
        <div className="row">
          <div className="col-md-6 mt-5 mx-auto">
            <form noValidate onSubmit={this.onSubmit}>
              <h1 className="h3 mb-3 font-weight-normal text-center">
                  Inicio de Sesion
              </h1>

              <div className="form-group text-center">
                <label htmlFor="alias">Alias</label>
                <input
                  type="alias"
                  className="form-control"
                  name="alias"
                  planceholder="Enter your Alias"
                  value={this.state.alias}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group text-center">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
              </div>
              <button
                type="submit"
                className="btn btn-lg btn-primary btn-block"
              >
                Iniciar
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default Login;

import React, { Component } from "react";
import axios from "axios";
import { Multiselect } from "multiselect-react-dropdown";
import TimerMixin from "react-timer-mixin";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";


class Consultacreditosactivos extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  consultarCliente = () => {
    try {
      const url = `${api}/pagoactivo/get_clientes/${this.state.Tipo_usuario}/${this.state.sucursal}/${this.state.SUCURSAL_C}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ ClienteA: resultado }));
      
    } catch (error) {
      console.log(error)
    }
    
  };

  // Post consulta creditos activos
  Consultacreditos = (registro) => {
    return axios
      .post(`${api}/pagoactivo/getcredito`, {
        CLIENTE: registro.CLIENTE,
      })
      .then((response) => {
        // console.log(
        //   "Consulta de los creditos activos del cliente",
        //   response.data
        // );

        this.setState({
          Nombre: response.data.NOMBRE_CLIENTE,
          Creditos: response.data.ConsultaCreditosJson,
        });
        // window.location.reload();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        //console.log("el error es ", err);
        window.alert("Verifique el nombre del cliente", err);
        window.location.reload();
      });
  };

  //********************************************************************************************/
  //Get de los creditos
  getCredito = (Id_credito) => {
    return axios
      .get(`${api}/pagoactivo/get/${this.state.Creditos_A}/${this.state.Tipo_usuario}/${this.state.SUCURSAL_C}`)
      .then((res1) => {
        return res1;
      })
      .catch((err) => {
        window.alert("El credito no esta registrado ");
        window.location.reload();
        return err;
      });
  };

  //Busqueda de los creditos : props de
  busquedaRef = React.createRef();
  obtenerDatos = (e) => {
    e.preventDefault();

    this.getCredito().then((res1) => {
      this.setState({
        COD_CREDITO: res1.data.COD_CREDITO,
        NOMBRE_CLIENTE: res1.data.NOMBRE_CLIENTE,
        ANALISTA: res1.data.ANALISTA,
        FECHA_DESEMBOLSO: res1.data.FECHA_DESEMBOLSO,
        MONTO_TOTAL_APROBADO: res1.data.MONTO_TOTAL_APROBADO,
        TIC_APROBADO: res1.data.TIC_APROBADO,
        INTERES_PAGAR: res1.data.INTERES_PAGAR,
        DESEMB_PRINCIP: res1.data.DESEMB_PRINCIP,
        PLAZO_APROBADO: res1.data.PLAZO_APROBADO,
        FREC_PAG_APROBADO: res1.data.FREC_PAG_APROBADO,
        FECHA_VENCIMIENTO: res1.data.FECHA_VENCIMIENTO,
        TOTAL_RECIBIDO: res1.data.TOTAL_RECIBIDO,
        OTROS_CARGOS: res1.data.OTROS_CARGOS,
      TOTAL:res1.data.TOTAL,
        SALDO_FINAL: res1.data.SALDO_FINAL,
        /////
      });
      console.log(res1);
    });
  };

  constructor() {
    super();
    this.state = {
      Nombre: [],
      CLIENTE: "",
      Creditos: [], //GET ARRAY
      Creditos_A: [], //GET ARRAY
      ClienteA: [], //GET ARRAY
      IP: [],
      P: "localhost",
      COD_ROL_SUC: "",
      //****************/
      COD_CREDITO: [],
      NOMBRE_CLIENTE: [],
      ANALISTA: [],
      FECHA_DESEMBOLSO: [],
      MONTO_TOTAL_APROBADO: [],
      TIC_APROBADO: [],
      INTERES_PAGAR: [],
      DESEMB_PRINCIP: [],
      PLAZO_APROBADO: [],
      FREC_PAG_APROBADO: [],
      FECHA_VENCIMIENTO: [],
      TOTAL_RECIBIDO: [],
      OTROS_CARGOS:  [],
      TOTAL: [],
      SALDO_FINAL: [],

      SUCURSAL_C:"",
      SucursalA:[],

      Tipo_usuario : "", //RESTRICCION
      sucursal     : "",//RESTRICCION

      ID_ROL: "",
      PERMISO_ROL: "1013",
      VALIDACION: "hidden",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.busqueda = this.busqueda.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  busquedaselect = React.createRef();
  onSubmit(e) {
    e.preventDefault();
    //console.log(this.busquedaselect.current.getSelectedItems());
    const termino = this.busquedaselect.current.getSelectedItems();

    this.setState({
      CLIENTE: termino.map((item) => item.NOMBRE_CLIENTE),
    });

    const registro = {
      CLIENTE: termino.map((item) => item.NOMBRE_CLIENTE),
    };

    this.Consultacreditos(registro).then((res) => {
      // this.props.history.push(`/login`)
     // window.alert("busqueda realizada exitosamente");
    });
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        alias: res.data.Alias,
        IP: res.data.IP,
        Tipo_usuario   : res.data.Tipo_usuario,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
      });
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
      }, 100);
      let tiempo = '300'
      TimerMixin.setTimeout(() => {
      this.consultarCliente();
    }, tiempo);   

    });
  }

  busqueda(e){
    e.preventDefault();
    const url = `${api}/pagoactivo/get_clientes/${this.state.Tipo_usuario}/${this.state.SUCURSAL_C}/${this.state.SUCURSAL_C}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ ClienteA: resultado }));
  }


  render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
        <div className="container mt-5">
          <div className="row">
            <div className="shadow-lg p-3 mb-5 bg-white rounded  col-md-6 mx-auto " style={{ width: "200px;" }}>

              <h1 className="h3 mb-3 font-weight-normal  text-center">
                <font size="5" >Consulta de creditos del cliente</font>
              </h1>

              <div className="form-row">


                <div className="form-group text-center col-md-6 mx-auto" style={{ width: "200px;" }}>
                  <label htmlFor="SucursalA">Sucursal</label>
                  <select
                    name="SUCURSAL_C"
                    className="form-control"
                    value={this.state.SUCURSAL_C}
                    onChange={this.onChange}
                    placeholder="Campo obligatorio"
                    onClick={this.consultarSucursal()}
                  >
                     <option>Todas</option>
                    {this.state.SucursalA.map((item) => (
                      <option
                        key={item.NOMBRE_SUCURSAL}
                        value={item.COD_SUCURSAL}
                      >
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                   
                  </select>
                </div>


              </div>
              <div className="form-group d-flex justify-content-center">
                <button
                  onClick={this.busqueda}
                  className="btn btn-lg btn-success btn-block col-md-6"
                >
                  Cargar datos
                </button>

              </div>

              <Multiselect
                options={this.state.ClienteA}
                selectionLimit="1"
                displayValue="NOMBRE_CLIENTE"
                ref={this.busquedaselect}
              />
              <div className="form-group">
              </div>
              <div className="form-group d-flex justify-content-center">
                <button
                  onClick={this.onSubmit}
                  className="btn btn-lg btn-danger btn-block col-md-6"
                >
                  Buscar
                </button>

              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="shadow p-3 mb-5 bg-white rounded col-md-6 mx-auto " style={{ width: "200px;" }}>
              <h1 className="h3 mb-4 font-weight-normal  text-center">
                Seleccionar credito
                </h1>

              <div className="d-flex justify-content-center">
                <select
                  name="Creditos_A"
                  className="form-control col-md-6"
                  multiple={false}//SI SE CAMBIA A TRUE PERMITIRA SELECCIONAR MAS DE UNA OPCION Y DESAPARECERA EL "Warning: The `value` prop supplied to <select> must be a scalar value if `multiple` is false." DE LA CONSOLA
                  value={this.state.Creditos_A}
                  onChange={this.onChange}
                >
                  <option>

                  </option>

                  {this.state.Creditos.map((item) => (
                    <option key={item.COD_CREDITO} value={item.COD_CREDITO}>
                      {item.COD_CREDITO}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="TELEFONO_NEG"></label>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  onClick={this.obtenerDatos}
                  className="btn btn-lg btn-primary btn-block col-md-6"
                >
                  Consultar credito
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div >
            <div className="col-sm-8 mx-auto">
              <h1 className="text-center"><font size="5" >Datos del credito</font></h1>
            </div>
            <table className="table table-striped font-weight-bold">
              <tbody>


              <tr>
                  <td>No.Credito<font size="1" color="white" style={{ visibility: "hidden" }}>--</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.COD_CREDITO}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Cliente<font size="1" color="white" style={{ visibility: "hidden" }}>-----</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font><font size="1" color="white" style={{ visibility: "hidden" }}>-----------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td><font size="2" >{this.state.NOMBRE_CLIENTE}</font></td>
                </tr>
                
                <tr>
                  <td>Analista<font size="1" color="white" style={{ visibility: "hidden" }}>----</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font><font size="1" color="white" style={{ visibility: "hidden" }}>------------------------------------------</font></td>
                 
                </tr>

                <tr>
                  <td><font size="2" >{this.state.ANALISTA}</font></td>
                </tr>
                <tr>
                  <td>Fec desembolso<font size="1" color="white" style={{ visibility: "hidden" }}>--</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.FECHA_DESEMBOLSO}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Monto aprobado<font size="1" color="white" style={{ visibility: "hidden" }}>---</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.MONTO_TOTAL_APROBADO}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>TIC Aprobado<font size="1" color="white" style={{ visibility: "hidden" }}>--------</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.TIC_APROBADO}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Interes a pagar<font size="1" color="white" style={{ visibility: "hidden" }}>----</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.INTERES_PAGAR}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Plazo aprobado<font size="1" color="white" style={{ visibility: "hidden" }}>---</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.PLAZO_APROBADO}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Frec de pago<font size="1" color="white" style={{ visibility: "hidden" }}>--------</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.FREC_PAG_APROBADO}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Vencimiento<font size="1" color="white" style={{ visibility: "hidden" }}>--------</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.FECHA_VENCIMIENTO}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Saldo inicial<font size="1" color="white" style={{ visibility: "hidden" }}>--------</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.DESEMB_PRINCIP}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Total pagado<font size="1" color="white" style={{ visibility: "hidden" }}>-----</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.TOTAL_RECIBIDO}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Otros cargos<font size="1" color="white" style={{ visibility: "hidden" }}>-----</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.OTROS_CARGOS}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Total<font size="1" color="white" style={{ visibility: "hidden" }}>------------------------</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.TOTAL}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>
                <tr>
                  <td>Saldo final<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>:<font size="1" color="white" style={{ visibility: "hidden" }}>-----------</font>{this.state.SALDO_FINAL}<font size="1" color="white" style={{ visibility: "hidden" }}>--------------------------------------------</font></td>
                 
                </tr>


               
              

               

                

             

               

               

              

               

                

                

               
              </tbody>
            </table>
          </div>
        </div>
      </form>
    );
  }
}

export default Consultacreditosactivos;

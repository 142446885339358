import axios from "axios";
import api from "../../src/api/axiosConfigRoute";

export const DatosCuota = ( Empresa, Sucursal, Credito ) => {
    return axios
      .get(`${api}/DataCredito/DatoCuota/${Empresa}/${Sucursal}/${Credito}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
      });
  };


  
export const PagoCredito = ( DataCredito ) => {
    return axios
      .post(`${api}/CajaIngreso/Pago/${DataCredito.EmpresaCredito}/${DataCredito.SucursalCredito}/${DataCredito.CodCreditoCuota}/${DataCredito.Id}`, {
         CobrarMora  : DataCredito.CobrarMora,
         DiasMora : DataCredito.DiasMora,
         TipoMovimiento : DataCredito.TipoMovimiento,
         ValidacionFecha : DataCredito.ValidacionFecha,
         FechaPago : DataCredito.FechaPago,
         Concepto : DataCredito.Concepto,
         ReciboManual : DataCredito.ReciboManual,
         Cordobas : DataCredito.Cordobas,
         Dolares : DataCredito.Dolares,
         MoraCobrar: DataCredito.MoraCobrar,
         Latitude : DataCredito.Latitude,
         Longitude : DataCredito.Longitude,
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
      });
  };
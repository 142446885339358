import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

class R_CAJA_CAJEROS extends Component {

    consultarAnalista = () => {
        const url = `${api}/cajero/get`;
        //console.log(url);
        fetch(url)
          .then((respuesta) => respuesta.json())
          .then((resultado) => this.setState({ Analista_A: resultado }));
      };

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
  getUser(decoded.uid).then((res) => {
    this.setState({
        alias: res.data.Alias,
        TIME: res.data.TIME,
        Tipo_usuario1: res.data.Tipo_usuario,
        IP: res.data.IP,
      });
      this.consultarAnalista()
    });
  }

  //Get reporte de caja
  getReport = (Id_usuario) => {
    return axios
      .get(`${api}/reportes/get/${this.state.A_ANALISTA}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        window.alert("No hay pagos para mostrar");
        window.location.reload();
        return err;
      });
  };

  //Busqueda de los creditos : props de
  busquedaRef = React.createRef();
  obtenerDatos = (e) => {
    var tiempo = "1000";
    e.preventDefault();
    if (this.state.Tipo_usuario1 === 1) {
      // console.log(this.busquedaRef.current.value);
      TimerMixin.setTimeout(
        () => {
          this.getReport().then((res) => {
            this.setState({
              CAJERO: res.data.CAJERO,
              NO_DE_PAGOS: res.data.NO_DE_PAGOS,
              TOTAL: res.data.TOTAL,
              CORDOBAS: res.data.CORDOBAS,
              DOLARES: res.data.DOLARES,
              /////
            });

            // console.log(res)
          });
        },

        tiempo
      );
    } else {
      e.preventDefault();
      Swal.fire(
        "Reporte de caja",
        '"No puede ver datos de otro usuario',
        "error"
      );
    }
  };

  constructor() {
    super();
    this.state = {
      CAJERO: [], //GET
      NO_DE_PAGOS: [], //GET
      TOTAL: [], //GET
      CORDOBAS: [], //GET
      DOLARES: [], //GET
      alias: [], //GET
      TIME: "",
      Analista_A: [],
      A_ANALISTA:"",
      Tipo_usuario1:"",
      IP:"",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <form ><SisprestamosNavbar />
      <div className="container">
          <div className="row">
      
          <div className="jumbotron mt-5 p-3 mb-2 bg-secondary text-white col-md-8 mx-auto" style={{width: "200px;"}}>
          <h1 className="h3 mb-4 font-weight-normal  text-center">
                Reporte de caja
              </h1> 

              <div className="d-flex justify-content-center">
                 <div className="form-group text-center col-md-6">
                <label htmlFor="Analista_A">Cajero</label>
                <select
                  name="A_ANALISTA"
                  className="form-control "
                  value={this.state.A_ANALISTA}
                  onChange={this.onChange}
                >
                  <option></option>
                  {this.state.Analista_A.map((item) => (
                    <option
                      key={item.id}
                      value={item.alias}
                    >
                      {item.alias}
                    </option>
                  ))}
                </select>
              </div>
              </div>

              <div className="d-flex justify-content-center">
              <button
                    onClick={this.obtenerDatos}
                    className="btn btn-lg btn-danger btn-block col-md-4"
                  >
                   Buscar
                  </button>
                  </div>
              </div>
            </div>
          </div>
         
      
        <div className="container">
          <div>
            <div className="col-sm-8 mx-auto">
              <h1 className="text-center">
                <font size="5">Datos de pagos recibidos</font>
              </h1>
            </div>
            <table className="table table-striped font-weight-bold">
              <tbody>
                <tr>
                  <td>
                    Cajero
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      -----------
                    </font>
                    :
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      -----------
                    </font>
                    {this.state.CAJERO}
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      --------------------------------------------
                    </font>
                  </td>
                </tr>

                <tr>
                  <td>
                    Pagos
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      -----------
                    </font>
                    :
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      -----------
                    </font>
                    {this.state.NO_DE_PAGOS}
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      --------------------------------------------
                    </font>
                  </td>
                </tr>

                <tr>
                  <td>
                    Total
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      -------------
                    </font>
                    :
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      -----------
                    </font>
                    {this.state.TOTAL}
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      --------------------------------------------
                    </font>
                  </td>
                </tr>

                <tr>
                  <td>
                    Cordobas
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      ----
                    </font>
                    :
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      -----------
                    </font>
                    {this.state.CORDOBAS}
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      --------------------------------------------
                    </font>
                  </td>
                </tr>

                <tr>
                  <td>
                    Dolares
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      --------
                    </font>
                    :
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      -----------
                    </font>
                    {this.state.DOLARES}
                    <font
                      size="1"
                      color="white"
                      style={{ visibility: "hidden" }}
                    >
                      --------------------------------------------
                    </font>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    );
  }
}
export default R_CAJA_CAJEROS;

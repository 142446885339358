import React, { Component } from "react";
import TimerMixin from "react-timer-mixin";
import axios from "axios";
import Swal from "sweetalert2";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";


class Tipos_De_Gastos extends Component {


  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarEmpresa = () => {
    const url = `${api}/empresa/get`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ EMPRESA_A: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  consultarCatalogo = () => {
    const url = `${api}/banco/catalogo/${this.state.COD_NIVEL}/0/${this.state.PERIODO_SERVER}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ A_CATALOGO: resultado }));
  };


  CONSULTA_DETALLE = () => {
    const url = `${api}/gastos/tipo/${this.state.COD_EMPRESA_B}/${this.state.COD_SUCURSAL_B}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ rowData: resultado }));
  };

  constructor() {
    super();
    this.state = {
      ALIAS: "",
      Tipo_usuario: "",
      COD_REGION: "",
      COD_NIVEL: "",
      ID_ROL: "",
      COD_EMPRESA: "",
      COD_ROL_SUC: "",
      PERIODO_SERVER:"",

      EMPRESA_A: [],
      SucursalA: [],
      A_CATALOGO:[],

      COD_EMPRESA_B: "",
      COD_SUCURSAL_B: "",
      COD_GASTO_REC:"",
      DESCRIPCION_GASTO_REC:"",
      CUENTA_CAJA:"",
      CUENTA_GASTO:"",
      CUENTA_CAJA_ME:"",


      columnDefs: [
        {
          headerName: "DESCRIPCION",
          field: "DESCRIPCION_GASTO",
          sortable: true,
          filter: true,
          editable: false,
          width: 360,
        },
         {
          headerName: "CUENTA DE CAJA",
          field: "CUENTA_CAJA",
          sortable: true,
          filter: true,
          editable: false,
          width: 330,
        },
        {
          headerName: "NO CUENTA",
          field: "NO_CUENTA_CAJA",
          sortable: true,
          filter: true,
          editable: false,
          width: 330,
        },
         {
          headerName: "CUENTA DE GASTO",
          field: "CUENTA_GASTO",
          sortable: true,
          filter: true,
          editable: false,
          width: 330,
        },
        {
          headerName: "NO CUENTA",
          field: "NO_CUENTA_GASTO",
          sortable: true,
          filter: true,
          editable: false,
          width: 330,
        },
        {
          headerName: "CUENTA CAJA MONEDA EXT",
          field: "CUENTA_CAJA_ME",
          sortable: true,
          filter: true,
          editable: false,
          width: 330,
        },
        {
          headerName: "NO CUENTA",
          field: "NO_CUENTA_CAJA_ME",
          sortable: true,
          filter: true,
          editable: false,
          width: 330,
        },
        {
          headerName: "FECHA",
          field: "FECHA_MODIFICADO",
          sortable: true,
          filter: true,
          editable: false,
          width: 250,
        },
       
      ],

      rowData: [],

      BOTON_FORMALIZACION:"true",

          /////////////PERMISOS DEL MODULO/////
          PERMISO_ROL: "1038",
          VALIDACION: "hidden", 

    };
    this.onChange = this.onChange.bind(this);
    this.change_suc = this.change_suc.bind(this);
    this.getSelectedRowData = this.getSelectedRowData.bind(this);
    this.limpiar = this.limpiar.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  change_suc(e) {
    this.setState({ [e.target.name]: e.target.value });

    this.setState({
      GASTOS:[]
    });
    
    TimerMixin.setTimeout(() => {
      this.CONSULTA_DETALLE();
    }, 300);
  
  }

  ACTIVAR_BOTON_FORMALIZACION(e){  
    // e.preventDefault();
    this.setState({
      BOTON_FORMALIZACION:"false",
    });
    }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        Tipo_usuario: res.data.Tipo_usuario,
        COD_REGION: res.data.COD_REGION,
        COD_NIVEL: res.data.COD_NIVEL,
        ID_ROL: res.data.ID_ROL,
        COD_EMPRESA: res.data.COD_EMPRESA_B,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
        PERIODO_SERVER: res.data.PERIODO_SERVER,
        COD_EMPRESA_B: res.data.COD_EMPRESA_B,
      });
  
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
        this.consultarEmpresa();
        this.consultarSucursal();
        this.consultarCatalogo();
      }, 300);
    });
  }

  getSelectedRowData = (e) => {
    //  e.preventDefault();

    const selectedData = this.gridApi.getSelectedRows();

    var datos = [];
    datos = this.state.rowData;

    try {
      for (var i = 0; i < datos.length; i++) {
        if (datos[i].COD_GASTO === selectedData[0].COD_GASTO) {
          this.setState({
            COD_EMPRESA_B: datos[i].COD_EMPRESA,
            COD_SUCURSAL_B: datos[i].COD_SUCURSAL,
            COD_GASTO_REC: datos[i].COD_GASTO,
            DESCRIPCION_GASTO_REC: datos[i].DESCRIPCION_GASTO,
            CUENTA_CAJA: datos[i].NO_CUENTA_CAJA,
            CUENTA_GASTO: datos[i].NO_CUENTA_GASTO,
            CUENTA_CAJA_ME: datos[i].NO_CUENTA_CAJA_ME,
          });
        }
      }

      
    } catch (error) {
      console.log(error);
    }
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  limpiar = (e) => {
    this.setState({
        COD_GASTO_REC: "",
        DESCRIPCION_GASTO_REC: "",
        BOTON_FORMALIZACION:"true",
        CUENTA_CAJA:"",
        CUENTA_GASTO:"",
        CUENTA_CAJA_ME:"",
      });

  }

  registro = (newCliente) => {
    return axios
      .post(`${api}/gastos/registro`, {
        COD_EMPRESA: newCliente.COD_EMPRESA,
        COD_SUCURSAL: newCliente.COD_SUCURSAL,
        COD_GASTO: newCliente.COD_GASTO,
        DESCRIPCION_GASTO: newCliente.DESCRIPCION_GASTO,
        MODIFICADO_POR: newCliente.MODIFICADO_POR,
        CUENTA_CAJA: newCliente.CUENTA_CAJA,
        CUENTA_GASTO: newCliente.CUENTA_GASTO,
        CUENTA_CAJA_ME: newCliente.CUENTA_CAJA_ME,
      })
      .then((response) => {
        Swal.fire(
            "Registro De Tipo De Gasto",
          response.data.mensaje,
          response.data.alerta
        );

        var tiempo = "2000";
        TimerMixin.setTimeout(() => {
          if (response.data.alerta === "error") {
            this.setState({
     
              BOTON_FORMALIZACION:"true",
            });
          } else {
            // window.location.href = "/";
            // window.location.href = "/Solicitud_items";
            this.limpiar();
            TimerMixin.setTimeout(() => {
                this.CONSULTA_DETALLE();
              }, 600);
          }
        }, tiempo);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Confrimacion De Reserva",
          "Por Favor Verifique Los Datos",
          "error"
        );
      });
  };


  onSubmit(e) {
    let tiempo = "1000";

    if (this.state.DESCRIPCION_GASTO_REC === "") {
      e.preventDefault();
      Swal.fire(
        "Registro De Tipo De Gasto",
        "Por Favor Agregue Una Descripcion",
        "error"
      );
    }else if (this.state.COD_EMPRESA_B === "") {
      e.preventDefault();
      Swal.fire(
        "Registro De Tipo De Gasto",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    }else if (this.state.COD_SUCURSAL_B === "") {
      e.preventDefault();
      Swal.fire(
        "Registro De Tipo De Gasto",
        "Por Favor Seleccione una Sucursal",
        "error"
      );
    }
    else {
      e.preventDefault();
      Swal.fire({
        title: "Desea Registrar El Tipo De Gasto",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.ACTIVAR_BOTON_FORMALIZACION();
          // e.preventDefault();
          TimerMixin.setTimeout(() => {
            let timerInterval;
            Swal.fire({
              title:  "Registro De Tipo De Gasto",
              html: "Lo Datos Se Estan Registrando",
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
              }
            });
            const newCliente = {
              COD_EMPRESA: this.state.COD_EMPRESA_B,
              COD_SUCURSAL: this.state.COD_SUCURSAL_B,
              COD_GASTO: this.state.COD_GASTO_REC,
              DESCRIPCION_GASTO: this.state.DESCRIPCION_GASTO_REC,
              MODIFICADO_POR: this.state.ALIAS,
              CUENTA_CAJA: this.state.CUENTA_CAJA,
              CUENTA_GASTO: this.state.CUENTA_GASTO,
              CUENTA_CAJA_ME: this.state.CUENTA_CAJA_ME,
             
            };

            this.registro(newCliente).then((res) => {});
          }, tiempo);
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    } // here else cerrare
  }

  render() {
    return (
      <div className="homeDashboard" style={{ visibility: this.state.VALIDACION }}>
        <SisprestamosNavbar />
        <div className="homeDashboardContainer">

          <div className="container mt-5">
            <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
              <h3 className=" p-3">Tipos De Gastos</h3>

              <div className="shadow-lg  mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
              <div className="container">
                <div className="row">
                <div className="col-md-2">
                    <div style={{ textAlign: "left" }} htmlFor="COD_GASTO_REC">
                      NO.GASTO
                    </div>

                    <input
                      type="COD_GASTO_REC"
                      className="form-control text-right"
                      name="COD_GASTO_REC"
                      planceholder="Enter your Password"
                      value={this.state.COD_GASTO_REC}
                      onChange={this.onChange}
                      disabled = "off"
                    />
                  </div>
                  <div className="col-md-3">
                    <div style={{ textAlign: "left" }} htmlFor="COD_EMPRESA_B">
                      EMPRESA
                    </div>
                    <select
                      name="COD_EMPRESA_B"
                      className="form-control"
                      value={this.state.COD_EMPRESA_B}
                      onChange={this.onChange}
                    >
                      {this.state.EMPRESA_A.map((item) => (
                        <option
                          key={item.COD_EMPRESA}
                          value={item.COD_EMPRESA}
                        >
                          {item.NOMBRE_EMPRESA}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <div style={{ textAlign: "left" }} htmlFor="COD_SUCURSAL_B">
                      SUCURSAL
                    </div>
                    <select
                      name="COD_SUCURSAL_B"
                      className="form-control"
                      value={this.state.COD_SUCURSAL_B}
                      onChange={this.change_suc}
                      placeholder="Campo obligatorio"
                    >
                        <option></option>
                      {this.state.SucursalA.map((item) => (
                        <option
                          key={item.COD_SUCURSAL}
                          value={item.COD_SUCURSAL}
                        >
                          {item.NOMBRE_SUCURSAL}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div  className="col-md-4 p-3 ">
                 
                      <button
                        onClick={this.limpiar}
                        className="btn btn-lg btn-danger col-md-12"
                      >
                        LIMPIAR
                      </button>
                    </div>
                </div>
              </div>

              <div className="container text-center">
  <div className="row">
 
                  <div className="col-md-8">
                    <div style={{ textAlign: "left" }} htmlFor="DESCRIPCION_GASTO_REC">
                      DESCRIPCION
                    </div>

                    <input
                      type="DESCRIPCION_GASTO_REC"
                      className="form-control text-left"
                      name="DESCRIPCION_GASTO_REC"
                      planceholder="Enter your Password"
                      value={this.state.DESCRIPCION_GASTO_REC}
                      onChange={this.onChange}
                      autoComplete = "off"
                    />
                  </div>
                  
                  <div  className="col-md-4 p-3 ">
                 
                 <button
                   onClick={this.onSubmit}
                   className="btn btn-lg btn-success col-md-12"

                   disabled={
                    this.state.BOTON_FORMALIZACION === "false"
                      ? true
                      : false
                  }
                 >
                   REGISTRAR
                 </button>
               </div>
  </div>
  <div className="container text-center">
  <div className="row">
  <div className="col-md-4">
                    <div style={{ textAlign: "left" }} htmlFor="CONCEPTO">
                      CUENTA DE CAJA
                    </div>
                    <input
                      list="data"
                      name="CUENTA_CAJA"
                      autoComplete="off"
                      className="form-control "
                      value={this.state.CUENTA_CAJA}
                      onChange={this.onChange}
                    />
                    <datalist id="data">
                      <option></option>
                      {this.state.A_CATALOGO.map((item) => (
                        <option key={item.NO_CUENTA} value={item.NO_CUENTA}>
                          {item.NOMBRE_CUENTA}
                        </option>
                      ))}
                    </datalist>
                  </div>
                  <div className="col-md-4">
                    <div style={{ textAlign: "left" }} htmlFor="CONCEPTO">
                      CUENTA DE GASTOS
                    </div>
                    <input
                      list="data"
                      name="CUENTA_GASTO"
                      autoComplete="off"
                      className="form-control "
                      value={this.state.CUENTA_GASTO}
                      onChange={this.onChange}
                    />
                    <datalist id="data">
                      <option></option>
                      {this.state.A_CATALOGO.map((item) => (
                        <option key={item.NO_CUENTA} value={item.NO_CUENTA}>
                          {item.NOMBRE_CUENTA}
                        </option>
                      ))}
                    </datalist>
                  </div>
                  <div className="col-md-4">
                    <div style={{ textAlign: "left" }} htmlFor="CONCEPTO">
                    CUENTA DE CAJA MONEDA EXT
                    </div>
                    <input
                      list="data"
                      name="CUENTA_CAJA_ME"
                      autoComplete="off"
                      className="form-control "
                      value={this.state.CUENTA_CAJA_ME}
                      onChange={this.onChange}
                    />
                    <datalist id="data">
                      <option></option>
                      {this.state.A_CATALOGO.map((item) => (
                        <option key={item.NO_CUENTA} value={item.NO_CUENTA}>
                          {item.NOMBRE_CUENTA}
                        </option>
                      ))}
                    </datalist>
                  </div>
                  </div>
                  </div>
</div>

</div>
              <div className="container">
          <div className="row">
            <div className="col-md-12  mx-auto">
              <div className="container jumbotrom   bg-light text-dark ">
                <div className="d-flex flex-column  bd-highlight jumbotrom  bg-light text-dark border rounded border-dark">
                  <div className="form-row">
                    <div
                      className="ag-theme-balham col-md-12"
                      style={{
                        width: 400,
                        height: 200,
                      }}
                    >
                       <AgGridReact 
                        columnDefs={this.state.columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        rowData={this.state.rowData}
                        rowSelection="multiple"
                        onRowSelected={this.getSelectedRowData}
                      />
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

              </div>
        </div>
      </div>
      </div>
    );
  }
}
        export default Tipos_De_Gastos;

import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from "../../../api/axiosConfigRoute";
import SisprestamosNavbar from "../../SisprestamosNavbar";

class Anular_planpago extends Component {
  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarEmpresa = () => {
    const url = `${api}/empresa/get`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ EmpresaA: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  Anular = (anularplan) => {
    return axios
      .post(`${api}/anular/A_credito`, {
        COD_EMPRESA:anularplan.COD_EMPRESA,
        USUARIO: anularplan.USUARIO,
        COD_CREDITO: anularplan.COD_CREDITO,
        CREDITO_ANTERIOR: anularplan.CREDITO_ANTERIOR,
        FECHA_DESEMB: anularplan.FECHA_DESEMB,
      })
      .then((response) => {
        Swal.fire(
          "Anulacion de Credito",
          response.data.mensaje,
          response.data.alerta
        );
        this.setState({
          COD_EMPRESA_B: "",
          COD_SUCURSAL_B: "",

          CREDITO_B: "",

          COD_CREDITO: "",
          FECHA: "",
          PAGO: "",
          COD_ANTERIOR: "",
          N_SUCURSAL: "",
          NOMBRE: "",
          color: "white",
          BTN_APL: "true",
          block: "true",
        });
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        window.alert("Verifique los Datos");
      });
  };

  BTN_APL_DESAC() {
    this.setState({
      BTN_APL: "false",
      block: "false",
    });
  }

  getdatos = (Id_credito) => {
    return axios
      .get(
        `${api}/anular/credito/${this.state.CREDITO_B}/${this.state.COD_EMPRESA_B}/${this.state.COD_SUCURSAL_B}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  obtenerDatos = (e) => {
    if (this.state.VALIDACION === "visible") {
      if (this.state.CREDITO_B === "") {
        e.preventDefault();
        Swal.fire(
          "Anulacion del Credito",
          "Por Favor Agregue un Numero de Credito",
          "error"
        );
      } else if (this.state.COD_EMPRESA_B === "") {
        e.preventDefault();
        Swal.fire(
          "Anulacion del Credito",
          "Por Favor Seleccione Una Empresa",
          "error"
        );
      } else if (this.state.COD_SUCURSAL_B === "") {
        e.preventDefault();
        Swal.fire(
          "Anulacion del Credito",
          "Por Favor Seleccione Una Sucursal",
          "error"
        );
      } else {
        e.preventDefault();
        this.getdatos().then((res) => {
          this.setState({
            COD_CREDITO: res.data.COD_CREDITO,
            FECHA: res.data.FECHA,
            PAGO: res.data.PAGO,
            N_SUCURSAL: res.data.N_SUCURSAL,
            block: res.data.block,
            COD_ANTERIOR: res.data.COD_ANTERIOR,
            NOMBRE: res.data.NOMBRE,
            color: res.data.color,
          });
        });
      }
    } else {
      window.alert("No tiene permisos para usar este modulo");
    }
  };

  constructor() {
    super();
    this.state = {
      ALIAS: "",
      COD_NIVEL: "",
      ID_ROL: "",
      COD_ROL_SUC: "",
      FECHA_SERVER: "",
      MANEJO_GASTO: "",

      SucursalA: [],
      EmpresaA: [],

      COD_EMPRESA_B: "",
      COD_SUCURSAL_B: "",

      CREDITO_B: "",

      COD_CREDITO: "",
      FECHA: "",
      PAGO: "",
      COD_ANTERIOR: "",
      N_SUCURSAL: "",
      NOMBRE: "",
      color: "white",

      PERMISO_ROL: "1021",
      VALIDACION: "hidden",
      BTN_APL: "true",
      block: "true",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        COD_NIVEL: res.data.COD_NIVEL,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
        FECHA_SERVER: res.data.FECHA_SERVER,
        MANEJO_GASTO: res.data.MANEJO_GASTO,
      });
      let tiempo = "300";
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
        this.consultarEmpresa();
        this.consultarSucursal();
      }, tiempo);
    });
  }

  onSubmit(e) {
    if (this.state.COD_CREDITO === "") {
      e.preventDefault();
      Swal.fire(
        "Anulacion del Credito",
        "Por Favor Busque Los Datos Del Credito",
        "error"
      );
    }else  if (this.state.COD_EMPRESA_B === "") {
      e.preventDefault();
      Swal.fire(
        "Anulacion del Credito",
        "Por Favor Seleccione la Empresa",
        "error"
      );
    }  else {
      e.preventDefault();
      Swal.fire({
        title: "Desea Anular El Credito",
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.BTN_APL_DESAC();
          let timerInterval;
          TimerMixin.setTimeout(() => {
            Swal.fire({
              title: "Anulacion del Credito",
              html: "Los Datos se Estan Anulando",
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                try {
                } catch (error) {}
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
              }
            });
            const anularplan = {
              COD_EMPRESA:this.state.COD_EMPRESA_B,
              USUARIO: this.state.ALIAS,
              COD_CREDITO: this.state.COD_CREDITO,
              CREDITO_ANTERIOR: this.state.COD_ANTERIOR,
              FECHA_DESEMB: this.state.FECHA,
            };
            this.Anular(anularplan).then((res) => {});
          }, 300);
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    }
  }

  render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}>
        <SisprestamosNavbar />
        <div className="busqueda container">
          <div className="shadow-lg mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
            <div className="row">
              <div className="col-md-3">
                <div style={{ textAlign: "left" }} htmlFor="COD_EMPRESA_B">
                  EMPRESA
                </div>
                <select
                  name="COD_EMPRESA_B"
                  className="form-control text-center"
                  value={this.state.COD_EMPRESA_B}
                  onChange={this.onChange}
                  disabled={this.state.BTN_APL === "false" ? true : false}
                >
                  <option></option>
                  {this.state.EmpresaA.map((item) => (
                    <option key={item.COD_EMPRESA} value={item.COD_EMPRESA}>
                      {item.NOMBRE_EMPRESA}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <div style={{ textAlign: "left" }} htmlFor="COD_SUCURSAL_B">
                  SUCURSAL
                </div>
                <select
                  name="COD_SUCURSAL_B"
                  className="form-control text-center"
                  value={this.state.COD_SUCURSAL_B}
                  onChange={this.onChange}
                  placeholder="Campo obligatorio"
                  disabled={this.state.BTN_APL === "false" ? true : false}
                >
                  <option></option>
                  {this.state.SucursalA.map((item) => (
                    <option key={item.COD_SUCURSAL} value={item.COD_SUCURSAL}>
                      {item.NOMBRE_SUCURSAL}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="CREDITO_B">
                  NO.CREDITO
                </div>
                <input
                  type="CREDITO_B"
                  className="form-control text-right"
                  name="CREDITO_B"
                  placeholder="No.Credito.Ejemplo: 718"
                  value={this.state.CREDITO_B}
                  onChange={this.onChange}
                  autoComplete="off"
                />
              </div>
              <div className="form-group col-md-2 p-4">
                <button
                  onClick={this.obtenerDatos}
                  className="btn btn-outline-info"
                  disabled={this.state.BTN_APL === "false" ? true : false}
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-5">
          <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
            <div className="container">
              <div className="row">
                <div className="col-md-12 mt-3 mx-auto">
                  <h1 className="h3 mb-4 font-weight-normal  text-center">
                    DATOS DEL CREDITO
                  </h1>
                  <div align="end" className="col-md-12 mb-2">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        onClick={this.onSubmit}
                        className="btn btn-outline-success btn-lg"
                        disabled={this.state.block === "false" ? true : false}
                      >
                        ANULAR
                      </button>
                    </div>
                  </div>
                  <div className="container jumbotrom p-3 mb-2 bg-light text-dark ">
                    <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-2 bg-light text-dark border rounded border-dark">
                      <div className="form-row">
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          NO.CREDITO{" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          : {this.state.COD_CREDITO}
                        </div>

                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          FECHA{" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          : {this.state.FECHA}
                        </div>

                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4 text-left">
                          SUCURSAL{" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          : {this.state.N_SUCURSAL}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12 text-left">
                          CLIENTE{" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          : {this.state.NOMBRE}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12 text-left">
                          VERIFICACION{" "}
                          <font
                            size="1"
                            color="white"
                            style={{ visibility: "hidden" }}
                          >
                            -
                          </font>
                          :{" "}
                          <font color={this.state.color}>
                            {this.state.PAGO}
                          </font>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Anular_planpago;

// import { BrowserRouter as Router, Route } from "react-router-dom";
import { HashRouter as Router, Route } from "react-router-dom";
import React, { Component } from "react";
import "./style.css";

import HomeNavbar from "./components/HomeNavbar";
import SisprestamosNavbar from "./components/SisprestamosNavbar";
// +++++++++++++++++++++++++++++ QUITAR LOS MODULOS PARA NO DAR ACCESO A QUIENES NO LO HAN ADQUIRIDO ++++++++++++++++
// import SiscontapresNavbar from "./components/SiscontapresNavbar";
// import SiscontapresBancoNav from "./components/SiscontapresBancoNav";
// import SiscontapresConta from "./components/SiscontapresConta";
// import SisRRHNavbar from "./components/SisRRHNavbar";
// +++++++++++++++++++++++++++++ QUITAR LOS MODULOS PARA NO DAR ACCESO A QUIENES NO LO HAN ADQUIRIDO ++++++++++++++++
import Landing from "./components/Landing";

import Login from "../src/components/SISPRESTAMOS/REGISTRO/Login";
import Register from "../src/components/SISPRESTAMOS/REGISTRO/Register";

/////////////////////////************CREDITO*******************/////////////
//////////------------- REGISTRO
import Datos_Cliente from "../src/components/SISPRESTAMOS/REGISTRO/Datos_Cliente";
// import Caja from "../src/components/SISPRESTAMOS/REGISTRO/Caja";
import CajaIngresos from "../src/components/SISPRESTAMOS/REGISTRO/CajaIngresos";
import Solicitud from "../src/components/SISPRESTAMOS/REGISTRO/Solicitud";
import Seguimiento from "../src/components/SISPRESTAMOS/REGISTRO/Seguimiento";
import No_Pago from "../src/components/SISPRESTAMOS/REGISTRO/No_Pago";
import Aprobacion from "../src/components/SISPRESTAMOS/REGISTRO/Aprobacion";
import PlanpagoNivelado from "../src/components/SISPRESTAMOS/REGISTRO/PlanpagoNivelado";
import OtrosCargos from "../src/components/SISPRESTAMOS/REGISTRO/OtrosCargos";
import SobreSaldo from "../src/components/SISPRESTAMOS/REGISTRO/SobreSaldo";
import CajaSobreSaldo from "../src/components/SISPRESTAMOS/REGISTRO/CajaSobreSaldo";
import Tipos_De_Gastos from "../src/components/SISPRESTAMOS/REGISTRO/Tipos_De_Gastos";
import Caja_Gasto from "../src/components/SISPRESTAMOS/REGISTRO/Caja_Gasto";
import AnularGasto from "../src/components/SISPRESTAMOS/REGISTRO/AnularGasto";
import Creditos_desembolsar from "../src/components/SISPRESTAMOS/REGISTRO/Creditos_desembolsar";
import CostoAnalista from "../src/components/SISPRESTAMOS/REGISTRO/CostoAnalista";
//////////------------- CONSULTA Y MODIFICACION
import Profile from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION/Profile";
import Google_coordinates from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION/google_coordinates";
import Ubicaciones_analistas from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION//Ubicaciones_analistas";
import Consultacreditosactivos from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION//Consultacreditosactivos";
import Ubicaciones_Recibos from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION//Ubicaciones_Recibos";
import Edit_grid from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION//Edit_grid";
import Grid_M_Caja from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION//Grid_M_Caja";
import Grid_M_Cuota from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION//Grid_M_Cuota";
import Estado_de_cuenta from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION/Estado_de_cuenta";
import Ubicacion_Desembolso from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION//Ubicacion_Desembolso";
import Estado_desembolso from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION//Estado_desembolso";
import Desembolsos_dia from "../src/components/SISPRESTAMOS/CONSULTA Y MODIFICACION/Desembolsos_dia";
//////////------------- ANULACION
import Anular_planpago from "../src/components/SISPRESTAMOS/ANULACION/Anular_planpago";
import Anular_recibos from "../src/components/SISPRESTAMOS/ANULACION/Anular_recibos";
//////////------------- REPORTE
import Recibos_dia from "../src/components/SISPRESTAMOS/REPORTES/Recibos_dia";
import Reporte_CajaU from "../src/components/SISPRESTAMOS/REPORTES/Reporte_CajaU";
import R_CAJA_CAJEROS from "../src/components/SISPRESTAMOS/REPORTES/R_CAJA_CAJEROS";
import Reporte_Caja from "../src/components/SISPRESTAMOS/REPORTES/Reporte_Caja";
import Reporte_consolidado from "../src/components/SISPRESTAMOS/REPORTES/Reporte_consolidado";
import IngrsoFinanciero from "../src/components/SISPRESTAMOS/REPORTES/IngrsoFinanciero";
import Reporte_desembolso from "../src/components/SISPRESTAMOS/REPORTES/Reporte_desembolso";
import Reporte_desembolso_analista from "../src/components/SISPRESTAMOS/REPORTES/Reporte_desembolso_analista";
import Reporte_desembolso_promotor from "../src/components/SISPRESTAMOS/REPORTES/Reporte_desembolso_promotor";
import agenda_dia from "../src/components/SISPRESTAMOS/REPORTES/agenda_dia";
import Reporte_saldo_cartera from "../src/components/SISPRESTAMOS/REPORTES/Reporte_saldo_cartera";
import Reporte_saldo_cartera_bruta from "../src/components/SISPRESTAMOS/REPORTES/Reporte_saldo_cartera_bruta";
import Reporte_prospectacion from "../src/components/SISPRESTAMOS/REPORTES/Reporte_prospectacion";
import ReporteMora from "../src/components/SISPRESTAMOS/REPORTES/ReporteMora";
import Reporte_caja_egreso from "../src/components/SISPRESTAMOS/REPORTES/Reporte_caja_egreso";
import Reporte_Consolidado_Egreso from "../src/components/SISPRESTAMOS/REPORTES/Reporte_Consolidado_Egreso";
import ReporteDetalleCajaEgreso from "../src/components/SISPRESTAMOS/REPORTES/ReporteDetalleCajaEgreso";
import Reporte_Consolidado_Gasto from "../src/components/SISPRESTAMOS/REPORTES/Reporte_Consolidado_Gasto";
import ResumenRecGestor from "../src/components/SISPRESTAMOS/REPORTES/ResumenRecGestor";
import ResumenRecCartera from "../src/components/SISPRESTAMOS/REPORTES/ResumenRecCartera";
import ResumenMoraDias from "../src/components/SISPRESTAMOS/REPORTES/ResumenMoraDias";
import EfectividadAnalista from "../src/components/SISPRESTAMOS/REPORTES/EfectividadAnalista";
// // +++++++++++++++++++++++++++++ QUITAR LOS MODULOS PARA NO DAR ACCESO A QUIENES NO LO HAN ADQUIRIDO ++++++++++++++++
// // //++++++++++++++++++++++++++++++++++++++++++ BANCO ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
//  ////////------------- REGISTRO
// import Registro_cheque from "../src/components/SISCONTAPRES/BANCO/REGISTROS/Registro_cheque";
// import Registro_deposito from "../src/components/SISCONTAPRES/BANCO/REGISTROS/Registro_deposito";
// import Registro_nota_credito from "../src/components/SISCONTAPRES/BANCO/REGISTROS/Registro_nota_credito";
// import Registro_nota_debito from "../src/components/SISCONTAPRES/BANCO/REGISTROS/Registro_nota_debito";
// import Registro_Cuenta_Banco from "../src/components/SISCONTAPRES/BANCO/REGISTROS/Registro_Cuenta_Banco";
// import Conciliacion_Bancaria from "../src/components/SISCONTAPRES/BANCO/REGISTROS/Conciliacion_Bancaria";
// import Registro_Deposito_Ent_suc from "../src/components/SISCONTAPRES/BANCO/REGISTROS/Registro_Deposito_Ent_suc";
// import Transferencia_de_fondos from "../src/components/SISCONTAPRES/BANCO/REGISTROS/Transferencia_de_fondos";
// import Transferencia_bancaria from "../src/components/SISCONTAPRES/BANCO/REGISTROS/Transferencia_bancaria";
// import Clasf_Dep_Suc from "../src/components/SISCONTAPRES/BANCO/REGISTROS/Clasf_Dep_Suc";
// //////////------------- REPORTE
// import Comprobante_cheque from "../src/components/SISCONTAPRES/BANCO/REPORTES/Comprobante_cheque";
// import Comprobante_deposito from "../src/components/SISCONTAPRES/BANCO/REPORTES/Comprobante_deposito";
// import Comprobante_nota_debito from "../src/components/SISCONTAPRES/BANCO/REPORTES/Comprobante_nota_debito";
// import Comprobante_nota_credito from "../src/components/SISCONTAPRES/BANCO/REPORTES/Comprobante_nota_credito";
// import Reporte_Comprobantes from "../src/components/SISCONTAPRES/BANCO/REPORTES/Reporte_Comprobantes";
// import Saldo_Cuenta from "../src/components/SISCONTAPRES/BANCO/REPORTES/Saldo_Cuenta";
// //++++++++++++++++++++++++++++++++++++++++++ CONTABILIDAD ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// // //////////------------- REGISTRO
// import Registro_comprobante_conta from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Registro_comprobante_conta";
// import Recuperacion_de_credito_conta from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Recuperacion_de_credito_conta";
// import Comprobante_diario from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Comprobante_diario";
// import Actualizar_Modificar_Comprobante_Contable from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Actualizar_Modificar_Comprobante_Contable";
// import Recuperacion_Desemb_Conta from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Recuperacion_Desemb_Conta";
// // import Comprobante_Gasto from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Comprobante_Gasto";
// import Catalogo_Maestro from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Catalogo_Maestro";
// import Apertura_Catalogo_Suc from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Apertura_Catalogo_Suc";
// import Cierre_Contable from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Cierre_Contable";
// import Revercion_comprobante from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Revercion_comprobante";
// // import Clasificar_cuentas_contable from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Clasificar_cuentas_contable";
// import Clasf_Balanza_General from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/Clasf_Balanza_General";
// import AperturaBalaceGeneral from "../src/components/SISCONTAPRES/CONTABILIDAD/REGISTRO/AperturaBalaceGeneral";
// //////////------------- REPORTE
// import Reporte_Cierre_Contable from "../src/components/SISCONTAPRES/CONTABILIDAD/REPORTES/Reporte_Cierre_Contable";
// import Auxiliar_contable from "../src/components/SISCONTAPRES/CONTABILIDAD/REPORTES/Auxiliar_contable";
// import Balance_general from "../src/components/SISCONTAPRES/CONTABILIDAD/REPORTES/Balance_general";
// import ReporteComposicion from "../src/components/SISCONTAPRES/CONTABILIDAD/REPORTES/ReporteComposicion";
// import EstadoResultado from "../src/components/SISCONTAPRES/CONTABILIDAD/REPORTES/EstadoResultado";
// //++++++++++++++++++++++++++++++++++++++++++ RR - HH  ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// import Registro_empleado from "../src/components/SISRRHH/REGISTRO/Registro_empleado";
// import Control_Saldo from "../src/components/SISRRHH/REGISTRO/Control_Saldo";
// +++++++++++++++++++++++++++++ QUITAR LOS MODULOS PARA NO DAR ACCESO A QUIENES NO LO HAN ADQUIRIDO ++++++++++++++++
class App extends Component {
  state = {};

  render() {
    return (
      <Router>
        <div className="App">
          <Route exact path="/" component={Landing} />
          <section id="modul" className="intro-section pb-2">
            <div className="container text-center topmargin-lg2">
              <div data-animate="fadeInDown" className="logo"></div>
              <div className="container">
                <Route exact path="/HomeNavbar" component={HomeNavbar} />

                <Route
                  exact
                  path="/SisprestamosNavbar"
                  component={SisprestamosNavbar}
                />
                {/* QUITAR LOS MODULOS PARA NO DAR ACCESO A QUIENES NO LO HAN ADQUIRIDO  */}
              {/*    <Route
                  exact
                  path="/SiscontapresNavbar"
                  component={SiscontapresNavbar}
                />
                <Route
                  exact
                  path="/SiscontapresBancoNav"
                  component={SiscontapresBancoNav}
                />
                <Route
                  exact
                  path="/SiscontapresConta"
                  component={SiscontapresConta}
                />
                <Route exact path="/SisRRHNavbar" component={SisRRHNavbar} />  
                {/* QUITAR LOS MODULOS PARA NO DAR ACCESO A QUIENES NO LO HAN ADQUIRIDO  */}

                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />

                <Route exact path="/profile" component={Profile} />

                {/* +++++++++++++++++++++++++ CREDITO ++++++++++++++++++++ */}
                <Route exact path="/datos_cliente" component={Datos_Cliente} />
                {/* <Route exact path="/caja" component={Caja} /> */}
                <Route exact path="/CajaIngresos" component={CajaIngresos} />
                <Route exact path="/solicitud" component={Solicitud} />
                <Route exact path="/seguimiento" component={Seguimiento} />
                <Route exact path="/No_Pago" component={No_Pago} />
                <Route exact path="/Aprobacion" component={Aprobacion} />
                <Route
                  exact
                  path="/PlanpagoNivelado"
                  component={PlanpagoNivelado}
                />
                <Route exact path="/OtrosCargos" component={OtrosCargos} />
                <Route exact path="/SobreSaldo" component={SobreSaldo} />

                <Route
                  exact
                  path="/CajaSobreSaldo"
                  component={CajaSobreSaldo}
                />
                <Route
                  exact
                  path="/Tipos_De_Gastos"
                  component={Tipos_De_Gastos}
                />
                <Route exact path="/Caja_Gasto" component={Caja_Gasto} />

                <Route exact path="/AnularGasto" component={AnularGasto} />
                
                <Route
                  exact
                  path="/Creditos_desembolsar"
                  component={Creditos_desembolsar}
                />

<Route
                  exact
                  path="/CostoAnalista"
                  component={CostoAnalista}
                />

<Route
                  exact
                  path="/EfectividadAnalista"
                  component={EfectividadAnalista}
                />

                <Route exact path="/reporte_cajau" component={Reporte_CajaU} />

                <Route
                  exact
                  path="/consultacreditosactivos"
                  component={Consultacreditosactivos}
                />
                <Route
                  exact
                  path="/google_coordinates"
                  component={Google_coordinates}
                />
                <Route
                  exact
                  path="/Ubicaciones_analistas"
                  component={Ubicaciones_analistas}
                />
                <Route
                  exact
                  path="/Ubicaciones_Recibos"
                  component={Ubicaciones_Recibos}
                />

                <Route
                  exact
                  path="/R_CAJA_CAJEROS"
                  component={R_CAJA_CAJEROS}
                />
                <Route exact path="/Edit_grid" component={Edit_grid} />
                <Route exact path="/Grid_M_Caja" component={Grid_M_Caja} />
                <Route exact path="/Grid_M_Cuota" component={Grid_M_Cuota} />
                <Route
                  exact
                  path="/Anular_planpago"
                  component={Anular_planpago}
                />
                <Route
                  exact
                  path="/Anular_recibos"
                  component={Anular_recibos}
                />
                <Route exact path="/Reporte_Caja" component={Reporte_Caja} />
                <Route
                  exact
                  path="/Reporte_consolidado"
                  component={Reporte_consolidado}
                />
                <Route
                  exact
                  path="/ingrsoFinanciero"
                  component={IngrsoFinanciero}
                />
                <Route
                  exact
                  path="/Reporte_desembolso"
                  component={Reporte_desembolso}
                />
                <Route
                  exact
                  path="/Reporte_desembolso_analista"
                  component={Reporte_desembolso_analista}
                />
                <Route
                  exact
                  path="/Reporte_desembolso_promotor"
                  component={Reporte_desembolso_promotor}
                />
                <Route exact path="/agenda_dia" component={agenda_dia} />
                <Route
                  exact
                  path="/Reporte_saldo_cartera"
                  component={Reporte_saldo_cartera}
                />
                <Route exact path="/Recibos_dia" component={Recibos_dia} />
                <Route
                  exact
                  path="/Desembolsos_dia"
                  component={Desembolsos_dia}
                />

                <Route
                  exact
                  path="/Reporte_saldo_cartera_bruta"
                  component={Reporte_saldo_cartera_bruta}
                />
                <Route
                  exact
                  path="/Ubicacion_Desembolso"
                  component={Ubicacion_Desembolso}
                />
                <Route
                  exact
                  path="/Estado_desembolso"
                  component={Estado_desembolso}
                />
                <Route
                  exact
                  path="/Reporte_prospectacion"
                  component={Reporte_prospectacion}
                />

                <Route
                  exact
                  path="/Estado_de_cuenta"
                  component={Estado_de_cuenta}
                />

                <Route exact path="/ReporteMora" component={ReporteMora} />

                <Route
                  exact
                  path="/Reporte_caja_egreso"
                  component={Reporte_caja_egreso}
                />
                <Route
                  exact
                  path="/Reporte_Consolidado_Egreso"
                  component={Reporte_Consolidado_Egreso}
                />
                <Route
                  exact
                  path="/ReporteDetalleCajaEgreso"
                  component={ReporteDetalleCajaEgreso}
                />

                <Route
                  exact
                  path="/Reporte_Consolidado_Gasto"
                  component={Reporte_Consolidado_Gasto}
                />


<Route
                  exact
                  path="/ResumenRecGestor"
                  component={ResumenRecGestor}
                />


<Route
                  exact
                  path="/ResumenRecCartera"
                  component={ResumenRecCartera}
                />

<Route
                  exact
                  path="/ResumenMoraDias"
                  component={ResumenMoraDias}
                />
                {/* QUITAR LOS MODULOS PARA NO DAR ACCESO A QUIENES NO LO HAN ADQUIRIDO  */}
                {/**********************CONTABILIDAD****************************************/}

                {/* <Route
                  exact
                  path="/Clasificar_cuentas_contable"
                  component={Clasificar_cuentas_contable}
                /> */}

             {/*    <Route
                  exact
                  path="/Clasf_Balanza_General"
                  component={Clasf_Balanza_General}
                />

                <Route
                  exact
                  path="/AperturaBalaceGeneral"
                  component={AperturaBalaceGeneral}
                />
                <Route
                  exact
                  path="/Registro_cheque"
                  component={Registro_cheque}
                />
                <Route
                  exact
                  path="/Registro_deposito"
                  component={Registro_deposito}
                />
                <Route
                  exact
                  path="/Registro_nota_credito"
                  component={Registro_nota_credito}
                />
                <Route
                  exact
                  path="/Registro_nota_debito"
                  component={Registro_nota_debito}
                />
                <Route
                  exact
                  path="/Comprobante_cheque"
                  component={Comprobante_cheque}
                />
                <Route
                  exact
                  path="/Comprobante_deposito"
                  component={Comprobante_deposito}
                />
                <Route
                  exact
                  path="/Comprobante_nota_debito"
                  component={Comprobante_nota_debito}
                />
                <Route
                  exact
                  path="/Comprobante_nota_credito"
                  component={Comprobante_nota_credito}
                />
                <Route
                  exact
                  path="/Registro_comprobante_conta"
                  component={Registro_comprobante_conta}
                />
                <Route
                  exact
                  path="/Registro_Cuenta_Banco"
                  component={Registro_Cuenta_Banco}
                />
                <Route
                  exact
                  path="/Conciliacion_Bancaria"
                  component={Conciliacion_Bancaria}
                />

                <Route
                  exact
                  path="/Reporte_Comprobantes"
                  component={Reporte_Comprobantes}
                />

                <Route exact path="/Saldo_Cuenta" component={Saldo_Cuenta} />

                <Route
                  exact
                  path="/Recuperacion_de_credito_conta"
                  component={Recuperacion_de_credito_conta}
                />

                <Route
                  exact
                  path="/Comprobante_diario"
                  component={Comprobante_diario}
                />

                <Route
                  exact
                  path="/Actualizar_Modificar_Comprobante_Contable"
                  component={Actualizar_Modificar_Comprobante_Contable}
                />

                <Route
                  exact
                  path="/Recuperacion_Desemb_Conta"
                  component={Recuperacion_Desemb_Conta}
                />  
                {/* <Route
                  exact
                  path="/Comprobante_Gasto"
                  component={Comprobante_Gasto}
                /> */}
            {/*    <Route
                  exact
                  path="/Catalogo_Maestro"
                  component={Catalogo_Maestro}
                />

                <Route exact path="/Clasf_Dep_Suc" component={Clasf_Dep_Suc} />

                <Route
                  exact
                  path="/Registro_Deposito_Ent_suc"
                  component={Registro_Deposito_Ent_suc}
                />

                <Route
                  exact
                  path="/Apertura_Catalogo_Suc"
                  component={Apertura_Catalogo_Suc}
                />

                <Route
                  exact
                  path="/Transferencia_de_fondos"
                  component={Transferencia_de_fondos}
                />

                <Route
                  exact
                  path="/Transferencia_bancaria"
                  component={Transferencia_bancaria}
                />

                <Route
                  exact
                  path="/Cierre_Contable"
                  component={Cierre_Contable}
                />

                <Route
                  exact
                  path="/Reporte_Cierre_Contable"
                  component={Reporte_Cierre_Contable}
                />
                <Route
                  exact
                  path="/Auxiliar_contable"
                  component={Auxiliar_contable}
                />

                <Route
                  exact
                  path="/Balance_general"
                  component={Balance_general}
                />

                <Route
                  exact
                  path="/Revercion_comprobante"
                  component={Revercion_comprobante}
                />

                <Route
                  exact
                  path="/ReporteComposicion"
                  component={ReporteComposicion}
                />

                <Route
                  exact
                  path="/EstadoResultado"
                  component={EstadoResultado}
                />  

                {/**********************RRH****************************************/}
             {/*    <Route
                  exact
                  path="/Registro_empleado"
                  component={Registro_empleado}
                />

                <Route exact path="/Control_Saldo" component={Control_Saldo} /> 

                {/* QUITAR LOS MODULOS PARA NO DAR ACCESO A QUIENES NO LO HAN ADQUIRIDO  */}
              </div>
            </div>
          </section>
        </div>
      </Router>
    );
  }
}

export default App;

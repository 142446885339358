import React, { Component } from "react";
import axios from "axios";
import { Map, Marker, TileLayer, Popup } from "react-leaflet";
// import Swal from "sweetalert2";
import TimerMixin from "react-timer-mixin";
// import { Icon } from "leaflet";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

class google_coordinates extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };
  // consultarCliente = () => {
  //   const url = ` https://localhost:3000/pagoactivo/get`;
  //   //console.log(url);
  //   fetch(url)
  //     .then((respuesta) => respuesta.json())
  //     .then((resultado) => this.setState({ Consulta: resultado }));

  // };

  // Post consulta creditos activos
  ubicaciones = (registro) => {
    return axios
      .post(`${api}/maps/recibo`, {
        // CAJERO: registro.CAJERO,
        No_recibo: registro.No_recibo,
      
      })
      .then((response) => {
        // console.log(
        //   "Consulta de los creditos activos del cliente",
        //   response.data
        // );

        this.setState({
          Consulta: response.data.Consulta,
        });
        // window.location.reload();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("el error es ", err);
        window.alert("Verifique los datos ingresados", err);
        // window.location.reload();
      });
  };

  constructor() {
    super();
    this.state = {
        No_recibo:"",
      CAJERO: "",
      fecha_1: "",
      fecha_2: "",
      Tipo_usuario: "",
      Consulta: [],

      ID_ROL: "",
      PERMISO_ROL: "1016",
      VALIDACION: "hidden",

      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {

      e.preventDefault();

      const registro = {
        // CAJERO: this.state.CAJERO,
        No_recibo: this.state.No_recibo,
        
      };

      this.ubicaciones(registro).then((res) => {
        // this.props.history.push(`/login`)
        // window.alert("busqueda realizada exitosamente");
      });
  
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        alias: res.data.Alias,
        IP: res.data.IP,
        Tipo_usuario: res.data.Tipo_usuario,
        ID_ROL: res.data.ID_ROL,
        // consulta:res.data.Consulta,
      });
      let tiempo = '300'
      TimerMixin.setTimeout(() => {
      this.CONSULTA_PERMISO(); 
    }, tiempo);   
      //this.consultarCliente();
      //console.log(res);
      //console.log("el puerto es", window.location.hostname);
    });
  }

  render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
        <div className="container mt-5">
          <div className="row">
          <div className="shadow-lg p-3 mb-5 bg-white rounded  col-md-8 mx-auto " style={{width: "200px;"}}>
          <h1 className="h3 mb-4 font-weight-normal  text-center">
                Consulta
              </h1>

              {/* <div className="form-group text-center">
                <label htmlFor="CAJERO">Cajero</label>
                <input
                  type="CAJERO"
                  className="form-control text-center"
                  name="CAJERO"
                  planceholder="Ingrese el nombre del cliente"
                  value={this.state.CAJERO}
                  onChange={this.onChange}
                />
              </div> */}


               
        
<div className="d-flex justify-content-center">
            
        
<div className="form-group text-center col-md-4">
               
                    <label className="required text-center">No.Recibo</label>
                    <input
                      type="No_recibo"
                      className="form-control text-center"
                      name="No_recibo"
                      planceholder="Ingrese el nombre del cliente"
                      value={this.state.No_recibo}
                      onChange={this.onChange}
                    />
              
               </div>
               
               </div>
             












              {/* <div className="form-group text-center">
                <label htmlFor="fecha_1">Fecha inicio</label>
                <input
                  type="fecha_1"
                  className="form-control text-center"
                  name="fecha_1"
                  planceholder="Ingrese el nombre del cliente"
                  value={this.state.fecha_1}
                  onChange={this.onChange}
                />
              </div>

              <div className="form-group text-center">
                <label htmlFor="fecha_2">Fecha fin</label>
                <input
                  type="fecha_2"
                  className="form-control text-center"
                  name="fecha_2"
                  planceholder="Ingrese el nombre del cliente"
                  value={this.state.fecha_2}
                  onChange={this.onChange}
                />
              </div> */}
<div className="d-flex justify-content-center">
              <button
                onClick={this.onSubmit}
                className="btn btn-lg btn-primary btn-block col-md-4"
              >
                Buscar
              </button>
              </div>
            </div>
          </div>
        </div>
{/* CORDENADAS DE MATAGALPA */}
        {/* <Map center={[12.921159, -85.9164926]} zoom={12}> */}
        {/* CORDENADAS DE CHINANDEGA */}
        <Map center={[12.8647101, -87.2627031]} zoom={7}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          {this.state.Consulta.map((crime) => (
            <Marker
              key={crime.NO_RECIBO}
              position={[crime.LATITUD, crime.LONGITUD]}
            >
              <Popup>
                <div>
                <h2>Cajero:{crime.CAJERO}</h2>
                  <h2>No.Recibo:{crime.NO_RECIBO}</h2>
                  <h2>Fecha:{crime.FECHA}</h2>
                  <h2>Hora:{crime.HORA}</h2>
                </div>
              </Popup>
            </Marker>
          ))}

          
        </Map>
      </form>
    );
  }
}
export default google_coordinates;

import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";


registerLocale("es", es);

class Seguimiento extends Component {


  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  // Post de pagos
  seguimientos = (registro) => {
    return axios
      .post(
        `${api}/seguimiento/credito/${this.busquedaRef.current.value}`,

        {
          MOTIVO_C: registro.MOTIVO_C,
          N_GESTOR: registro.N_GESTOR,
          FECHA_SEGUIMIENTO: registro.FECHA_SEGUIMIENTO,
          FECHA_ARREGLO: registro.FECHA_ARREGLO,
          DESCRIPCION: registro.DESCRIPCION,
          RESULTADO_GESTION: registro.RESULTADO_GESTION,
          MONTO_ARREGLO: registro.MONTO_ARREGLO,
          ADICIONADO_POR: registro.ADICIONADO_POR,
          TIME_P:registro.TIME_P,
        }
      )
      .then((response) => {
        Swal.fire(
          'Registro seguimiento',
          'Seguimiento registrado',
          'SUCCESS'
        )
        this.props.history.push('/')
        this.props.history.push('/Seguimiento')
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        window.alert("Verifique los Datos del seguimiento");
        window.location.reload();
      });
  };
  //Get de los creditos
  getdatos = (Id_credito) => {
    return axios
      .get(`${api}/seguimiento/get/${this.busquedaRef.current.value}/${this.state.Tipo_usuario}/${this.state.sucursal}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        window.alert("El credito no esta registrado");
        window.location.reload();
        return err;
      });
  };

  //Busqueda de los creditos : props de
  busquedaRef = React.createRef();
  obtenerDatos = (e) => {
    e.preventDefault();
    // console.log(this.busquedaRef.current.value);

    this.getdatos().then((res) => {
      this.setState({
        NO_CREDITO: res.data.NO_CREDITO,
        NO_CLIENTE: res.data.NO_CLIENTE,
        NOMBRE: res.data.NOMBRE,
        VENCIMIENTO: res.data.VENCIMIENTO,
        TELEFONO: res.data.TELEFONO,
        CELULAR_MOV: res.data.CELULAR_MOV,
        DIRECCION: res.data.DIRECCION,

        //DESCRIPCION_M : res.DESCRIPCION_M,
      }); //, () => {
      this.consultarApi();
      this.consultarGestor();
      //}
      // console.log(res);
    });
  };

  consultarApi = () => {
    //
    // console.log("LA IP ES ", this.state.IP );
    //
    const url = `${api}/motivo/get`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ MotivoA: resultado }));
  };

  consultarGestor = () => {
    const url = `${api}/gestor/get/${this.state.Tipo_usuario}/${this.state.sucursal}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ GestorA: resultado }));
  };

  constructor() {
    super();
    this.state = {
      NO_CREDITO: [], //GET
      NO_CLIENTE: [], //GET
      NOMBRE: [], //GET
      VENCIMIENTO: [], //GET
      TELEFONO: [], //GET
      CELULAR_MOV: [], //GET
      DIRECCION: [], //GET
      MotivoA: [], //GET ARRAY
      GestorA: [], //GET ARRAY
      IP: [], //GET ARRAY
      P: "localhost",
      MOTIVO_C: "", //POST
      N_GESTOR: "", //POST
      FECHA_SEGUIMIENTO: "", //POST
      FECHA_ARREGLO: "", //POST
      DESCRIPCION: "", //POST
      RESULTADO_GESTION: "", //POST
      MONTO_ARREGLO: "", //POST
      ADICIONADO_POR: "", //POST
      TIME: "",
      TIME_P:"",
      COD_SUCURSAL_U:"",
      COD_EMPRESA_U:"",
      Tipo_usuario:"",
      sucursal:"",
      fecha              : new Date(),//GET

      PERMISO_ROL: "1003",
      VALIDACION: "hidden",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    var tiempo ="1000";
    if (this.state.MOTIVO_C === "") {
      e.preventDefault();
      Swal.fire(
        'Registro seguimiento',
        'Por favor ingrese el motivo',
        'error'
      )
    } else if (this.state.N_GESTOR === "") {
      e.preventDefault();
      Swal.fire(
        'Registro seguimiento',
        'Por favor ingrese el gestor',
        'error'
      )
    } else if (this.state.FECHA_SEGUIMIENTO === "") {
      e.preventDefault();
      Swal.fire(
        'Registro seguimiento',
        'Por favor ingrese la fecha del seguimiento',
        'error'
      )
    } else if (this.state.FECHA_ARREGLO === "") {
      e.preventDefault();
      Swal.fire(
        'Registro seguimiento',
        'Por favor ingrese la fecha del arreglo',
        'error'
      )
    } else if (this.state.DESCRIPCION === "") {
      e.preventDefault();
      Swal.fire(
        'Registro seguimiento',
        'Por favor ingrese la descripcion',
        'error'
      )
    } else if (this.state.RESULTADO_GESTION === "") {
      e.preventDefault();
      Swal.fire(
        'Registro seguimiento',
        'Por favor ingrese el resultado de gestion',
        'error'
      )
    } else if (this.state.MONTO_ARREGLO === "") {
      e.preventDefault();
      Swal.fire(
        'Registro seguimiento',
        'Por favor ingrese el monto del arreglo',
        'error'
      )
    } else {
    
        e.preventDefault();
        Swal.fire({
          title: 'Desea registrar el seguimiento',
          showDenyButton: true,
          // showCancelButton: true,
          confirmButtonText: `Registrar`,
          denyButtonText: `Cancelar`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // e.preventDefault();
        TimerMixin.setTimeout(
          () => {
            const registro = {
              MOTIVO_C: this.state.MOTIVO_C,
              N_GESTOR: this.state.N_GESTOR,
              FECHA_SEGUIMIENTO: this.state.FECHA_SEGUIMIENTO,
              FECHA_ARREGLO: this.state.FECHA_ARREGLO,
              DESCRIPCION: this.state.DESCRIPCION,
              RESULTADO_GESTION: this.state.RESULTADO_GESTION,
              MONTO_ARREGLO: this.state.MONTO_ARREGLO,
              ADICIONADO_POR: this.state.alias,
              TIME_P:this.state.TIME_P,
            };

            this.seguimientos(registro).then((res) => {
              // this.props.history.push(`/login`)
            });
          },

          tiempo
        );
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire('Registro cancelado', '', 'info')
        }
      })
      }
 
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        alias: res.data.Alias,
        IP: res.data.IP,
        TIME: res.data.TIME,
        TIME_P: res.data.TIME,
        COD_SUCURSAL_U: res.data.COD_SUCURSAL,
        COD_EMPRESA_U: res.data.COD_EMPRESA,
        Tipo_usuario: res.data.Tipo_usuario,
        sucursal: res.data.sucursal,
        ID_ROL: res.data.ID_ROL,
      });
      let tiempo = '300'
      TimerMixin.setTimeout(() => {
      this.CONSULTA_PERMISO(); 
    }, tiempo);  
      // console.log(res);
    });
  }

  onChange2 = (fecha) => {
    this.setState({ FECHA_SEGUIMIENTO: fecha });
};

onChange3 = (fecha1) => {
    this.setState({ FECHA_ARREGLO: fecha1 });
};

  render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}> <SisprestamosNavbar />
        <div className="busqueda container">
        <div className="jumbotron mt-5 p-3 mb-2 bg-secondary text-white">
          <h1 className="h3 mb-4 font-weight-normal  text-center">
              Consultar Datos
            </h1>
            <div className="row">
              <div className="form-group col-md-8">
                <input
                  ref={this.busquedaRef}
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Credito.Ejemplo: 718"
                />
              </div>
              <div className="form-group col-md-4">
                <button
                  onClick={this.obtenerDatos}
                  className="btn btn-lg btn-danger btn-block"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-sm-8 mx-auto">
            <h1 className="h3 mb-4 font-weight-normal  text-center">Datos del credito</h1>
               
            <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-2 bg-light text-dark border rounded border-dark">
            <div className="form-row">
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold col-md-4">
              No.Credito{" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  
                </font>
                : {this.state.NO_CREDITO}
              </div>
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold col-md-8">
              Vencimiento{" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  
                </font>
                : {this.state.VENCIMIENTO}
              </div>
              </div>
              <div className="form-row">
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold col-md-12">
              Nombre{" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  ----
                </font>
                : {this.state.NOMBRE}
              </div>
             
              </div>
              <div className="form-row">
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold col-md-6">
              Telefono <font size="1" color="white" style={{ visibility: "hidden" }}>
                  --
                </font> : {this.state.TELEFONO}
              </div>
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold col-md-6">
              Celular{" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  ------
                </font>
                : {this.state.CELULAR_MOV}
              </div>
            </div>
            <div className="form-row">
            <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold col-md-12">
          Direccion{" "}
                <font size="1" color="white" style={{ visibility: "hidden" }}>
                  --
                </font>
                : {this.state.DIRECCION}
              </div>
              </div>
            </div>
           

            
          </div>
        </div>

        <div className="container">
          <div className="row">
          <div className="col-md-12 mt-3 mx-auto">
              <h1 className="h3 mb-4 font-weight-normal  text-center">
                Seguimiento
              </h1>

              <div className="form-row">
              <div className="form-group text-center col-md-4">
                <label htmlFor="MotivoA">Motivos</label>
                <select
                  name="MOTIVO_C"
                  className="form-control"
                  value={this.state.MOTIVO_C}
                  onChange={this.onChange}
                >
                  <option></option>
                  {this.state.MotivoA.map((item) => (
                    <option key={item.ID_MOTIVO} value={item.DESCRIPCION}>
                      {item.DESCRIPCION}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group text-center col-md-4">
                <label htmlFor="GestorA">Gestor</label>
                <select
                  name="N_GESTOR"
                  className="form-control"
                  value={this.state.N_GESTOR}
                  onChange={this.onChange}
                >
                  <option></option>
                  {this.state.GestorA.map((item) => (
                    <option
                      key={item.COD_ANALISTA}
                      value={item.NOMBRE_ANALISTA}
                    >
                      {item.NOMBRE_ANALISTA}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group text-center col-md-4">
                <label htmlFor="RESULTADO_GESTION">Resultado de gestion</label>
                <select
                  name="RESULTADO_GESTION"
                  className="form-control"
                  value={this.state.RESULTADO_GESTION}
                  onChange={this.onChange}
                >
                  <option></option>
                  <option>ARREGLO DE PAGO</option>
                  <option>PROXIMA VISITA</option>
                  <option>LLAMADA</option>
                </select>
              </div>
              
              </div>


              <div className="form-row">
                
              <div className="form-group text-center col-md-4 mx-auto" style={{width: "200px;"}}>
                     <label htmlFor="FECHA_SEGUIMIENTO">Fecha seguimiento <font size="1" color="white" style={{ visibility: "hidden" }}>---------</font></label>
                
                        <DatePicker
                          selected={this.state.FECHA_SEGUIMIENTO}
                          onChange={this.onChange2}
                          locale="es"
                          className="form-control"
                          dateFormat="dd '/' M '/' yyyy"
                        />
                      
                    
                      </div> 
                      <div className="form-group text-center col-md-4 mx-auto" style={{width: "200px;"}}>
                     <label htmlFor="FECHA_ARREGLO">Fecha arreglo<font size="1" color="white" style={{ visibility: "hidden" }}>-------------------</font></label>
                
                        <DatePicker
                          selected={this.state.FECHA_ARREGLO}
                          onChange={this.onChange3}
                          locale="es"
                          className="form-control"
                          dateFormat="dd '/' M '/' yyyy"
                        />
                      
                    
                      </div> 

              <div className="form-group text-center col-md-4">
                <label htmlFor="MONTO_ARREGLO">Monto del arreglo</label>
                <input
                  type="MONTO_ARREGLO"
                  className="form-control"
                  name="MONTO_ARREGLO"
                  placeholder="                   Campo obligatorio"
                  value={this.state.MONTO_ARREGLO}
                  onChange={this.onChange}
                />
              </div>
              </div>
              <div className="form-group text-center col-md-12">
                <label htmlFor="DESCRIPCION">Descripcion</label>
                <input
                  type="DESCRIPCION"
                  className="form-control"
                  name="DESCRIPCION"
                  placeholder="               Campo obligatorio"
                  value={this.state.DESCRIPCION}
                  onChange={this.onChange}
                />
              </div>

             
             
              <div className="d-flex justify-content-center">
              <button
                onClick={this.onSubmit}
                className="btn btn-lg btn-primary btn-block col-md-4"
              >
                Registrar
              </button>
              </div>
            </div>
          </div>
        </div>

      </form>
    );
  }
}
export default Seguimiento;

import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import moment from "moment-timezone";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

registerLocale("es", es);

class Datos_Cliente extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };


  
  consultarNacionalidad = () => {
    const url = `${api}/nacionalidad/get`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ NacionalidadA: resultado }));
  };

  consultarDepartamento = () => {
    const url = ` ${api}/departamento/get/${this.state.A_NACIONALIDAD}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ DepartamentoA: resultado }));
  };

  consultarMunicipio = () => {
    const url = `${api}/municipio/get/${this.state.A_DEPARTAMENTOS}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ MunicipioA: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  consultarEmpresa = () => {
    const url = ` ${api}/empresa/get`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ EmpresaA: resultado }));
  };

  consultarBarrio = () => {
    const url = `${api}/barrios/get/${this.state.A_MUNICIPIOS}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ BarriosA: resultado }));
  };

  consultarProfesion = () => {
    const url = `${api}/consulta/get_profesion`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ ProfesionA: resultado }));
  };

  registro = (newCliente) => {
    return axios
      .post(`${api}/registros/registrar`, {
        UBICACION_GEOGRAFICA: newCliente.UBICACION_GEOGRAFICA,
        BARRIO: newCliente.BARRIO,
        NOMBRE1: newCliente.NOMBRE1,
        TELEFONO: newCliente.TELEFONO,
        TELEFONO_NEG: newCliente.TELEFONO_NEG,
        CELULAR_CL: newCliente.CELULAR_CL,
        CELULAR_MOV: newCliente.CELULAR_MOV,
        A_NACIONALIDAD: newCliente.A_NACIONALIDAD,
        CEDULA: newCliente.CEDULA,
        ESTADO_CIVIL: newCliente.ESTADO_CIVIL,
        A_DEPARTAMENTOS: newCliente.A_DEPARTAMENTOS,
        A_MUNICIPIOS: newCliente.A_MUNICIPIOS,
        DIRECCION_ACTUAL: newCliente.DIRECCION_ACTUAL,
        NOMBRE_NEG: newCliente.NOMBRE_NEG,
        DIRECCION_NEG: newCliente.DIRECCION_NEG,
        SEXO: newCliente.SEXO,
        A_SUCURSAL: newCliente.A_SUCURSAL,
        A_EMPRESA: newCliente.A_EMPRESA,
        ADICIONADO_POR: newCliente.ADICIONADO_POR,
        PROFESION: newCliente.PROFESION,
        FECHA_V_CEDULA:newCliente.FECHA_V_CEDULA,
        PROSPECTADOR:newCliente.PROSPECTADOR,
        FECHA_PROSPECTACION:newCliente.FECHA_PROSPECTACION,
        MONTO:newCliente.MONTO,
        FECHA_PROCESO:newCliente.FECHA_PROCESO,
        TIME_P: newCliente.TIME_P,
        FECHA_MODIFICADO:newCliente.FECHA_MODIFICADO,
      })
      .then((response) => {
        Swal.fire("Registro de cliente", response.data.mensaje, "success");
        this.props.history.push("/");
        this.props.history.push("/Datos_Cliente");
        // window.alert("Cliente registrado");
        // console.log("Registrado");
        // window.location.reload();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Swal.fire(
          "Registro de cliente",
          "El cliente ya fue registrado anteriormente",
          "error"
        );
        this.props.history.push("/");
        this.props.history.push("/Datos_Cliente");
        // window.alert("El cliente ya fue registrado");

        // window.location.reload();
      });
  };

  constructor() {
    super();
    this.state = {
      NacionalidadA : [],        //GET ARRAY
      DepartamentoA : [],       //GET ARRAY
      MunicipioA    : [],      //GET ARRAY
      SucursalA     : [],     //GET ARRAY
      EmpresaA      : [],    //GET ARRAY
      BarriosA      : [],   //GET ARRAY
      ProfesionA    : [],  //GET ARRAY
      IP            : [], //GET ARRAY
      P             : "localhost",
      COD_ROL_SUC: "",

      UBICACION_GEOGRAFICA : "",                           //post
      BARRIO               : "",                          //post
      NOMBRE1              : "",                         //post
      TELEFONO             : "",                        //post
      TELEFONO_NEG         : "",                       //post
      CELULAR_CL           : "",                      //post
      CELULAR_MOV          : "",                     //post
      A_NACIONALIDAD       : "",                    //post
      CEDULA               : "",                   //post
      ESTADO_CIVIL         : "",                  //post
      A_DEPARTAMENTOS      : "",                 //post
      A_MUNICIPIOS         : "",                //post
      DIRECCION_ACTUAL     : "",               //post
      NOMBRE_NEG           : "",              //post
      DIRECCION_NEG        : "",             //post
      SEXO                 : "",            //post
      PROFESION            : "",           //post
      //A_SUCURSAL         : "",          //post
      A_EMPRESA            : "",         //post
      A_SUCURSAL           : "",        //post
      ADICIONADO_POR       : "",       //post
      FECHA_V_CEDULA       : "",      //post
      startDate            : "",     //post
      TIME                 : "",   //post
      TIME_P               : "",  //post
      COD_SUCURSAL_U       : "", //post
      COD_EMPRESA_U        : "",//post

     
      COD_DEPT     : "",

      PROSPECTADOR:"",
      FECHA_PROSPECTACION:"",
      MONTO:"0",
      FECHA_PROCESO:"",
      COD_USUARIO  :"",
      Fecha_del_dia      : new Date(),//GET
      Fecha_conv         : "",
      FECHA_MOMENT_TZ    : "",
      //HORA_MOMENT_TZ     : "",

      ALIAS : "",
      Tipo_usuario : "",
      sucursal : "",
      COD_REGION : "",
      COD_NIVEL : "",
      NOMBRE_SUCURSAL : "",
      ID_ROL : "",

      fecha        : new Date(),//GET

      PERMISO_ROL: "1000",
      VALIDACION: "hidden",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.reestriccion_telef = this.reestriccion_telef.bind(this);
    this.reestriccion_claro = this.reestriccion_claro.bind(this);
    this.reestriccion_tigo = this.reestriccion_tigo.bind(this);
    this.reestriccion_telef_negov = this.reestriccion_telef_negov.bind(this);
    this.reestriccion_cedula = this.reestriccion_cedula.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });



    var concat_cedula=this.state.CEDULA;

    if (this.state.CEDULA !== "") {

      if(this.state.CEDULA.length === 3){
        
        concat_cedula=concat_cedula + "-";

        this.setState({
          CEDULA:concat_cedula
        });

      }else if(this.state.CEDULA.length === 10){
       
        concat_cedula=concat_cedula + "-";
        this.setState({
          CEDULA:concat_cedula
        });
      } 

      
    }
  }

  onSubmit(e) {
    var tiempo = "1000";
    if (this.state.CEDULA === "") {
      e.preventDefault();
      Swal.fire("Registro de cliente", "Por favor ingrese la cedula", "error");
    } else if (this.state.A_SUCURSAL === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "Por favor ingrese la sucursal",
        "error"
      );
    } else if (this.state.A_EMPRESA === "") {
      e.preventDefault();
      Swal.fire("Registro de cliente", "Por favor ingrese la empresa", "error");
    } else if (this.state.NOMBRE1 === "") {
      e.preventDefault();
      Swal.fire("Registro de cliente", "Por favor ingrese el nombre", "error");
    } else if (this.state.SEXO === "") {
      e.preventDefault();
      Swal.fire("Registro de cliente", "Por favor ingrese el sexo", "error");
    } else if (this.state.UBICACION_GEOGRAFICA === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "Por favor ingrese la ubicacion geografica",
        "error"
      );
    } else if (this.state.A_NACIONALIDAD === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "Por favor ingrese la nacionalidad",
        "error"
      );
    } else if (this.state.A_DEPARTAMENTOS === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "Por favor ingrese el departamento",
        "error"
      );
    } else if (this.state.A_MUNICIPIOS === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "Por favor ingrese el municipio",
        "error"
      );
    } else if (this.state.DIRECCION_ACTUAL === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "Por favor ingrese la direccion actual",
        "error"
      );
    } else if (this.state.BARRIO === "") {
      e.preventDefault();
      Swal.fire("Registro de cliente", "Por favor ingrese el barrio", "error");
    } else if (this.state.ESTADO_CIVIL === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "Por favor ingrese el estado civil",
        "error"
      );
    } else if (this.state.FECHA_V_CEDULA === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "Por favor ingrese la fecha de vencimiento de la cedula",
        "error"
      );
    }  else {
      e.preventDefault();
      Swal.fire({
        title: "Desea registrar el cliente",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Registrar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // e.preventDefault();
          TimerMixin.setTimeout(() => {
            const newCliente = {
              UBICACION_GEOGRAFICA: this.state.UBICACION_GEOGRAFICA,
              BARRIO: this.state.BARRIO,
              NOMBRE1: this.state.NOMBRE1,
              TELEFONO: this.state.TELEFONO,
              TELEFONO_NEG: this.state.TELEFONO_NEG,
              CELULAR_CL: this.state.CELULAR_CL,
              CELULAR_MOV: this.state.CELULAR_MOV,
              A_NACIONALIDAD: this.state.A_NACIONALIDAD,
              CEDULA: this.state.CEDULA,
              ESTADO_CIVIL: this.state.ESTADO_CIVIL,
              A_DEPARTAMENTOS: this.state.A_DEPARTAMENTOS,
              A_MUNICIPIOS: this.state.A_MUNICIPIOS,
              DIRECCION_ACTUAL: this.state.DIRECCION_ACTUAL,
              NOMBRE_NEG: this.state.NOMBRE_NEG,
              DIRECCION_NEG: this.state.DIRECCION_NEG,
              SEXO: this.state.SEXO,
              A_SUCURSAL: this.state.A_SUCURSAL,
              A_EMPRESA: this.state.A_EMPRESA,
              ADICIONADO_POR: this.state.ALIAS,
              TIME_P: this.state.TIME_P,
              PROFESION:this.state.PROFESION,
              FECHA_V_CEDULA:this.state.FECHA_V_CEDULA,
              PROSPECTADOR  :this.state.COD_USUARIO,
              FECHA_PROSPECTACION:this.state.FECHA_MOMENT_TZ,
              MONTO:this.state.MONTO,
              FECHA_PROCESO:this.state.FECHA_MOMENT_TZ,
              FECHA_MODIFICADO:this.state.FECHA_MOMENT_TZ,
            };

            this.registro(newCliente).then((res) => {});
          }, tiempo);
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    } // here else cerrare
  }

  componentDidMount() {
    this.Conv_fecha()
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        Tipo_usuario: res.data.Tipo_usuario,
        COD_REGION: res.data.COD_REGION,
        COD_NIVEL: res.data.COD_NIVEL,
        NOMBRE_SUCURSAL: res.data.COD_SUCURSAL,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
      });
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
      }, 300);
      let tiempo = '300'
      TimerMixin.setTimeout(() => {
      this.consultarEmpresa();
      this.consultarNacionalidad();
      this.consultarProfesion();
    }, tiempo);  
      
      // console.log(res);
    });
  }
  // handleChange = date => {

  //   this.setState({
  //     startDate: date
  //   });
  // };
  onChange2 = (fecha) => {
    this.setState({ FECHA_V_CEDULA: fecha });
};

busquedaRef = React.createRef();
datos(){
  console.log(this.busquedaRef.current.value)
  this.setState({ COD_DEPT: this.busquedaRef.current.value }); 
}
reestriccion_claro(e){

  if (this.state.CELULAR_CL !== "") {
    if(this.state.CELULAR_CL.length < 8){
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "El numero de celular claro debe tener 8 caracteres",
        "error"
      );
    }else if(this.state.CELULAR_CL.length > 8){
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "El numero de celular claro debe tener 8 caracteres",
        "error"
      );
    } 
  }
}
reestriccion_tigo(e){

  if (this.state.CELULAR_MOV !== "") {
    if(this.state.CELULAR_MOV.length < 8){
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "El numero de celular tigo debe tener 8 caracteres",
        "error"
      );
    }else if(this.state.CELULAR_MOV.length > 8){
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "El numero de celular tigo debe tener 8 caracteres",
        "error"
      );
    } 
  }
}
reestriccion_telef(e){

  if (this.state.TELEFONO !== "") {

    if(this.state.TELEFONO.length < 8){
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "El numero de telefono debe tener 8 caracteres",
        "error"
      );
    }else if(this.state.TELEFONO.length > 8){
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "El numero de telefono debe tener 8 caracteres",
        "error"
      );
    } 
  }
}
reestriccion_telef_negov(e){

  if (this.state.TELEFONO_NEG !== "") {

    if(this.state.TELEFONO_NEG.length < 8){
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "El numero de telefono debe tener 8 caracteres",
        "error"
      );
    }else if(this.state.TELEFONO_NEG.length > 8){
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "El numero de telefono debe tener 8 caracteres",
        "error"
      );
    } 
  }
}
reestriccion_cedula(e){

  if (this.state.CEDULA !== "") {

    if(this.state.CEDULA.length < 16){
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "El numero de cedula debe tener 16 caracteres",
        "error"
      );
    }else if(this.state.CEDULA.length > 16){
      e.preventDefault();
      Swal.fire(
        "Registro de cliente",
        "El numero de cedula debe tener 16 caracteres",
        "error"
      );
    } 
  }
}

Conv_fecha(){

  //**************************MOMENT TIME ZONE************************/
  var jun = moment(this.state.Fecha_del_dia);
  
  var fecha_time_conv=jun.tz('America/Managua').format('l');  // 5am PDT
 // var hora_time_conv=jun.tz('América/Managua').format('LT');  // 5am PDT
  
  //**************************MOMENT TIME ZONE************************/
  
  
  
  
    var hoy = new Date(this.state.Fecha_del_dia);
    var dd = hoy.getDate();
    var mm = hoy.getMonth() + 1;
    var yyyy = hoy.getFullYear();
  
    hoy = yyyy + '-' + mm + '-' + dd;
  
    console.log(hoy)
  
    this.setState({
     Fecha_conv : hoy,
     FECHA_MOMENT_TZ:fecha_time_conv,
     //HORA_MOMENT_TZ:hora_time_conv,
      });
  }
  render() {
    return (
      <div className="container" style={{ visibility: this.state.VALIDACION }}>  <SisprestamosNavbar />
        <div className="row">
          <div className="col-md-12 mt-3 mx-auto">
            <form noValidate onSubmit={this.onSubmit}>
              <h1 className="h3 mb-4 font-weight-normal  text-center">
                Datos del Cliente
              </h1>

              <div className="form-row">
                <div className="form-group text-center col-md-4">
                  <label htmlFor="SucursalA">Sucursal</label>
                  <select
                    name="A_SUCURSAL"
                    className="form-control"
                    value={this.state.A_SUCURSAL}
                    onChange={this.onChange}
                    placeholder="Campo obligatorio"
                    onClick={this.consultarSucursal()}
                  >
                    <option></option>
                    {this.state.SucursalA.map((item) => (
                      <option
                        key={item.NOMBRE_SUCURSAL}
                        value={item.COD_SUCURSAL}
                      >
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}

                  </select>
                </div>
                <div className="form-group text-center col-md-4">
                  <label htmlFor="EmpresaA">Empresa</label>
                  <select
                    name="A_EMPRESA"
                    className="form-control"
                    value={this.state.A_EMPRESA}
                    onChange={this.onChange}
                    // onClick={this.consultarEmpresa}
                  >
                    <option></option>
                    {this.state.EmpresaA.map((item) => (
                      <option
                        key={item.NOMBRE_EMPRESA}
                        value={item.COD_EMPRESA}
                      >
                        {item.NOMBRE_EMPRESA}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group text-center col-md-4">
                  <label htmlFor="Sexo">Sexo</label>
                  <select
                    name="SEXO"
                    className="form-control"
                    value={this.state.SEXO}
                    onChange={this.onChange}
                  >
                    <option></option>
                    <option>MASCULINO</option>
                    <option>FEMENINO</option>
                  </select>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group text-center col-md-4">
                  <label htmlFor="NOMBRE1">Nombre</label>
                  <input
                style={{ textTransform: 'uppercase'}}
                    type="NOMBRE1"
                    className="form-control"
                    name="NOMBRE1"
                    placeholder="                       Campo obligatorio"
                    value={this.state.NOMBRE1}
                    onChange={this.onChange}
                  />
                </div>
                <div className="form-group text-center col-md-4">
                  <label htmlFor="CEDULA">Cedula</label>
                  <input
                    style={{ textTransform: 'uppercase'}}
                    type="CEDULA"
                    className="form-control"
                    name="CEDULA"
                    placeholder="                       Campo obligatorio"
                    value={this.state.CEDULA}
                    onChange={this.onChange}
                    onBlur={this.reestriccion_cedula}
                  />
                </div>

                {/* <DatePicker
        selected={this.state.startDate}
       onChange=  {this.handleChange}

        dateFormat="dd-MM-yyyy"
      /> */}

<div className="form-group text-center col-md-4 mx-auto" >
                     <label htmlFor="Fecha">Fecha vencimiento cedula  </label>
                
                        <DatePicker
                          selected={this.state.FECHA_V_CEDULA}
                          onChange={this.onChange2}
                          locale="es"
                          className="form-control"
                          dateFormat="dd '/' M '/' yyyy"
                        />
                      
                    
                      </div> 
              

             
              </div>


              <div className="form-row">



              <div className="form-group text-center col-md-4">
                  <label htmlFor="TELEFONO">Telefono</label>
                  <input
                    type="TELEFONO"
                    className="form-control"
                    name="TELEFONO"
                    planceholder="Ingrese el numero de telefono"
                    value={this.state.TELEFONO}
                    onChange={this.onChange}
                    onBlur={this.reestriccion_telef}
                  />
                </div>


                <div className="form-group text-center col-md-4">
                  <label htmlFor="CELULAR_CL">Celular Claro</label>
                  <input
                    type="CELULAR_CL"
                    className="form-control"
                    name="CELULAR_CL"
                    planceholder="Ingrese el Numero"
                    value={this.state.CELULAR_CL}
                    onChange={this.onChange}
                    onBlur={this.reestriccion_claro}
                  />
                </div>

                <div className="form-group text-center col-md-4">
                  <label htmlFor="CELULAR_MOV">Celular Movistar</label>
                  <input
                    type="CELULAR_MOV"
                    className="form-control"
                    name="CELULAR_MOV"
                    planceholder="Ingrese el numero"
                    value={this.state.CELULAR_MOV}
                    onChange={this.onChange}
                    onBlur={this.reestriccion_tigo}
                  />
                </div>

              </div>

              <div className="form-row">

              <div className="form-group text-center col-md-4">
                  <label htmlFor="ESTADO_CIVIL">Estado Civil</label>
                  <select
                    name="ESTADO_CIVIL"
                    className="form-control"
                    value={this.state.ESTADO_CIVIL}
                    onChange={this.onChange}
                  >
                    <option></option>
                    <option>Soltero</option>
                    <option>Casado</option>
                    <option>Viudo</option>
                    <option>Viuda</option>
                    <option>Union libre</option>
                  </select>
                </div>
                
              <div className="form-group text-center col-md-4">
                  <label htmlFor="DIRECCION_ACTUAL">Direccion Actual</label>
                  <input
                    type="DIRECCION_ACTUAL"
                    className="form-control"
                    name="DIRECCION_ACTUAL"
                    placeholder="                       Campo obligatorio"
                    value={this.state.DIRECCION_ACTUAL}
                    onChange={this.onChange}
                  />
                </div>

                <div className="form-group text-center col-md-4">
                  <label htmlFor="UBICACION_GEOGRAFICA">
                    Ubicacion geografica
                  </label>
                  <select
                    name="UBICACION_GEOGRAFICA"
                    className="form-control"
                    value={this.state.UBICACION_GEOGRAFICA}
                    onChange={this.onChange}
                  >
                    <option></option>
                    <option>Urbano</option>
                    <option>Rural</option>
                  </select>
                </div>
                </div>

              <div className="form-row">

              <div className="form-group text-center col-md-4">
                <label htmlFor="NacionalidadA">Nacionalidad</label>
                  <select
                    name="A_NACIONALIDAD"
                    className="form-control"
                    value={this.state.A_NACIONALIDAD}
                    onChange={this.onChange}
                    //onClick={this.consultarNacionalidad()}
                  >
                    <option></option>
                    {this.state.NacionalidadA.map((item) => (
                      <option
                        value ={item.COD_NACIONALIDAD}
                        key   ={item.NOMBRE_NACIONALIDAD}
                      >
                        {item.NOMBRE_NACIONALIDAD}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group text-center col-md-4">
                  <label htmlFor="DepartamentoA">Departamento</label>
                  <select
                    name="A_DEPARTAMENTOS"
                    className="form-control"
                    value={this.state.A_DEPARTAMENTOS}
                    onChange={this.onChange}
                     onClick={this.consultarDepartamento}
                  >
                    <option></option>
                    {this.state.DepartamentoA.map((item) => (
                      <option
                      value = {item.COD_DEPARTAMENTOS}
                        key = {item.NOMBRE_DEPARTAMENTOS}
                      >
                        {item.NOMBRE_DEPARTAMENTOS}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group text-center col-md-4">
                  <label htmlFor="MunicipioA">Municipio</label>
                  <select
                    name="A_MUNICIPIOS"
                    className="form-control"
                    value    = {this.state.A_MUNICIPIOS}
                    onChange = {this.onChange}
                    onClick  = {this.consultarMunicipio}
                  >
                    <option>   </option>
                    {this.state.MunicipioA.map((item) => (
                      <option
                      value = {item.COD_MUNICIPIOS}
                        key = {item.NOMBRE_MUNICIPIOS}
                      >
                        {item.NOMBRE_MUNICIPIOS}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-row">
              <div className="form-group text-center col-md-4">
                <label htmlFor="BarriosA">Barrio</label>
                  <select
                    name="BARRIO"
                    className="form-control"
                    value={this.state.BARRIO}
                    onChange={this.onChange}
                    onClick={this.consultarBarrio}
                  >
                    <option></option>
                    {this.state.BarriosA.map((item) => (
                      <option
                       key = {item.COD_BARRIO} 
                       value   = {item.BARRIO}>

                        {item.BARRIO}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group text-center col-md-4">
                  <label htmlFor="NOMBRE_NEG">Nombre del Negocio</label>
                  <input
                    type="NOMBRE_NEG"
                    className="form-control"
                    name="NOMBRE_NEG"
                    planceholder="Enter your Password"
                    value={this.state.NOMBRE_NEG}
                    onChange={this.onChange}
                  />
                </div>
                
                <div className="form-group text-center col-md-4">
                  <label htmlFor="TELEFONO_NEG">Telefono del Negocio</label>
                  <input
                    type="TELEFONO_NEG"
                    className="form-control"
                    name="TELEFONO_NEG"
                    planceholder="Enter your Password"
                    value={this.state.TELEFONO_NEG}
                    onChange={this.onChange}
                    onBlur={this.reestriccion_telef_negov}
                  />
                </div>
               
                
              </div>

              <div className="form-row">
               
                <div className="form-group text-center col-md-4">
                  <label htmlFor="DIRECCION_NEG">Direccion del Negocio</label>
                  <input
                    type="DIRECCION_NEG"
                    className="form-control"
                    name="DIRECCION_NEG"
                    planceholder="Enter your Password"
                    value={this.state.DIRECCION_NEG}
                    onChange={this.onChange}
                  />
                </div>

                <div className="form-group text-center col-md-4">
                  <label htmlFor="ProfesionA">Profesion</label>
                  <select
                    name="PROFESION"
                    className="form-control"
                    value={this.state.PROFESION}
                    onChange={this.onChange}
                    // onClick={this.consultarEmpresa}
                  >
                    <option></option>
                    {this.state.ProfesionA.map((item) => (
                      <option
                        key={item.PROFESION}
                        value={item.COD_PROFESION}
                      >
                        {item.PROFESION}
                      </option>
                    ))}
                  </select>
                </div>
               
                <div className="form-group text-center col-md-4">
                  <label htmlFor="MONTO">Monto</label>
                  <input
                    type="MONTO"
                    className="form-control text-right"
                    name="MONTO"
                    planceholder="Enter your Password"
                    value={this.state.MONTO}
                    onChange={this.onChange}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-lg btn-primary btn-block col-md-4"
                >
                  Registrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default Datos_Cliente;

import React, { Component } from "react";
import axios from "axios";
import { Map, Marker, TileLayer, Popup } from "react-leaflet";
// import Swal from "sweetalert2";
// import { Icon } from "leaflet";'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import TimerMixin from "react-timer-mixin";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

registerLocale("es", es);

class google_coordinates extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };
  
  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  ubicaciones = (registro) => {
    return axios
      .post(`${api}/maps/leaflet/${this.state.Tipo_usuario}/${this.state.SUCURSAL_C}/${this.state.SUCURSAL_C}`, {
        // CAJERO: registro.CAJERO,
        fecha_1: registro.fecha_1,
        fecha_2: registro.fecha_2,
      })
      .then((response) => {
        // console.log(
        //   "Consulta de los creditos activos del cliente",
        //   response.data
        // );

        this.setState({
          Consulta: response.data.doubles,
        });
        // window.location.reload();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log("el error es ", err);
        window.alert("Verifique los datos ingresados", err);
        // window.location.reload();
      });
  };

  constructor() {
    super();
    this.state = {
      CAJERO: "",
      fecha_1: "",
      fecha_2: "",
      Consulta: [ {NO_RECIBO:2344,LATITUD:12.6238165, LONGITUD:-87.1383193}],
      Consulta_2: [],
      fecha              : new Date(),//GET

      COD_ROL_SUC: "",

      SucursalA    : [], //GET ARRAY
      A_SUCURSAL   : "", //POST 
      SUCURSAL_C   : "",//POST 

      Tipo_usuario : "", //RESTRICCION
      sucursal     : "",//RESTRICCION

      ID_ROL: "",
      PERMISO_ROL: "1014",
      VALIDACION: "hidden",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {

      e.preventDefault();

      const registro = {
        // CAJERO: this.state.CAJERO,
        fecha_1: this.state.fecha_1,
        fecha_2: this.state.fecha_2,
      };

      this.ubicaciones(registro).then((res) => {
        // this.props.history.push(`/login`)
        // window.alert("busqueda realizada exitosamente");
      });
 
  
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        alias: res.data.Alias,
        IP: res.data.IP,
        Tipo_usuario   : res.data.Tipo_usuario,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
      });
      
      TimerMixin.setTimeout(() => {
      this.CONSULTA_PERMISO(); 
    }, 300); 
    });
  }

  onChange2 = (fecha) => {
    this.setState({ fecha_1: fecha });
};

onChange3 = (fecha1) => {
    this.setState({ fecha_2: fecha1 });
};

  render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
        <div className="container mt-5">
          <div className="row">
           
            <div className="shadow-lg p-3 mb-5 bg-white rounded  col-md-8 mx-auto" style={{width: "200px;"}}>
          <h1 className="h3 mb-4 font-weight-normal  text-center">
                CONSULTA
              </h1>

              {/* <div className="form-group text-center">
                <label htmlFor="CAJERO">Cajero</label>
                <input
                  type="CAJERO"
                  className="form-control text-center"
                  name="CAJERO"
                  planceholder="Ingrese el nombre del cliente"
                  value={this.state.CAJERO}
                  onChange={this.onChange}
                />
              </div> */}

<div className="form-group text-center">
               
              </div>

              <div className="form-row">

              <div className="form-group text-center col-md-4">
                  <label htmlFor="SucursalA">Sucursal</label>
                  <select
                    name="SUCURSAL_C"
                    className="form-control"
                    value={this.state.SUCURSAL_C}
                    onChange={this.onChange}
                    placeholder="Campo obligatorio"
                    onClick={this.consultarSucursal()}
                   
                  >
                    <option>Todas</option>
                    {this.state.SucursalA.map((item) => (
                      <option
                        key={item.NOMBRE_SUCURSAL}
                        value={item.COD_SUCURSAL}
                      >
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}

                  </select>
                </div>

                  <div className="form-group text-center col-md-4 mx-auto" style={{width: "200px;"}}>
                    <label htmlFor="Fecha">Fecha : </label>
                   
                        <DatePicker
                          selected={this.state.fecha_1}
                          onChange={this.onChange2}
                          locale="es"
                          className="form-control"
                          dateFormat="dd '/' M '/' yyyy"
                        />
                         </div> 
                     <div className="form-group text-center col-md-4 mx-auto" style={{width: "200px;"}}>
                     <label htmlFor="Fecha">Fecha : </label>
                
                        <DatePicker
                          selected={this.state.fecha_2}
                          onChange={this.onChange3}
                          locale="es"
                          className="form-control"
                          dateFormat="dd '/' M '/' yyyy"
                        />
                      
                    
                      </div> 
                  </div> 

              <div className="d-flex justify-content-center">
              <button
                onClick={this.onSubmit}
                className="btn btn-lg btn-primary btn-block col-md-4"
              >
                Buscar
              </button>

              </div>

              <div    style={{ visibility: "hidden" }}> 
           
              <div align="center">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        onClick={this.jsPdfGenerator}
                        className="btn btn-danger"
                      >
                        Generar pdf
                      </button>

                      </div>
                      
                      </div>
                      <div align="center">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        onClick={this.jsPdfGenerator}
                        className="btn btn-danger"
                      >
                        Generar pdf
                      </button>

                      </div>
                      
                      </div>
                      <div align="center">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        onClick={this.jsPdfGenerator}
                        className="btn btn-danger"
                      >
                        Generar pdf
                      </button>

                      </div>
                      
                      </div>
                      </div>

              {/* <div className="form-group text-center">
                <label htmlFor="fecha_1">Fecha inicio</label>
                <input
                  type="fecha_1"
                  className="form-control text-center"
                  name="fecha_1"
                  planceholder="Ingrese el nombre del cliente"
                  value={this.state.fecha_1}
                  onChange={this.onChange}
                />
              </div>

              <div className="form-group text-center">
                <label htmlFor="fecha_2">Fecha fin</label>
                <input
                  type="fecha_2"
                  className="form-control text-center"
                  name="fecha_2"
                  planceholder="Ingrese el nombre del cliente"
                  value={this.state.fecha_2}
                  onChange={this.onChange}
                />
              </div> */}

             
            
            </div>
          </div>
        </div>
{/* CORDENADAS DE MATAGALPA */}
        {/* <Map center={[12.921159, -85.9164926]} zoom={12}> */}
        {/* CORDENADAS DE CHINANDEGA */}
        <Map center={[12.8647101, -87.2627031]} zoom={7}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          {this.state.Consulta.map((crime) => (
            <Marker
              key={crime.NO_RECIBO}
              position={[crime.LATITUD, crime.LONGITUD]}
            >
              <Popup>
                <div>
                <h2>Cajero:{crime.CAJERO}</h2>
                  <h2>No.Recibo:{crime.NO_RECIBO}</h2>
                  <h2>Fecha:{crime.FECHA}</h2>
                  <h2>Hora:{crime.HORA}</h2>
                </div>
              </Popup>
            </Marker>
          ))}

          
        </Map>
      </form>
    );
  }
}
export default google_coordinates;

import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import axios from "axios";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import * as jsPDF from "jspdf";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from "../../../api/axiosConfigRoute";
import SisprestamosNavbar from "../../SisprestamosNavbar";

registerLocale("es", es);

class Caja_Gasto extends Component {
  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarEmpresa = () => {
    const url = `${api}/empresa/get`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ EMPRESA_A: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  consultartipo_gasto = () => {
    const url = `${api}/gastos/tipo/${this.state.COD_EMPRESA_B}/${this.state.COD_SUCURSAL_B}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ GASTOS: resultado }));
  };

  constructor() {
    super();
    this.state = {
      mensaje:"",
      alerta:"",

      ALIAS: "",
      Tipo_usuario: "",
      COD_REGION: "",
      COD_NIVEL: "",
      ID_ROL: "",
      COD_EMPRESA: "",
      COD_ROL_SUC: "",

      COD_EMPRESA_B: "",
      COD_SUCURSAL_B: "",

      EMPRESA_A: [],
      SucursalA: [],
      GASTOS: [],

      GASTOS_REC: "",
      OBSERVACION_REC: "",
      TOTAL_CORDOBAS: "0",
      TOTAL_DOLARES: "0",

      fecha_1: "",
      PAGO_A: "",

      LINEA: 0,

      columnDefs: [
        {
          headerName: "DESCRIPCION",
          field: "DESCRIPCION",
          sortable: true,
          filter: true,
          editable: false,
          width: 430,
        },
        {
          headerName: "MONTO",
          field: "MONTO",
          sortable: true,
          filter: true,
          editable: true,
          width: 130,
        },
        {
          headerName: "MONEDA",
          field: "NOMBRE_MONEDA",
          width: 200,
          sortable: true,
          filter: true,
          editable: true,
          type: "rightAligned",
          cellEditor: "agSelectCellEditor",
          cellEditorParams: {
            values: ["CORDOBAS", "DOLARES"],
          },
        },
        {
          headerName: "SUBTOTAL",
          field: "SUB_TOTAL",
          sortable: true,
          filter: true,
          editable: false,
        },
      ],
      rowData: [],
      BOTON_FORMALIZACION: "true",

      //+++++++++RECIBO++++++++++
      NoRecibo: "",
      Fecha: "",
      Hora: "",
      PagoA: "",
      Concepto: "",
      TotalCordobas: "",
      TotalDolares: "",
      DetalleGastos: [],
      NombreEmpresa:"",
      NombreSucursal:"",
     //+++++++++Comprobante++++++++++

     DESCRIPCION:"",
     MONTO:"",
     DatosComprobante:[],
     BALANCE_DEBE:"",
     BALANCE_HABER:"",
     MONEDA:"1",
     FECHA_REGISTRO:"",
     FECHA_DEL_DIA:"",
     TCambioOficial:"",
     PERIODO_BANC:"",
     VerifComprob:"",
     COD_EGRESO:"",
      /////////////PERMISOS DEL MODULO/////
      PERMISO_ROL: "1039",
      VALIDACION: "hidden",
    };
    this.onChange = this.onChange.bind(this);
    this.BUSQUEDA = this.BUSQUEDA.bind(this);
    this.ARRAY_SERVICIOS = this.ARRAY_SERVICIOS.bind(this);
    this.onChange_data_grid = this.onChange_data_grid.bind(this);
    this.getSelectedRowData = this.getSelectedRowData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  BUSQUEDA(e) {
    this.setState({ [e.target.name]: e.target.value });

    this.setState({
      GASTOS: [],
    });

    TimerMixin.setTimeout(() => {
      this.consultartipo_gasto();
    }, 300);

    // var tiempo = '300'
    //   TimerMixin.setTimeout(() => {
    //     this.consultarCliente();
    //   }, tiempo);
  }

  ACTIVAR_BOTON_FORMALIZACION(e) {
    // e.preventDefault();
    this.setState({
      BOTON_FORMALIZACION: "false",
    });
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        Tipo_usuario: res.data.Tipo_usuario,
        COD_REGION: res.data.COD_REGION,
        COD_NIVEL: res.data.COD_NIVEL,
        ID_ROL: res.data.ID_ROL,
        COD_EMPRESA: res.data.COD_EMPRESA_B,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
        COD_EMPRESA_B: res.data.COD_EMPRESA_B,
      });
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
        this.consultarEmpresa();
        this.consultarSucursal();
      }, 300);
    });
  }

  ARRAY_SERVICIOS(e) {
    if (this.state.GASTOS_REC === "") {
      e.preventDefault();
      Swal.fire("Caja De Gastos", "Por Favor Seleccione Un Gasto", "error");
    } else if (this.state.COD_SUCURSAL_B === "") {
      e.preventDefault();
      Swal.fire("Caja De Gastos", "Por Favor Seleccione Una Sucursal", "error");
    } else {
      e.preventDefault();

      let PARAMETRO = false;
      if (this.state.GASTOS.length > 0) {
        for (let i = 0; i < this.state.GASTOS.length; i++) {
          if (
            this.state.GASTOS_REC === this.state.GASTOS[i].DESCRIPCION_GASTO
          ) {
            PARAMETRO = true;
          }
        }
      }

      if (PARAMETRO === true) {
        let tiempo = "300";
        TimerMixin.setTimeout(() => {
          let linea = parseInt(this.state.LINEA) + 1;
          let c;
          let r = [
            {
              COD_SUCURSAL: this.state.COD_SUCURSAL_B,
              LINEA: linea,
              DESCRIPCION: this.state.GASTOS_REC,
              NOMBRE_MONEDA: "CORDOBAS",
              MONTO: 0,
              SUB_TOTAL: 0,
            },
          ];
          c = [].concat(this.state.rowData, r);

          this.setState({
            rowData: c,
            LINEA: linea,
            GASTOS_REC: "",
          });
          // this.onChange_data_grid();
        }, tiempo);
      } else {
        Swal.fire(
          "Caja De Gastos",
          "Por Favor Seleccione Un Gasto de la Lista",
          "error"
        );
        this.setState({
          GASTOS_REC: "",
        });
      }
    }
  }

  onChange_data_grid(e) {
    let tiempo = "300";
    TimerMixin.setTimeout(() => {
      let DATOS_ARRAY = this.state.rowData;

      let MONTO_TOTAL_CORDOBAS = 0;
      let MONTO_TOTAL_DOLARES = 0;

      for (let i = 0; i < DATOS_ARRAY.length; i++) {
        if (DATOS_ARRAY[i].NOMBRE_MONEDA === "CORDOBAS") {
          MONTO_TOTAL_CORDOBAS = (
            parseFloat(DATOS_ARRAY[i].MONTO) + parseFloat(MONTO_TOTAL_CORDOBAS)
          ).toFixed(4);
          DATOS_ARRAY[i].SUB_TOTAL = parseFloat(DATOS_ARRAY[i].MONTO).toFixed(
            4
          );
        } else if (DATOS_ARRAY[i].NOMBRE_MONEDA === "DOLARES") {
          MONTO_TOTAL_DOLARES = (
            parseFloat(DATOS_ARRAY[i].MONTO) + parseFloat(MONTO_TOTAL_DOLARES)
          ).toFixed(4);
          DATOS_ARRAY[i].SUB_TOTAL = parseFloat(DATOS_ARRAY[i].MONTO).toFixed(
            4
          );
        }
      }
      this.setState({ rowData: [] });

      TimerMixin.setTimeout(() => {
        this.setState({
          rowData: DATOS_ARRAY,
          TOTAL_CORDOBAS: MONTO_TOTAL_CORDOBAS,
          TOTAL_DOLARES: MONTO_TOTAL_DOLARES,
          GASTOS_REC: "",
        });
      }, 100);
    }, tiempo);
  }

  getSelectedRowData = (e) => {
    e.preventDefault();
    const selectedData = this.gridApi.getSelectedRows();

    var datos = [];
    datos = this.state.rowData;

    if (selectedData.length > 0) {
      for (var i = 0; i < datos.length; i++) {
        if (datos[i].LINEA === selectedData[0].LINEA) {
          datos.splice(i, 1);
        }
      }

      this.setState({
        rowData: datos,
      });

      const res = this.gridApi.applyTransaction({ remove: selectedData });
      console.log(res);

      var tiempo = "500";
      TimerMixin.setTimeout(() => {
        this.onChange_data_grid();
      }, tiempo);
    } else {
      Swal.fire("Caja De Gastos", "Por Favor Seleccione Un Gasto", "error");
    }
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  registro = (newCliente) => {
    return axios
      .post(`${api}/gastos/caja`, {
        COD_EMPRESA: newCliente.COD_EMPRESA,
        COD_SUCURSAL: newCliente.COD_SUCURSAL,
        OBSERVACION: newCliente.OBSERVACION,
        TOTAL_CORDOBAS: newCliente.TOTAL_CORDOBAS,
        TOTAL_DOLARES: newCliente.TOTAL_DOLARES,
        DETALLE_EGRESOS: newCliente.DETALLE_EGRESOS,
        ADICIONADO_POR: newCliente.ADICIONADO_POR,
        fecha_1: newCliente.fecha_1,
        PAGO_A: newCliente.PAGO_A,
      })
      .then((response) => {
        let tiempo = "2000";
        TimerMixin.setTimeout(() => {
          if (response.data.alerta === "error") {
            this.setState({
              BOTON_FORMALIZACION: "true",
            });
          } else {
            this.setState({
              mensaje: response.data.mensaje,
              alerta:  response.data.alerta,
              NoRecibo: response.data.NoRecibo,
              Fecha: response.data.Fecha,
              Hora: response.data.Hora,
              PagoA: response.data.PagoA,
              Concepto: response.data.Concepto,
              TotalCordobas: response.data.TotalCordobas,
              TotalDolares: response.data.TotalDolares,
              DetalleGastos: response.data.DetalleGastos,
              DESCRIPCION: response.data.DESCRIPCION,
              MONTO: response.data.MONTO,
              DatosComprobante: response.data.DatosComprobante,
              BALANCE_DEBE: response.data.BALANCE_DEBE,
              BALANCE_HABER: response.data.BALANCE_HABER,
              FECHA_REGISTRO: response.data.FECHA_REGISTRO,
              FECHA_DEL_DIA: response.data.FECHA_DEL_DIA,
              TCambioOficial: response.data.TCambioOficial,
              PERIODO_BANC: response.data.PERIODO_BANC,
              NombreEmpresa:response.data.NombreEmpresa,
              NombreSucursal:response.data.NombreSucursal,
              VerifComprob:response.data.VerifComprob,
              COD_EGRESO:response.data.COD_EGRESO,
            });
          
            TimerMixin.setTimeout(() => { 
              if(response.data.VerifComprob === true){
                 this.Registrar_comprobante();
              }else{
                this.ReciboGasto();
              }
             
             
             }, 2000);
          }
        }, tiempo);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Caja De Gastos", "Por Favor Verifique Los Datos", "error");
      });
  };

  onSubmit(e) {
    let tiempo = "1000";

    if (this.state.COD_EMPRESA_B === "") {
      e.preventDefault();
      Swal.fire("Caja De Gastos", "Por Favor Seleccione Una Empresa", "error");
    } else if (this.state.COD_SUCURSAL_B === "") {
      e.preventDefault();
      Swal.fire("Caja De Gastos", "Por Favor Seleccione Una Sucursal", "error");
    } else if (this.state.OBSERVACION_REC === "") {
      e.preventDefault();
      Swal.fire("Caja De Gastos", "Por Favor Redacte Un Concepto", "error");
    } else if (this.state.rowData.length === 0) {
      e.preventDefault();
      Swal.fire(
        "Caja De Gastos",
        "Por Favor Agregue El Detalle De Gatos",
        "error"
      );
    } else if (this.state.fecha_1 === "") {
      e.preventDefault();
      Swal.fire("Caja De Gastos", "Por Favor Seleccione una Fecha", "error");
    } else if (this.state.PAGO_A === "") {
      e.preventDefault();
      Swal.fire(
        "Caja De Gastos",
        "Por Favor Ingrese la Descripcion de Pago",
        "error"
      );
    } else {
      e.preventDefault();
      Swal.fire({
        title: "Desea Registrar Los Datos",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.ACTIVAR_BOTON_FORMALIZACION();
          // e.preventDefault();
          TimerMixin.setTimeout(() => {
            let timerInterval;
            Swal.fire({
              title: "Caja De Gastos",
              html: "Los Datos Se Estan Registrando",
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
              }
            });
            const newCliente = {
              COD_EMPRESA: this.state.COD_EMPRESA_B,
              COD_SUCURSAL: this.state.COD_SUCURSAL_B,
              OBSERVACION: this.state.OBSERVACION_REC,
              TOTAL_CORDOBAS: this.state.TOTAL_CORDOBAS,
              TOTAL_DOLARES: this.state.TOTAL_DOLARES,
              DETALLE_EGRESOS: this.state.rowData,
              ADICIONADO_POR: this.state.ALIAS,
              fecha_1: this.state.fecha_1,
              PAGO_A: this.state.PAGO_A,
            };

            this.registro(newCliente).then((res) => {});
          }, tiempo);
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    } // here else cerrare
  }

  onChange2 = (fecha) => {
    this.setState({ fecha_1: fecha });
  };

  ReciboGasto(e) {
    let NombreEmpresa = String(this.state.NombreEmpresa);
    let NombreSucursal = String(this.state.NombreSucursal);
    let NoRecibo = String(this.state.NoRecibo);
    let Fecha = String(this.state.Fecha);
    let Hora = String(this.state.Hora);
    let PagoA = String(this.state.PagoA);
    let Concepto = String(this.state.Concepto);
    let TotalCordobas = String(this.state.TotalCordobas);
    let TotalDolares = String(this.state.TotalDolares);

    let ArrayS = [];
    ArrayS=this.state.DetalleGastos;

    let AltoPagina=parseInt(400);

    
      for (let i = 0; i < ArrayS.length; i++) {
        if (i > 4){
        AltoPagina=parseInt(AltoPagina) + 20
        }
      }

    let doc = new jsPDF("p", "mm", [AltoPagina, 250 ]);
    doc.setFont("courier");
    doc.setFontType("normal");
    // doc.setFontType("bold");
    doc.setFontSize(9);

    doc.text(25, 5, "RECIBO DE GASTOS");
    doc.text(20, 10, NombreEmpresa + " / " + NombreSucursal );
    doc.setFontSize(8);
    doc.text(3, 20, "NO.RECIBO :");
    doc.text(23, 20, NoRecibo);
    doc.text(3, 25, "FECHA     :");
    doc.text(23, 25, Fecha + " /" + Hora);
    doc.text(3, 30, "PAGO A    :");
    doc.text(23, 30, PagoA);
    doc.text(3, 35, "CONCEPTO  :");
    // doc.text(23, 25, Concepto);

    let DATA = [
      {
        Concepto: Concepto,
      },
    ];
    doc.autoTable({
      columns: [{ dataKey: "Concepto" }],
      body: DATA,
      startY: 31,
      // showHead: "firstPage",
      bodyStyles: { halign: "left" },
      styles: { fontSize: 7.5 },
      margin: { left: 21, top: 31 },
      theme: "plain",
      tableWidth: "auto",
    });



  
    doc.autoTable({
      columns: [
        { dataKey: "DESCRIPCION", header: "DESCRIPCION" },
        { dataKey: "MONTO", header: "MONTO" },
        { dataKey: "SUB_TOTAL", header: "SUB TOTAL" },
      ],
      body: ArrayS,
      foot: [[TotalCordobas],
      [TotalDolares]],
      
      showHead: "firstPage",
      headStyles: { halign: "center" },
      bodyStyles: { halign: "center" },
      styles: {cellPadding: 0.9, fontSize: 7 },
      footStyles: {cellPadding: 1, halign: "left" ,fontSize: 8 },
      margin: { left: 0, top: 32 },
      theme: "plain",
      tableWidth: "auto",
    });

  

    doc.save("RECIBO DE GASTO.pdf");

    Swal.fire(
      "Caja De Gastos",
      this.state.mensaje,
      this.state.alerta
    );
    this.setState({ 
      mensaje:"",
      alerta:"",
      GASTOS_REC: "",
      OBSERVACION_REC: "",
      TOTAL_CORDOBAS: "0",
      TOTAL_DOLARES: "0",

      fecha_1: "",
      PAGO_A: "",
      LINEA: 0,
      rowData: [],
      BOTON_FORMALIZACION: "true",
       //+++++++++RECIBO++++++++++
       NoRecibo: "",
       Fecha: "",
       Hora: "",
       PagoA: "",
       Concepto: "",
       TotalCordobas: "",
       TotalDolares: "",
       DetalleGastos: [],
       NombreEmpresa:"",
       NombreSucursal:"",
      //+++++++++Comprobante++++++++++
 
      DESCRIPCION:"",
      MONTO:"",
      DatosComprobante:[],
      BALANCE_DEBE:"",
      BALANCE_HABER:"",
      MONEDA:"1",
      FECHA_REGISTRO:"",
      FECHA_DEL_DIA:"",
      TCambioOficial:"",
      PERIODO_BANC:"",
      VerifComprob:"",
      COD_EGRESO:"",
     });
  }

  SaveData = (DatosComprobante) => {
    return axios
      .post(`${api}/banco/registro_comprobante_contable`, {
        COD_EMPRESA: DatosComprobante.COD_EMPRESA,
        COD_SUCURSAL: DatosComprobante.COD_SUCURSAL,
        COD_MONEDA: DatosComprobante.COD_MONEDA,
        TIP_DOC: DatosComprobante.TIP_DOC,
        FECHA_REGISTRO: DatosComprobante.FECHA_REGISTRO,
        MONTO: DatosComprobante.MONTO,
        CONCEPTO: DatosComprobante.CONCEPTO,
        ADICIONADO_POR: DatosComprobante.ADICIONADO_POR,
        FECHA_MODIFICADO: DatosComprobante.FECHA_MODIFICADO,
        VALOR_T_CAMBIO: DatosComprobante.VALOR_T_CAMBIO,
        DATOS_DATA_GRID: DatosComprobante.DATOS_DATA_GRID,
        PERIODO_BANC: DatosComprobante.PERIODO_BANC,
        COD_EGRESO:DatosComprobante.COD_EGRESO,
      })
      .then((response) => {
        this.ReciboGasto();
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Caja De Gastos", "Error al Registrar el Comprobante", "error");
       });
  };

  Registrar_comprobante(e) {
    // e.preventDefault();
            const DatosComprobante = {
              COD_EMPRESA: this.state.COD_EMPRESA_B,
              COD_SUCURSAL: this.state.COD_SUCURSAL_B,
              COD_MONEDA: this.state.MONEDA,
              TIP_DOC: "COMPROBANTE DE GASTOS",
              FECHA_REGISTRO: this.state.FECHA_REGISTRO,
              MONTO: this.state.MONTO,
              CONCEPTO: this.state.DESCRIPCION,
              ADICIONADO_POR: this.state.ALIAS,
              FECHA_MODIFICADO: this.state.FECHA_DEL_DIA,
              VALOR_T_CAMBIO: this.state.TCambioOficial,
              DATOS_DATA_GRID: this.state.DatosComprobante,
              PERIODO_BANC: this.state.PERIODO_BANC,
              COD_EGRESO: this.state.COD_EGRESO,
            };

            this.SaveData(DatosComprobante).then((res) => {});
  }


  render() {
    return (
      <div
        className="container mt-5"
        style={{ visibility: this.state.VALIDACION }}
      >
        {" "}
        <SisprestamosNavbar />
        <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
          <h3 className=" p-1">CAJA DE GASTOS</h3>

          <div className="shadow-lg  mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div style={{ textAlign: "left" }} htmlFor="COD_EMPRESA_B">
                    EMPRESA
                  </div>
                  <select
                    name="COD_EMPRESA_B"
                    className="form-control"
                    value={this.state.COD_EMPRESA_B}
                    onChange={this.BUSQUEDA}
                    disabled={
                      this.state.BOTON_FORMALIZACION === "false" ? true : false
                    }
                  >
                    {this.state.EMPRESA_A.map((item) => (
                      <option key={item.COD_EMPRESA} value={item.COD_EMPRESA}>
                        {item.NOMBRE_EMPRESA}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <div style={{ textAlign: "left" }} htmlFor="COD_SUCURSAL_B">
                    SUCURSAL
                  </div>
                  <select
                    name="COD_SUCURSAL_B"
                    className="form-control"
                    value={this.state.COD_SUCURSAL_B}
                    onChange={this.BUSQUEDA}
                    disabled={
                      this.state.BOTON_FORMALIZACION === "false" ? true : false
                    }
                  >
                    <option> </option>
                    {this.state.SucursalA.map((item) => (
                      <option key={item.COD_SUCURSAL} value={item.COD_SUCURSAL}>
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-2">
                  <div style={{ textAlign: "left" }} htmlFor="BANCO">
                    <b>Fecha</b>
                  </div>
                  <DatePicker
                    type="date"
                    dateFormat="dd '/' M '/' yyyy"
                    selected={this.state.fecha_1}
                    onChange={this.onChange2}
                    locale="es"
                    className="form-control "
                    disabled={
                      this.state.BOTON_FORMALIZACION === "false" ? true : false
                    }
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div style={{ textAlign: "left" }} htmlFor="PAGO_A">
                    PAGO A
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="PAGO_A"
                    value={this.state.PAGO_A}
                    onChange={this.onChange}
                    autoComplete="off"
                    disabled={
                      this.state.BOTON_FORMALIZACION === "false" ? true : false
                    }
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div style={{ textAlign: "left" }} htmlFor="OBSERVACION_REC">
                    CONCEPTO
                  </div>
                  <textarea
                    type="text"
                    className="form-control"
                    name="OBSERVACION_REC"
                    aria-label="Sizing example input"
                    aria-describedby="inputGroup-sizing-lg"
                    value={this.state.OBSERVACION_REC}
                    onChange={this.onChange}
                    autoComplete="off"
                    disabled={
                      this.state.BOTON_FORMALIZACION === "false" ? true : false
                    }
                  />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div style={{ textAlign: "left" }} htmlFor="GASTOS_REC">
                    GASTOS
                  </div>
                  <input
                    list="data_1"
                    name="GASTOS_REC"
                    autoComplete="off"
                    className="form-control "
                    value={this.state.GASTOS_REC}
                    onChange={this.onChange}
                    disabled={
                      this.state.BOTON_FORMALIZACION === "false" ? true : false
                    }
                  />
                  <datalist id="data_1">
                    <option></option>
                    {this.state.GASTOS.map((item) => (
                      <option
                        key={item.COD_GASTO}
                        value={item.DESCRIPCION_GASTO}
                      >
                        {item.DESCRIPCION_GASTO}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div align="center" className="col-md-4 p-3 ">
                  <div
                    className="btn-group"
                    role="group"
                    // aria-div="Basic example"
                  >
                    <button
                      onClick={this.ARRAY_SERVICIOS}
                      className="btn btn-lg  btn-outline-success col-md-12"
                      disabled={
                        this.state.BOTON_FORMALIZACION === "false" ? true : false
                      }
                    >
                      Agregar
                    </button>

                    <button
                      onClick={this.getSelectedRowData}
                      className="btn btn-lg btn-outline-danger col-md-12"
                      disabled={
                        this.state.BOTON_FORMALIZACION === "false" ? true : false
                      }
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12  mx-auto">
                <div className="container jumbotrom   bg-light text-dark ">
                  <div className="d-flex flex-column  bd-highlight jumbotrom  bg-light text-dark border rounded border-dark">
                    <div className="form-row">
                      <div
                        className="ag-theme-balham col-md-12"
                        style={{
                          width: 400,
                          height: 200,
                        }}
                      >
                        <AgGridReact
                          columnDefs={this.state.columnDefs}
                          defaultColDef={this.state.defaultColDef}
                          onGridReady={this.onGridReady}
                          rowData={this.state.rowData}
                          rowSelection="multiple"
                          onCellValueChanged={this.onChange_data_grid}
                         
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-4 p-3">
                <button
                  onClick={this.onSubmit}
                  className="btn btn-lg btn-outline-dark col-md-12"
                  disabled={
                    this.state.BOTON_FORMALIZACION === "false" ? true : false
                  }
                >
                  REGISTRAR
                </button>
              </div>
            
              <div className="col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="TOTAL_CORDOBAS">
                  TOTAL CORDOBAS
                </div>
                <input
                  type="TOTAL_CORDOBAS"
                  className="form-control text-right"
                  name="TOTAL_CORDOBAS"
                  value={this.state.TOTAL_CORDOBAS}
                  onChange={this.onChange}
                  disabled="disabled"
                />
              </div>

              <div className="col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="TOTAL_DOLARES">
                  TOTAL DOLARES
                </div>
                <input
                  type="TOTAL_DOLARES"
                  className="form-control text-right"
                  name="TOTAL_DOLARES"
                  value={this.state.TOTAL_DOLARES}
                  onChange={this.onChange}
                  disabled="disabled"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Caja_Gasto;

import React, { Component } from "react";
import axios from "axios";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import TimerMixin from "react-timer-mixin";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";

registerLocale("es", es);





class agenda_dia extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

    R_desembolso = (registro) => {
        return axios
            .post(`${api}/pago/agenda/${this.state.Tipo_usuario}/${this.state.sucursal}`, {
                CAJERO     : registro.CAJERO,
                SUCURSAL_C : registro.SUCURSAL_C,
                fecha_1    : registro.fecha_1,
            })
            .then((response) => {

                this.setState({
                    LISTA: response.data.agenda_dia,
                });
            })
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log("el error es ", err);
                window.alert("No hay creditos por pagar el dia de hoy", err);
                window.location.reload();
            });
    };
    constructor() {
        super();
        this.state = {

            CAJERO       : "",  //POST 
            A_SUCURSAL   : "", //POST 
            SUCURSAL_C   : "",//POST 
            fecha_1      : "", //POST 
            COD_ROL_SUC: "",

            LISTA        : [], //GET ARRAY
            SucursalA    : [],     //GET ARRAY

            IP           : [],          //GET
            alias        : "",         //GET

            Tipo_usuario : "", //RESTRICCION
            sucursal     : "",//RESTRICCION

            Activar_c          : "",  
            boton              : "true",

            fecha              : "",//GET

            ID_ROL: "",
            PERMISO_ROL: "1010",
            VALIDACION: "hidden",
            errors             : {},       
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
     
      e.preventDefault();
            const registro = {
                CAJERO     : this.state.alias,
                SUCURSAL_C :this.state.SUCURSAL_C,
                fecha_1: this.state.fecha_1,
            };

            this.R_desembolso(registro).then((res) => {
            });
     
    }

    componentDidMount() {
        const token = localStorage.usertoken;
        const decoded = jwt_decode(token);
        getUser(decoded.uid).then((res) => {
            this.setState({
                alias          : res.data.Alias,
                IP             : res.data.IP,
                Tipo_usuario   : res.data.Tipo_usuario,
                sucursal       : res.data.sucursal,
                SUCURSAL_C     : res.data.sucursal,
                ID_ROL: res.data.ID_ROL,
                COD_ROL_SUC: res.data.COD_ROL_SUC,
                fecha:new Date(res.data.FECHA_SERVER),
                fecha_1:new Date(res.data.FECHA_SERVER)
            });  
        
            TimerMixin.setTimeout(() => {
            this.CONSULTA_PERMISO(); 
          }, 300);    
       
});
        
    }

    consultarSucursal = () => {
      const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
      fetch(url)
        .then((respuesta) => respuesta.json())
        .then((resultado) => this.setState({ SucursalA: resultado }));
    };

    onChange2 = (fecha) => {
      this.setState({ fecha_1: fecha });
  };
  
    render() {
        return (
          <form style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
            <div className="container mt-5">
              <div className="row">
              <div className="shadow-lg p-3 mb-5 bg-white rounded  col-md-8 mx-auto border rounded border-dark" style={{width: "200px;"}}>
                  <h1 className="h3 mb-4 font-weight-normal  text-center">
                    Agenda del dia
                  </h1>

                  
                  <div className="d-flex justify-content-center">
                  <div className="form-group text-center col-md-4">
                  <label htmlFor="SucursalA">Sucursal</label>
                  <select
                    name="SUCURSAL_C"
                    className="form-control"
                    value={this.state.SUCURSAL_C}
                    onChange={this.onChange}
                    placeholder="Campo obligatorio"
                    onClick={this.consultarSucursal()}
                   
                  >
                    <option>Todas</option>
                    {this.state.SucursalA.map((item) => (
                      <option
                        key={item.NOMBRE_SUCURSAL}
                        value={item.COD_SUCURSAL}
                      >
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}

                  </select>
                </div>

                <div className="form-group text-center col-md-4 mx-auto" style={{width: "200px;"}}>
                    <label htmlFor="Fecha">Fecha : </label>
                   
                        <DatePicker
                          selected={this.state.fecha_1}
                          onChange={this.onChange2}
                          locale="es"
                          className="form-control"
                          dateFormat="dd '/' M '/' yyyy"
                        />
                         </div> 
                </div>

                  <div className="d-flex justify-content-center">
                  <button
                    onClick={this.onSubmit}
                    className="btn btn-lg btn-primary btn-block col-md-4"
                  >
                    Buscar
                  </button>
             </div> 
             
                </div>
              </div>
            </div>

            <div
              style={{
                //  display: "flex",
                //  justifyContent: "flex-end",
                marginTop: "5px",
                marginRight: "10px",
                color: "black",
                //  width: "110%",
                // height: "50px"
              }}
            >
              <div className="table-responsive">
                <table className="table table-striped " id="body">
                  <thead className="text-center thead-dark">
                    <tr>
                      <th>Credito</th>
                      <th>Cliente</th>
                      <th>Cuotas en mora</th>
                      <th>Dias en mora</th>
                      <th>Monto en mora</th>
                      <th>Direccion</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.LISTA.map((item) => {
                      return (
                        <tr>
                          <td>{item.COD_CREDITO}</td >
                          <td>{item.NOMBRE1}</td>
                          <td>{item.CUOTAS_EN_MORA}</td>
                          <td>{item.DIAS_MORA}</td>
                          <td>{item.VALOR_CUOTA}</td >
                          <td>{item.DIRECCION_NEG}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        );
      }
}

export default agenda_dia;
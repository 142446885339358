import React, { Component } from "react";
import Swal from "sweetalert2";
import TimerMixin from "react-timer-mixin";
import axios from "axios";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from "../../../api/axiosConfigRoute";
import SisprestamosNavbar from "../../SisprestamosNavbar";

class Register extends Component {
  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarTipousuario = () => {
    const url = `${api}/tipousuario/get`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ tipo_usuario_A: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  consultarEmpresa = () => {
    const url = `${api}/empresa/get`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ EmpresaA: resultado }));
  };
  constructor() {
    super();
    this.state = {
      ALIAS: "",
      COD_REGION: "",
      COD_NIVEL: "",
      ID_ROL: "",
      COD_ROL_SUC: "",
      COD_EMPRESA_B: "",

      SucursalA: [],
      EmpresaA: [],
      tipo_usuario_A: [],

      COD_EMPRESA: "",
      COD_SUCURSAL: "",
      Tipo_usuario: "",
      name: "",
      A_ANALISTA: "",
      alias: "",
      password: "",

      PERMISO_ROL: "1032",
      VALIDACION: "hidden",

      BOTON: "true",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  register = (newUser) => {
    return axios
      .post(`${api}/usuario/register`, {
        name: newUser.name,
        alias: newUser.alias,
        password: newUser.password,
        Tipo_usuario: newUser.Tipo_usuario,
        COD_SUCURSAL: newUser.COD_SUCURSAL,
        COD_EMPRESA: newUser.COD_EMPRESA,
        A_ANALISTA: newUser.A_ANALISTA,
        ADICIONADO_POR: newUser.ADICIONADO_POR,
      })
      .then((response) => {
        Swal.fire(
          "Registro De Usuario",
          response.data.mensaje,
          response.data.alerta
        );
        let tiempo = "2000";
        TimerMixin.setTimeout(() => {
          if (response.data.alerta === "error") {
            this.setState({
              BOTON: "true",
            });
          } else {
            this.setState({
              COD_EMPRESA: "",
              COD_SUCURSAL: "",
              Tipo_usuario: "",
              name: "",
              A_ANALISTA: "",
              alias: "",
              password: "",
              BOTON: "true",
            });
          }
        }, tiempo);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Registro De Usuario",
          "Por Favor Verifique Los Datos",
          "error"
        );
      });
  };

  onSubmit(e) {
    let COD_ANALISTA;
    if (this.state.A_ANALISTA === "") {
      COD_ANALISTA = 0;
    } else {
      COD_ANALISTA = this.state.A_ANALISTA;
    }
    let tiempo = "1000";
    if (this.state.COD_EMPRESA === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de Usuario",
        "Por Favor Seleccione Una Empresa",
        "error"
      );
    } else if (this.state.COD_SUCURSAL === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de Usuario",
        "Por Favor Seleccione Una Sucursal",
        "error"
      );
    } else if (this.state.Tipo_usuario === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de Usuario",
        "Por Favor Seleccione Un Tipo De Usuario",
        "error"
      );
    } else if (this.state.name === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de Usuario",
        "Por Favor Ingrese El Nombre Del Usuario",
        "error"
      );
    } else if (this.state.alias === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de Usuario",
        "Por Favor Ingrese el Alias del Usuario",
        "error"
      );
    } else if (this.state.password === "") {
      e.preventDefault();
      Swal.fire(
        "Registro de Usuario",
        "Por Favor Ingrese Una Contraseña",
        "error"
      );
    } else {
      e.preventDefault();
      Swal.fire({
        title: "Desea Registrar los Datos del Usuario",
        showDenyButton: true,
        confirmButtonText: `Registrar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.DESACTIVAR_BOTON();
          TimerMixin.setTimeout(() => {
            let timerInterval;
            Swal.fire({
              title: "Registro de Usuario",
              html: "Lo Datos Se Estan Registrando",
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
              }
            });
            const newUser = {
              name: this.state.name,
              alias: this.state.alias,
              password: this.state.password,
              Tipo_usuario: this.state.Tipo_usuario,
              COD_SUCURSAL: this.state.COD_SUCURSAL,
              COD_EMPRESA: this.state.COD_EMPRESA,
              A_ANALISTA: COD_ANALISTA,
              ADICIONADO_POR:this.state.ALIAS,
            };
            this.register(newUser).then((res) => {});
          }, tiempo);
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    }
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        COD_REGION: res.data.COD_REGION,
        COD_NIVEL: res.data.COD_NIVEL,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
        COD_EMPRESA_B: res.data.COD_EMPRESA_B,
      });
      let tiempo = "300";
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
        this.consultarTipousuario();
        this.consultarEmpresa();
        this.consultarSucursal();
      }, tiempo);
    });
  }

  DESACTIVAR_BOTON(e) {
    this.setState({
      BOTON: "false",
    });
  }
  render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}>
        <SisprestamosNavbar />
        <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-3 mx-auto">
                <h1 className="h3 mb-4 font-weight-normal  text-center">
                  REGISTRO DE DATOS DEL USUARIO
                </h1>
              </div>
            </div>
          </div>
          {/* */}
          <div className="container">
            <div className="form-row">
              <div className="form-group text-center col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="COD_EMPRESA">
                  EMPRESA
                </div>
                <select
                  name="COD_EMPRESA"
                  className="form-control"
                  value={this.state.COD_EMPRESA}
                  onChange={this.onChange}
                  disabled={this.state.BOTON === "false" ? true : false}
                >
                  <option></option>
                  {this.state.EmpresaA.map((item) => (
                    <option key={item.COD_EMPRESA} value={item.NOMBRE_EMPRESA}>
                      {item.NOMBRE_EMPRESA}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group text-center col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="COD_SUCURSAL">
                  SUCURSAL
                </div>
                <select
                  name="COD_SUCURSAL"
                  className="form-control"
                  value={this.state.COD_SUCURSAL}
                  onChange={this.onChange}
                  disabled={this.state.BOTON === "false" ? true : false}
                >
                  <option></option>
                  {this.state.SucursalA.map((item) => (
                    <option
                      key={item.COD_SUCURSAL}
                      value={item.NOMBRE_SUCURSAL}
                    >
                      {item.NOMBRE_SUCURSAL}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group text-center col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="Tipo_usuario">
                  TIPO DE USUARIO
                </div>
                <select
                  name="Tipo_usuario"
                  className="form-control"
                  value={this.state.Tipo_usuario}
                  onChange={this.onChange}
                  disabled={this.state.BOTON === "false" ? true : false}
                >
                  <option> </option>
                  {this.state.tipo_usuario_A.map((item) => (
                    <option
                      key={item.Tipo_usuario}
                      value={item.Nombre_tipo_usuario}
                    >
                      {item.Nombre_tipo_usuario}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group text-center col-md-6">
                <div style={{ textAlign: "left" }} htmlFor="name">
                  NOMBRE
                </div>
                <input
                  className="form-control"
                  name="name"
                  planceholder="Enter your Name"
                  value={this.state.name}
                  onChange={this.onChange}
                  autoComplete="off"
                  disabled={this.state.BOTON === "false" ? true : false}
                />
              </div>

              <div className="form-group col-md-6 text-center">
                <div style={{ textAlign: "left" }} htmlFor="A_ANALISTA">
                  ANALISTA
                </div>
                <input
                  className="form-control"
                  name="A_ANALISTA"
                  planceholder="Enter your Name"
                  value={this.state.A_ANALISTA}
                  onChange={this.onChange}
                  autoComplete="off"
                  disabled={this.state.BOTON === "false" ? true : false}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group text-center col-md-6">
                <div style={{ textAlign: "left" }} htmlFor="alias">
                  ALIAS
                </div>
                <input
                  className="form-control"
                  name="alias"
                  planceholder="Enter your Alias"
                  value={this.state.alias}
                  onChange={this.onChange}
                  autoComplete="off"
                  disabled={this.state.BOTON === "false" ? true : false}
                />
              </div>

              <div className="form-group text-center col-md-6">
                <div style={{ textAlign: "left" }} htmlFor="password">
                  CONTRASEÑA
                </div>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  planceholder="Enter your Password"
                  value={this.state.password}
                  onChange={this.onChange}
                  autoComplete="off"
                  disabled={this.state.BOTON === "false" ? true : false}
                />
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <button
                onClick={this.onSubmit}
                className="btn btn-outline-dark  col-md-4 "
                disabled={this.state.BOTON === "false" ? true : false}
              >
                Registrar
              </button>
            </div>
          </div>
          {/*  */}
        </div>
      </form>
    );
  }
}
export default Register;

import axios from "axios";
import api from "../../src/api/axiosConfigRoute";

export const PermisoModulo = (IdRol, Permiso) => {
  return axios
    .get(`${api}/permiso/get/${IdRol}/${Permiso}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

export const PermisoFA = (CodPermiso, Modulo) => {
  return axios
    .get(`${api}/permiso/GetFAt/${CodPermiso}/${Modulo}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

export const PermisoTipoIngreso = (CodPermiso) => {
  return axios
    .get(`${api}/permiso/GetTipoIngreso/${CodPermiso}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

export const Empresa = () => {
  return axios
    .get(`${api}/empresa/Datos`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

export const DatosEmpresa = (CodigoEmpresa) => {
  ///// LOGOS , ENCARGADO , NOMBRE, CODIGO .
  return axios
    .get(`${api}/mantenimiento/empresa/${CodigoEmpresa}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

export const Sucursal = (IdRol) => {
  return axios
    .get(`${api}/sucursal/Datos/${IdRol}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

export const DatosTipoCredito = (Empresa) => {
  return axios
    .get(`${api}/DatosGeneral/TipoCredito/${Empresa}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

export const CreditoCajaIngreso = (Empresa, Sucursal) => {
  return axios
    .get(`${api}/consulta/Creditos/${Empresa}/${Sucursal}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};


export const ListaAnalista = (Empresa, Sucursal) => {
  return axios
    .get(`${api}/analista/data/${Empresa}/${Sucursal}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

export const DataAnalista = (Empresa, Sucursal, Analista) => {
  return axios
    .get(`${api}/analista/detalle/${Empresa}/${Sucursal}/${Analista}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

export const DataCosto = (Empresa, Sucursal) => {
  return axios
    .get(`${api}/analista/datacosto/${Empresa}/${Sucursal}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};


export const CostoPorAnalista = (DataAnalistaCosto) => {
  return axios
    .post(
      `${api}/analista/costoanalista/${DataAnalistaCosto.Empresa}/${DataAnalistaCosto.Sucursal}/${DataAnalistaCosto.Analista}`,
      {
        Mes: DataAnalistaCosto.Mes,
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};
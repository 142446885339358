import React, { Component } from "react";
import axios                from "axios";
import { geolocated }       from "react-geolocated";
import TimerMixin           from "react-timer-mixin";
import Swal                 from "sweetalert2";
import  { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import moment from "moment-timezone";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";


registerLocale("es", es);

class Creditos_desembolsar extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

getCredito = (Id_credito) => {
return axios
.get(`${api}/desemb/credito/${this.busquedaRef.current.value}/${this.state.Tipo_usuario}/${this.state.sucursal}`)
.then((res_credito) => {
return res_credito;
})
.catch((err) => {
window.alert("El Credito no esta registrado");
window.location.reload();
return err;
});
};

busquedaRef = React.createRef();
obtenerDatos = (e) => {
e.preventDefault();
this.obtenerlatitude();
this.getCredito().then((res_credito) => {
this.setState({
COD_CREDITO      : res_credito.data.COD_CREDITO,
CLIENTE          : res_credito.data.CLIENTE,
MONTO            : res_credito.data.MONTO,
SALDO_ANTERIOR   : res_credito.data.SALDO_ANTERIOR,
CREDITO_ANTERIOR : res_credito.data.CREDITO_ANTERIOR,
DIRECCION         : res_credito.data.DIRECCION,
DIRECCION_NEG : res_credito.data.DIRECCION_NEG,
});
});
};

registro_no_pago = (newSolicitud) => {
    console.log(this.state.FECHA)
return axios
.post(`${api}/desemb/r_desembolso/${this.state.Tipo_usuario}/${this.state.sucursal}`, {
DESEMBOLSADOR    : newSolicitud.DESEMBOLSADOR,                
MONTO            : newSolicitud.MONTO,              
TIPO             : newSolicitud.TIPO,           
latitude         : newSolicitud.latitude,             
longitude        : newSolicitud.longitude,      
FECHA            : newSolicitud.FECHA,   
HORA_DESEMB      : newSolicitud.HORA_DESEMB,   
COD_CREDITO      : newSolicitud.COD_CREDITO,
CREDITO_ANTERIOR : newSolicitud.CREDITO_ANTERIOR,
})
.then((response) => {
Swal.fire(
'Desembolso',
'Registrado',
'SUCCESS'
)
this.props.history.push('/')
this.props.history.push('/Creditos_desembolsar')
})
.catch((err) => {
console.log(err)
window.alert("Verifique los Datos");
window.location.reload();
});
};

constructor() {
super();
this.state = {
place            : null,
FECHA_MOMENT_TZ  : "",
HORA_DESEMB      : "",
HORA_MOMENT_TZ   : "",
COD_CREDITO      : [],       //GET
CLIENTE          : [],      //GET
MONTO            : [],     //GET
SALDO_ANTERIOR   : "",

DESEMBOLSADOR  : "",                
TOTAL_EFECTIVO : "",             
TIPO           : "Entregado",           
latitude       : "",             
longitude      : "",        


boton           : "false",
CONFIRMAR       : "false",

CREDITO_ANTERIOR : "",


DIRECCION:"",
DIRECCION_NEG : "",
    
block         : "enviar",
Condicion     : "",
V_Condicion   : "FALSE",
V_Condicion_T : "TRUE",
Tipo_usuario  :"",
sucursal      :"",
FECHA         : new Date(),//GET
PERMISO_ROL: "1004",
VALIDACION: "hidden",
errors        : {},
};
this.onChange = this.onChange.bind(this);
this.onSubmit = this.onSubmit.bind(this);
this.activar  = this.activar.bind(this);
}

onChange(e) {
this.setState({ [e.target.name]: e.target.value });
}

onSubmit(e) {


e.preventDefault();

Swal.fire({
title: 'Desea realizar el desembolso',
showDenyButton: true,
// showCancelButton: true,
confirmButtonText: `Registrar`,
denyButtonText: `Cancelar`,
}).then((result) => {
/* Read more about isConfirmed, isDenied below */
if (result.isConfirmed) {
// e.preventDefault();
TimerMixin.setTimeout(() => {
//e.preventDefault();
const newSolicitud = {
    DESEMBOLSADOR    : this.state.DESEMBOLSADOR,                
    MONTO            : this.state.MONTO,             
    TIPO             : this.state.TIPO,           
    latitude         : this.state.latitude,             
    longitude        : this.state.longitude,      
    FECHA            : this.state.FECHA_MOMENT_TZ,    
    HORA_DESEMB      : this.state.HORA_MOMENT_TZ,  
    COD_CREDITO      : this.state.COD_CREDITO,
    CREDITO_ANTERIOR : this.state.CREDITO_ANTERIOR,
};
this.registro_no_pago(newSolicitud).then((res) => {});
}, );
// Swal.fire('Registrado!', '', 'success')
} else if (result.isDenied) {
Swal.fire('Registro cancelado', '', 'info')
}
})


}

Conv_fecha(){

  //**************************MOMENT TIME ZONE************************/
  var jun = moment(this.state.FECHA);
  
  var fecha_time_conv=jun.tz('América/Managua').format('l');  // 5am PDT
  var hora_time_conv=jun.tz('América/Managua').format('LT');  // 5am PDT
  
  //**************************MOMENT TIME ZONE************************/
    
    this.setState({
     FECHA_MOMENT_TZ:fecha_time_conv,
     HORA_MOMENT_TZ:hora_time_conv,
      });
  }

componentDidMount() {
  this.Conv_fecha()

const token = localStorage.usertoken;
const decoded = jwt_decode(token);
getUser(decoded.uid).then((res) => {
this.setState({
CAJERO         : res.data.Alias,
DESEMBOLSADOR  : res.data.Alias,
TIME           : res.data.TIME,
IP             : res.data.IP,
TIME_P         : res.data.TIME,
COD_SUCURSAL_U : res.data.COD_SUCURSAL,
COD_EMPRESA_U  : res.data.COD_EMPRESA,
Tipo_usuario: res.data.Tipo_usuario,
sucursal: res.data.sucursal,
ID_ROL: res.data.ID_ROL,
});
let tiempo = '300'
TimerMixin.setTimeout(() => {
this.CONSULTA_PERMISO(); 
}, tiempo);  
});
}


busquedalatitude = React.createRef();

obtenerlatitude = (e) => {
console.log(this.busquedalatitude.current.value);
this.obtenerlongitude();
const termino1 = this.busquedalatitude.current.value;
this.setState({
latitude: termino1,
});
};

busquedalongitude = React.createRef();

obtenerlongitude = (e) => {
console.log(this.busquedalongitude.current.value);
const termino2 = this.busquedalongitude.current.value;
this.setState({
longitude: termino2,
});
};


activar(e){ 

    if (this.state.CONFIRMAR === "false"){
        this.setState({
            CONFIRMAR:"true",
             });
     
    }else{

        this.setState({
            CONFIRMAR:"false",
             });

    } 

   
    if (this.state.CONFIRMAR === "true"){
        this.setState({
            
            boton     :"false" ,
            });
    }else{
        this.setState({
       
            boton     :"true" ,
            });
    }
    
    }
  render() {
    // console.warn("RESULT RETURN HERE",this.state.place)
    return !this.props.isGeolocationAvailable ? (
      <div>Your browser does not support Geolocation</div>
    ) : !this.props.isGeolocationEnabled ? (
      <div>Geolocation is not enabled</div>
    ) : this.props.coords ? (

      <form onSubmit={this.obtenerDatos} style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
        <div className="busqueda container">
      <div className="jumbotron jumbotron mt-5 p-3 mb-2 bg-secondary text-white">
        <h1 className="h3 mb-4 font-weight-normal  text-center">
            </h1>

            <div className="row">
              <div className="form-group col-md-8">
                <input
                  ref={this.busquedaRef}
                  type="text"
                  className="form-control 
                    form-control-lg text-right"
                  placeholder="Credito.Ejemplo: 
                    718"
                />
              </div>

              <div className="form-group col-md-4">
                <input
                  type="submit"
                  className="btn btn-lg 
                    btn-danger btn-block"
                  value="Buscar"
                />
              </div>
            </div>
          </div>
        </div>
     
     

        <div className="container" >
          <div className="row">
          <div className="col-md-12 mt-3 mx-auto">
          <h1 className="h3 mb-4 font-weight-normal  text-center">
              Datos del credito
              </h1>
           

              <div className="container jumbotrom p-3 mb-2 bg-light text-dark ">
            <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-2 bg-light text-dark border rounded border-dark">
              <div className="form-row">
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  Credito{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    ------
                  </font>
                  : {this.state.COD_CREDITO}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-8">
                    Nombre{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -------
                  </font>
                  : {this.state.CLIENTE}
                </div>

               
              </div>


              <div className="row">
              <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6">
                  Total entregado{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -------
                  </font>
                  : {this.state.MONTO}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6">
                  Saldo anterior{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -------
                  </font>
                  : {this.state.SALDO_ANTERIOR}
                </div>
               
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12">
                 DIRECCION{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -------
                  </font>
                  : {this.state.DIRECCION}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12">
                 DIRECCION NEGOCIO{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -------
                  </font>
                  : {this.state.DIRECCION_NEG}
                </div>

              </div>
              </div>
              </div>
             </div>
          </div>
        </div>

        

        <div className="container">
            
        <div className="d-flex justify-content-center">
                <div className="form-group text-center col-md-6">
              <div className="container jumbotrom p-3 mb-2 bg-light text-dark ">

              <div className="form-row">


              <div className="form-row">
                  <div className="form-group text-center col-md-4">
                  <label htmlFor="CONFIRMAR">Desactivar</label>
            <input
              type="checkbox"
              defaultChecked={this.state.CONFIRMAR}
              onChange={this.activar}
            />
         </div>
    
                  </div>
           

                  <div className="form-group text-center col-md-12">
            
                    <label htmlFor="TIPO">Concepto</label>
                    <select
                      name="TIPO"
                      className="form-control text-center"
                      value={this.state.TIPO}
                      onChange={this.onChange}
                      disabled={
                      this.state.boton === "false"
                          ? true
                          : false
                      }
                    >
        
                      <option className="form-control text-center">
                      Entregado
                      </option>
                      <option className="form-control text-center">
                        Anular
                      </option>
                      <option className="form-control text-center">
                        Denegado
                      </option>
                      <option className="form-control text-center">
                        Rechazado
                      </option>
                    </select>
                  </div>

                
    
                  </div>
                  <div className="d-flex justify-content-center" >  
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary btn-block col-md-4"
                    onClick={this.onSubmit}
                  >
                    Registrar
                  </button>
  
               </div>
             
            </div>
          </div>

          </div>

          <div className="col-md-12" >
                  <p className="text-light bg-dark text-center">Usuario : {this.state.CAJERO} <font size="1" color="white" style={{ visibility: "hidden" }}>---</font>Sucursal : {this.state.COD_SUCURSAL_U}</p>
              </div>
               </div>








          
       

        <div style={{ visibility: "hidden",position: "fixed"  }}>
          <div className="jumbotron">
            <p className="lead text-center h3 mb-3 font-weight-normal">
              Consultar datos del Cliente
            </p>

           
            < div className="form-group">
                <label htmlFor="latitude">latitude</label>
                <input
                  type="latitude"
                  className="form-control"
                  name="latitude"
                  planceholder="Enter your Alias"
                  value={this.props.coords.latitude}
                  ref={this.busquedalatitude}
                />
              </div>


              <  div className="form-group">
                <label htmlFor="longitude">longitude</label>
                <input
                  type="longitude"
                  className="form-control"
                  name="longitude"
                  planceholder="Enter your Alias"
                  value={this.props.coords.longitude}
                  ref={this.busquedalongitude}
                />
              </div>




           
               
              </div>
            </div>
          


        
      </form>
    ) : (
      <div>Getting the location data&hellip; </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 10000,
})(Creditos_desembolsar);

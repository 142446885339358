import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import  { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";


registerLocale("es", es);

class Anular_recibos extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

Anular = (anularrec) => {
return axios
.post(
`${api}/anular/A_recibo/${this.busquedaRef.current.value}`,
{
PAGO    : anularrec.PAGO,
Usuario : anularrec.Usuario,
FECHA_A : anularrec.FECHA_A,
COD_NIVEL : anularrec.COD_NIVEL
}
)
.then((response) => {
  Swal.fire({
    title: 'Anular Recibo',
    text: response.data.mensaje,
    icon: response.data.alerta,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'OK!'
  }).then((result) => {
    if (result.isConfirmed) {
      window.location.reload();
    }
  })
})
.then((res) => {
//window.alert("Actualizado");
return res;
})
.catch((err) => {
window.alert("Verifique los Datos");
window.location.reload();
});
};    


getdatos = (Id_credito) => {
return axios
.get(`${api}/anular/recibo/${this.busquedaRef.current.value}/${this.state.Tipo_usuario}/${this.state.sucursal}`)
.then((res) => {
return res;
})
.catch((err) => {
window.alert("El recibo no esta registrado");
window.location.reload();
return err;
});
};
    
busquedaRef = React.createRef();
obtenerDatos = (e) => {

if (this.state.Tipo_usuario === 1 || this.state.Tipo_usuario === 2) {
  
e.preventDefault();
     
this.getdatos().then((res) => {
this.setState({
NO_RECIBO       : res.data.NO_RECIBO,
FECHA           : res.data.FECHA,
PAGO            : res.data.PAGO,
block           : res.data.block,
Cliente         : res.data.Cliente,
CREDITO         : res.data.CREDITO,
TIPO_MOVIMIENTO : res.data.TIPO_MOVIMIENTO,
TIPODECAMBIO    : res.data.TIPODECAMBIO,
MONTO_CORDOBA   : res.data.MONTO_CORDOBA,
MONTO_DOLAR     : res.data.MONTO_DOLAR,
TOTAL_RECIBIDO  : res.data.TOTAL_RECIBIDO,
SALDO_CREDITO   : res.data.SALDO_CREDITO,
N_SUCURSAL      : res.data.N_SUCURSAL,
});     
});

} else {
  window.alert("No tiene permisos para usar este modulo");
  } 

};

constructor() {
super();
this.state = {
FECHA_MOMENT_TZ  : "",
HORA_A           : "",
FECHA_A          : "",
HORA_MOMENT_TZ   : "",

FECHA_dia        : new Date(),//GET

NO_RECIBO       : "",
FECHA           : "",
PAGO            : "",
block           : "",
Cliente         : "",
CREDITO         : "",
TIPO_MOVIMIENTO : "",
TIPODECAMBIO    : "",
MONTO_CORDOBA   : "",
MONTO_DOLAR     : "",
TOTAL_RECIBIDO  : "",
SALDO_CREDITO   : "",

IP             : [], 
alias          : "",
Tipo_usuario   : "",
COD_SUCURSAL_U : "",
visibilidad    : "",

sucursal       : "",

N_SUCURSAL     : "", 
      
ID_ROL: "",
PERMISO_ROL: "1022",
VALIDACION: "hidden",
COD_NIVEL      : "",


errors: {},
};
this.onChange = this.onChange.bind(this);
this.onSubmit = this.onSubmit.bind(this);
}

onChange(e) {
this.setState({ [e.target.name]: e.target.value });
}


componentDidMount() {
const token = localStorage.usertoken;
const decoded = jwt_decode(token);
getUser(decoded.uid).then((res) => {
this.setState({
alias          : res.data.Alias,
Tipo_usuario   : res.data.Tipo_usuario,
COD_SUCURSAL_U : res.data.COD_SUCURSAL,
sucursal       : res.data.sucursal,
ID_ROL         : res.data.ID_ROL,
COD_NIVEL      : res.data.COD_NIVEL,
});
let tiempo = '300'
TimerMixin.setTimeout(() => {
this.CONSULTA_PERMISO(); 
}, tiempo);   
});
//this.visibilidad()
}


onSubmit(e) {

e.preventDefault();
Swal.fire({
title: 'Desea anular el recibo',
showDenyButton: true,
// showCancelButton: true,
confirmButtonText: `Anular`,
denyButtonText: `Cancelar`,
}).then((result) => {
  /* Read more about isConfirmed, isDenied below */
if (result.isConfirmed) {
const anularplan = {
PAGO    : this.state.PAGO,
Usuario : this.state.alias,
FECHA_A : this.state.FECHA,
COD_NIVEL : this.state.COD_NIVEL
};
this.Anular(anularplan).then((res) => {
});
// Swal.fire('Registrado!', '', 'success')
} else if (result.isDenied) {
  Swal.fire('cancelado', '', 'info')
}
})

}

visibilidad() {
TimerMixin.setTimeout(
() =>{ 
if (this.state.Tipo_usuario === 1 || this.state.Tipo_usuario === 2) {
this.setState({
visibilidad : "none",
});
} else {
this.setState({
visibilidad : "hidden",
});
window.alert("No tiene permisos para usar este modulo");
} 
},
5000
);
}

render() {
    return (
      <form  style={{ visibility: this.state.VALIDACION }}> <SisprestamosNavbar />
        <div className="busqueda container">
          <div className="jumbotron mt-4 shadow-lg p-3 mb-5 bg-secondary text-white rounded">
            <h1 className="h3 mb-4 font-weight-normal  text-center">
              Consultar Datos
            </h1>
            <div className="row">
              <div className="form-group col-md-8">
                <input
                  ref={this.busquedaRef}
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="No.Recibo.Ejemplo: 718"
                />
              </div> 
              <div className="form-group col-md-4">
                <button
                  onClick={this.obtenerDatos}
                  className="btn btn-lg btn-danger btn-block"
                >
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
      <div className="row">
        <div className="col-md-12 mt-3 mx-auto">
          <h1 className="h3 mb-4 font-weight-normal  text-center">
            Datos del recibo
          </h1>
          <div align="end" className="col-md-12 mb-2">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button onClick={this.onSubmit} className="btn btn-lg btn-primary "  disabled={
                 this.state.block === "false"
                    ? true
                    : false
                }>
              Anular
            </button>
          </div>
        </div>
          <div className="container jumbotrom p-3 mb-2 bg-light text-dark ">
            <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-2 bg-light text-dark border rounded border-dark">
             
              <div className="form-row">
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  No.Recibo{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    --
                  </font>
                  : {this.state.NO_RECIBO}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  Fecha{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -
                  </font>
                  : {this.state.FECHA}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  Pago{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    --
                  </font>
                  : {this.state.PAGO}
                </div>
              </div>

              <div className="form-row">
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-12">
                  Cliente{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    --------
                  </font>
                  : {this.state.Cliente}
                </div>
              </div>

              <div className="form-row">
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  No.Credito{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -
                  </font>
                  : {this.state.CREDITO}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  Tipo{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    ---
                  </font>
                  : {this.state.TIPO_MOVIMIENTO}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  Tipo de cambio{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -
                  </font>
                  : {this.state.TIPODECAMBIO}
                </div>
              </div>

              <div className="form-row">
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  Cordoba{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -----
                  </font>
                  : {this.state.MONTO_CORDOBA}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  Dolar{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -
                  </font>
                  : {this.state.MONTO_DOLAR}
                </div>

                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-4">
                  Total{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    --
                  </font>
                  : {this.state.TOTAL_RECIBIDO}
                </div>
              </div>

              <div className="form-row">
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6">
                  Saldo{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -----------
                  </font>
                  : {this.state.SALDO_CREDITO}
                </div>
                <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group col-md-6">
                  Sucursal{" "}
                  <font size="1" color="white" style={{ visibility: "hidden" }}>
                    -----------
                  </font>
                  : {this.state.N_SUCURSAL}
                </div>
                </div>


            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-12">
                  <p className="text-light bg-dark text-center">Usuario : {this.state.alias} <font size="1" color="white" style={{ visibility: "hidden" }}>---</font>Sucursal : {this.state.COD_SUCURSAL_U}</p>
              </div>
        </form>
    );
}
}

  export default Anular_recibos;


import React, { Component } from "react";
import TimerMixin from "react-timer-mixin";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";


registerLocale("es", es);

class SobreSaldo extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarEmpresa = () => {
    const url = `${api}/empresa/get`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ EmpresaA: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  consultarCreditos= () => {
    const url = `${api}/sobresaldo/creditos/${this.state.COD_EMPRESA_B}/${this.state.COD_SUCURSAL_B}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ ClienteA: resultado }));
  };

  constructor() {
    super();
    this.state = {
      ALIAS: "",
      Tipo_usuario: "",
      COD_SUCURSAL: "",
      COD_REGION: "",
      COD_NIVEL: "",
      NOMBRE_SUCURSAL: "",
      COD_ROL_SUC: "",

      //////DATOS Y RESULTADO DE BUSQUEDA
      EmpresaA: [],
      COD_EMPRESA_B: "",
      SucursalA    : [], //GET ARRAY
      COD_SUCURSAL_B   : "", //POST 
      ClienteA: [],
      COD_CREDITO_B:"",

      /////CONSULTA DE CREDITO
      COD_EMPRESA_R: "",
      COD_SUCURSAL_R:"",
      COD_CREDITO: "",
      NOMBRE_SUCURSAL_R:"",
      NOMBRE1:"",
      FECHA_CREDITO:"",
      PLAZO_APROBADO:"",
      TIC_APROBADO:"",
      MONTO_TOTAL_APROBADO:"",
      fecha_1: new Date(), //GET

      BTN_APL: "true",

      PERMISO_ROL: "1034",
      VALIDACION: "hidden",

      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onChange_sucursal = this.onChange_sucursal.bind(this);
    this.CONSULTA_DATOS = this.CONSULTA_DATOS.bind(this);
    this.FECHA_FORM = this.FECHA_FORM.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onChange_sucursal(e) {
    this.setState({ [e.target.name]: e.target.value });
    TimerMixin.setTimeout(() => {
        this.consultarCreditos();
      }, 300);
  }

  FECHA_FORM = (fecha_1_1) => {
      this.setState({ fecha_1: fecha_1_1 });
  };

  BTN_APL_DESAC() {
    this.setState({ BTN_APL: "false" });
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        Tipo_usuario: res.data.Tipo_usuario,
        COD_REGION: res.data.COD_REGION,
        COD_NIVEL: res.data.COD_NIVEL,
        NOMBRE_SUCURSAL: res.data.COD_SUCURSAL,
        COD_EMPRESA_USUARIO: res.data.COD_EMPRESA_B,
        COD_EMPRESA_B: res.data.COD_EMPRESA_B,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
      });
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
      }, 300);
      TimerMixin.setTimeout(() => {
      this.consultarEmpresa();
      this.consultarSucursal();
      this.consultarCreditos();
    }, 300);
    });
  }

  //////BUSQUEDA DE DATOS DEL CREDITO
  CONSULTA_CREDITO = (newCliente) => {
    return axios
      .post(`${api}/sobresaldo/consulta`, {
        COD_EMPRESA: newCliente.COD_EMPRESA,
        COD_SUCURSAL: newCliente.COD_SUCURSAL,
        COD_CREDITO: newCliente.COD_CREDITO,
      })
      .then((response) => {
        this.setState({
            COD_EMPRESA_R: response.data.COD_EMPRESA_R,
            COD_SUCURSAL_R: response.data.COD_SUCURSAL_R,
            NOMBRE_SUCURSAL_R: response.data.NOMBRE_SUCURSAL_R,
            COD_CREDITO: response.data.COD_CREDITO,
            NOMBRE1: response.data.NOMBRE1,
            FECHA_CREDITO: response.data.FECHA_CREDITO,
            PLAZO_APROBADO: response.data.PLAZO_APROBADO,
            TIC_APROBADO: response.data.TIC_APROBADO,
            MONTO_TOTAL_APROBADO: response.data.MONTO_TOTAL_APROBADO,
        });
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {});
  };

  CONSULTA_DATOS(e) {
    e.preventDefault();
    TimerMixin.setTimeout(() => {
      const newCliente = {
        COD_EMPRESA: this.state.COD_EMPRESA_B,
        COD_SUCURSAL: this.state.COD_SUCURSAL_B,
        COD_CREDITO: this.state.COD_CREDITO_B,
      };
      this.CONSULTA_CREDITO(newCliente).then((res) => {});
    }, 100);
  }

  ////////////Aplicar plan de pago////


  registro = (newCliente) => {
    return axios
      .post(`${api}/sobresaldo/registro`, {
        COD_EMPRESA: newCliente.COD_EMPRESA,
        COD_SUCURSAL: newCliente.COD_SUCURSAL,
        COD_CREDITO: newCliente.COD_CREDITO,
        PLAZO_APROBADO: newCliente.PLAZO_APROBADO,
        TIC_APROBADO: newCliente.TIC_APROBADO,
        MONTO_TOTAL_APROBADO: newCliente.MONTO_TOTAL_APROBADO,
        FECHA_FORMALIZACION: newCliente.FECHA_FORMALIZACION,
        ADICIONADO_POR: newCliente.ADICIONADO_POR,
    })
    .then((response) => {
      Swal.fire(
        "Credito",
        response.data.mensaje,
        response.data.alerta
      );

      let tiempo = "300";
      TimerMixin.setTimeout(() => {
        if (response.data.alerta === "error") {
          this.setState({
            BTN_APL: "true",
          });
        } else {
          TimerMixin.setTimeout(() => {
            window.location.reload();
          }, 300);
    
        }
      }, tiempo);
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      Swal.fire(
        "Credito",
        "Por Favor Verifique Los Datos",
        "error"
      );
    });
};

  onSubmit(e) {

    if (this.state.COD_CREDITO === "") {
        e.preventDefault();
        Swal.fire(
          "Credito",
          "Por Favor Busque Los Datos Del Credito",
          "error"
        );
      } else if (this.state.COD_EMPRESA_R === "") {
        e.preventDefault();
        Swal.fire(
          "Credito",
          "Por Favor Verifique Los Datos(Empresa No Encontrada",
          "error"
        );
      }else if (this.state.COD_SUCURSAL_R === "") {
        e.preventDefault();
        Swal.fire(
          "Credito",
          "Por Favor Verifique Los Datos(Sucursal No Encontrada",
          "error"
        );
      }else if (this.state.PLAZO_APROBADO === "") {
        e.preventDefault();
        Swal.fire(
          "Credito",
          "Por Favor Verifique Los Datos(Plazo No Encontrado",
          "error"
        );
      }else if (this.state.TIC_APROBADO === "") {
        e.preventDefault();
        Swal.fire(
          "Credito",
          "Por Favor Verifique Los Datos(TIC No Encontrada",
          "error"
        );
      }else if (this.state.MONTO_TOTAL_APROBADO === "") {
        e.preventDefault();
        Swal.fire(
          "Credito",
          "Por Favor Verifique Los Datos(Monto No Encontrado",
          "error"
        );
      } else if (this.state.fecha_1 === "") {
        e.preventDefault();
        Swal.fire(
          "Credito",
          "Por Favor Verifique Los Datos(Fecha De Formalizacion No Encontrada",
          "error"
        );
      } else {
        e.preventDefault();
    Swal.fire({
        title: "Desea Aplicar El Desembolso",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

            this.BTN_APL_DESAC();
          TimerMixin.setTimeout(() => {
            let timerInterval;


            Swal.fire({
              title: "Credito",
              html: "El Credito Se Esta Aplicando",
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                try {
                  const b = Swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft();
                  }, 2000000);
                } catch (error) {}
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
              }
            });
            const newCliente = {
              COD_EMPRESA: this.state.COD_EMPRESA_R,
              COD_SUCURSAL: this.state.COD_SUCURSAL_R,
              COD_CREDITO: this.state.COD_CREDITO,
              PLAZO_APROBADO: this.state.PLAZO_APROBADO,
              TIC_APROBADO: this.state.TIC_APROBADO,
              MONTO_TOTAL_APROBADO: this.state.MONTO_TOTAL_APROBADO,
              FECHA_FORMALIZACION: this.state.fecha_1,
              ADICIONADO_POR: this.state.ALIAS,
            };

            this.registro(newCliente).then((res) => {});
          }, 100);
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
       }
    }

  render() {
    return (
      <form>
    
        <div style={{ visibility: this.state.VALIDACION }}>     <SisprestamosNavbar />
        <div className="container mt-5">
          <div className="row">
            <div className="shadow-lg mb-2  bg-white rounded  col-md-12 mx-auto border rounded border-dark">
              <h1 className="h3  font-weight-normal  text-center">
                PLAN DE PAGO SOBRE SALDO
              </h1>

              <div className="container">
                <div className="row">

                  <div className="col-md-3">
                    <div style={{ textAlign: "left" }} htmlFor="COD_EMPRESA_B">
                      EMPRESA
                    </div>
                    <select
                      name="COD_EMPRESA_B"
                      className="form-control"
                      defaultValue={this.state.COD_EMPRESA_B}
                      onChange={this.onChange}
                    >
                    
                      {this.state.EmpresaA.map((item) => (
                        <option
                          key={item.NOMBRE_EMPRESA}
                          value={item.COD_EMPRESA}
                        >
                          {item.NOMBRE_EMPRESA}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-3">
                  <div style={{ textAlign: "left" }} htmlFor="COD_SUCURSAL_B">
                      SUCURSAL
                    </div>
                  <select
                    name="COD_SUCURSAL_B"
                    className="form-control"
                    defaultValue={this.state.COD_SUCURSAL_B}
                    onChange={this.onChange_sucursal}
                    placeholder="Campo obligatorio"
                   
                   
                  >
                  <option></option>
                    {this.state.SucursalA.map((item) => (
                      <option
                        key={item.NOMBRE_SUCURSAL}
                        value={item.COD_SUCURSAL}
                      >
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}

                  </select>
                </div>

                <div className="col-md-4">
                <div style={{ textAlign: "left" }} htmlFor="COD_CREDITO_B">
                  NO.CREDITO/CLIENTE
                </div>
                <input
                  list="data"
                  name="COD_CREDITO_B"
                  className="form-control "
                  defaultValue={this.state.COD_CREDITO_B}
                  onChange={this.onChange}
                  autoComplete="off"
                />
                <datalist id="data">
                  <option></option>
                  {this.state.ClienteA.map((item) => (
                    <option key={item.NOMBRE1} value={item.COD_CREDITO}>
                      {item.NOMBRE1}
                    </option>
                  ))}
                </datalist>
              </div>
              <div className="col-md-2 p-3">

<button type="button" className="btn btn-secondary btn-lg col-md-12" 
onClick={this.CONSULTA_DATOS}
>BUSCAR</button>
  
</div>

                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container">
                <div className="row">
                <div className="shadow-lg mb-5 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
         
                <div className="container p-2">
                <div className="row">

                  <div className="col-md-2">
                  <div style={{ textAlign: "left" }} htmlFor="COD_CREDITO">
                NO.CREDITO
                </div>
                <input
                  type="COD_CREDITO"
                  className="form-control text-right"
                  name="COD_CREDITO"
                  value={this.state.COD_CREDITO}
                  onChange={this.onChange}
                  disabled="disabled"
                />
                    
                  </div>

                  <div className="col-md-2">
                  <div style={{ textAlign: "left" }} htmlFor="NOMBRE_SUCURSAL_R">
                SUCURSAL
                </div>
                <input
                  type="NOMBRE_SUCURSAL_R"
                  className="form-control text-left"
                  name="NOMBRE_SUCURSAL_R"
                  value={this.state.NOMBRE_SUCURSAL_R}
                  onChange={this.onChange}
                  disabled="disabled"
                />
                    
                  </div>

                  <div className="col-md-5">
                  <div style={{ textAlign: "left" }} htmlFor="NOMBRE1">
              CLIENTE
                </div>
                <input
                  type="NOMBRE1"
                  className="form-control text-left"
                  name="NOMBRE1"
                  value={this.state.NOMBRE1}
                  onChange={this.onChange}
                  disabled="disabled"
                />
                
                </div>

                <div className="col-md-3">

                <div style={{ textAlign: "left" }} htmlFor="FECHA_CREDITO">
                FECHA DE APROBACION
                </div>
                <input
                  type="FECHA_CREDITO"
                  className="form-control text-center"
                  name="FECHA_CREDITO"
                  value={this.state.FECHA_CREDITO}
                  onChange={this.onChange}
                  disabled="disabled"
                />
               
              </div>
            

                </div>
              </div>

              <div className="container p-2">
                <div className="row">

                <div className="col-md-3">
                    <div style={{ textAlign: "left" }} htmlFor="fecha_1">
                     FECHA DE FORMALIZACION
                    </div>
                    <DatePicker
                      selected={this.state.fecha_1}
                      onChange={this.FECHA_FORM}
                      locale="es"
                      className="form-control text-center"
                      dateFormat="dd '/' M '/' yyyy"
                    />
                  </div>

                  <div className="col-md-3">
                  <div style={{ textAlign: "left" }} htmlFor="PLAZO_APROBADO">
                PLAZO/MESES
                </div>
                <input
                  type="PLAZO_APROBADO"
                  className="form-control text-right"
                  name="PLAZO_APROBADO"
                  value={this.state.PLAZO_APROBADO}
                  onChange={this.onChange}
                  disabled="disabled"
                />
                    
                  </div>

                  <div className="col-md-3">
                  <div style={{ textAlign: "left" }} htmlFor="TIC_APROBADO">
            TASA DE INTERES
                </div>
                <input
                  type="TIC_APROBADO"
                  className="form-control text-right"
                  name="TIC_APROBADO"
                  value={this.state.TIC_APROBADO}
                  onChange={this.onChange}
                  disabled="disabled"
                />
                    
                  </div>

                  <div className="col-md-3">
                  <div style={{ textAlign: "left" }} htmlFor="MONTO_TOTAL_APROBADO">
              MONTO APROBADO
                </div>
                <input
                  type="MONTO_TOTAL_APROBADO"
                  className="form-control text-right"
                  name="MONTO_TOTAL_APROBADO"
                  value={this.state.MONTO_TOTAL_APROBADO}
                  onChange={this.onChange}
                  disabled="disabled"
                />
                
                </div>

               
           

                </div>
              </div>

              <div className="container p-2">
                <div className="row">
                <div className="col-md-4 ">
                </div>
     <div className="col-md-4">

<button type="button" className="btn btn-success btn-lg col-md-12" 
onClick={this.onSubmit}
disabled={this.state.BTN_APL === "false" ? true : false}
>APLICAR</button>
  
</div>
                </div>
                </div>

                </div>
                </div>
          </div>
          </div>
      </form>
    );
  }
}

export default SobreSaldo;

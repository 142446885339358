import React, { Component } from "react";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import * as jsPDF from "jspdf";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import { PermisoModulo, PermisoFA, Empresa, Sucursal, DatosEmpresa } from "../../GlobalFuntions"; // Usuarios
import { RecibosCaja, ImpresionRecibosCaja } from "../../FuncionesCaja"; // Usuarios

import SisprestamosNavbar from "../../SisprestamosNavbar";

registerLocale("es", es);

class Recibos_dia extends Component {
  constructor() {
    super();
    this.state = {
      Id: "",
      ALIAS: "",
      NombreUsuario: "",
      Tipo_usuario: "",

      IMAGEN: "",
      width: "",
      height: "",
      REPORT_WIDTH: "",
      REPORT_HEIGHT: "",

      DatoEmpresa: [],
      COD_EMPRESA_B: "",
      DatoSucursal: [],
      COD_SUCURSAL_B: "",

      Fecha: "",

      DataRecibo: [],
      NO_RECIBO: "",

      RecNoRecibo: "",
      RecEmpresa: "",
      RecSucursal: "",
      RecFecha: "",
      RecHora: "",
      RecNombre: "",
      RecCredito: "",
      RecConcepto: "",
      RecDiasMora: "",
      RecFechaVencimiento: "",
      RecCordobas: "",
      RecDolares: "",
      RecAbono: "",
      RecMora: "",
      RecTotal: "",
      RecSaldoInicial: "",
      RecSaldoFinal: "",
      RecCajero: "",
      RecFechaEmision: "",
      RecTipoCambio: "",

      columnDefs: [
        {
          headerName: "No.Recibo",
          field: "NO_RECIBO",
          sortable: true,
          filter: true,
          editable: false,
          width: 150,
        },
         {
          headerName: "No.Credito",
          field: "CO_CREDITO",
          sortable: true,
          filter: true,
          editable: false,
          width: 150,
        },
        {
          headerName: "Cliente",
          field: "NOMBRE1",
          sortable: true,
          filter: true,
          editable: false,
          width: 330,
        },
         {
          headerName: "Cordobas",
          field: "MONTO_CORDOBA",
          sortable: true,
          filter: true,
          editable: false,
          width: 150,
        },
        {
          headerName: "Dolares",
          field: "MONTO_DOLAR",
          sortable: true,
          filter: true,
          editable: false,
          width: 150,
        },
        {
          headerName: "Total",
          field: "TOTAL_RECIBIDO",
          sortable: true,
          filter: true,
          editable: false,
          width: 150,
        },
        {
          headerName: "Cajero",
          field: "ADICIONADO_POR",
          sortable: true,
          filter: true,
          editable: false,
          width: 200,
        },
        // {
        //   headerName: "Sucursal",
        //   field: "FECHA_MODIFICADO",
        //   sortable: true,
        //   filter: true,
        //   editable: false,
        //   width: 200,
        // },
       
      ],

      ValidacionFA: false,
      PERMISO_ROL: "1011",
      VALIDACION: "hidden",
      BTN_APL: true,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.OnChangeDataRecibo = this.OnChangeDataRecibo.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.ImpresionPDF = this.ImpresionPDF.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      console.log(new Date(res.data.FECHA_SERVER))
      this.setState({
        Id: res.data.COD_USUARIO,
        ALIAS: res.data.Alias,
        NombreUsuario: res.data.Nombre,
        Tipo_usuario: res.data.Tipo_usuario,
        // Fecha: new Date(res.data.FECHA_SERVER),
      });
      let tiempo = 300;
      TimerMixin.setTimeout(() => {
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Empresa().then((res) => {
          this.setState({
            DatoEmpresa: res.data.EMPRESA,
            COD_EMPRESA_B: res.data.EMPRESA[0].COD_EMPRESA,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Sucursal(res.data.COD_ROL_SUC).then((res) => {
          this.setState({
            DatoSucursal: res.data.SUCURSAL,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        PermisoModulo(res.data.ID_ROL, this.state.PERMISO_ROL).then((res) => {
          this.setState({
            VALIDACION: res.data.ESTADO,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        if (res.data.FECHA_ATRAS === true) {
          PermisoFA(res.data.CodPermFA, this.state.PERMISO_ROL).then((res) => {
            this.setState({
              ValidacionFA: res.data.ESTADO,
            });
          });
        }
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        TimerMixin.setTimeout(() => {
          // if (this.state.COD_EMPRESA_B !== "" && this.state.IMAGEN === "") {
          ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
          DatosEmpresa(this.state.COD_EMPRESA_B).then((res) => {
            this.setState({
              IMAGEN: res.data.IMAGEN,
              width: res.data.width,
              height: res.data.height,
              REPORT_WIDTH: res.data.REPORT_WIDTH,
              REPORT_HEIGHT: res.data.REPORT_HEIGHT,
            });
          });
          // }
        }, 500);
      }, tiempo);
    });
  }

  OnChangeDataRecibo = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.BusquedaDataRecibo();
  };

  OnChangeFecha = (Fecha) => {
    this.setState({ Fecha: Fecha });
    this.BusquedaDataRecibo();
  };

  BusquedaDataRecibo() {
    this.setState({ DataRecibo: [] });

    TimerMixin.setTimeout(() => {
      if (this.state.COD_EMPRESA_B !== "" && this.state.COD_SUCURSAL_B !== "") {
        let FechaData = this.state.Fecha;
        if (this.state.Fecha === "") {
          FechaData = 0;
        }
        const DataCajero = {
          cajero: this.state.Id,
          Empresa: this.state.COD_EMPRESA_B,
          Sucursal: this.state.COD_SUCURSAL_B,
          tpusuario: this.state.Tipo_usuario,
          CAJERO: this.state.ALIAS,
          FECHA: FechaData,
          ValidacionFecha: this.state.ValidacionFA,
        };
        RecibosCaja(DataCajero).then((res) => {
          this.setState({
            DataRecibo: res.data.DATA,
          });
        });
      }
    }, 600);
  }
  obtenerDatos = () => {
    this.Limpiar();
    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Impresion de Recibo",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    } else if (this.state.COD_SUCURSAL_B === "") {
      Swal.fire(
        "Impresion de Recibo",
        "Por Favor Seleccione una Sucursal",
        "error"
      );
    } else if (this.state.NO_RECIBO === "") {
      Swal.fire(
        "Impresion de Recibo",
        "Por Favor Seleccione una Sucursal",
        "error"
      );
    } else {
      let FechaData = this.state.Fecha;
      if (this.state.Fecha === "") {
        FechaData = 0;
      }
      const DataRecibo = {
        NO_RECIBO: this.state.NO_RECIBO,
        cajero: this.state.Id,
        Empresa: this.state.COD_EMPRESA_B,
        Sucursal: this.state.COD_SUCURSAL_B,
        tpusuario: this.state.Tipo_usuario,
        CAJERO: this.state.ALIAS,
        FECHA: FechaData,
        ValidacionFecha: this.state.ValidacionFA,
      };
      ImpresionRecibosCaja(DataRecibo).then((res) => {
        this.setState({
          RecNoRecibo: res.data.RecNoRecibo,
          RecEmpresa: res.data.RecEmpresa,
          RecSucursal: res.data.RecSucursal,
          RecFecha: res.data.RecFecha,
          RecHora: res.data.RecHora,
          RecNombre: res.data.RecNombre,
          RecCredito: res.data.RecCredito,
          RecConcepto: res.data.RecConcepto,
          RecDiasMora: res.data.RecDiasMora,
          RecFechaVencimiento: res.data.RecFechaVencimiento,
          RecCordobas: res.data.RecCordobas,
          RecDolares: res.data.RecDolares,
          RecAbono: res.data.RecAbono,
          RecMora: res.data.RecMora,
          RecTotal: res.data.RecTotal,
          RecSaldoInicial: res.data.RecSaldoInicial,
          RecSaldoFinal: res.data.RecSaldoFinal,
          RecCajero: res.data.RecCajero,
          RecFechaEmision: res.data.RecFechaEmision,
          RecTipoCambio: res.data.RecTipoCambio,
        });
      });
    }
  };

  ImpresionPDF(e) {
    TimerMixin.setTimeout(() => {
    const RecNoRecibo = String(this.state.RecNoRecibo);
    const RecEmpresa = String(this.state.RecEmpresa);
    const RecSucursal = String(this.state.RecSucursal);
    const RecFecha = String(this.state.RecFecha);
    const RecNombre = String(this.state.RecNombre);
    const RecCredito = String(this.state.RecCredito);
    const RecConcepto = String(this.state.RecConcepto);
    const RecDiasMora = String(this.state.RecDiasMora);
    const RecFechaVencimiento = String(this.state.RecFechaVencimiento);
    const RecCordobas = String(this.state.RecCordobas);
    const RecDolares = String(this.state.RecDolares);
    const RecAbono = String(this.state.RecAbono);
    const RecMora = String(this.state.RecMora);
    const RecTotal = String(this.state.RecTotal);
    const RecSaldoInicial = String(this.state.RecSaldoInicial);
    const RecSaldoFinal = String(this.state.RecSaldoFinal);
    const RecCajero = String(this.state.RecCajero);
    const RecFechaEmision = String(this.state.RecFechaEmision);
    const RecTipoCambio = String(this.state.RecTipoCambio);

    let doc = new jsPDF("p", "mm", [220, 180]);
    doc.setFont("courier");
    doc.setFontSize(6);
    doc.text(20, 6, RecEmpresa);
    doc.text(20, 9, RecSucursal);
    doc.text(2, 13, "NO.RECIBO           :");
    doc.text(30, 13, RecNoRecibo);
    doc.text(2, 16, "FECHA Y HORA        :");
    doc.text(30, 16, RecFecha);
    doc.text(2, 19, "NOMBRE              :");
    doc.text(2, 22, RecNombre);
    doc.text(2, 25, "NO.CREDITO          :");
    doc.text(30, 25, RecCredito);

    let y = parseInt(25);

    y = parseInt(y) + 3;
    doc.text(2, y, "CONCEPTO            :");
    y = parseInt(y) + 3;
    doc.text(2, y, RecConcepto);

    y = parseInt(y) + 3;
    doc.text(2, y, "DIAS EN MORA        :");
    doc.text(30, y, RecDiasMora);

    y = parseInt(y) + 3;
    doc.text(2, y, "FECHA VENCIMIENTO   :");
    doc.text(30, y, String(RecFechaVencimiento));

    y = parseInt(y) + 3;
    doc.text(2, y, "MONTO CORDOBAS      :");
    doc.text(30, y, String(RecCordobas));

    y = parseInt(y) + 3;
    doc.text(2, y, "MONTO DOLARES       :");
    doc.text(30, y, String(RecDolares));

    y = parseInt(y) + 3;
    doc.text(2, y, "ABONO AL PRESTAMO   :");
    doc.text(30, y, String(RecAbono));

    // // y=(parseInt(y) + 3 );
    // // doc.text(3,y,'ABONO A PRESTAMO :');

    y = parseInt(y) + 3;
    doc.text(2, y, "INTERES MORATORIO   :");
    doc.text(30, y, String(RecMora));

    y = parseInt(y) + 3;
    doc.text(2, y, "TOTAL PAGADO        :");
    doc.text(30, y, String(RecTotal));

    y = parseInt(y) + 3;
    doc.text(2, y, "SALDO ANTERIOR      :");
    doc.text(30, y, String(RecSaldoInicial));

    y = parseInt(y) + 3;
    doc.text(2, y, "SALDO ACTUAL        :");
    doc.text(30, y, String(RecSaldoFinal));

    y = parseInt(y) + 3;
    doc.text(2, y, "TIPO DE CAMBIO      :");
    doc.text(30, y, String(RecTipoCambio));

    y = parseInt(y) + 3;
    doc.text(2, y, "CAJERO              :");
    doc.text(30, y, String(RecCajero));

    y = parseInt(y) + 3;
    doc.text(2, y, "FECHA EMICION       :");
    doc.text(30, y, String(RecFechaEmision));

    let NameRec = `RECIBO DE CAJA NO ${RecNoRecibo}.pdf`;

    doc.save(NameRec);
  }, 600);
  }

  Limpiar () {
    this.setState({
      RecNoRecibo: "",
      RecEmpresa: "",
      RecSucursal: "",
      RecFecha: "",
      RecHora: "",
      RecNombre: "",
      RecCredito: "",
      RecConcepto: "",
      RecDiasMora: "",
      RecFechaVencimiento: "",
      RecCordobas: "",
      RecDolares: "",
      RecAbono: "",
      RecMora: "",
      RecTotal: "",
      RecSaldoInicial: "",
      RecSaldoFinal: "",
      RecCajero: "",
      RecFechaEmision: "",
      RecTipoCambio: "",
    });
  }

  render() {
    return (
      <form>
        <div style={{ visibility: this.state.VALIDACION }}>
          {" "}
          <SisprestamosNavbar />
          {/*  */}
          <div className="container mt-5">
            <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
              <div className="row">
                <div className="col-md-3">
                  <h5 className="labelb"> EMPRESA </h5>

                  <select
                    name="COD_EMPRESA_B"
                    className="form-control text-center"
                    value={this.state.COD_EMPRESA_B}
                    onChange={this.OnChangeDataRecibo}
                  >
                    <option></option>
                    {this.state.DatoEmpresa.map((item) => (
                      <option key={item.COD_EMPRESA} value={item.COD_EMPRESA}>
                        {item.NOMBRE_EMPRESA}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <h5 className="labelb">SUCURSAL</h5>
                  <select
                    name="COD_SUCURSAL_B"
                    className="form-control text-center"
                    value={this.state.COD_SUCURSAL_B}
                    onChange={this.OnChangeDataRecibo}
                  >
                    <option></option>
                    {this.state.DatoSucursal.map((item) => (
                      <option key={item.COD_SUCURSAL} value={item.COD_SUCURSAL}>
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <h5 className="labelb">FECHA</h5>
                  <DatePicker
                    type="date"
                    dateFormat="dd '/' M '/' yyyy"
                    selected={this.state.Fecha}
                    onChange={this.OnChangeFecha}
                    locale="es"
                    className="form-control text-center"
                    disabled={this.state.ValidacionFA === false ? true : false}
                  />
                </div>
                <div className="col-md-3">
                  <h5 className="labelb">No.RECIBO</h5>
                  <input
                    list="data"
                    name="NO_RECIBO"
                    className="form-control text-center"
                    value={this.state.NO_RECIBO}
                    onChange={this.onChange}
                    autoComplete="off"
                  />
                  <datalist id="data">
                    <option></option>
                    {this.state.DataRecibo.map((item) => (
                      <option key={item.NO_RECIBO} value={item.NO_RECIBO}>
                        {item.NOMBRE1}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              {/*  */}
              <div className="container ">
                <div className="row">
                  <div className="col-md-2"> </div>
                  <div className="col-md-4 p-1">
                    <button
                      type="button"
                      onClick={this.obtenerDatos}
                      className="btn btn-outline-dark btn-lg  col-md-12 "
                    >
                      BUSCAR
                    </button>
                  </div>
                  <div className="col-md-4 p-1">
                    <button
                      type="button"
                      onClick={this.ImpresionPDF}
                      className="btn btn-outline-danger btn-lg  col-md-12 "
                    >
                      IMPRIMIR
                    </button>
                  </div>
                </div>
              </div>
              {/*  */}
            </div>
          </div>
          {/*  */}
          <div className="container ">
            <div className="row">
              <div className="shadow-lg bg-white rounded  col-md-12 mx-auto border rounded border-dark p-3">
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <img
                        src={this.state.IMAGEN}
                        alt="logo"
                        className="rounded"
                        style={{
                          width: this.state.width,
                          height: this.state.height,
                          float: "left",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 table-responsive">
                      <h3 className="text-center encabezado p-2">
                        RECIBO DE INGRESO
                      </h3>
                      {/*  */}
                      <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-0 bg-light text-dark border rounded border-dark">
                   {/*  */}
                   <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-3">
                        No.RECIBO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecNoRecibo}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-3">
                      No.CREDITO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecCredito}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-6">
                       FECHA Y HORA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecFecha}
                      </div>
                    </div>
                    {/*  */}
                   <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-12">
                      NOMBRE{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecNombre}
                      </div>
                    </div>
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-12">
                      CONCEPTO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecConcepto}
                      </div>
                    </div>
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-5">
                      DIAS EN MORA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecDiasMora}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-5">
                      FECHA VENCIMIENTO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecFechaVencimiento}
                      </div>
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-2">
                   </div>
                    
                    </div>
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                      MONTO CORDOBAS{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecCordobas}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                      MONTO DOLARES{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecDolares}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                      ABONO AL PRESTAMO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecAbono}
                      </div>
                    </div>
                    {/*  */}
                    <div className="form-row">
                   
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                      INTERES MORATORIO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecMora}
                      </div>
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                      TOTAL PAGADO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecTotal}
                      </div>
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                      TIPO DE CAMBIO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.RecTipoCambio}
                      </div>
                    </div>
                    {/*  */}
                    <div className="form-row">
                   
                   <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-5">
                   SALDO ANTERIOR{" "}
                     <font
                       size="1"
                       color="white"
                       style={{ visibility: "hidden" }}
                     >
                       -
                     </font>
                     : {this.state.RecSaldoInicial}
                   </div>
                   <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-5">
                   SALDO ACTUAL{" "}
                     <font
                       size="1"
                       color="white"
                       style={{ visibility: "hidden" }}
                     >
                       -
                     </font>
                     : {this.state.RecSaldoFinal}
                   </div>
                   <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-2">
                   </div>
                 </div>
                 {/*  */}
                 <div className="form-row">
                   
                   <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-5">
                   CAJERO{" "}
                     <font
                       size="1"
                       color="white"
                       style={{ visibility: "hidden" }}
                     >
                       -
                     </font>
                     : {this.state.RecCajero}
                   </div>
                   <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-5">
                   FECHA EMICION{" "}
                     <font
                       size="1"
                       color="white"
                       style={{ visibility: "hidden" }}
                     >
                       -
                     </font>
                     : {this.state.RecFechaEmision}
                   </div>
                   <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-2">
                   </div>
                 </div>
                 {/*  */}
                      </div>
                      {/*  */}
                      <div className="container">
          <div className="row">
            <div className="col-md-12  mx-auto">
              <div className="container jumbotrom   bg-light text-dark ">
                <div className="d-flex flex-column  bd-highlight jumbotrom  bg-light text-dark border rounded border-dark">
                  <div className="form-row">
                    <div
                      className="ag-theme-balham col-md-12"
                      style={{
                        width: 400,
                        height: 500,
                      }}
                    >
                       <AgGridReact 
                        columnDefs={this.state.columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        rowData={this.state.DataRecibo}
                        rowSelection="multiple"
                        onRowSelected={this.getSelectedRowData}
                      />
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
                         {/*  */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </form>
    );
  }
}
export default Recibos_dia;

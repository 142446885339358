import React, { Component } from "react";
import axios from "axios";
import * as jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ReactToExcel from "react-html-table-to-excel";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import TimerMixin from "react-timer-mixin";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import api from '../../../api/axiosConfigRoute';
import SisprestamosNavbar      from "../../SisprestamosNavbar";


registerLocale("es", es);

// import ExportExcel from 'react-export-excel';
// import * as $ from "jquery";
// const ExcelFile = ExportExcel.ExcelFile;
// const ExcelSheet = ExportExcel.ExcelSheet;
// const Excelcolumn = ExportExcel.Excelcolumn;
class IngrsoFinanciero extends Component {

  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };


  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  // Post consulta creditos activos
Ingresofinanciero = (registro) => {
return axios
.post(`${api}/ingreso/ingresof/${this.state.Tipo_usuario}/${this.state.SUCURSAL_C}/${this.state.SUCURSAL_C}`, {
fecha_1: registro.fecha_1,
fecha_2: registro.fecha_2,
})
.then((response) => {
        // console.log(
        //   "Consulta ingreso financiero",
        //   response.data
        // );

this.setState({
Creditos              : response.data.ConsultaCreditosJson,
T_INTERES_PAGADO      : response.data.T_INTERES_PAGADO,
T_PRINCIPAL_PAGADO    : response.data.T_PRINCIPAL_PAGADO,
T_EXTRA_PAGADO        : response.data.T_EXTRA_PAGADO,
T_OTROS_CARGOS_PAGADO : response.data.T_OTROS_CARGOS_PAGADO,
T_TOTAL_PAGADO        : response.data.T_TOTAL_PAGADO,
});
        // window.location.reload();
})
.then((res) => {
return res;
})
.catch((err) => {
console.log("el error es ", err);
window.alert("Verifique las fechas", err);
window.location.reload();
});
};

  //********************************************************************************************/

constructor() {
super();
this.state = {
fecha_1      : "",  //POST
fecha_2      : "", //POST
COD_ROL_SUC: "",

Creditos              : [],       //GET ARRAY
Creditos_A            : [],      //GET ARRAY

T_INTERES_PAGADO      : "",     //GET
T_PRINCIPAL_PAGADO    : "",    //GET
T_EXTRA_PAGADO        : "",   //GET
T_OTROS_CARGOS_PAGADO : "",  //GET
T_TOTAL_PAGADO        : "", //GET

SucursalA    : [], //GET ARRAY
A_SUCURSAL   : "", //POST 
SUCURSAL_C   : "",//POST 

Tipo_usuario : "", //RESTRICCION
sucursal     : "",//RESTRICCION

IP           : [],   //GET
alias        : "",  //GET
fecha        : new Date(),//GET

ID_ROL: "",
PERMISO_ROL: "1025",
VALIDACION: "hidden",
errors       : {},
};
this.onChange = this.onChange.bind(this);
this.onSubmit = this.onSubmit.bind(this);
}

onChange(e) {
this.setState({ [e.target.name]: e.target.value });
}

onSubmit(e) {

e.preventDefault();
const registro = {
fecha_1: this.state.fecha_1,
fecha_2: this.state.fecha_2,
};

this.Ingresofinanciero(registro).then((res) => {
// this.props.history.push(`/login`)
});


}

componentDidMount() {
const token = localStorage.usertoken;
const decoded = jwt_decode(token);
getUser(decoded.uid).then((res) => {
this.setState({
alias: res.data.Alias,
IP: res.data.IP,
Tipo_usuario   : res.data.Tipo_usuario,
COD_ROL_SUC: res.data.COD_ROL_SUC,
ID_ROL: res.data.ID_ROL,
});   

TimerMixin.setTimeout(() => {
this.CONSULTA_PERMISO(); 
}, 300);    

// console.log(res);
});
}

jsPdfGenerator = (e) => {
e.preventDefault();
const doc = new jsPDF("l", "pt", "letter");

// var doc = new jsPDF("p", "mm", [216 , 356 ]);

autoTable(doc, { html: "#body" });

// doc.fromHTML($("#body").get(0), 0, -33);

doc.save("reporte.pdf");
// window.location.reload();
};
onChange2 = (fecha) => {
this.setState({ fecha_1: fecha });
};
onChange3 = (fecha1) => {
this.setState({ fecha_2: fecha1 });
};

render() {
    return (
      <form style={{ visibility: this.state.VALIDACION }}><SisprestamosNavbar />
        <div className="container mt-5">
          <div className="row">
            <div className="shadow-lg p-3 mb-5 bg-white rounded  col-md-8 mx-auto border rounded border-dark" style={{ width: "200px;" }}>
              <h1 className="h3 mb-4 font-weight-normal  text-center">
                Reporte de ingreso financiero
              </h1>

              <div className="form-row">

              <div className="form-group text-center col-md-4">
                  <label htmlFor="SucursalA">Sucursal</label>
                  <select
                    name="SUCURSAL_C"
                    className="form-control"
                    value={this.state.SUCURSAL_C}
                    onChange={this.onChange}
                    placeholder="Campo obligatorio"
                    onClick={this.consultarSucursal()}
                   
                  >
                    <option>Todas</option>
                    {this.state.SucursalA.map((item) => (
                      <option
                        key={item.NOMBRE_SUCURSAL}
                        value={item.COD_SUCURSAL}
                      >
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}

                  </select>
                </div>

                <div className="form-group text-center col-md-4 mx-auto" style={{ width: "200px;" }}>
                  <label htmlFor="Fecha">Fecha : </label>

                  <DatePicker
                    selected={this.state.fecha_1}
                    onChange={this.onChange2}
                    locale="es"
                    className="form-control"
                    dateFormat="dd '/' M '/' yyyy"
                  />
                </div>

                <div className="form-group text-center col-md-4 mx-auto" style={{ width: "200px;" }}>
                  <label htmlFor="Fecha">Fecha : </label>

                  <DatePicker
                    selected={this.state.fecha_2}
                    onChange={this.onChange3}
                    locale="es"
                    className="form-control"
                    dateFormat="dd '/' M '/' yyyy"
                  />
                </div>
              </div>


              <div className="d-flex justify-content-center">
                <button
                  onClick={this.onSubmit}
                  className="btn btn-lg btn-primary btn-block col-md-4"
                >
                  Buscar
              </button>
              </div>
              <br></br>

              <div align="center">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    onClick={this.jsPdfGenerator}
                    className="btn btn-danger"
                  >
                    Generar pdf
                  </button>
                  <ReactToExcel
                    className="btn btn-success"
                    table="body"
                    filename="ecxelfile"
                    sheet="sheet 1"
                    buttonText="Generar excel"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div align="center">
          <ExcelFile element= {<button>Exportar a excel</button>} filename="Ingresos financieros"> 
          <ExcelSheet data= {this.state.Creditos} name="Creditos"/>
          <Excelcolumn label="No.credito" value="COD_CREDITO"/>
          <Excelcolumn label="Fecha" value="FECHA_PAGO"/>
          <Excelcolumn label="No.Cliente" value="COD_CLIENTE"/>
          <Excelcolumn label="Analista" value="ANALISTA"/>
          <Excelcolumn label="Cliente" value="NOMBRE_CLIENTE"/>
          <Excelcolumn label="Interes pagado" value="INTERES_PAGADOS"/>
          <Excelcolumn label="Principal pagado" value="PRINCIPAL_PAGADO"/>
          <Excelcolumn label="Extra" value="EXTRA"/>
          <Excelcolumn label="Otros cargos" value="OTROS_CARGOS"/>
          <Excelcolumn label="Total pagado" value="TOTAL_PAGADO"/>
          <Excelcolumn label="Tipo movimiento" value="TIPO_MOVIMIENTO"/>
          
            
          </ExcelFile>
           
      
        </div> */}
        <div
          style={{
            //  display: "flex",
            //  justifyContent: "flex-end",
            marginTop: "5px",
            marginRight: "10px",
            color: "black",
            //  width: "110%",
            // height: "50px"
          }}
        >
          <div className="table-responsive">
            <table className="table table-striped " id="body">
              <thead className="text-center thead-dark">
                <tr>
                  <th>No.Credito</th>
                  <th>No.Recibo</th>
                  <th>Sucursal</th>
                  <th>Fecha</th>
                  <th>No.Cliente</th>
                  <th>Analista</th>
                  <th>Cliente</th>
                  <th>Interes pagado</th>
                  <th>Principal pagado</th>
                  <th>Extra</th>
                  <th>Otros cargos</th>
                  <th>Total pagado</th>
                  <th>Tipo de movimiento</th>
                </tr>
              </thead>
              <tbody>
                {this.state.Creditos.map((item) => {
                  return (
                    <tr>
                      <td>{item.COD_CREDITO}</td>
                      <td>{item.NO_RECIBO}</td>
                      <td>{item.NOMBRE_SUCURSAL}</td>
                      <td style={{ width: "10%" }}>{item.FECHA_PAGO}</td>
                      <td>{item.COD_CLIENTE}</td>
                      <td>{item.ANALISTA}</td>
                      <td>{item.NOMBRE_CLIENTE}</td>
                      <td>{item.INTERES_PAGADOS}</td>
                      <td>{item.PRINCIPAL_PAGADO}</td>
                      <td>{item.EXTRA}</td>
                      <td>{item.OTROS_CARGOS}</td>
                      <td className=" text-rigth">{item.TOTAL_PAGADO}</td>
                      <td className=" text-center">{item.TIPO_MOVIMIENTO}</td>
                    </tr>
                  );
                })}
                <tr>
                     <td colspan="6"></td>
                     <td>TOTAL</td>
                     <td className=" text-center">{this.state.T_INTERES_PAGADO}</td>
                     <td className=" text-center">{this.state.T_PRINCIPAL_PAGADO}</td>
                     <td className=" text-center">{this.state.T_EXTRA_PAGADO}</td>
                     <td className=" text-center">{this.state.T_OTROS_CARGOS_PAGADO}</td>
                     <td className=" text-center">{this.state.T_TOTAL_PAGADO}</td>
                   </tr>
              </tbody>
            </table>
          </div>
        </div>
      </form>
    );
  }
}

export default IngrsoFinanciero;


import axios from "axios";
import Swal from "sweetalert2";
import TimerMixin from "react-timer-mixin";
import api from '../../src/api/axiosConfigRoute';


export const login = (user) => {
  return axios
    .post(`${api}/usuario/login`, {
      alias: user.alias,
      password: user.password,
    })
    .then((res) => {
      if (res.data.token !== undefined){ 
        localStorage.setItem("usertoken", res.data.token);
        return res.data;
      }else{
        Swal.fire(
          "Inicio de sesion",
         "Por favor verifique los datos",
          "error"
        );
      }

    })
    .catch((err) => {
    });
};
export const getUser = (Id_usuario) => {
  return axios
    .get(
      `${api}/usuario/getuser/${Id_usuario}`
    )
    .then((res) => {
      let tiempo = "28800000";
     
      TimerMixin.setTimeout(() => {
        localStorage.removeItem("usertoken");
        window.location.href = `${api}/#/login`
      }, tiempo);
      return res;
    })
    .catch((err) => {
      return err;
    });
};

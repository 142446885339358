import React, { Component } from "react";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import * as jsPDF from "jspdf";
import { geolocated } from "react-geolocated";

import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import { PermisoModulo } from "../../GlobalFuntions"; // Usuarios
import { PermisoFA } from "../../GlobalFuntions"; // Usuarios
import { PermisoTipoIngreso } from "../../GlobalFuntions"; // Usuarios
import { Empresa } from "../../GlobalFuntions"; // Usuarios
import { Sucursal } from "../../GlobalFuntions"; // Usuarios
import { CreditoCajaIngreso } from "../../GlobalFuntions"; // Usuarios

import { DatosCuota } from "../../CreditoFuntions"; // Usuarios
import { PagoCredito } from "../../CreditoFuntions"; // Usuarios

import SisprestamosNavbar from "../../SisprestamosNavbar";

registerLocale("es", es);

class CajaIngresos extends Component {
  constructor() {
    super();
    this.state = {
      Id: "",
      ALIAS: "",
      NombreUsuario: "",

      DatoEmpresa: [],
      COD_EMPRESA_B: "",
      DatoSucursal: [],
      COD_SUCURSAL_B: "",

      Creditos: [],
      CLIENTE: "",

      EstadoCuota: "",
      EmpresaCredito: "",
      SucursalCredito: "",
      CodCreditoCuota: "",
      FechaCuota: "",
      NombreCliente: "",
      Direccion: "",
      DireccionNegocio: "",
      NoCuota: "",
      MontoTipCambio: "",
      ValorTipoCambio: "",
      ValorCuota: "",
      DiasMora: "",
      TotalMora: "",
      InteresMora: "",
      TotalInteresMora: "",
      SaldoFinal: "",
      SaldoMora:"",

      Concepto: "Abono al credito",
      ReciboManual: "",
      Cordobas: "",
      Dolares: "",
      MoraCobrar: "",
      Fecha: "",

      CheckDisable:false,
      CheckClear: false,
      CobrarMora: false,
      EstadoCobrarMora: "",

      TipoIngresoCaja: [],
      TipoIngresoCaj: "",

      Latitude: "",
      Longitude: "",

      PERMISO_ROL: "1007",
      VALIDACION: "hidden",
      ValidacionFA: false,
      ValidacionFAClear: false,
      BTN_APL: true,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.DatosCredito = this.DatosCredito.bind(this);
    this.BusquedaCredito = this.BusquedaCredito.bind(this);
    // this.CheckMora = this.CheckMora.bind(this);
    this.PagarCuota = this.PagarCuota.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  DatosCredito(e) {
    this.setState({ [e.target.name]: e.target.value });
    this.Creditos();
  }

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        Id: res.data.COD_USUARIO,
        ALIAS: res.data.Alias,
        NombreUsuario: res.data.Nombre,
      });
      let tiempo = 300;
      TimerMixin.setTimeout(() => {
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Empresa().then((res) => {
          this.setState({
            DatoEmpresa: res.data.EMPRESA,
            COD_EMPRESA_B: res.data.EMPRESA[0].COD_EMPRESA,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        Sucursal(res.data.COD_ROL_SUC).then((res) => {
          this.setState({
            DatoSucursal: res.data.SUCURSAL,
            COD_SUCURSAL_B: res.data.SUCURSAL[0].COD_SUCURSAL,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        PermisoModulo(res.data.ID_ROL, this.state.PERMISO_ROL).then((res) => {
          this.setState({
            VALIDACION: res.data.ESTADO,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        if (res.data.FECHA_ATRAS === true) {
          PermisoFA(res.data.CodPermFA, this.state.PERMISO_ROL).then((res) => {
            this.setState({
              ValidacionFA: res.data.ESTADO,
              ValidacionFAClear: res.data.ESTADO,
            });
          });
        }
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        PermisoTipoIngreso(res.data.PermTIngreso).then((res) => {
          this.setState({
            TipoIngresoCaja: res.data.TipoIngreso,
            TipoIngresoCaj: res.data.TipoIngreso[0].TIPO_INGRESO,
          });
        });
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        this.Creditos();
      }, tiempo);
    });
  }

  Creditos() {
    console.log("INGRESO 1");
    let tiempo = 1000;
    TimerMixin.setTimeout(() => {
      if (this.state.COD_EMPRESA_B !== "" && this.state.COD_SUCURSAL_B !== "") {
        console.log("INGRESO 2");
        ///++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        CreditoCajaIngreso(
          this.state.COD_EMPRESA_B,
          this.state.COD_SUCURSAL_B
        ).then((res) => {
          this.setState({
            Creditos: res.data.CLIENTES,
          });
        });
      } else {
        this.setState({
          Creditos: [],
        });
      }
    }, tiempo);
  }
  //+++++++++++++++ BOTON BUSCAR PARA LOS DATOS DEL CREDITO ++++++++++++++++++
  BusquedaCredito = () => {
    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Datos de la Cuota",
        "Por Favor Seleccione una Empresa",
        "error"
      );
    } else if (this.state.COD_SUCURSAL_B === "") {
      Swal.fire(
        "Datos de la Cuota",
        "Por Favor Seleccione una Sucursal",
        "error"
      );
    } else if (this.state.CLIENTE === "") {
      Swal.fire(
        "Datos de la Cuota",
        "Por Favor Seleccione un Credito",
        "error"
      );
    } else {
      DatosCuota(
        this.state.COD_EMPRESA_B,
        this.state.COD_SUCURSAL_B,
        this.state.CLIENTE
      ).then((res) => {
        this.setState({
          EstadoCuota: res.data.EstadoCuota,
          EmpresaCredito: res.data.EmpresaCredito,
          SucursalCredito: res.data.SucursalCredito,
          CodCreditoCuota: res.data.CodCreditoCuota,
          FechaCuota: res.data.FechaCuota,
          NombreCliente: res.data.NombreCliente,
          Direccion: res.data.Direccion,
          DireccionNegocio: res.data.DireccionNegocio,
          NoCuota: res.data.NoCuota,
          MontoTipCambio: res.data.MontoTipCambio,
          ValorTipoCambio: res.data.ValorTipoCambio,
          ValorCuota: res.data.ValorCuota,
          DiasMora: res.data.DiasMora,
          TotalMora: res.data.TotalMora,
          InteresMora: res.data.InteresMora,
          MoraCobrar: res.data.MoraCobrar,
          TotalInteresMora: res.data.TotalInteresMora,
          SaldoFinal: res.data.SaldoFinal,
          SaldoMora: res.data.SaldoMora,
          CheckDisable: res.data.CheckDisable,
          CheckClear: res.data.CheckDisable,
        });
      });
    }
  };

  // CheckMora() {
  //   TimerMixin.setTimeout(() => {

  //     if (this.state.CobrarMora === false) {
  //       this.setState({
  //         CobrarMora: true,
  //       });
  //     } else {
  //       this.setState({
  //         CobrarMora: false,
  //       });
  //     }
  //   }, 1000);
  // }

  FuncionBoton() {
    this.setState({
      BTN_APL: false,
      CheckDisable: "disabled",
      ValidacionFA: false,
    });
  }
  OnChangeFecha = (Fecha) => {
    this.setState({ Fecha: Fecha });
  };

  //+++++++++++++++ BOTON BUSCAR PARA LOS DATOS DEL CREDITO ++++++++++++++++++

  // busquedalatitude = React.createRef();
  // busquedalongitude = React.createRef();
  PagarCuota = () => {
    console.log(this.state.CobrarMora);
    Swal.fire({
      title: "Desea Realizar el Pago",
      showDenyButton: true,
      confirmButtonText: `Confirmar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        let timerInterval;
        TimerMixin.setTimeout(() => {
          Swal.fire({
            title: "Pago del Credito",
            html: "El Pago se Esta Realizando",
            timer: 2000000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              try {
              } catch (error) {}
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
            }
          });

          const termino1 = this.props.coords.latitude;
          const termino2 = this.props.coords.longitude;

          this.FuncionBoton();

          let TotalCordobas;
          let TotalDolares;
          let DolaresConv;
          let TotalMora = 0;

          let TotalRecibido;
          let ValidacionFecha;

          let ValidacionPago = true;
          let ValidacionPagoMora = false;

          if (this.state.EstadoCobrarMora === "COBRAR") {
            ValidacionPagoMora = true;
          }

          TimerMixin.setTimeout(() => {
            if (this.state.Fecha === "") {
              ValidacionFecha = false;
            } else {
              ValidacionFecha = true;
            }

            if (this.state.Cordobas === "") {
              TotalCordobas = 0;
            } else {
              TotalCordobas = this.state.Cordobas;
            }
            if (this.state.Dolares === "") {
              TotalDolares = 0;
            } else {
              TotalDolares = this.state.Dolares;
            }

            if (ValidacionPagoMora === true) {
              if (this.state.MoraCobrar === "") {
                TotalMora = 0;
              } else {
                TotalMora = parseFloat(this.state.MoraCobrar);
              }
            }

            TotalRecibido =
              parseFloat(TotalCordobas) +
              parseFloat(TotalDolares) +
              parseFloat(TotalMora);
            if (TotalRecibido > 0) {
              DolaresConv =
                parseFloat(TotalDolares) *
                parseFloat(this.state.ValorTipoCambio);
              TotalRecibido =
                parseFloat(TotalCordobas) + parseFloat(DolaresConv);

              TotalRecibido = parseFloat(TotalRecibido);
              TotalMora = parseFloat(TotalMora);

              if (ValidacionPagoMora === true) {
                if (TotalRecibido < TotalMora) {
                  ValidacionPago = false;
                }
              }

              if (ValidacionPago === true) {
                if (this.state.EmpresaCredito === "") {
                  Swal.fire(
                    "Pago del Credito",
                    "Por Favor Busque los Datos del Credito",
                    "error"
                  );
                } else if (this.state.SucursalCredito === "") {
                  Swal.fire(
                    "Pago del Credito",
                    "Por Favor Busque los Datos del Credito",
                    "error"
                  );
                } else if (this.state.CodCreditoCuota === "") {
                  Swal.fire(
                    "Pago del Credito",
                    "Por Favor Busque los Datos del Credito",
                    "error"
                  );
                } else if (this.state.TipoIngresoCaj === "") {
                  Swal.fire(
                    "Pago del Credito",
                    "Por Favor Seleccione un Tipo de Ingreso",
                    "error"
                  );
                } else if (this.state.Concepto === "") {
                  Swal.fire(
                    "Pago del Credito",
                    "Por Favor Agregue el Concepto del Pago",
                    "error"
                  );
                } else {
                  const DataCredito = {
                    EmpresaCredito: this.state.EmpresaCredito,
                    SucursalCredito: this.state.SucursalCredito,
                    CodCreditoCuota: this.state.CodCreditoCuota,
                    Id: this.state.Id,

                    CobrarMora: ValidacionPagoMora,
                    DiasMora: this.state.DiasMora,
                    TipoMovimiento: this.state.TipoIngresoCaj,
                    ValidacionFecha: ValidacionFecha,
                    FechaPago: this.state.Fecha,
                    Concepto: this.state.Concepto,
                    ReciboManual: this.state.ReciboManual,
                    Cordobas: TotalCordobas,
                    Dolares: TotalDolares,
                    MoraCobrar: TotalMora,
                    Latitude: termino1,
                    Longitude: termino2,
                  };
                  PagoCredito(DataCredito).then((res) => {
                    Swal.fire(
                      "Pago del Credito",
                      res.data.Mensaje,
                      res.data.Alerta
                    );
                    if (res.data.Alerta === "success") {
                      const RecNoRecibo = String(res.data.RecNoRecibo);
                      const RecEmpresa = String(res.data.RecEmpresa);
                      const RecSucursal = String(res.data.RecSucursal);
                      const RecFecha = String(res.data.RecFecha);
                      const RecNombre = String(res.data.RecNombre);
                      const RecCredito = String(res.data.RecCredito);
                      const RecConcepto = String(res.data.RecConcepto);
                      const RecDiasMora = String(res.data.RecDiasMora);
                      const RecFechaVencimiento = String(
                        res.data.RecFechaVencimiento
                      );
                      const RecCordobas = String(res.data.RecCordobas);
                      const RecDolares = String(res.data.RecDolares);
                      const RecAbono = String(res.data.RecAbono);
                      const RecMora = String(res.data.RecMora);
                      const RecTotal = String(res.data.RecTotal);
                      const RecSaldoInicial = String(res.data.RecSaldoInicial);
                      const RecSaldoFinal = String(res.data.RecSaldoFinal);
                      const RecCajero = String(res.data.RecCajero);
                      const RecFechaEmision = String(res.data.RecFechaEmision);
                      const RecTipoCambio = String(res.data.RecTipoCambio);

                      let doc = new jsPDF("p", "mm", [220, 180]);
                      doc.setFont("courier");
                      doc.setFontSize(6);
                      doc.text(20, 6, RecEmpresa);
                      doc.text(20, 9, RecSucursal);
                      doc.text(2, 13, "NO.RECIBO           :");
                      doc.text(30, 13, RecNoRecibo);
                      doc.text(2, 16, "FECHA Y HORA        :");
                      doc.text(30, 16, RecFecha);
                      doc.text(2, 19, "NOMBRE              :");
                      doc.text(2, 22, RecNombre);
                      doc.text(2, 25, "NO.CREDITO          :");
                      doc.text(30, 25, RecCredito);

                      let y = parseInt(25);

                      y = parseInt(y) + 3;
                      doc.text(2, y, "CONCEPTO            :");
                      y = parseInt(y) + 3;
                      doc.text(2, y, RecConcepto);

                      y = parseInt(y) + 3;
                      doc.text(2, y, "DIAS EN MORA        :");
                      doc.text(30, y, RecDiasMora);

                      y = parseInt(y) + 3;
                      doc.text(2, y, "FECHA VENCIMIENTO   :");
                      doc.text(30, y, String(RecFechaVencimiento));

                      y = parseInt(y) + 3;
                      doc.text(2, y, "MONTO CORDOBAS      :");
                      doc.text(30, y, String(RecCordobas));

                      y = parseInt(y) + 3;
                      doc.text(2, y, "MONTO DOLARES       :");
                      doc.text(30, y, String(RecDolares));

                      y = parseInt(y) + 3;
                      doc.text(2, y, "ABONO AL PRESTAMO   :");
                      doc.text(30, y, String(RecAbono));

                      // // y=(parseInt(y) + 3 );
                      // // doc.text(3,y,'ABONO A PRESTAMO :');

                      y = parseInt(y) + 3;
                      doc.text(2, y, "INTERES MORATORIO   :");
                      doc.text(30, y, String(RecMora));

                      y = parseInt(y) + 3;
                      doc.text(2, y, "TOTAL PAGADO        :");
                      doc.text(30, y, String(RecTotal));

                      y = parseInt(y) + 3;
                      doc.text(2, y, "SALDO ANTERIOR      :");
                      doc.text(30, y, String(RecSaldoInicial));

                      y = parseInt(y) + 3;
                      doc.text(2, y, "SALDO ACTUAL        :");
                      doc.text(30, y, String(RecSaldoFinal));

                      y = parseInt(y) + 3;
                      doc.text(2, y, "TIPO DE CAMBIO      :");
                      doc.text(30, y, String(RecTipoCambio));

                      y = parseInt(y) + 3;
                      doc.text(2, y, "CAJERO              :");
                      doc.text(30, y, String(RecCajero));

                      y = parseInt(y) + 3;
                      doc.text(2, y, "FECHA EMICION       :");
                      doc.text(30, y, String(RecFechaEmision));

                      let NameRec = `RECIBO DE CAJA NO ${RecNoRecibo}.pdf`;

                      doc.save(NameRec);

                      // window.location.reload();
                      this.setState({
                        CLIENTE: "",

                        EstadoCuota: "",
                        EmpresaCredito: "",
                        SucursalCredito: "",
                        CodCreditoCuota: "",
                        FechaCuota: "",
                        NombreCliente: "",
                        Direccion: "",
                        DireccionNegocio: "",
                        NoCuota: "",
                        MontoTipCambio: "",
                        ValorTipoCambio: "",
                        ValorCuota: "",
                        DiasMora: "",
                        TotalMora: "",
                        InteresMora: "",
                        TotalInteresMora: "",
                        SaldoFinal: "",
                        SaldoMora:"",

                        Concepto: "Abono al credito",
                        ReciboManual: "",
                        Cordobas: "",
                        Dolares: "",
                        MoraCobrar: "",
                        Fecha: "",

                        CheckDisable: false,
                        CheckClear: false,
                        CobrarMora: false,
                        EstadoCobrarMora: "",

                        TipoIngresoCaj: this.state.TipoIngresoCaja[0].TIPO_INGRESO,

                        Latitude: "",
                        Longitude: "",

                        ValidacionFA: this.state.ValidacionFAClear,

                        BTN_APL: true,
                      });
                    } else {
                      this.setState({
                        BTN_APL: true,
                        CheckDisable: this.state.CheckClear,
                        ValidacionFA: this.state.ValidacionFAClear,
                      });
                    }
                  });
                }
              } else {
                Swal.fire(
                  "Pago del Credito",
                  "El Monto a Pagar Debe Ser Mayor Al Monto en Mora",
                  "error"
                );

                this.setState({
                  BTN_APL: true,
                  CheckDisable: this.state.CheckClear,
                  ValidacionFA: this.state.ValidacionFAClear,
                });
              }
            } else {
              Swal.fire(
                "Pago del Credito",
                "Por Favor Verifique El Monto Digitado",
                "error"
              );

              this.setState({
                BTN_APL: true,
                CheckDisable: this.state.CheckClear,
                ValidacionFA: this.state.ValidacionFAClear,
              });
            }
          }, 300);
        }, 2000);
      } else if (result.isDenied) {
        Swal.fire("Registro cancelado", "", "info");
      }
    });
  };

  render() {
    return !this.props.isGeolocationAvailable ? (
      <div>Este navegador no soporta la geolocalizacion</div>
    ) : !this.props.isGeolocationEnabled ? (
      <div>Por favor activa la UBICACION(GPS)</div>
    ) : this.props.coords ? (
      <form>
        <div style={{ visibility: this.state.VALIDACION }}>
          {" "}
          <SisprestamosNavbar />
          {/*  */}
          <div className="container mt-5">
            <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
              <div className="row">
                <div className="col-md-3">
                  <div style={{ textAlign: "left" }} htmlFor="COD_EMPRESA_B">
                    EMPRESA
                  </div>
                  <select
                    name="COD_EMPRESA_B"
                    className="form-control text-center"
                    value={this.state.COD_EMPRESA_B}
                    onChange={this.DatosCredito}
                    disabled={this.state.BTN_APL === false ? true : false}
                  >
                    <option></option>
                    {this.state.DatoEmpresa.map((item) => (
                      <option key={item.COD_EMPRESA} value={item.COD_EMPRESA}>
                        {item.NOMBRE_EMPRESA}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <div style={{ textAlign: "left" }} htmlFor="COD_SUCURSAL_B">
                    SUCURSAL
                  </div>
                  <select
                    name="COD_SUCURSAL_B"
                    className="form-control text-center"
                    value={this.state.COD_SUCURSAL_B}
                    onChange={this.DatosCredito}
                    disabled={this.state.BTN_APL === false ? true : false}
                  >
                    <option></option>
                    {this.state.DatoSucursal.map((item) => (
                      <option key={item.COD_SUCURSAL} value={item.COD_SUCURSAL}>
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <div style={{ textAlign: "left" }} htmlFor="CLIENTE">
                    CREDITO
                  </div>
                  <input
                    list="data"
                    name="CLIENTE"
                    className="form-control text-center"
                    value={this.state.CLIENTE}
                    onChange={this.onChange}
                    autoComplete="off"
                    disabled={this.state.BTN_APL === false ? true : false}
                  />
                  <datalist id="data">
                    <option></option>
                    {this.state.Creditos.map((item) => (
                      <option key={item.COD_CREDITO} value={item.COD_CREDITO}>
                        {item.NOMBRE1}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className="col-md-3 p-3">
                  <button
                    type="button"
                    onClick={this.BusquedaCredito}
                    className="btn btn-outline-success btn-lg col-md-12"
                    disabled={this.state.BTN_APL === false ? true : false}
                  >
                    BUSCAR
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="container mt-2">
            <div className="row">
              <div className="shadow-lg mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
                <div className="container p-3">
                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                      <h1 className="h3 mb-0 font-weight-normal p-2  text-center">
                        DATOS DE LA CUOTA
                      </h1>
                    </div>
                  </div>
                  <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-0 bg-light text-dark border rounded border-dark">
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                        ESTADO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.EstadoCuota}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        No.CREDITO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.CodCreditoCuota}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        FECHA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.FechaCuota}
                      </div>
                    </div>
                    {/*  */}
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-12">
                      CLIENTE{" "}
                      <font
                        size="1"
                        color="white"
                        style={{ visibility: "hidden" }}
                      >
                        -
                      </font>
                      : {this.state.NombreCliente}
                    </div>
                    {/*  */}
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-12">
                      DIRECCION{" "}
                      <font
                        size="1"
                        color="white"
                        style={{ visibility: "hidden" }}
                      >
                        -
                      </font>
                      : {this.state.Direccion}
                    </div>
                    {/*  */}
                    <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-12">
                      NEGOCIO{" "}
                      <font
                        size="1"
                        color="white"
                        style={{ visibility: "hidden" }}
                      >
                        -
                      </font>
                      : {this.state.DireccionNegocio}
                    </div>
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                        No.CUOTA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.NoCuota}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        DIAS MORA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.DiasMora}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        VALOR CUOTA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.ValorCuota}
                      </div>
                    </div>
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        TOTAL MORA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.TotalMora}
                      </div>
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                        INT.MORA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.InteresMora}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        TOT + INT.MORA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.TotalInteresMora}
                      </div>
                    </div>
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        SALDO CREDITO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.SaldoFinal}
                      </div>
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        SALDO + MORA {" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.SaldoMora}
                      </div>
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                        TIPO.CAMBIO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.MontoTipCambio}
                      </div>
                    </div>

                    {/*  */}
                  </div>
                  {/*  */}
                  <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-0 bg-light text-dark border rounded border-dark">
                    <div className="container p-3">
                      <div className="row">
                        <div className="col-md-3">
                          {/* <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox9"
                              onChange={this.CheckMora}
                              disabled={this.state.CheckDisable}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineCheckbox9"
                            >
                              {" "}
                              <b>(COBRAR MORA)</b>
                            </label>
                          </div> */}

                          <div
                            style={{ textAlign: "left" }}
                            htmlFor="EstadoCobrarMora"
                          >
                            <b> (COBRAR MORA)</b>
                          </div>
                          <select
                            name="EstadoCobrarMora"
                            className="form-control text-center"
                            value={this.state.EstadoCobrarMora}
                            onChange={this.onChange}
                            autoComplete="off"
                            disabled={
                              this.state.CheckDisable === false ? true : false
                            }
                          >
                            <option> </option>
                            <option>COBRAR</option>
                          </select>
                        </div>
                        <div className="form-group col-md-3">
                          <div
                            style={{ textAlign: "left" }}
                            htmlFor="MoraCobrar"
                          >
                            <b>MORA COBRAR</b>
                          </div>
                          <input
                            type="text"
                            className="form-control text-right"
                            name="MoraCobrar"
                            autoComplete="off"
                            value={this.state.MoraCobrar}
                            onChange={this.onChange}
                            disabled={
                              this.state.BTN_APL === false ? true : false
                            }
                          />
                        </div>
                        <div className="col-md-3">
                          <div
                            style={{ textAlign: "left" }}
                            htmlFor="TipoIngresoCaj"
                          >
                            <b> TIPO</b>
                          </div>
                          <select
                            name="TipoIngresoCaj"
                            className="form-control text-center"
                            value={this.state.TipoIngresoCaj}
                            onChange={this.onChange}
                            autoComplete="off"
                            disabled={
                              this.state.BTN_APL === false ? true : false
                            }
                          >
                            {this.state.TipoIngresoCaja.map((item) => (
                              <option
                                key={item.COD_TIPO_INGRESO}
                                value={item.TIPO_INGRESO}
                              >
                                {item.TIPO_INGRESO}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-3">
                          <div style={{ textAlign: "left" }} htmlFor="Fecha">
                            <b> FECHA</b>
                          </div>
                          <DatePicker
                            type="date"
                            dateFormat="dd '/' M '/' yyyy"
                            selected={this.state.Fecha}
                            onChange={this.OnChangeFecha}
                            locale="es"
                            className="form-control text-center"
                            disabled={
                              this.state.ValidacionFA === false ? true : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group text-center col-md-6">
                        <div style={{ textAlign: "left" }} htmlFor="Concepto">
                          <b>CONCEPTO</b>
                        </div>
                        <input
                          type="text"
                          className="form-control text-left"
                          name="Concepto"
                          value={this.state.Concepto}
                          onChange={this.onChange}
                          disabled={this.state.BTN_APL === false ? true : false}
                        />
                      </div>
                      <div className="form-group text-center col-md-6">
                        <div
                          style={{ textAlign: "left" }}
                          htmlFor="ReciboManual"
                        >
                          <b>RECIBO/MANUAL</b>
                        </div>
                        <input
                          type="text"
                          className="form-control text-left"
                          name="ReciboManual"
                          value={this.state.ReciboManual}
                          onChange={this.onChange}
                          autoComplete="off"
                          disabled={this.state.BTN_APL === false ? true : false}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-2"></div>
                      <div className="form-group text-center col-md-4">
                        <div style={{ textAlign: "left" }} htmlFor="Cordobas">
                          <b>CORDOBAS</b>
                        </div>
                        <input
                          type="text"
                          className="form-control text-right"
                          name="Cordobas"
                          planceholder="Enter your Alias"
                          value={this.state.Cordobas}
                          onChange={this.onChange}
                          autoComplete="off"
                          disabled={this.state.BTN_APL === false ? true : false}
                        />
                      </div>

                      <div className="form-group text-center col-md-4">
                        <div style={{ textAlign: "left" }} htmlFor="Dolares">
                          <b>DOLARES</b>
                        </div>
                        <input
                          type="text"
                          className="form-control text-right"
                          name="Dolares"
                          value={this.state.Dolares}
                          onChange={this.onChange}
                          autoComplete="off"
                          disabled={this.state.BTN_APL === false ? true : false}
                        />
                      </div>
                    </div>
                    {/*  */}
                    <div className="container ">
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 p-3">
                          <button
                            type="button"
                            onClick={this.PagarCuota}
                            className="btn btn-outline-secondary btn-lg col-md-12"
                            disabled={
                              this.state.BTN_APL === false ? true : false
                            }
                          >
                            PAGAR
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </form>
    ) : (
      <div>Getting the location data&hellip; </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 10000,
})(CajaIngresos);

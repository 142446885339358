import axios from "axios";
import api from "../../src/api/axiosConfigRoute";

// REPORTES RESUMEN DE RECUPERACION
/** RESUMEN DE RECUPERACION POR GESTOR **/
export const RecuperacionGestor = (Data) => {
  return axios
    .post(`${api}/reporte/ResumenRecuperacionGestor`, {
      CodigoEmpresa: Data.CodigoEmpresa,
      CodigoSucursal: Data.CodigoSucursal,
      FechaInicio: Data.FechaInicio,
      FechaFin: Data.FechaFin,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

/** RESUMEN DE RECUPERACION POR CARTERA **/
export const RecuperacionCartera = (Data) => {
  return axios
    .post(`${api}/reporte/ResumenRecuperacionCartera`, {
      CodigoEmpresa: Data.CodigoEmpresa,
      CodigoSucursal: Data.CodigoSucursal,
      FechaInicio: Data.FechaInicio,
      FechaFin: Data.FechaFin,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

/** RESUMEN DE MORA **/
export const ConsolidadoMora = (Data) => {
  return axios
    .post(`${api}/reporte/ResumenMoraDias`, {
      CodigoEmpresa: Data.CodigoEmpresa,
      CodigoSucursal: Data.CodigoSucursal,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

/** EFECTIVIDAD POR ANALISTA**/
export const Efectividad = (Data) => {
  return axios
    .post(`${api}/reporte/EfectividadGestor`, {
      CodigoEmpresa: Data.CodigoEmpresa,
      CodigoSucursal: Data.CodigoSucursal,
      Periodo: Data.Periodo,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

import React, { Component } from "react";
import HomeNavbar from "./HomeNavbar";

class Landing extends Component {
  
  render() {
    return (
      <div className="container"> <HomeNavbar />
        <div className="col-sm-8 mx-auto mt-3 ">
          {/* <img src={require('./imagenes/PRESTA CASH.jpg')}alt=""   align="center" 
          responsive width= "100%" height= "auto"/>  */}
          </div>
     
      </div>
    );
  }
}
export default Landing;

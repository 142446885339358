import React, { Component } from "react";
import axios from "axios";
import TimerMixin from "react-timer-mixin";
import Swal from "sweetalert2";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
// import { geolocated } from "react-geolocated";
import * as jsPDF from "jspdf";
import jwt_decode from "jwt-decode"; // Usuarios
import { getUser } from "../../UserFunctions"; // Usuarios
import { PermisoFA } from "../../GlobalFuntions"; // Usuarios
import api from "../../../api/axiosConfigRoute";
import SisprestamosNavbar from "../../SisprestamosNavbar";

registerLocale("es", es);

class PlanpagoNivelado extends Component {
  CONSULTA_PERMISO = () => {
    const url = `${api}/permiso/get/${this.state.ID_ROL}/${this.state.PERMISO_ROL}`;

    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ VALIDACION: resultado.ESTADO }));
  };

  consultarEmpresa = () => {
    const url = `${api}/empresa/get`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ EmpresaA: resultado }));
  };

  consultarSucursal = () => {
    const url = `${api}/sucursal/get/${this.state.COD_ROL_SUC}`;
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ SucursalA: resultado }));
  };

  consultarCliente = () => {
    const url = `${api}/formalizacion/pendiente/${this.state.COD_EMPRESA_B}/${this.state.COD_SUCURSAL_B}`;
    //console.log(url);
    fetch(url)
      .then((respuesta) => respuesta.json())
      .then((resultado) => this.setState({ ClienteA: resultado }));
  };

  constructor() {
    super();
    this.state = {
      ALIAS: "",
      Tipo_usuario: "",
      COD_SUCURSAL: "",
      COD_REGION: "",
      COD_NIVEL: "",
      NOMBRE_SUCURSAL: "",
      ID_ROL: "",
      COD_ROL_SUC: "",
      MANEJO_GASTO: "",

      SucursalA: [],
      EmpresaA: [],
      ClienteA: [],

      CLIENTE: "",

      TipoCreditoForm:"",
      COD_EMPRESA: "",
      NOMBRE_EMPRESA: "",
      COD_SUCURSAL_DATO: "",
      NOMBRE_SUCURSAL_DATO: "",
      COD_CLIENTE: "",
      NOMBRE1: "",
      CEDULA: "",
      FECHA:"",
      FECHA_CREDITO: "",
      FECHA_CREDITO_formato: "",
      COD_CREDITO: "",
      NO_SOLI: "",
      TIPO_CREDITO: "",
      TIPO_MONEDA: "",
      NOMBRE_MONEDA: "",
      PLAZO_APROBADO: "",
      TIC_APROBADO: "",
      TIM_APROBADO: "",
      FREC_PAG_APROBADO: "",
      TIPO_DESEMBOLSO_APROBADO: "",
      ANALISTA: "",
      COD_PROMOTOR: "",
      PROMOTOR: "",
      MONTO_TOTAL_APROBADO: "",
      GASTO_DESEMBOLSO: "",
      CREDITO_ANTERIOR: "",
      SALDO_ANTERIOR: "0",
      Barrio: "",
      COD_DEPARTAMENTO: "",
      COD_MUNICIPIO: "",

      SALDO_ANT_REST: "0",
      BTN_APL: "true",

      LATITUD: "12.9209864",
      LONGITUD: "-85.9161283",

      VALOR_TIPO_CAMBIO: "",

      fecha_1: "",
      FILTRO_MOV: "false",
      TI: "0.00",

      ///////////datos recibo
      NOMBRE_EMPRESA_R: "",
      NOMBRE_SUCURSAL_Recibo: "",
      TELEFONO_R: "",
      CELULAR_R: "",
      NO_RECIBO_R: "",
      FECHA_R: "",
      HORA_R: "",
      NOMBRE1_R: "",
      CO_CREDITO_R: "",
      CONCEPTO_1_R: "",
      MONTO_CORDOBA_R: "",
      MONTO_DOLAR_R: "",
      TOTAL_RECIBIDO_R: "",
      ADICIONADO_POR_R: "",
      /////////////VARIABLES DEL RECIBO CANCELACION SALDO ANTERIOR
      NOMBRE_EMPRESA_CL: "",
      NOMBRE_SUCURSAL_CL: "",
      TELEFONO_CL: "",
      CELULAR_CL: "",
      NO_RECIBO_CL: "",
      FechaCancelacion: "",
      HORA_CL: "",
      NOMBRE1_CL: "",
      CO_CREDITO_CL: "",
      CONCEPTO_1_CL: "",
      MONTO_CORDOBA_CL: "",
      MONTO_DOLAR_CL: "",
      TOTAL_RECIBIDO_CL: "",
      OTROS_CARGOS_CL: "",
      ADICIONADO_POR_CL: "",
      ////////liquidacion
      Liq_COD_CREDITO: "",
      Liq_CREDITO_ANTERIOR: "",
      Liq_FECHA: "",
      Liq_COD_CLIENTE: "",
      Liq_NOMBRE: "",
      Liq_CEDULA: "",
      Liq_ANALISTA: "",
      Liq_MONTO: "",
      Liq_SALDO_PENDIENTE: "",
      Liq_GASTO: "",
      Liq_SALDO: "",
      Liq_TIC: "",
      Liq_ValorCuota: "",
      Liq_Plazo: "",
      Liq_Vencimiento: "",

      SaldoArray: [],
      DATOS_CLIENTE: [],

      ValidacionFA: false,
      ValidacionFAClear: false,

      PERMISO_ROL: "1043",
      VALIDACION: "hidden",
      IMAGEN: "",
      REPORT_WIDTH: "",
      REPORT_HEIGHT: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.BUSQUEDA = this.BUSQUEDA.bind(this);
    this.obtenerDatos = this.obtenerDatos.bind(this);
    this.onChange_check_mov = this.onChange_check_mov.bind(this);
    this.RegistroCredito = this.RegistroCredito.bind(this);
    this.CancelacionCredito = this.CancelacionCredito.bind(this);
    //  this.Liquidacion = this.Liquidacion.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  BUSQUEDA(e) {
    this.setState({ [e.target.name]: e.target.value });

    let tiempo = "300";
    TimerMixin.setTimeout(() => {
      if (this.state.COD_EMPRESA_B !== "" && this.state.COD_SUCURSAL_B !== "") {
        this.consultarCliente();
      }
      if (this.state.COD_EMPRESA_B !== "" && this.state.IMAGEN === "") {
        this.datos_empresa();
      }
    }, tiempo);
  }

  Datos = (ID) => {
    return axios
      .get(`${api}/mantenimiento/empresa/${this.state.COD_EMPRESA_B}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  datos_empresa = (e) => {
    if (this.state.COD_EMPRESA_B === "") {
      Swal.fire(
        "Registro del Credito",
        "Por Favor Selecciona Una Empresa",
        "error"
      );
    } else {
      this.Datos().then((res) => {
        this.setState({
          IMAGEN: res.data.IMAGEN,
          REPORT_WIDTH: res.data.REPORT_WIDTH,
          REPORT_HEIGHT: res.data.REPORT_HEIGHT,
        });
      });
    }
  };

  componentDidMount() {
    const token = localStorage.usertoken;
    const decoded = jwt_decode(token);
    getUser(decoded.uid).then((res) => {
      this.setState({
        ALIAS: res.data.Alias,
        COD_NIVEL: res.data.COD_NIVEL,
        ID_ROL: res.data.ID_ROL,
        COD_ROL_SUC: res.data.COD_ROL_SUC,
        MANEJO_GASTO: res.data.MANEJO_GASTO,
      });

      let tiempo = "300";
      TimerMixin.setTimeout(() => {
        this.CONSULTA_PERMISO();
        this.consultarEmpresa();
        this.consultarSucursal();

        if (res.data.FECHA_ATRAS === true) {
          PermisoFA(res.data.CodPermFA, this.state.PERMISO_ROL).then((res) => {
            this.setState({
              ValidacionFA: res.data.ESTADO,
              ValidacionFAClear: res.data.ESTADO,
            });
          });
        }
      }, tiempo);
    });
  }

  consulta = (dato) => {
    return axios
      .post(`${api}/formalizacion/datos_credito`, {
        COD_EMPRESA: dato.COD_EMPRESA,
        COD_SUCURSAL: dato.COD_SUCURSAL,
        COD_CREDITO: dato.COD_CREDITO,
      })
      .then((response) => {
        this.setState({
          TipoCreditoForm:response.data.TipoCreditoForm,
          COD_EMPRESA: response.data.COD_EMPRESA,
          NOMBRE_EMPRESA: response.data.NOMBRE_EMPRESA,
          COD_SUCURSAL_DATO: response.data.COD_SUCURSAL,
          NOMBRE_SUCURSAL_DATO: response.data.NOMBRE_SUCURSAL,
          COD_CLIENTE: response.data.COD_CLIENTE,
          NOMBRE1: response.data.NOMBRE1,
          CEDULA: response.data.CEDULA,
          FECHA: new Date(response.data.FECHA_DEL_DIA),
          FECHA_CREDITO: response.data.FECHA_CREDITO,
          FECHA_CREDITO_formato: response.data.FECHA_CREDITO_formato,
          COD_CREDITO: response.data.COD_CREDITO,
          NO_SOLI: response.data.NO_SOLI,
          TIPO_CREDITO: response.data.TIPO_CREDITO,
          TIPO_MONEDA: response.data.TIPO_MONEDA,
          NOMBRE_MONEDA: response.data.NOMBRE_MONEDA,
          PLAZO_APROBADO: response.data.PLAZO_APROBADO,
          TIC_APROBADO: response.data.TIC_APROBADO,
          TIM_APROBADO: response.data.TIM_APROBADO,
          FREC_PAG_APROBADO: response.data.FREC_PAG_APROBADO,
          TIPO_DESEMBOLSO_APROBADO: response.data.TIPO_DESEMBOLSO_APROBADO,
          ANALISTA: response.data.ANALISTA,
          COD_PROMOTOR: response.data.COD_PROMOTOR,
          PROMOTOR: response.data.PROMOTOR,
          MONTO_TOTAL_APROBADO: response.data.MONTO_TOTAL_APROBADO,
          GASTO_DESEMBOLSO: response.data.GASTO_DESEMBOLSO,
          CREDITO_ANTERIOR: response.data.CREDITO_ANTERIOR,
          SALDO_ANTERIOR: response.data.SALDO_ANTERIOR,
          SALDO_ANT_REST: response.data.SALDO_ANTERIOR,
          Barrio: response.data.Barrio,
          COD_DEPARTAMENTO: response.data.COD_DEPARTAMENTO,
          COD_MUNICIPIO: response.data.COD_MUNICIPIO,
          VALOR_TIPO_CAMBIO: response.data.VALOR_TIPO_CAMBIO,
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Registro del Credito",
          "Hay un Problema Con un Dato Del Credito , Por Fvor Reportarlo",
          "error"
        );
    
      });
  };

  obtenerDatos(e) {
    e.preventDefault();
    let tiempo = "100";
    TimerMixin.setTimeout(() => {
      const dato = {
        COD_EMPRESA: this.state.COD_EMPRESA_B,
        COD_SUCURSAL: this.state.COD_SUCURSAL_B,
        COD_CREDITO: this.state.CLIENTE,
      };

      this.consulta(dato).then((res) => {});
    }, tiempo);
  }

  onChange_check_mov(e) {
    let tiempo = "300";

    TimerMixin.setTimeout(() => {
      if (this.state.FILTRO_MOV === "true") {
        this.setState({
          FILTRO_MOV: "false",
        });
      } else {
        this.setState({
          FILTRO_MOV: "true",
        });
      }
    }, tiempo);
  }

  onChange2 = (fecha) => {
    this.setState({ fecha_1: fecha });
  };

  onChange3 = (fechaCredito) => {
    this.setState({ FECHA: fechaCredito });
  };

  //////////*********GEOLOCALIZACION */

  busquedalatitude = React.createRef();

  obtenerlatitude = (e) => {
    this.obtenerlongitude();
    const termino1 = this.busquedalatitude.current.value;

    this.setState({
      LATITUD: termino1,
    });
  };

  busquedalongitude = React.createRef();
  obtenerlongitude = (e) => {
    const termino2 = this.busquedalongitude.current.value;
    this.setState({
      LONGITUD: termino2,
    });
  };

  registro = (newCliente) => {
    return axios
      .post(`${api}/formalizacion/registro`, {
        COD_EMPRESA: newCliente.COD_EMPRESA,
        COD_SUCURSAL: newCliente.COD_SUCURSAL,
        COD_CREDITO: newCliente.COD_CREDITO,
        INCL_SABADO: newCliente.INCL_SABADO,
        FECHA_FORMALIZACION: newCliente.FECHA_FORMALIZACION,
        FECHA_CREDITO: newCliente.FECHA_CREDITO,
        TI: newCliente.TI,
        ALIAS: newCliente.ALIAS,
        BARRIO: newCliente.BARRIO,
        LATITUD: newCliente.LATITUD,
        LONGITUD: newCliente.LONGITUD,
        DEPARTAMENTO: newCliente.DEPARTAMENTO,
        MUNICIPIO: newCliente.MUNICIPIO,
        VALOR_TIPO_CAMBIO: newCliente.VALOR_TIPO_CAMBIO,
        CancelacionSaldo: newCliente.CancelacionSaldo,
        TipoPlanPago:newCliente.TipoPlanPago,
      })
      .then((response) => {
        Swal.fire("Caja", response.data.mensaje, response.data.alerta);
        this.setState({
          NOMBRE_EMPRESA_R: response.data.NOMBRE_EMPRESA_R,
          NOMBRE_SUCURSAL_Recibo: response.data.NOMBRE_SUCURSAL_R,
          TELEFONO_R: response.data.TELEFONO_R,
          CELULAR_R: response.data.CELULAR_R,
          NO_RECIBO_R: response.data.NO_RECIBO_R,
          FECHA_R: response.data.FECHA_R,
          HORA_R: response.data.HORA_R,
          NOMBRE1_R: response.data.NOMBRE1_R,
          CO_CREDITO_R: response.data.CO_CREDITO_R,
          CONCEPTO_1_R: response.data.CONCEPTO_1_R,
          MONTO_CORDOBA_R: response.data.MONTO_CORDOBA_R,
          MONTO_DOLAR_R: response.data.MONTO_DOLAR_R,
          TOTAL_RECIBIDO_R: response.data.TOTAL_RECIBIDO_R,
          ADICIONADO_POR_R: response.data.ADICIONADO_POR_R,

          NOMBRE_EMPRESA_CL: response.data.NOMBRE_EMPRESA_CL,
          NOMBRE_SUCURSAL_CL: response.data.NOMBRE_SUCURSAL_CL,
          TELEFONO_CL: response.data.TELEFONO_CL,
          CELULAR_CL: response.data.CELULAR_CL,
          NO_RECIBO_CL: response.data.NO_RECIBO_CL,
          FechaCancelacion: response.data.FechaCancelacion,
          HORA_CL: response.data.HORA_CL,
          NOMBRE1_CL: response.data.NOMBRE1_CL,
          CO_CREDITO_CL: response.data.CO_CREDITO_CL,
          CONCEPTO_1_CL: response.data.CONCEPTO_1_CL,
          MONTO_CORDOBA_CL: response.data.MONTO_CORDOBA_CL,
          MONTO_DOLAR_CL: response.data.MONTO_DOLAR_CL,
          TOTAL_RECIBIDO_CL: response.data.TOTAL_RECIBIDO_CL,
          ADICIONADO_POR_CL: response.data.ADICIONADO_POR_CL,

          Liq_COD_CREDITO: response.data.Liq_COD_CREDITO,
          Liq_CREDITO_ANTERIOR: response.data.Liq_CREDITO_ANTERIOR,
          Liq_FECHA: response.data.Liq_FECHA,
          Liq_COD_CLIENTE: response.data.Liq_COD_CLIENTE,
          Liq_NOMBRE: response.data.Liq_NOMBRE,
          Liq_CEDULA: response.data.Liq_CEDULA,
          Liq_ANALISTA: response.data.Liq_ANALISTA,
          Liq_MONTO: response.data.Liq_MONTO,
          Liq_SALDO_PENDIENTE: response.data.Liq_SALDO_PENDIENTE,
          Liq_GASTO: response.data.Liq_GASTO,
          Liq_SALDO: response.data.Liq_SALDO,
          Liq_TIC: response.data.Liq_TIC,
          Liq_ValorCuota: response.data.Liq_ValorCuota,
          Liq_Plazo: response.data.Liq_Plazo,
          Liq_Vencimiento: response.data.Liq_Vencimiento,

          SaldoArray: response.data.SaldoArray,
          DATOS_CLIENTE: response.data.DATOS_CLIENTE,
        });

        // let tiempo = "300";
        // TimerMixin.setTimeout(() => {
          if (response.data.alerta === "error") {
            this.setState({
              BTN_APL: "true",
              SALDO_ANT_REST: this.state.SALDO_ANTERIOR,
              ValidacionFA: this.state.ValidacionFAClear,
            });
          } else {
            TimerMixin.setTimeout(() => {
              // if (response.data.TOTAL_RECIBIDO_R > 0) {
              //   this.jsPdfGenerator();
              // } else {
              //   if (response.data.TOTAL_RECIBIDO_CL > 0) {
              //     this.ReciboCancelacion();
              //   }else{
              //     this.Liquidacion();
              //   }
              // }
              this.Liquidacion();
            }, 1000);
          }
        // }, tiempo);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Registro del Credito",
          "Por Favor Verifique Los Datos",
          "error"
        );
        this.setState({
          BTN_APL: "true",
          SALDO_ANT_REST: this.state.SALDO_ANTERIOR,
          ValidacionFA: this.state.ValidacionFAClear,
        });
      });
  };

  RegistroCredito(e) {
    if (this.state.COD_CREDITO === "") {
      e.preventDefault();
      Swal.fire(
        "Registro del Credito",
        "Por Favor Busque Los Datos Del Credito",
        "error"
      );
    } else if (this.state.COD_EMPRESA === "") {
      e.preventDefault();
      Swal.fire(
        "Registro del Credito",
        "Por Favor Seleccione Una Empresa",
        "error"
      );
    } else if (this.state.COD_SUCURSAL_DATO === "") {
      e.preventDefault();
      Swal.fire(
        "Registro del Credito",
        "Por Favor Seleccione Una Sucursal",
        "error"
      );
    } else if (this.state.VALOR_TIPO_CAMBIO === "") {
      e.preventDefault();
      Swal.fire(
        "Registro del Credito",
        "Por Favor Verifique Los Datos(Tipo De Cambio No Encontrado",
        "error"
      );
    } else {
      e.preventDefault();
      // this.obtenerlatitude();
      Swal.fire({
        title: "Desea Registrar El Credito",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.BTN_APL_DESAC();
          let timerInterval;
          // TimerMixin.setTimeout(() => {
            Swal.fire({
              title: "Registro del Credito",
              html: "Los Datos se Estan Registrando",
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                try {
                } catch (error) {}
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
              }
            });
            const newCliente = {
              COD_EMPRESA: this.state.COD_EMPRESA,
              COD_SUCURSAL: this.state.COD_SUCURSAL_DATO,
              COD_CREDITO: this.state.COD_CREDITO,
              INCL_SABADO: this.state.FILTRO_MOV,
              FECHA_FORMALIZACION: this.state.fecha_1,
              FECHA_CREDITO: this.state.FECHA,
              TI: this.state.TI,
              ALIAS: this.state.ALIAS,
              BARRIO: this.state.Barrio,
              LATITUD: this.state.LATITUD,
              LONGITUD: this.state.LONGITUD,
              DEPARTAMENTO: this.state.COD_DEPARTAMENTO,
              MUNICIPIO: this.state.COD_MUNICIPIO,
              VALOR_TIPO_CAMBIO: this.state.VALOR_TIPO_CAMBIO,
              CancelacionSaldo: false,
              TipoPlanPago:this.state.TipoCreditoForm,
            };

            this.registro(newCliente).then((res) => {});
          // }, 300);
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    }
  }

  CancelacionCredito(e) {
    if (this.state.COD_CREDITO === "") {
      e.preventDefault();
      Swal.fire(
        "Registro del Credito",
        "Por Favor Busque Los Datos Del Credito",
        "error"
      );
    } else if (this.state.COD_EMPRESA === "") {
      e.preventDefault();
      Swal.fire(
        "Registro del Credito",
        "Por Favor Seleccione Una Empresa",
        "error"
      );
    } else if (this.state.COD_SUCURSAL_DATO === "") {
      e.preventDefault();
      Swal.fire(
        "Registro del Credito",
        "Por Favor Seleccione Una Sucursal",
        "error"
      );
    } else if (this.state.VALOR_TIPO_CAMBIO === "") {
      e.preventDefault();
      Swal.fire(
        "Registro del Credito",
        "Por Favor Verifique Los Datos(Tipo De Cambio No Encontrado",
        "error"
      );
    } else {
      e.preventDefault();
      // this.obtenerlatitude();
      Swal.fire({
        title: "Desea Cancelar El Credito Anterior",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.BTN_APL_DESAC();
          let timerInterval;
          // TimerMixin.setTimeout(() => {
            Swal.fire({
              title: "Registro del Credito",
              html: "Los Datos se Estan Registrando",
              timer: 2000000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
                try {
                } catch (error) {}
              },
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log("I was closed by the timer");
              }
            });
            const newCliente = {
              COD_EMPRESA: this.state.COD_EMPRESA,
              COD_SUCURSAL: this.state.COD_SUCURSAL_DATO,
              COD_CREDITO: this.state.COD_CREDITO,
              INCL_SABADO: this.state.FILTRO_MOV,
              FECHA_FORMALIZACION: this.state.fecha_1,
              FECHA_CREDITO: this.state.FECHA,
              TI: this.state.TI,
              ALIAS: this.state.ALIAS,
              BARRIO: this.state.Barrio,
              LATITUD: this.state.LATITUD,
              LONGITUD: this.state.LONGITUD,
              DEPARTAMENTO: this.state.COD_DEPARTAMENTO,
              MUNICIPIO: this.state.COD_MUNICIPIO,
              VALOR_TIPO_CAMBIO: this.state.VALOR_TIPO_CAMBIO,
              CancelacionSaldo: true,
              TipoPlanPago:this.state.TipoCreditoForm,
            };

            this.registro(newCliente).then((res) => {});
          // }, 300);
          // Swal.fire('Registrado!', '', 'success')
        } else if (result.isDenied) {
          Swal.fire("Registro cancelado", "", "info");
        }
      });
    }
  }

  BTN_APL_DESAC() {
    this.setState({ BTN_APL: "false", SALDO_ANT_REST: 0 , ValidacionFA: false,});
  }

  jsPdfGenerator = (e) => {
    // e.preventDefault();

    let RECIBO = String(this.state.NO_RECIBO_R);

    let CO_CREDITO_R = String(this.state.CO_CREDITO_R);

    // TimerMixin.setTimeout(() => {
      var doc = new jsPDF("p", "mm", [300, 180]);
      doc.setFont("courier");
      doc.setFontSize(6);
      doc.text(20, 6, String(this.state.NOMBRE_EMPRESA_R));
      doc.text(20, 9, String(this.state.NOMBRE_SUCURSAL_Recibo));
      doc.text(2, 13, "NO.RECIBO           :");
      doc.text(30, 13, RECIBO);
      doc.text(2, 16, "FECHA               :");
      doc.text(30, 16, String(this.state.FECHA_R));
      doc.text(2, 19, "NOMBRE              :");
      doc.text(2, 22, String(this.state.NOMBRE1_R));
      doc.text(2, 25, "NO.CREDITO          :");
      doc.text(30, 25, CO_CREDITO_R);

      let y = parseInt(25);

      y = parseInt(y) + 3;
      doc.text(2, y, "CONCEPTO            :");
      y = parseInt(y) + 3;
      doc.text(2, y, String(this.state.CONCEPTO_1_R));

      y = parseInt(y) + 3;
      doc.text(2, y, "MONTO CORDOBAS      :");
      doc.text(30, y, String(this.state.MONTO_CORDOBA_R));

      y = parseInt(y) + 3;
      doc.text(2, y, "MONTO DOLARES       :");
      doc.text(30, y, String(this.state.MONTO_DOLAR_R));

      // // y=(parseInt(y) + 3 );
      // // doc.text(3,y,'ABONO A PRESTAMO :');

      y = parseInt(y) + 3;
      doc.text(2, y, "TOTAL PAGADO        :");
      doc.text(30, y, String(this.state.TOTAL_RECIBIDO_R));

      y = parseInt(y) + 3;
      doc.text(2, y, "USUARIO             :");
      doc.text(30, y, String(this.state.ADICIONADO_POR_R));

      y = parseInt(y) + 3;
      doc.text(2, y, "FECHA DEL RECIBO    :");
      doc.text(30, y, String(this.state.FECHA_R));

      doc.save(`RECIBO DE CAJA${this.state.NOMBRE1_R}.pdf`);

      // TimerMixin.setTimeout(() => {
        if (this.state.TOTAL_RECIBIDO_CL > 0) {
          this.ReciboCancelacion();
        } else {
          window.location.reload();
        }
    //   }, 600);
    // }, 300);
  };

  ReciboCancelacion = (e) => {
    // e.preventDefault();

    let RECIBO = String(this.state.NO_RECIBO_CL);

    let CO_CREDITO_CL = String(this.state.CO_CREDITO_CL);

    TimerMixin.setTimeout(() => {
      var doc = new jsPDF("p", "mm", [300, 180]);
      doc.setFont("courier");
      doc.setFontSize(6);
      doc.text(20, 6, String(this.state.NOMBRE_EMPRESA_CL));
      doc.text(20, 9, String(this.state.NOMBRE_SUCURSAL_CL));
      doc.text(2, 13, "NO.RECIBO           :");
      doc.text(30, 13, RECIBO);
      doc.text(2, 16, "FECHA               :");
      doc.text(30, 16, String(this.state.FechaCancelacion));
      doc.text(2, 19, "NOMBRE              :");
      doc.text(2, 22, String(this.state.NOMBRE1_CL));
      doc.text(2, 25, "NO.CREDITO          :");
      doc.text(30, 25, CO_CREDITO_CL);

      let y = parseInt(25);

      y = parseInt(y) + 3;
      doc.text(2, y, "CONCEPTO            :");
      y = parseInt(y) + 3;
      doc.text(2, y, String(this.state.CONCEPTO_1_CL));

      y = parseInt(y) + 3;
      doc.text(2, y, "MONTO CORDOBAS      :");
      doc.text(30, y, String(this.state.MONTO_CORDOBA_CL));

      y = parseInt(y) + 3;
      doc.text(2, y, "MONTO DOLARES       :");
      doc.text(30, y, String(this.state.MONTO_DOLAR_CL));

      // // y=(parseInt(y) + 3 );
      // // doc.text(3,y,'ABONO A PRESTAMO :');

      y = parseInt(y) + 3;
      doc.text(2, y, "TOTAL PAGADO        :");
      doc.text(30, y, String(this.state.TOTAL_RECIBIDO_CL));

      y = parseInt(y) + 3;
      doc.text(2, y, "USUARIO             :");
      doc.text(30, y, String(this.state.ADICIONADO_POR_CL));

      y = parseInt(y) + 3;
      doc.text(2, y, "FECHA DEL RECIBO    :");
      doc.text(30, y, String(this.state.FechaCancelacion));

      doc.save(`RECIBO DE CAJA${this.state.NOMBRE1_CL}.pdf`);

      TimerMixin.setTimeout(() => {
        window.location.reload();
      }, 600);
    }, 300);
  };

  Liquidacion(e) {
    //  e.preventDefault();
    let doc = new jsPDF("P", "pt", "letter");
    let imgData;
    let REPORT_WIDTH = parseInt(this.state.REPORT_WIDTH);
    let REPORT_HEIGHT = parseInt(this.state.REPORT_HEIGHT);
    imgData = this.state.IMAGEN;

    doc.addImage(imgData, 20, 12, REPORT_WIDTH, REPORT_HEIGHT);
    doc.setFontSize(14);
    doc.setTextColor(1);
    doc.text(210, 60, "LIQUIDACION DEL CREDITO");

    doc.rect(90, 80, 420, 180);

    doc.line(90, 105, 510, 105); // horizontal line

    doc.line(90, 135, 510, 135); // horizontal line

    doc.line(90, 165, 510, 165); // horizontal line

    doc.line(90, 195, 510, 195); // horizontal line

    doc.line(90, 225, 510, 225); // horizontal line

    doc.line(120, 535, 260, 535); // horizontal line

    doc.line(350, 535, 475, 535); // horizontal line

    doc.setLineWidth(0.5);

    doc.setFontSize(10);
    doc.setTextColor(100);
    let COD_CREDITO = String(this.state.Liq_COD_CREDITO);
    let Liq_CREDITO_ANTERIOR = String(this.state.Liq_CREDITO_ANTERIOR);
    let Liq_COD_CLIENTE = String(this.state.Liq_COD_CLIENTE);

    doc.setTextColor(1);
    doc.text(95, 100, "NO.CREDITO :");
    doc.setTextColor(100);
    doc.text(165, 100, COD_CREDITO);

    doc.setTextColor(1);
    doc.text(210, 100, "CREDITO ANTERIOR :");
    doc.setTextColor(100);
    doc.text(320, 100, Liq_CREDITO_ANTERIOR);

    doc.setTextColor(1);
    doc.text(370, 100, "FECHA :");
    doc.setTextColor(100);
    doc.text(420, 100, this.state.Liq_FECHA);

    doc.setTextColor(1);
    doc.text(95, 130, "NO.CLIENTE  :");
    doc.setTextColor(100);
    doc.text(165, 130, Liq_COD_CLIENTE);

    doc.setTextColor(1);
    doc.text(210, 130, "CEDULA :");
    doc.setTextColor(100);
    doc.text(260, 130, this.state.Liq_CEDULA);

    doc.setTextColor(1);
    doc.text(95, 160, "NOMBRE        :");
    doc.setTextColor(100);
    doc.text(165, 160, this.state.Liq_NOMBRE);

    doc.setTextColor(1);
    doc.text(95, 190, "ANALISTA      :");
    doc.setTextColor(100);
    doc.text(165, 190, this.state.Liq_ANALISTA);

    let Liq_TIC = String(this.state.Liq_TIC);
    let Liq_Plazo = String(this.state.Liq_Plazo);
    let Liq_ValorCuota = String(this.state.Liq_ValorCuota);
    doc.setTextColor(1);
    doc.text(95, 220, "TIC                 :");
    doc.setTextColor(100);
    doc.text(165, 220, Liq_TIC);

    doc.setTextColor(1);
    doc.text(210, 220, "PLAZO :");
    doc.setTextColor(100);
    doc.text(260, 220, Liq_Plazo);

    doc.setTextColor(1);
    doc.text(370, 220, "V.CUOTA :");
    doc.setTextColor(100);
    doc.text(430, 220, Liq_ValorCuota);

    doc.setTextColor(1);
    doc.text(95, 250, "FECHA VENCIMIENTO :");
    doc.setTextColor(100);
    doc.text(215, 250, this.state.Liq_Vencimiento);

    let ArrayS = [];
    ArrayS = this.state.SaldoArray;

    doc.autoTable({
      columns: [
        { dataKey: "MONTO", header: "MONTO" },
        { dataKey: "SALDO_PENDIENTE", header: "SALDO PENDIENTE" },
        { dataKey: "GASTO", header: this.state.MANEJO_GASTO },
        { dataKey: "SALDO", header: "TOTAL" },
      ],
      body: ArrayS,
      showHead: "firstPage",
      headStyles: { halign: "center", fillColor: [0, 0, 0] },
      bodyStyles: { halign: "right" },
      styles: { fontSize: 10 },
      margin: { left: 30, top: 290 },
      theme: "grid",
      tableWidth: "auto",
    });

    doc.setTextColor(1);
    doc.text(130, 550, "ENTREGUE CONFORME");

    let DATA = [];
    DATA = this.state.DATOS_CLIENTE;

    doc.autoTable({
      columns: [{ dataKey: "DATOS" }],
      body: DATA,
      startY: 540,
      showHead: "firstPage",
      bodyStyles: { halign: "center" },
      styles: { cellPadding: 0.3, fontSize: 10 },
      margin: { left: 255, top: 540 },
      theme: "plain",
      tableWidth: "auto",
    });

    doc.save("LIQUIDACION DE CREDITO.pdf");

    // TimerMixin.setTimeout(() => {
      if (this.state.TOTAL_RECIBIDO_R > 0) {
        this.jsPdfGenerator();
      } else {
        if (this.state.TOTAL_RECIBIDO_CL > 0) {
          this.ReciboCancelacion();
        } else {
          window.location.reload();
        }
      }
    // }, 600);
  }

  render() {
    return (
      // return !this.props.isGeolocationAvailable ? (
      //   <div>Este navegador no soporta la geolocalizacion</div>
      // ) : !this.props.isGeolocationEnabled ? (
      //   <div>Por favor activa la UBICACION(GPS)</div>
      // ) : this.props.coords ? (
      <form>
        <div style={{ visibility: this.state.VALIDACION }}>
          {" "}
          <SisprestamosNavbar />
          <div className="container mt-5">
            <div className="shadow-lg p-3 mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
              <div className="row">
                <div className="col-md-3">
                  <div style={{ textAlign: "left" }} htmlFor="COD_EMPRESA_B">
                    EMPRESA
                  </div>
                  <select
                    name="COD_EMPRESA_B"
                    className="form-control text-center"
                    value={this.state.COD_EMPRESA_B}
                    onChange={this.BUSQUEDA}
                    disabled={this.state.BTN_APL === "false" ? true : false}
                  >
                    <option></option>
                    {this.state.EmpresaA.map((item) => (
                      <option key={item.COD_EMPRESA} value={item.COD_EMPRESA}>
                        {item.NOMBRE_EMPRESA}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <div style={{ textAlign: "left" }} htmlFor="COD_SUCURSAL_B">
                    SUCURSAL
                  </div>
                  <select
                    name="COD_SUCURSAL_B"
                    className="form-control text-center"
                    value={this.state.COD_SUCURSAL_B}
                    onChange={this.BUSQUEDA}
                    placeholder="Campo obligatorio"
                    disabled={this.state.BTN_APL === "false" ? true : false}
                  >
                    <option></option>
                    {this.state.SucursalA.map((item) => (
                      <option key={item.COD_SUCURSAL} value={item.COD_SUCURSAL}>
                        {item.NOMBRE_SUCURSAL}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <div style={{ textAlign: "left" }} htmlFor="CLIENTE">
                    CREDITO
                  </div>
                  <input
                    list="data"
                    name="CLIENTE"
                    className="form-control text-center"
                    value={this.state.CLIENTE}
                    onChange={this.onChange}
                    autoComplete="off"
                    disabled={this.state.BTN_APL === "false" ? true : false}
                  />
                  <datalist id="data">
                    <option></option>
                    {this.state.ClienteA.map((item) => (
                      <option key={item.COD_CREDITO} value={item.COD_CREDITO}>
                        {item.NOMBRE1}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className="col-md-3 p-3">
                  <button
                    type="button"
                    onClick={this.obtenerDatos}
                    className="btn btn-outline-success btn-lg col-md-12"
                    disabled={this.state.BTN_APL === "false" ? true : false}
                  >
                    BUSCAR
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="container mt-2">
            <div className="row">
              <div className="shadow-lg mb-3 bg-white rounded  col-md-12 mx-auto border rounded border-dark">
                <div className="container p-3">
                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                      <h1 className="h3 mb-0 font-weight-normal p-2  text-center">
                        REGISTRO DEL CREDITO
                      </h1>
                    </div>
                  </div>
                  {/*  */}
                  <div className="d-flex flex-column  bd-highlight mb-3 jumbotrom p-3 mb-0 bg-light text-dark border rounded border-dark">
                    {/*  */}
                    {/* <div className="form-row">
                       
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                          EMPRESA{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                          -
                          </font>
                          : {this.state.NOMBRE_EMPRESA}
                        </div>

                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                          SUCURSAL{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            -
                          </font>
                          : {this.state.NOMBRE_SUCURSAL_DATO}
                        </div>
    
                        <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                          FECHA CREDITO{" "}
                          <font size="1" color="white" style={{ visibility: "hidden" }}>
                            -
                          </font>
                          : {this.state.FECHA_CREDITO_formato}
                        </div>
                      
                      </div> */}
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-8">
                        CLIENTE{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.NOMBRE1}
                      </div>
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                        FECHA CREDITO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.FECHA_CREDITO_formato}
                      </div>
                      {/* <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                         CEDULA{" "}
                         <font size="1" color="white" style={{ visibility: "hidden" }}>
                           -
                         </font>
                         : {this.state.CEDULA}
                       </div> */}
                    </div>
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                        CREDITO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.COD_CREDITO}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        TIPO CREDITO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.TIPO_CREDITO}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        MONEDA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.NOMBRE_MONEDA}
                      </div>
                    </div>
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                        PLAZO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.PLAZO_APROBADO}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        TIC{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.TIC_APROBADO}
                      </div>
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        TIM{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.TIM_APROBADO}
                      </div>
                    </div>

                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group   text-left col-md-4">
                        ANALISTA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.ANALISTA}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        PROMOTOR{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.PROMOTOR}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left  col-md-4">
                        MONTO A DESEMBOLSAR{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.MONTO_TOTAL_APROBADO}
                      </div>
                    </div>
                    {/*  */}

                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                        {this.state.MANEJO_GASTO}{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.GASTO_DESEMBOLSO}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        CREDITO ANTERIOR{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.CREDITO_ANTERIOR}
                      </div>
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        SALDO ANTERIOR{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.SALDO_ANTERIOR}
                      </div>
                    </div>
                    {/*  */}
                    {/*  */}
                    <div className="form-row">
                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group  text-left col-md-4">
                        FRECUENCIA{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.FREC_PAG_APROBADO}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4">
                        DESEMBOLSO{" "}
                        <font
                          size="1"
                          color="white"
                          style={{ visibility: "hidden" }}
                        >
                          -
                        </font>
                        : {this.state.TIPO_DESEMBOLSO_APROBADO}
                      </div>

                      <div className="p-2 flex-fill bd-highlight border-bottom border-secondary font-weight-bold form-group text-left col-md-4"></div>
                    </div>

                    {/*  */}
                  </div>
                </div>
                {/*  */}
                <div className="container ">
                  <div className="row">
                  <div className="col-md-2">
                      <div className="form-check form-check-inline p-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox1"
                          onChange={this.onChange_check_mov}
                          disabled={
                            this.state.BTN_APL === "false" ? true : false
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox1"
                        >
                          <b>(INCLUIR SABADO)</b>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                    <div style={{ textAlign: "left" }} htmlFor="fecha_1">
                        <b>FECHA CREDITO</b>
                      </div>
                      <DatePicker
                        type="date"
                        dateFormat="dd '/' M '/' yyyy"
                        selected={this.state.FECHA}
                        onChange={this.onChange3}
                        locale="es"
                        className="form-control text-center"
                        disabled={
                          this.state.ValidacionFA === false ? true : false
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <div style={{ textAlign: "left" }} htmlFor="fecha_1">
                        <b>PRIMER FECHA PAGO</b>
                      </div>
                      <DatePicker
                        type="date"
                        dateFormat="dd '/' M '/' yyyy"
                        selected={this.state.fecha_1}
                        onChange={this.onChange2}
                        locale="es"
                        className="form-control text-center"
                        disabled={this.state.BTN_APL === "false" ? true : false}
                      />
                    </div>
                    <div className="col-md-2">
                      <div style={{ textAlign: "left" }} htmlFor="TI">
                        <b>TI</b>
                      </div>
                      <input
                        type="TI"
                        className="form-control text-right"
                        name="TI"
                        value={this.state.TI}
                        onChange={this.onChange}
                        autoComplete="off"
                        disabled={this.state.BTN_APL === "false" ? true : false}
                      />
                    </div>

                    <div className="col-md-2">
                      <div
                        style={{ textAlign: "left" }}
                        htmlFor="VALOR_TIPO_CAMBIO"
                      >
                        <b>TIPO DE CAMBIO</b>
                      </div>
                      <input
                        type="VALOR_TIPO_CAMBIO"
                        className="form-control text-right"
                        name="VALOR_TIPO_CAMBIO"
                        value={this.state.VALOR_TIPO_CAMBIO}
                        onChange={this.onChange}
                        disabled="disabled"
                      />
                    </div>
                    </div>
                </div>
                {/*  */}

                <div className="container ">
                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-4 p-3">
                      <button
                        type="button"
                        onClick={this.RegistroCredito}
                        className="btn btn-outline-secondary btn-lg col-md-12"
                        disabled={this.state.BTN_APL === "false" ? true : false}
                      >
                        REGISTRAR
                      </button>
                    </div>
                    <div className="col-md-4 p-3">
                      <button
                        type="button"
                        onClick={this.CancelacionCredito}
                        className="btn btn-outline-danger btn-lg col-md-12"
                        disabled={this.state.SALDO_ANT_REST > 0 ? false : true}
                      >
                        CANCELAR SALDO
                      </button>
                    </div>
                    {/* <div className="col-md-4 p-3">
                      <button
                        type="button"
                        onClick={this.Liquidacion}
                        className="btn btn-outline-danger btn-lg col-md-12"
                       
                      >
                         SALDO
                      </button>
                    </div> */}
                  </div>
                </div>
                {/*  */}
              </div>
            </div>
          </div>
          {/*  */}
        </div>
        {/*  */}
        {/* <div style={{ visibility: "hidden", position: "fixed" }}>
          <div className="jumbotron">
            <p className="lead text-center h3 mb-3 font-weight-normal">
              Consultar datos del Cliente
            </p>

            <div className="form-group">
              <label htmlFor="latitude">latitude</label>
              <input
                type="latitude"
                className="form-control"
                name="latitude"
                planceholder="Enter your Alias"
                value={this.props.coords.latitude}
                ref={this.busquedalatitude}
                onChange={this.onChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="longitude">longitude</label>
              <input
                type="longitude"
                className="form-control"
                name="longitude"
                planceholder="Enter your Alias"
                value={this.props.coords.longitude}
                ref={this.busquedalongitude}
                onChange={this.onChange}
              />
            </div>
          </div>
        </div> */}
        {/*  */}
      </form>
    );
  }
}
export default PlanpagoNivelado;
//     ) : (
//       <div>Getting the location data&hellip; </div>
//     );
//   }
// }

// export default geolocated({
//   positionOptions: {
//     enableHighAccuracy: false,
//   },
//   userDecisionTimeout: 10000,
// })(PlanpagoNivelado);
